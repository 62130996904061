import { SearchOutlined, ViewWeekOutlined } from '@mui/icons-material';
import { CustomMuiDataTableFilterList } from '../components/UI/MuiDatatable/CustomMuiDataTableFilterList/CustomMuiDataTableFilterList';
import CustomToolbarBtn from '../components/UI/MuiDatatable/CustomToolbarBtn/CustomToolbarBtn';
import { ReactComponent as ExportIcon } from '../shared/assets/icons/export-file.svg';

const muiComponents = (filters={}, setFilters, components = {}) => {
  // Search toolbar Button
  const SearchIcon = () => (
    <CustomToolbarBtn Icon={SearchOutlined} label='buttons.table.search' />
  );

  // Download toolbar Button
  const DownloadIcon = () => (
    <CustomToolbarBtn Icon={ExportIcon} label='buttons.table.export' />
  );

  // ViewColumn toolbar Button
  const ViewColumnIcon = () => (
    <CustomToolbarBtn Icon={ViewWeekOutlined} label='buttons.table.columns' />
  );

  // Custom filter list
  const CustomFilterList = () => (
    <CustomMuiDataTableFilterList filters={filters} setFilters={setFilters}/>
  );

  return {
    ...components,
    TableFilterList: CustomFilterList,
    icons: {
      SearchIcon,
      DownloadIcon,
      ViewColumnIcon,
    },
  };
};

export default muiComponents;
