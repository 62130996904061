import { memo, useState, useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Zoom, Autocomplete, CircularProgress, TextField } from '@mui/material';

import { getResponsiveSize } from '../../../utils';
import translateClientName from '../../../utils/TranslatedPossibleClientName';
import { AutocomletePoper } from './AutocomletePoper/AutocomletePoper';
import { autocompleteStyles } from './style';

function AutocompleteUi(props) {
  const [inputValue, setInputValue] = useState('');
  const {
    autocompleteValue,
    label,
    placeholder,
    onAutocompleteChangeHandler,
    onAutocompleteOpenHandler,
    onAutocompleteFilterOptionHandler,
    onAutocompleteRenderOptionsHandler,
    options,
    width = getResponsiveSize(428), // 428px
    id = '',
    loading = false,
    withTransition = false,
    className,
    selfSx,
  } = props;

  const { popperSx } = selfSx || {};

  const renderPopper = useCallback(
    (props) => {
      if (loading) return null;
      return <AutocomletePoper {...props} sx={popperSx} />;
    },
    [loading, popperSx],
  );

  const renderAutocomplete = useMemo(
    () => (
      <Autocomplete
        value={autocompleteValue}
        // open={true}
        className={className}
        onChange={onAutocompleteChangeHandler}
        PopperComponent={renderPopper}
        onOpen={onAutocompleteOpenHandler}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        filterOptions={onAutocompleteFilterOptionHandler}
        id={id}
        clearOnBlur
        selectOnFocus
        options={options}
        getOptionLabel={(option) => translateClientName(option.text)}
        isOptionEqualToValue={(option, value) =>
          value?.text === 'Add New CLient' ? true : option?.text === value?.text
        }
        ListboxProps={{ style: { overflow: 'auto' } }}
        loading={loading}
        key={(option) => option.value}
        sx={{
          ...autocompleteStyles.autocompleteBox,
          width,
          ...selfSx,
        }}
        popupIcon={<FontAwesomeIcon icon='chevron-down' />}
        renderOption={onAutocompleteRenderOptionsHandler}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    ),
    [
      autocompleteValue,
      className,
      id,
      inputValue,
      label,
      loading,
      onAutocompleteChangeHandler,
      onAutocompleteFilterOptionHandler,
      onAutocompleteOpenHandler,
      onAutocompleteRenderOptionsHandler,
      options,
      placeholder,
      renderPopper,
      selfSx,
      width,
    ],
  );

  if (withTransition)
    return (
      <Zoom
        in={true}
        timeout={{ enter: 300, exit: 0 }}
        mountOnEnter
        unmountOnExit
      >
        {renderAutocomplete}
      </Zoom>
    );

  return renderAutocomplete;
}

export default memo(AutocompleteUi);
