import { Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { clientActions } from '../../../../store/actions/client.actions';

import { ReactComponent as AnonymousClientIcon } from '../../../../shared/assets/icons/sessions/anonymous-session.svg';
import { ReactComponent as ClientIcon } from '../../../../shared/assets/icons/sessions/client-session.svg';

import { useActions } from '../../../../hooks/useActions';
import { ANON_CLIENT_IDENTIFIER } from '../../../../utils/config';
import translateClientName from '../../../../utils/TranslatedPossibleClientName';
import AutocompleteUi from '../../../UI/Autocomplete/Autocomplete';
import CheckboxCard from '../../../UI/CheckboxCard/CheckboxCard';
import { WizardContentBox } from '../../../UI/WizardContentBox/WizardContentBox';
import { useFetchAction } from '../hooks/useFetchAction';

import { teamActions } from '../../../../store/actions/team.actions';
import { InputUi } from '../../../UI/Input/Input';
import { useWizardClientError } from '../hooks/useWizardClientError';
import { clientStepStyles } from './style';
import { useFetchSessionType } from '../hooks/useFetchSessionType';

const sessionTypes = {
  ANONYMOUS: 'ANONYMOUS',
  CLIENT: 'CLIENT',
};

export const sessionsData = {
  ANONYMOUS: {
    type: sessionTypes.ANONYMOUS,
    checked: false,
    title: 'create-treatment-wizard.select-client.session-type.ANONYMOUS.title',
    desc: 'create-treatment-wizard.select-client.session-type.ANONYMOUS.description',
    Icon: <AnonymousClientIcon />,
  },
  CLIENT: {
    type: sessionTypes.CLIENT,
    checked: false,
    title: 'create-treatment-wizard.select-client.session-type.CLIENT.title',
    desc: 'create-treatment-wizard.select-client.session-type.CLIENT.description',
    Icon: <ClientIcon />,
  },
};

const contentBoxes = {
  auotocomplete: false,
  input: false,
};

function SelectClient(props) {
  const {
    authentication,
    getAllClients,
    clients: clientSelector,
    teams,
    createClient,
    getAllTeams,
    t,
  } = props;
  const { clientSelect } = clientStepStyles;
  const { practitioner: therapist } = authentication;
  const { clients, isLoading: clientsIsLoading } = clientSelector;

  const { canGoNext, selectedClient, isAnonymousSession, anonymousClient } =
    useSelector((state) => state.createTreatmentWizard);
  const { setClient, setNextButton, setAnonymousSession } = useActions();
  const filter = createFilterOptions();

  const [sessionType, setSessionType] = React.useState(sessionsData);
  const [contentBoxActive, setContentBoxActive] = React.useState(contentBoxes);
  const [clientNumber, setClientNumber] = React.useState('');
  const [autocompleteValue, setAutocompleteValue] = React.useState(null);

  /**
   * Handle Autocomplete change event
   * (option selection, clear value, close...)
   * @param event
   * @param value selected option value
   */
  const handleAutocompleteChange = useCallback(
    (event, value) => {
      setClientNumber(() => '');
      if (!value && selectedClient) {
        setClient(null);
        if (canGoNext) setNextButton(false);
      }
      setClient(clients.find((client) => client.id === value?.value));
      setAutocompleteValue(value);
      if (!canGoNext) setNextButton(true);
    },
    [
      setClient,
      setNextButton,
      setAutocompleteValue,
      setClientNumber,
      selectedClient,
      canGoNext,
      clients,
    ],
  );

  /**
   * Handle Autocomplete`s options render
   * @param optionProps option props
   * @param obj option text and value
   * @returns ReactNode for each Autocomplete option
   */
  const handleAutocompleteRenderOptions = useCallback(
    (optionProps, { text, value }) => (
      <li {...optionProps} key={value}>
        {translateClientName(text)}
      </li>
    ),
    [],
  );

  /**
   * Handle Autocomplete open event
   * @param event
   */
  const handleAutocompleteOpen = useCallback(() => {
    setContentBoxActive(() => ({ input: false, auotocomplete: true }));
  }, [setContentBoxActive]);

  /**
   * Handle Autocomplete serach event
   * @param options Autocomplete options
   * @param params search string
   * @returns filtered Autocomplete options by search
   */
  const handleAutocompleteFilterOption = useCallback(
    (options, params) => {
      const filtered = filter(options, params);
      return filtered;
    },
    [filter],
  );

  /**
   * Handle clients input change event
   * @param value clients input value
   */
  const handleClientInputChange = useCallback(
    (value) => {
      setClientNumber(() => value);
    },
    [setClientNumber],
  );

  /**
   * Handle focus on client input and set it's content box as active
   */
  const handleClientInputFocus = useCallback(() => {
    setContentBoxActive(() => ({ input: true, auotocomplete: false }));
  }, [setContentBoxActive]);

  /**
   * Handle client input btn click and create a new client
   */
  const onCreateClient = useCallback(async () => {
    if (clientNumber.trim()) {
      const actualTeam = teams?.teams[0] || [][0];
      const createdClient = await createClient({
        name: clientNumber,
        teamId: actualTeam.id,
      });
      setClient(clients.find((client) => client.id === createdClient?.id));
      setAutocompleteValue({
        text: createdClient.name,
        value: createdClient.id,
      });
    }
  }, [
    clientNumber,
    createClient,
    setClient,
    setAutocompleteValue,
    clients,
    teams,
  ]);

  /**
   * Client's Autocomplete option filter
   * @returns filtered clients array
   */
  const sortOptionsByLastTreatment = useCallback(
    () =>
      clients
        .filter((client) => client.name !== ANON_CLIENT_IDENTIFIER)
        .sort((a, b) => {
          if (!a.lastTreatment)
            a.lastTreatment = new Date(1, 1, 1900).toDateString();
          else if (!b.lastTreatment)
            b.lastTreatment = new Date(1, 1, 1900).toDateString();
          return (
            Number(new Date(b?.lastTreatment).getTime()) -
            Number(new Date(a?.lastTreatment).getTime())
          );
        })
        .map((client) => ({ text: client.name, value: client.id })),
    [clients],
  );

  /**
   * Handle session changed
   * @param checkbox selected checkbox data
   */
  const handleSessionTypeSelect = useCallback(
    (checkbox) => {
      switch (checkbox.type) {
        case sessionTypes.ANONYMOUS:
          if (!isAnonymousSession) setAnonymousSession(true);
          setSessionType((prev) => ({
            ANONYMOUS: { ...prev.ANONYMOUS, checked: true },
            CLIENT: { ...prev.CLIENT, checked: false },
          }));
          setClient(anonymousClient || { name: ANON_CLIENT_IDENTIFIER });
          break;
        case sessionTypes.CLIENT:
          setClient(null);
          if (isAnonymousSession) setAnonymousSession(false);
          setSessionType((prev) => ({
            ANONYMOUS: { ...prev.ANONYMOUS, checked: false },
            CLIENT: { ...prev.CLIENT, checked: true },
          }));
          break;
      }
    },
    [isAnonymousSession, setAnonymousSession, setClient, anonymousClient],
  );

  /**
   * Tracks does client session selected
   */
  const isClientSession = useMemo(
    () => !!sessionType.CLIENT.checked,
    [sessionType],
  );

  /**
   * Sets session type and selected client if it was previously selected
   * @param setSessionType sectionType setter
   * @param setAutocompleteValue autocompleteValue setter
   */
  useFetchSessionType(setSessionType, setAutocompleteValue);

  /**
   * Fetch all teams
   */
  useFetchAction(getAllTeams);

  /**
   * Fetch all clients
   */
  useFetchAction(
    getAllClients,
    `filter[where][organizationId]=${therapist.organizationId}`,
  );

  /**
   * Set error if client isn't selected
   */
  useWizardClientError(sessionType.ANONYMOUS.checked);

  /**
   * Tracks the use selection to set Next button
   */
  useEffect(() => {
    if (isAnonymousSession) setNextButton(true);
    else if (
      !isAnonymousSession &&
      selectedClient &&
      selectedClient.name !== ANON_CLIENT_IDENTIFIER
    ) {
      setNextButton(true);
    } else setNextButton(false);

    return () => {
      setNextButton(false);
    };
  }, [selectedClient, isAnonymousSession, setNextButton]);

  return (
    <Box sx={clientSelect} className='step-container'>
      <WizardContentBox active>
        <Typography variant='h3' className='wizard-box-title'>
          {t('create-treatment-wizard.select-client.session-type.title')}
        </Typography>
        <Box className='checkbox-card-wrapper'>
          {sessionType &&
            Object.values(sessionType).map((session) => (
              <CheckboxCard
                key={session.type}
                checkboxItem={session}
                onCardSelectHandler={handleSessionTypeSelect}
              />
            ))}
        </Box>
      </WizardContentBox>
      <WizardContentBox
        disabled={!isClientSession}
        active={contentBoxActive.auotocomplete}
      >
        <Typography variant='h3' className='wizard-box-title'>
          {t('create-treatment-wizard.select-client.client-dropdown.title')}
        </Typography>
        <AutocompleteUi
          label={t(
            'create-treatment-wizard.select-client.client-dropdown.label',
          )}
          placeholder={t(
            'create-treatment-wizard.select-client.client-dropdown.placeholder',
          )}
          autocompleteValue={autocompleteValue}
          onAutocompleteChangeHandler={handleAutocompleteChange}
          onAutocompleteOpenHandler={handleAutocompleteOpen}
          onAutocompleteFilterOptionHandler={handleAutocompleteFilterOption}
          onAutocompleteRenderOptionsHandler={handleAutocompleteRenderOptions}
          loading={clientsIsLoading}
          options={sortOptionsByLastTreatment()}
        />
      </WizardContentBox>
      <WizardContentBox
        disabled={!isClientSession}
        active={contentBoxActive.input}
      >
        <Typography variant='h3' className='wizard-box-title'>
          {t('create-treatment-wizard.select-client.create-client-input.title')}
        </Typography>
        <InputUi
          className='add-client-input'
          label={t(
            'create-treatment-wizard.select-client.create-client-input.label',
          )}
          variant='outlined'
          name='add-client'
          value={clientNumber}
          onInputChangeHandler={handleClientInputChange}
          onInputFocusHandler={handleClientInputFocus}
          endButton
          btnText={t('buttons.treatment-wizard.add')}
          btnDisabled={clientNumber.length <= 0}
          btnClickHandler={onCreateClient}
        />
      </WizardContentBox>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllClients: clientActions.getAllClients,
  createClient: clientActions.createClient,
  getAllTeams: teamActions.getAllTeamsWithoutMeta,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(memo(SelectClient)),
);
