import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomSlider from '../CustomSlider';
import CustomToggle from '../CustomToggle';
import ClientsSelect from '../Clients/ClientsSelect';
import DevicesSelect from '../Devices/DevicesSelect';
import CreateClientForm from '../Clients/CreateClientForm';

import { treatmentActions } from '../../store/actions/treatment.actions';
import { treatmentRemoteActions } from '../../store/actions/treatment-remote.actions';
import { productsService } from '../../services/products';
import CustomDropdown from '../CustomDropdown';
import Loader from '../Loader';
import { clientService } from '../../services/clients';

import {
  getAllSelectedValuesByVariable,
  isJsonString,
  setDefaultValuesForVariables,
} from '../../utils/treatment-helpers';
import { FormModal } from '../UI/FormModal/FormModal';
import SelectComponent from '../SelectComponent';
import { productActions } from '../../store/actions/product.actions';
import { ANON_CLIENT_IDENTIFIER } from '../../utils/config';
import { userActions } from '../../store/actions/user.actions';
import { useValidateAccessToken } from '../../hooks/useValidateAccessToken';

const CreateTreatmentForm = function ({
  i18n,
  // reducers
  authentication,
  products: productsReducer,
  treatments: treatmentsReducer,
  clients: clientsReducer,
  // actions
  createTreatmentRemote,
  createTreatmentConfiguration,
  getAllProductConfigurations,
  clientId,
  productId,
  quickStart,
  logout,
}) {
  const { isValidatingToken } = useValidateAccessToken(logout);

  const emptyErrorState = {
    productError: null,
    deviceError: null,
    clientError: null,
  };
  const [isCreatingClient, setIsCreatingClient] = useState(false);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [variables, setVariables] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState(emptyErrorState);
  const [isAdvancedConfigOpen, setIsAdvancedConfigOpen] = useState(false);
  const { practitioner: therapist, teams } = authentication;
  const { isLoading } = treatmentsReducer;
  const { products, isLoading: productsIsLoading } = productsReducer;
  const { clients, isLoading: clientsIsLoading } = clientsReducer;

  const selectedClientId = (selectedClient || {}).value || clientId;

  const foundAnonClient = useMemo(() => {
    if (clientsIsLoading || clientsIsLoading === undefined) {
      return null;
    }

    const foundClient = clients.find((val) =>
      val?.name.includes(ANON_CLIENT_IDENTIFIER),
    );
    if (!foundClient) {
      return undefined;
    }
    return { value: foundClient.id, label: foundClient.name };
  }, [clientsIsLoading, clients]);

  const createAnonymousClient = async () => {
    try {
      const response = await clientService.createClient({
        name: ANON_CLIENT_IDENTIFIER,
        teamId: teams[0],
      });
      const { data: client } = response || {};
      return client;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (foundAnonClient === undefined) {
      createAnonymousClient();
    } else if (foundAnonClient) {
      setSelectedClient(foundAnonClient);
    }
  }, [foundAnonClient]);

  useEffect(() => {
    // initialise all reducer selects
    getAllProductConfigurations();
  }, []);

  const handleOpenAdvanced = () => {
    setIsAdvancedConfigOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (selectedProduct) {
      setVariables(setDefaultValuesForVariables(selectedProduct.variables));
      setFetchingTemplates(true);
      productsService
        .getConfigurationTemplatesOfProductId(selectedProduct.value)
        .then((result) => {
          setTemplates(result.data);
          setFetchingTemplates(false);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (products.length > 0 && !selectedProduct) {
      const product = {};
      if (!productId) {
        product.value = products[0].id;
        product.label = products[0].name;
        product.variables = products[0].variables;
        product.device_settings_needed = products[0].device_settings_needed;
      } else {
        const { id, name, variables, device_settings_needed } = products.find(
          (item) => item.id === productId,
        );
        product.value = id;
        product.label = name;
        product.variables = variables;
        product.device_settings_needed = device_settings_needed;
      }
      setVariables(setDefaultValuesForVariables(product.variables));
      setSelectedProduct(product);
    }
  }, [products, productId]);

  const clearState = () => {
    setFetchingTemplates(false);
    setSelectedProduct(null);
    setSelectedDevice(null);
    setSelectedTemplate(null);
    setVariables([]);
    setErrors(emptyErrorState);
    setIsSubmitted(false);
  };

  const handleSelect = (value) => {
    if (!value) {
      clearState();
      return;
    }
    window.location.search = `?productId=${value.value}`;
  };

  useEffect(() => {
    if (selectedProduct && selectedTemplate) {
      const { configurationdefinitionId } = selectedTemplate.template || {};
      if (configurationdefinitionId !== selectedProduct.value) {
        setSelectedTemplate(null);
      }
    }
  }, [selectedProduct, selectedTemplate, templates]);

  useEffect(() => {
    if (
      selectedTemplate &&
      selectedTemplate.template &&
      selectedProduct.device_settings_needed
    ) {
      const { template } = selectedTemplate || {};

      const { values: templateValues } = template || { templateValues: [] };

      const newVariables = [];
      // const possibleVariables = variables;

      templateValues.forEach((templateValue) => {
        const foundItem = variables.find(
          (item) => item.name === templateValue.name,
        );
        if (!foundItem) {
          return;
        }
        // const indexOf = variables.indexOf(foundItem);
        foundItem.selectedValue = JSON.parse(
          JSON.stringify(templateValue.value),
        );
        newVariables.push(foundItem);
        // variables[indexOf] = foundItem;
      });
      setVariables(newVariables);
    }
  }, [selectedTemplate, selectedProduct]);

  const handleTemplateSelect = (value) => {
    setSelectedTemplate(value);
  };

  const handleClientSelect = (value) => {
    setSelectedClient(value);
  };

  const handleDeviceSelect = (value) => {
    setSelectedDevice(value);
  };

  const handleVariableValueChanged = (value) => {
    if (!value || !value.variable) {
      return;
    }
    const newVariables = [...variables];
    // const possibleVariables = variables;
    const { variable, value: newValue } = value;

    const foundItem = newVariables.find((item) => item.name === variable);

    if (!foundItem) {
      return;
    }
    const indexOf = newVariables.indexOf(foundItem);

    foundItem.selectedValue = isJsonString(newValue)
      ? JSON.parse(newValue)
      : newValue;
    newVariables[indexOf] = foundItem;
    setVariables(newVariables);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errorsToSet = {};
    const startsAt = new Date();
    const {
      device_settings_needed: deviceSettings,
      value: selectedProductIdValue,
    } = selectedProduct;
    const isEmdrPlus = selectedProductIdValue === 3;

    if (!selectedProduct) {
      errorsToSet.productError = i18n.t('errors.select.product');
    }
    const shouldShowDeviceSelect = deviceSettings || isEmdrPlus;

    // EMDR Remote doesn't have devices.
    if (shouldShowDeviceSelect) {
      if (!selectedDevice) {
        errorsToSet.deviceError = i18n.t('errors.select.device');
      }
    }

    if (!selectedClient && !clientId) {
      errorsToSet.clientError = i18n.t('errors.select.client');
    }

    if (Object.keys(errorsToSet).length > 0) {
      setErrors(errorsToSet);
      setIsSubmitted(true);
      return;
    }

    const selectedValues = getAllSelectedValuesByVariable(variables);
    const selectedProductId = (selectedProduct || {}).value;
    const selectedDeviceId = (selectedDevice || {}).value;
    const selectedTemplateId = (selectedTemplate || {}).value;

    if (!therapist) {
      // only therapists are allowed to create treatments;
      return;
    }
    const { organizationId } = therapist;

    if (!deviceSettings) {
      const data = {
        clientId: selectedClientId,
        configurationdefinitionId: selectedProductId,
        values: selectedValues,
        therapistId: therapist.id,
        templateId: selectedTemplateId,
        deviceId: isEmdrPlus ? selectedDeviceId : null,
        organizationId,
      };
      createTreatmentRemote(data);
    } else {
      const configuration = {
        values: selectedValues,
        configurationdefinitionId: selectedProductId,
        organizationId,
      };
      const data = {
        deviceId: selectedDeviceId,
        clientId: selectedClientId,
        therapistId: therapist.id,
        configurationdefinitionId: selectedProductId,
        templateId: selectedTemplateId,
        organizationId,
        starts_at: startsAt,
      };
      createTreatmentConfiguration(configuration, data);
    }
  };

  const getCustomOption = (variable) => {
    if (!variable) return null;
    const { type } = variable.ui_configuration;
    const variableTranslation = i18n.t(
      `configurations.${selectedProduct.label}.variables.${variable.name}`,
      variable.name,
    );
    switch (type) {
      case 'slider':
        return (
          <CustomSlider
            key={`option-${variable.name}${variable.selectedValue}`}
            selectedValue={variable.selectedValue}
            variable={variable}
            handleVariableValueChanged={handleVariableValueChanged}
            variableTranslation={variableTranslation}
          />
        );
      case 'toggle':
        return (
          <CustomToggle
            key={`option-${variable.name}${variable.selectedValue}`}
            selectedValue={variable.selectedValue}
            variable={variable}
            handleVariableValueChanged={handleVariableValueChanged}
            variableTranslation={variableTranslation}
          />
        );
      case 'dropdown':
        return (
          <CustomDropdown
            key={`option-${variable.name}${variable.selectedValue}`}
            selectedValue={variable.selectedValue}
            variable={variable}
            label={variableTranslation}
            values={variable.ui_configuration.values}
            handleChange={handleVariableValueChanged}
          />
        );
      default:
        return '';
    }
  };

  const renderVariables = useMemo(() => {
    if (!isAdvancedConfigOpen) {
      return null;
    }
    const groups = Object.values(
      variables.reduce((items, variable) => {
        const { group } = variable.ui_configuration;
        const noGroup = 'no group';
        if (group) {
          const groupTranslation = i18n.t(
            `configurations.${selectedProduct.label}.groups.${group}`,
            group,
          );
          items[group] = items[group] || {
            groupName: groupTranslation,
            variables: [],
          };
          items[group].variables.push(variable);
        } else {
          items[noGroup] = items[noGroup] || {
            groupName: '',
            variables: [],
          };
          items[noGroup].variables.push(variable);
        }
        return items;
      }, {}),
    );

    return groups.map((group) => (
      <React.Fragment key={group.groupName}>
        <div className='group-title'>{group.groupName}</div>
        <div className='option-group'>
          {group.variables.map((variable) => (
            <div className='option' key={`custom-option-${variable.name}`}>
              {getCustomOption(variable)}
            </div>
          ))}
        </div>
      </React.Fragment>
    ));
  }, [getCustomOption, i18n, selectedProduct, variables, isAdvancedConfigOpen]);

  const renderProductsSelect = useMemo(() => {
    const productOptions = products.map((item) => ({
      value: item.id,
      label: item.name,
      variables: item.variables,
      device_settings_needed: item.device_settings_needed,
    }));

    return (
      <SelectComponent
        label='products.title'
        loadingText='products.loading'
        selectText='products.select'
        options={productOptions}
        value={selectedProduct}
        isLoading={productsIsLoading}
        isClearable={false}
        handleChange={handleSelect}
        isFullWidth
      />
    );
  }, [products, selectedProduct, productsIsLoading, handleSelect]);

  const renderTemplateSelect = useMemo(() => {
    if (templates.length === 0 || !selectedProduct?.device_settings_needed) {
      return null;
    }

    const options = templates
      .map((item) => ({
        value: item.id,
        label: `${i18n.t(
          `devices.templates.${(item.name || '').toLowerCase()}`,
        )}`,
        template: item,
      }))
      .sort((a, b) => a.value - b.value);

    if (options.length > 0 && !selectedTemplate) {
      setSelectedTemplate(options[0]);
    }

    return (
      <SelectComponent
        label='devices.profile.title'
        loadingText='devices.profile.loading'
        selectText='devices.profile.select'
        options={options}
        value={selectedTemplate}
        isLoading={fetchingTemplates}
        isClearable={false}
        handleChange={handleTemplateSelect}
        isFullWidth
      />
    );
  }, [
    templates,
    selectedTemplate,
    fetchingTemplates,
    i18n.language,
    selectedProduct,
  ]);

  const {
    device_settings_needed: deviceSettingsNeeded,
    value: selectedProductId,
  } = selectedProduct || {};
  const { clientError, deviceError, productError } = errors;
  const isEmdrPlus = selectedProductId === 3;

  if (!therapist) {
    return null;
  }

  const filter = `filter[where][organizationId]=${therapist.organizationId}`;
  if (isLoading || isValidatingToken) {
    return (
      <div className='main-container-center'>
        <Loader />
      </div>
    );
  }
  const shouldShowDeviceSelect = deviceSettingsNeeded || isEmdrPlus;
  const handleCloseModal = () => {
    setIsCreatingClient(false);
  };

  return (
    <>
      {/* Create client modal */}
      {isCreatingClient && (
        <FormModal
          isOpen={isCreatingClient}
          title={i18n.t('buttons.create.client')}
          // setOpen={setModalIsOpen}
          onClose={handleCloseModal}
        >
          <CreateClientForm
            closeModal={handleCloseModal}
            onSuccess={(client) => {
              setTimeout(() => {
                setSelectedClient({ value: client.id, label: client.name });
              }, 100);
            }}
          />
        </FormModal>
      )}
      <form
        className={`create-form validate-form create-treatment ${
          isAdvancedConfigOpen ? 'scroll' : ''
        }`}
        onSubmit={handleSubmit}
      >
        <Grid container className='pt-2'>
          <Grid item mobile={12} tablet={6}>
            <FormControl fullWidth>
              {renderProductsSelect}
              {isSubmitted && productError && (
                <FormHelperText error>{productError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid className='pt-2' container spacing={1}>
          <Grid className='pt-2' item mobile={12} tablet={6}>
            <FormControl fullWidth>
              <ClientsSelect
                filter={filter}
                selectedId={selectedClientId}
                disabled={quickStart}
                handleChange={handleClientSelect}
              />
              {isSubmitted && clientError ? (
                <FormHelperText error>{clientError}</FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
          </Grid>
          <Grid
            className='pt-2'
            item
            mobile={12}
            tablet={1}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FormControl
              fullWidth
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className='' style={{ position: 'absolute' }}>
                {i18n.t('treatments.create.or')}
              </div>
            </FormControl>
          </Grid>
          <Grid
            className='pt-2'
            item
            mobile={12}
            tablet={3}
          >
            <button
              style={{ maxHeight: 36, marginTop: 24 }}
              type='button'
              onClick={() => setIsCreatingClient(true)}
              className='login100-form-btn mt-4'
            >
              <FontAwesomeIcon style={{ marginRight: 4 }} icon='plus' />
              {i18n.t('buttons.create.client-treatment')}
            </button>
          </Grid>
        </Grid>

        {shouldShowDeviceSelect && (
          <>
            <Grid className='pt-2' container spacing={1}>
              <Grid className='pt-2' item mobile={12} tablet={6}>
                <FormControl fullWidth>
                  <DevicesSelect
                    isFullWidth
                    selectedId={selectedDevice?.value}
                    handleChange={handleDeviceSelect}
                  />
                  {isSubmitted && deviceError && (
                    <FormHelperText error>{deviceError}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid className='pt-2' container spacing={1}>
              <Grid className='pt-2' item mobile={12} tablet={6}>
                {renderTemplateSelect}
              </Grid>
            </Grid>
          </>
        )}
        <button type='submit' className='login100-form-btn max-w-xs mt-4'>
          {i18n.t('buttons.create.treatment')}
        </button>
        {variables && variables.length > 0 && (
          <div className='flex flex-start items-center mt-8 pr-8 cursor-pointer select-none'>
            <span
              role='button'
              className='text-indigo text-xl'
              onClick={handleOpenAdvanced}
            >
              Advanced configuration
            </span>
            <div
              role='button'
              onClick={handleOpenAdvanced}
              className='rounded-full border border border-indigo w-7 h-7 flex items-center justify-center bg-indigo ml-2'
            >
              <FontAwesomeIcon
                icon={isAdvancedConfigOpen ? 'chevron-up' : 'chevron-down'}
              />
            </div>
          </div>
        )}
        <div className='options-container'>{renderVariables}</div>
      </form>
    </>
  );
};

const mapDispatchToProps = {
  createTreatmentConfiguration: treatmentActions.createTreatmentConfiguration,
  createTreatmentRemote: treatmentRemoteActions.createTreatmentRemote,
  getAllProductConfigurations: productActions.getAllProductConfigurations,
  logout: userActions.logout,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateTreatmentForm),
);
