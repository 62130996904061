import { history } from './history';

const authUrl = () => {
  let token = '';
  try {
    token = JSON.parse(localStorage.getItem('token'));
  } catch (e) {
    localStorage.clear();
    history.push('/login');
  }

  if (token && token.id) {
    return `?access_token=${token.id}`;
  }
  return '';
};

export { authUrl };
