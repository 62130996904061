import { memo } from 'react';
import { withTranslation  } from 'react-i18next';

import { Card, CardContent, Typography } from '@mui/material';

import { classNames } from '../../../../../utils';

const StepItem = (props) => {
  const { active, imgUrl, message, stepNumber, t } = props;

  const res = active ? '.gif' : '.png';
  const mods = {
    active,
  };

  return (
    <Card className={classNames('step-instruction', { ...mods })}>
      <img className='fade-in' alt='not-found' src={`${imgUrl}${res}`} />
      <CardContent>
        <Typography className='desc' variant='h3'>
          <span>{`${stepNumber} ${t('device-connection.step')} `}</span>
          {t(message)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default withTranslation ()(memo(StepItem));
