import { getI18n } from 'react-i18next';
import { treatmentService } from '../../services/treatments';
import { treatmentConstants } from '../constants/treatment.constants';
import { alertActions } from './alert.actions';
import { history } from '../../utils/history';

function getTreatmentsOverview(
  offset = 0,
  limit = 10,
  order,
  organizationId = null,
  searchQuery = '',
  anonymousInclude,
  deviceName,
  treatmentType,
  totalSessions,
  startDate,
) {
  return (dispatch) => {
    if (!organizationId) return;
    dispatch(request());
    return treatmentService
      .getTreatmentsOverview(
        offset,
        limit,
        order,
        organizationId,
        searchQuery,
        anonymousInclude,
        deviceName,
        treatmentType,
        totalSessions,
        startDate,
      )
      .then(
        (result) => {
          const { treatments = [], totalCount = 0 } = result?.data || {};
          if (treatments.length > 0) {
            let attributes = Object.keys(treatments[0]);
            attributes = [...new Set(attributes)];
            dispatch(success({ attributes, data: treatments, totalCount }));
          } else {
            dispatch(success({ attributes: [], data: treatments, totalCount }));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(getI18n().t('errors.get.treatments')));
        },
      );
  };

  function request() {
    return { type: treatmentConstants.GETALL_REQUEST };
  }

  function success(treatments) {
    return { type: treatmentConstants.GETALL_SUCCESS, treatments };
  }

  function failure(error) {
    return { type: treatmentConstants.GETALL_FAILURE, error };
  }
}

function createTreatmentConfiguration(config, deviceConfig) {
  return (dispatch) => {
    dispatch(request(config));
    return treatmentService.createTreatmentConfiguration(config).then(
      (result) => {
        const treatmentConfigId = result.data.id;
        const body = {
          ...deviceConfig,
          treatmentconfigurationId: treatmentConfigId,
        };
        return treatmentService
          .createDeviceTreatmentConfiguration(body)
          .then((deviceConfig) => {
            dispatch(success(deviceConfig.data));
          });
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(
          alertActions.error(getI18n().t('errors.add.treatment-config')),
        );
        return error;
      },
    );
  };

  function request(config) {
    return { type: treatmentConstants.CREATE_CONFIG_REQUEST, config };
  }

  function success(config) {
    return { type: treatmentConstants.CREATE_CONFIG_SUCCESS, config };
  }

  function failure(error) {
    return { type: treatmentConstants.CREATE_CONFIG_FAILURE, error };
  }
}

function patchTreatmentConfiguration(treatmentConfigurationId, config) {
  return (dispatch) => {
    dispatch(request(config));
    return treatmentService
      .patchDeviceTreatmentConfiguration(treatmentConfigurationId, config)
      .then((deviceConfig) => {
        dispatch(success(deviceConfig.data));
        history.push('admin/treatments');
      })
      .catch((error) => {
        dispatch(failure('TODO'));
      });
  };

  function request(config) {
    return { type: treatmentConstants.PATCH_CONFIG_REQUEST, config };
  }

  function success(config) {
    return { type: treatmentConstants.PATCH_CONFIG_SUCCESS, config };
  }

  function failure(error) {
    return { type: treatmentConstants.PATCH_CONFIG_FAILURE, error };
  }
}

function checkIfDeviceConfigurationIsReceived(id) {
  return (dispatch) => {
    dispatch(request(id));
    return treatmentService.checkConfigurationStatus(id).then(
      (result) => {
        dispatch(success(result.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(id) {
    return { type: treatmentConstants.GET_DEVICE_CONNECTION_STATUS, id };
  }

  function success(data) {
    return {
      type: treatmentConstants.GET_DEVICE_CONNECTION_STATUS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: treatmentConstants.GET_DEVICE_CONNECTION_STATUS_FAILURE,
      error,
    };
  }
}

function resetDeviceTreatmentConnectionId() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: treatmentConstants.RESET_DEVICE_TREATMENT_CONNECTION_ID };
  }
}

function useDefaultDeviceConfiguration() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: treatmentConstants.USE_DEFAULT_DEVICE_CONFIGURATION };
  }
}

function deleteDeviceTreatmentConfiguration(id) {
  return (dispatch) => {
    dispatch(request(id));
    return treatmentService.deleteDeviceTreatmentConfigurationById(id).then(
      () => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.delete.treatment')));
      },
    );
  };

  function request(treatment) {
    return { type: treatmentConstants.DELETE_REQUEST, treatment };
  }

  function success(id) {
    return { type: treatmentConstants.DELETE_SUCCESS, id };
  }

  function failure(error) {
    return { type: treatmentConstants.DELETE_FAILURE, error };
  }
}

function resetDeviceConnectionState() {
  function request() {
    return { type: treatmentConstants.RESET_STATE };
  }
  return (dispatch) => {
    dispatch(request());
  };
}

function clearTreatments() {
  return (dispatch) => {
    dispatch({
      type: treatmentConstants.CLEAR_TREATMENTS,
    });
  };
}

export const treatmentActions = {
  getTreatmentsOverview,
  createTreatmentConfiguration,
  checkIfDeviceConfigurationIsReceived,
  resetDeviceTreatmentConnectionId,
  patchTreatmentConfiguration,
  useDefaultDeviceConfiguration,
  deleteDeviceTreatmentConfiguration,
  resetDeviceConnectionState,
  clearTreatments,
};
