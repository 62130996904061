import { Box } from '@mui/material';
import { classNames } from '../../../utils';
import { wizardContentBoxStyles } from './style';

export const WizardContentBox = (props) => {
  const {
    children, active, disabled, className,
  } = props;
  const { wizardContentBox } = wizardContentBoxStyles;

  const mods = {
    disabled,
    active,
  };

  return (
    <Box sx={wizardContentBox} className={classNames(className, mods)}>
      {children}
    </Box>
  );
};
