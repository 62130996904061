import { memo, useState } from 'react';
import {
  Box, Card, CardActionArea, CardContent, Typography,
} from '@mui/material';

import { withTranslation  } from 'react-i18next';

import { classNames } from '../../../utils';
import { selectionCardGroupStyles } from './style';
import { mapIconsNameToIcon } from '../../../utils/wizard-helper';

function SelectionCardGroup(props) {
  const {
    className, options, selectValue, onChangeHandler, name, t,
  } = props;
  const { selectionCardGroup } = selectionCardGroupStyles;
  const [displayValue, setDisplayValue] = useState(selectValue);

  const onChange = (value) => {
    setDisplayValue(value);
    onChangeHandler({
      variable: name,
      value,
    });
  };

  return (
    <Box sx={selectionCardGroup} className={classNames(className)}>
      {options?.map((option) => (
        <Card
          key={option?.value}
          className={classNames(option?.value, { active: displayValue === option?.value }, [
            'card',
          ])}
          onClick={() => onChange(option?.value)}
        >
          <CardActionArea>
            <Box className='icon'>{mapIconsNameToIcon(option.iconName)}</Box>
            <CardContent>
              <Typography className='desc' variant='h5'>
                {t(`${option?.content}`)}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Box>
  );
}

export default withTranslation ()(memo(SelectionCardGroup));
