import { keyframes } from '@emotion/react';
import { laptopRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

// Animation
export const zoomAnimation = keyframes`
  0% {
    transform: scale(0.82);
  }
  100% {
    transform: scale(1);
  }
`;

export const loaderStyles = {
  loader: {
    animation: `${zoomAnimation} 1.2s ease-in-out infinite alternate`,
    '& .loader': {
      width: getResponsiveSize(50), // 50px
      height: getResponsiveSize(50), // 50px
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .loader': {
        width: getResponsiveSize(38, laptopRatio), // 38px
        height: getResponsiveSize(38, laptopRatio), // 38px
      },
    },
  },
};
