import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import { Box } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';

import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { clientActions } from '../../store/actions/client.actions';

import { ReactComponent as DeleteIcon } from '../../shared/assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../shared/assets/icons/edit.svg';
import { ReactComponent as AddClientIcon } from '../../shared/assets/icons/sidenav/practitioners.svg';

import useDebounce from '../../hooks/useDebounce';
import { useTableFilterConfig } from '../../hooks/useTableFilterConfig';

import { classNames, getTransformedLogicTableFilterValue } from '../../utils';
import getMuiTheme from '../../utils/MUI-Style';
import tableConfig from '../../utils/MUI-table-config';
// eslint-disable-next-line import/no-cycle
import { checkRouteAccess } from '../../utils/check-access';
import muiComponents from '../../utils/MUI-table-components';
import translateClientName from '../../utils/TranslatedPossibleClientName';
import { muiTableLoadingSkeleton } from '../../utils/MUI-table-loading-skaleton';

import CreateClientForm from './CreateClientForm';
import { EditClientForm } from './EditClientForm';
import CustomDateSortHeader from '../Sessions/CustomDateSortHeader';
import { ModalUI } from '../UI/Modal/Modal';
import { FormModal } from '../UI/FormModal/FormModal';
import TableHeader from '../UI/TableHeader/TableHeader';
import { DataCell } from '../UI/MuiDatatable/DataCell/DataCell';
import { deleteConfirmationStyle } from '../UI/SimpleModalContent/style';
import { ClientAvatar } from '../UI/MuiDatatable/ClientAvatar/ClientAvatar';
import SimpleModalContent from '../UI/SimpleModalContent/SimpleModalContent';
import { CustomMuiDataTableFilter } from '../UI/MuiDatatable/CustomMuiDataTableFilter/CustomMuiDataTableFilter';
import { CustomMuiSessionsPerMonth } from '../UI/MuiDatatable/CustomMuiSessionsPerMonth/CustomMuiSessionsPerMonth';
import { CustomMuiDataTableBodyButton } from '../UI/MuiDatatable/CustomMuiDataTableBodyButton/CustomMuiDataTableBodyButton';

const ClientsTable = function ({
  authentication,
  clients: clientsReducer,
  location,
  getAllClients,
  clearClients,
  deleteClient,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { deleteConfirmation } = deleteConfirmationStyle;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentSort, setCurrentSort] = useState('default');
  const [currentSortType, setCurrentSortType] = useState('');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  const [filterOpen, setFilterOpen] = useState(false);
  const [clientFilters, setClientFilters] = useState();
  const tableRef = useRef();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [clientToEdit, setClientToEdit] = useState(null);
  const [clientToDelete, setClientToDelete] = useState(null);

  const [toRedirect, setToRedirect] = useState(false);

  const { clients, isLoading, totalClients } = clientsReducer;

  /**
   * Check does user has permision to be on this page
   */
  useEffect(() => {
    const { foundUser, roles, userIsAdmin } = authentication;
    checkRouteAccess(location.pathname, foundUser, roles, userIsAdmin, history);
  }, []);

  /**
   * Opens the filter's modal and grabs data for the filters
   */
  const onOpenFilterModal = useCallback(() => {
    setFilterOpen(true);
  }, []);

  /**
   * Generates filters
   */
  const clientFilter = useMemo(() => {
    let order = '';
    let direction = '';
    let searchQuery = '';
    let isAnonymous = false;

    const startScore = clientFilters?.start_score?.value;
    const endScore = clientFilters?.end_score?.value;
    const decrease = clientFilters?.decrease?.value;

    const totalSessions = getTransformedLogicTableFilterValue(
      clientFilters,
      'sessions',
    );
    const monthlySession = getTransformedLogicTableFilterValue(
      clientFilters,
      'monthly_sessions',
    );

    switch (currentSort) {
      case 'up':
        direction = 'DESC';
        break;
      case 'down':
        direction = 'ASC';
        break;
      default:
        break;
    }

    if (currentSort && currentSortType && direction !== 'default')
      order = `${currentSortType} ${direction}`;

    if (debouncedSearchTerm) {
      searchQuery = `${debouncedSearchTerm.toLowerCase()}`;
      if (t('treatments.anon-client-name').toLowerCase().includes(searchQuery))
        isAnonymous = true;
    }

    return {
      page: page * limit || 0,
      limit,
      order,
      searchQuery,
      isAnonymous,
      startScore,
      endScore,
      totalSessions,
      monthlySession,
      decrease,
    };
  }, [
    t,
    page,
    limit,
    currentSort,
    currentSortType,
    clientFilters,
    debouncedSearchTerm,
  ]);

  /**
   * Gets all Clients on filters changes
   */
  useEffect(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      isAnonymous,
      startScore,
      endScore,
      totalSessions,
      monthlySession,
      decrease,
    } = clientFilter;
    getAllClients(
      page,
      limit,
      order,
      searchQuery,
      isAnonymous,
      startScore,
      endScore,
      totalSessions,
      monthlySession,
      decrease,
    );
  }, [getAllClients, clientFilter]);

  /**
   * Refresh teams data
   */
  const refreshCurrentData = useCallback(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      isAnonymous,
      startScore,
      endScore,
      totalSessions,
      monthlySession,
      decrease,
    } = clientFilter;
    getAllClients(
      page,
      limit,
      order,
      searchQuery,
      isAnonymous,
      startScore,
      endScore,
      totalSessions,
      monthlySession,
      decrease,
    );
  }, [getAllClients, clientFilter]);

  /**
   * Open edit / create client modal
   */
  const openModal = () => {
    setModalIsOpen(true);
  };

  /**
   * Close edit / create client modal
   */
  const closeModal = useCallback(() => {
    if (toRedirect) {
      history.push('/admin/dashboard');
    }
    setModalIsOpen(false);
    refreshCurrentData();
    setClientToEdit(null);
  }, [history, refreshCurrentData, toRedirect]);

  /**
   * Open delete modal
   */
  const onOpenDeleteModal = useCallback((id) => {
    setClientToDelete(id);
    setDeleteModalIsOpen(true);
  }, []);

  /**
   * Close delete modal
   */
  const onCloseDeleteModal = useCallback(() => setDeleteModalIsOpen(false), []);

  /**
   * Open edit client modal
   * @param event native event
   * @param id client's to edit id
   */
  const editClient = useCallback(
    (event, id) => {
      event.stopPropagation();
      setClientToEdit(clients.find((item) => item.id === id));
      setModalIsOpen(true);
    },
    [clients],
  );

  /**
   * Delete client handler
   */
  const onDeleteClient = useCallback(async () => {
    await deleteClient(clientToDelete);
    refreshCurrentData();
    setDeleteModalIsOpen(false);
  }, [deleteClient, clientToDelete, refreshCurrentData]);

  /**
   * Sort handler
   * @param type sort property
   */
  const handleSort = useCallback(
    (type) => {
      let nextSort;
      if (currentSort === 'down') nextSort = 'up';
      else if (currentSort === 'up') nextSort = 'default';
      else if (currentSort === 'default') nextSort = 'down';

      setCurrentSortType(type);
      setCurrentSort(nextSort);
    },
    [currentSort],
  );

  /**
   * Init table's options
   */
  const options = useMemo(
    () => ({
      serverSide: true,
      page,
      rowsPerPage: limit,
      count: totalClients,
      onChangePage: (currentPage) => {
        setPage(currentPage);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        const { page: offset, limit } = clientFilter;
        const showedRows = offset + limit;
        const pagesLimit = Math.ceil(totalClients / numberOfRows);
        if (showedRows <= numberOfRows && page !== 0) {
          const newPage = Math.floor(numberOfRows / showedRows) - 1;
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        } else if (showedRows > numberOfRows) {
          const newPage = Math.floor(showedRows / numberOfRows);
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        }
        setLimit(numberOfRows);
      },
      onSearchChange: (text) => setSearchText(text),
      searchText,
      searchAlwaysOpen: true,
    }),
    [page, limit, totalClients, searchText, clientFilter],
  );

  /**
   * Generates table rows
   * @param dataToShow server data to transform
   */
  const createViewItemListForClients = (dataToShow) =>
    dataToShow.map((item) => {
      const { first, last, sum, monthSum, name, id } = item;
      const clientName = name || item.Naam;
      return {
        id: <DataCell>{id}</DataCell>,
        [t('forms.client_name')]: (
          <DataCell>
            <ClientAvatar
              clientName={translateClientName(clientName)}
              seedId={id}
            />
          </DataCell>
        ),
        // [t('forms.begin_score')]: <DataCell type='numerics'>{first}</DataCell>,
        [t('forms.begin_score')]: first || '-',
        [t('forms.end_score')]: <DataCell type='numerics'>{last}</DataCell>,
        [t('forms.delta_client')]: (
          <DataCell type='numerics'>{last - first}</DataCell>
        ),
        [t('forms.total.sessions_this_month')]: (
          <CustomMuiSessionsPerMonth sum={sum || 0} monthSum={monthSum || 0} />
        ),
      };
    });

  /**
   * Generates export CSV data
   * @param data server data to transform
   */
  const generateExportData = (data) =>
    data.map((item) => {
      const { first, last, sum, monthSum, name, id } = item;
      const clientName = name || item.Naam;
      return {
        id,
        [t('forms.client_name')]: translateClientName(clientName),
        [t('forms.begin_score')]: first || 'null',
        [t('forms.end_score')]: last || 'null',
        [t('forms.delta_client')]: (last || 0) - (first || 0),
        [t('forms.total.sessions_this_month')]: `${sum || 0} (${
          monthSum || 0
        })`,
      };
    });

  /**
   * Render talbe haed
   */
  const renderHead = useCallback(
    (mappedSortTypes, key) => {
      const sortType = mappedSortTypes[key];
      return (
        <CustomDateSortHeader
          key={`sort-header-${key}`}
          currentSort={currentSortType === sortType ? currentSort : 'default'}
          label={key}
          type={sortType}
          handleSort={handleSort}
        />
      );
    },
    [currentSort, currentSortType, handleSort],
  );

  /**
   * Remder talbe actions
   */
  const renderActions = useCallback(
    (value, tableMeta, updateValue) => (
      <CustomMuiDataTableBodyButton
        firstButtonIcon={<EditIcon />}
        secondButtonIcon={<DeleteIcon />}
        onPrimaryActionButton={(e) =>
          editClient(e, tableMeta.rowData[0]?.props?.children)
        }
        onSecondaryActionButton={() =>
          onOpenDeleteModal(tableMeta.rowData[0]?.props?.children)
        }
      />
    ),
    [editClient, onOpenDeleteModal],
  );

  /**
   * Generates table attributes
   */
  const createTableAttributes = useCallback(
    (dataToShow) => {
      const mappedSortTypes = {
        [t('forms.client_name')]: 'name',
        [t('forms.total.sessions_this_month')]: 'sessions',
      };
      const newAttributes = Object.keys(dataToShow[0]).map((key) => {
        const keysToHide = ['id', 'organizationId', 'teamId'];
        if (keysToHide.includes(key)) {
          return {
            name: key,
            options: { display: false, sort: false },
          };
        }
        if (mappedSortTypes[key]) {
          return {
            name: key,
            options: {
              customHeadRender: () => renderHead(mappedSortTypes, key),
            },
          };
        }

        return {
          name: key,
          options: { sort: false },
        };
      });

      newAttributes.push({
        name: '',
        options: {
          filter: false,
          sort: false,
          empty: true,
          viewColumns: false,
          setCellProps: (value) => ({
            className: 'action',
          }),
          customBodyRender: (value, tableMeta, updateValue) =>
            renderActions(value, tableMeta, updateValue),
        },
      });
      return newAttributes;
    },
    [t, renderHead, renderActions],
  );

  // Leave the JSON.parse/JSON.stringify here to create a deepcopy of clients.
  let dataToShow = JSON.parse(JSON.stringify(clients || []));
  let attributes = [];
  let exportData = {};

  if (dataToShow.length > 0) {
    exportData = generateExportData(dataToShow);
    dataToShow = createViewItemListForClients(dataToShow);
    attributes = createTableAttributes(dataToShow);
  }

  /**
   * Generates the table filter's config
   */
  const filtersConfig = useTableFilterConfig('client');

  /**
   * Closes the filter's modal and save data for the filters
   */
  const onSaveFilters = useCallback((filters) => setClientFilters(filters), []);

  /**
   * Checks does table have data
   */
  const isDataTableEmpty = dataToShow.length <= 0;

  /**
   * Flag to checks does filters active
   */
  const isFilters = Object.keys(clientFilters || {})?.length > 0;

  /**
   * mui datatable optional class names
   */
  const muiTableMods = {
    loading: isLoading,
    'no-data': isDataTableEmpty,
    filters: isFilters,
  };

  /**
   * Reset page for search if it's not the first page and there is no results
   */
  useEffect(() => {
    const pagesLimit = Math.ceil(totalClients / limit);
    if (page > pagesLimit && debouncedSearchTerm) setPage(0);
  }, [debouncedSearchTerm, limit, page, totalClients]);

  /**
   * Reset page for existings filters if it's not the first page and there is no results
   */
  useEffect(() => {
    if (isFilters && dataToShow.length <= 0 && page > 0) setPage(0);
  }, [dataToShow.length, isFilters, page]);

  /**
   * Table's query handler
   */
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      setToRedirect(Boolean(params.get('redirectTo')));
      setModalIsOpen(true);
    }
  }, [location]);

  /**
   * Clears clients on leaving the page
   */
  useEffect(
    () => () => {
      clearClients();
    },
    [],
  );

  return (
    <Box>
      <TableHeader
        buttonTitle='buttons.create.client'
        onClickCallback={openModal}
        title='clients.title'
        subTitle='clients.subtitle'
        btnIcon={<AddClientIcon className='practitioner-icon' />}
      />
      <Box className='table-container'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={getMuiTheme(
              {},
              {
                '&:nth-of-type(7)': {
                  width: 100,
                },
              },
            )}
          >
            <MUIDataTable
              ref={tableRef}
              data={isLoading ? muiTableLoadingSkeleton('body') : dataToShow}
              columns={
                isLoading ? muiTableLoadingSkeleton('header') : attributes
              }
              options={tableConfig(
                options,
                isLoading,
                isDataTableEmpty,
                tableRef,
                isFilters,
                onOpenFilterModal,
                exportData,
              )}
              components={muiComponents(clientFilters, setClientFilters)}
              className={classNames(
                'mui-data-table clients-table search-open',
                muiTableMods,
              )}
              title=''
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
      {/* Edit / create client modal */}
      {modalIsOpen && (
        <FormModal
          isOpen={modalIsOpen}
          title={
            clientToEdit ? t('buttons.edit.client') : t('buttons.create.client')
          }
          setOpen={setModalIsOpen}
          onClose={closeModal}
        >
          {clientToEdit ? (
            <EditClientForm
              closeModal={closeModal}
              clientToEdit={clientToEdit}
            />
          ) : (
            <CreateClientForm closeModal={closeModal} />
          )}
        </FormModal>
      )}
      {/* Delete confirmation modal */}
      <ModalUI
        modalSx={deleteConfirmation}
        open={deleteModalIsOpen}
        isCloseBtn={false}
        onCloseHandler={onCloseDeleteModal}
        className='confirmation-modal'
        modalTitle={t('alerts.titles.delete')}
      >
        <SimpleModalContent
          content={['alerts.confirm.client']}
          mainBtn='buttons.delete'
          onMainBtnClick={onDeleteClient}
          secondaryBtn='buttons.cancel'
          onSecondaryBtnClick={onCloseDeleteModal}
        />
      </ModalUI>
      {/* Filter modal */}
      {filterOpen && (
        <CustomMuiDataTableFilter
          isOpen={filterOpen}
          setFilterState={setFilterOpen}
          config={filtersConfig}
          filters={clientFilters}
          onSaveHandler={onSaveFilters}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllClients: clientActions.getAllClientsWithStats,
  updateClient: clientActions.updateClient,
  deleteClient: clientActions.deleteClient,
  clearClients: clientActions.clearClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsTable);
