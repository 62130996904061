import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const skeletonStyle = {
  skeleton: {
    width: '100%',
    '&.header': {
      height: getResponsiveSize(48), // 48px
      borderRadius: `${getResponsiveSize(12)} ${getResponsiveSize(12)} 0 0`, // 12px 12px 0 0
      backgroundColor: theme.palette.myPrimary.lightGrey,
    },
    '&.body': {
      height: getResponsiveSize(54), // 54px
      backgroundColor: theme.palette.myPrimary.lightBlue30,
    },
    '&.footer': {
      height: getResponsiveSize(48), // 48px
      borderRadius: `0 0 ${getResponsiveSize(12)} ${getResponsiveSize(12)}`, // 0 0 12px 12px
      backgroundColor: theme.palette.myPrimary.lightGrey,
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '&.header': {
        height: getResponsiveSize(42, laptopRatio), // 42px
        borderRadius: `${getResponsiveSize(
          12,
          laptopRatio,
        )} ${getResponsiveSize(12, laptopRatio)} 0 0`, // 12px 12px 0 0
      },
      '&.body': {
        height: getResponsiveSize(39, laptopRatio), // 39px
      },
      '&.footer': {
        height: getResponsiveSize(42, laptopRatio), // 42px
        borderRadius: `0 0 ${getResponsiveSize(
          12,
          laptopRatio,
        )} ${getResponsiveSize(12, laptopRatio)}`, // 0 0 12px 12px
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '&.body': {
        height: '60dvh',
      },
      '&.footer': {
        height: getResponsiveSize(44, mobileRatio), // 42px
        borderRadius: `0 0 ${getResponsiveSize(
          12,
          mobileRatio,
        )} ${getResponsiveSize(12, mobileRatio)}`, // 0 0 12px 12px
      },
    },
  },
};
