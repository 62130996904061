/**
 * https://www.dicebear.com/styles/thumbs#options
 */
import { createAvatar } from '@dicebear/core';
import { thumbs } from '@dicebear/collection';

const DEFAULT_CONFIG = {
  scale: 100,
  radius: 25,
  backgroundColor: ['beecff'],
  eyes: [
    'variant2W10',
    'variant2W12',
    'variant2W14',
    'variant2W16',
    'variant3W16',
    'variant4W10',
    'variant4W12',
    'variant4W14',
    'variant4W16',
    'variant5W10',
    'variant5W12',
    'variant5W14',
    'variant5W16',
    'variant6W10',
    'variant6W12',
    'variant6W14',
    'variant6W16',
    'variant7W10',
    'variant7W12',
    'variant7W14',
    'variant7W16',
    'variant8W10',
    'variant8W12',
    'variant8W14',
    'variant8W16',
    'variant9W10',
    'variant9W12',
    'variant9W14',
    'variant9W16',
  ],
  eyesColor: ['000000'],
  mouth: ['variant1', 'variant3', 'variant4'],
  // shapeColor: ['93d4ef', '72d6ff', '619cff', '6f8dff', '89d7f8', '89bdf8'],
    shapeColor: ['82ADED', '95B9F0', '83CDEC', '93D4EF', '98D6F0', 'A2CEF2', '94C6F0'],
  // shapeColor: [],
  //shapeColor: ['72d6ff', 'a2b6ff', '6586ff', '619cff', '6f8dff', '72b4ff', 'a1cdff'],
  dataUri: true,
};

/**
 * Generates custom avatar
 */
export const generateAvatar = (seedId, customConfig = {}) =>
  createAvatar(thumbs, {
    // seed: Math.floor(Math.random() * 1000), // random number to generate avatar model
    seed: seedId, // random number to generate avatar model
    ...DEFAULT_CONFIG,
    ...customConfig,
  });
