import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}/organizations`;

const getAllOrganizations = (filter = {}) => {
  if (filter) {
    if (!filter.where) filter.where = { is_deleted: false };
    else filter.where.is_deleted = false;
  }
  const filterString = `&filter=${JSON.stringify(filter)}`;
  return axios.get(`${baseURL}${authUrl()}${filterString}`);
};

const getOrganizationsOverview = (
  offset = 0,
  limit = 10,
  order = 'totalsessions DESC',
  searchQuery = '',
  totalSessions = null,
  monthlySession = null,
  activeClients = null,
) => {
  const sessions = totalSessions ? `&sessions=${totalSessions}` : '';
  const monthly_sessions = monthlySession
    ? `&monthly_sessions=${monthlySession}`
    : '';
  const therapists = activeClients ? `&therapists=${activeClients}` : '';
  const filterString = `&offset=${offset}&limit=${limit}&order=${order}&query=${searchQuery}${sessions}${monthly_sessions}${therapists}`;
  return axios.get(`${baseURL}/overview${authUrl()}${filterString}`);
};

const getOrganizationsCount = () => {
  const filter = '&filter[where][is_deleted]=false';
  return axios.get(`${baseURL}/count${authUrl()}${filter}`);
};

const createOrganization = (organization) => {
  const {
    name,
    contact_email,
    contact_name,
    contact_telephone,
    contact_address,
    funds,
    image,
  } = organization;

  return axios.post(baseURL + authUrl(), {
    name,
    funds,
    image,
    contact_name,
    contact_address,
    contact_email,
    contact_telephone,
  });
};

const updateOrganization = (organization) => {
  const { id, ...updatedOrganization } = organization;
  if (!id) return null;
  return axios.patch(`${baseURL}/${id}${authUrl()}`, updatedOrganization);
};

/**
 * Merge data from one into another organization
 * @fromId organization id that will be merged into
 * @toId organization id to where we are merging
 */
const mergeOrganizations = (fromId, toId) => {
  if (!fromId || !toId) return null;
  const mergeUrl = `${BASEURL.substring(0, BASEURL.length - 3)}merge`;
  return axios.post(`${mergeUrl}/organizations${authUrl()}`, {
    from: fromId,
    to: toId,
  });
};

const deleteOrganizationById = (id) => {
  if (!id) return null;
  return updateOrganization({
    id,
    is_deleted: true,
  });
};

const getOrganizationById = (id) => {
  if (!id) return Promise.reject();
  return axios.get(`${baseURL}/${id}${authUrl()}`);
};

export const organizationService = {
  getAllOrganizations,
  createOrganization,
  deleteOrganizationById,
  updateOrganization,
  mergeOrganizations,
  getOrganizationById,
  getOrganizationsCount,
  getOrganizationsOverview,
};
