import { sessionConstants } from '../constants/session.constants';

export function sessions(state = {
  sessions: [],
  selectedSession: {},
  totalSessions: 0,
}, action) {
  switch (action.type) {
    case sessionConstants.GETALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case sessionConstants.GETALL_SUCCESS: {
      const { attributes, data, totalCount } = action.sessions;
      return {
        ...state,
        columns: attributes,
        data,
        totalSessions: totalCount,
        isLoading: false,
      };
    }
    case sessionConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case sessionConstants.COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case sessionConstants.COUNT_SUCCESS: {
      return {
        ...state,
        totalSessions: action.count,
      };
    }
    case sessionConstants.COUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case sessionConstants.GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case sessionConstants.GET_SUCCESS:
      return {
        ...state,
        selectedSession: action.session,
        isLoading: false,
      };
    case sessionConstants.GET_FAILURE:
      return {
        ...state,
        error: true,
      };
    case sessionConstants.CLEAR_SESSIONSDATA:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
}
