import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}`;

const getAllTreatments = (organizationId = null) => {
  let orgFilter = '';
  if (organizationId !== null) {
    orgFilter = `&organizationId=${organizationId}`;
  }

  return axios.get(
    `${baseURL}/devicetreatmentconfigurations/overview${authUrl()}${orgFilter}`,
  );
};

const getTreatmentsOverview = (
  offset = 0,
  limit = 10,
  order = 'sessions DESC',
  orgId = null,
  searchQuery = '',
  anonymousInclude,
  deviceName,
  treatmentType,
  totalSessions,
  startDate,
) => {
  const anonymous = anonymousInclude ? `&anonymous=${anonymousInclude}` : '';
  const device_name = deviceName ? `&device_name=${deviceName}` : '';
  const treatment_type = treatmentType
    ? `&treatment_type=${treatmentType}`
    : '';
  const sessions = totalSessions ? `&sessions=${totalSessions}` : '';
  const start_date = startDate ? `&start_date=${startDate}` : '';
  const organizationId = orgId ? `&organizationId=${orgId}` : '';
  const filterString = `&offset=${offset}&limit=${limit}&order=${order}&query=${searchQuery}${anonymous}${organizationId}${treatment_type}${device_name}${sessions}${start_date}`;
  return axios.get(
    `${baseURL}/remotetreatmentconfigurations/combined_overview${authUrl()}${filterString}`,
  );
};

const getTreatmentRemoteConfigurationById = (treatmentId) =>
  axios.get(
    `${baseURL}/remotetreatmentconfigurations/${treatmentId}/configurationdefinition${authUrl()}`,
  );

const createTreatmentConfiguration = (config) => {
  const body = { ...config };
  return axios.post(`${baseURL}/treatmentconfigurations${authUrl()}`, body);
};

const createDeviceTreatmentConfiguration = (config) => {
  const body = { ...config };
  return axios.post(
    `${baseURL}/devicetreatmentconfigurations${authUrl()}`,
    body,
  );
};

const patchDeviceTreatmentConfiguration = (id, config) => {
  const body = { ...config };
  return axios.patch(
    `${baseURL}/treatmentconfigurations/${id}${authUrl()}`,
    body,
  );
};

const checkConfigurationStatus = (id) => {
  const filter = { include: ['device', 'client'] };
  return axios.get(
    `${baseURL}/devicetreatmentconfigurations/${id}${authUrl()}&filter=${JSON.stringify(
      filter,
    )}`,
  );
};

const deleteDeviceTreatmentConfigurationById = (id) =>
  axios.delete(
    `${baseURL}/devicetreatmentconfigurations/cancel${authUrl()}&dtcId=${id}`,
  );

export const treatmentService = {
  getAllTreatments,
  createTreatmentConfiguration,
  createDeviceTreatmentConfiguration,
  checkConfigurationStatus,
  patchDeviceTreatmentConfiguration,
  deleteDeviceTreatmentConfigurationById,
  getTreatmentsOverview,
  getTreatmentRemoteConfigurationById,
};
