import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { treatmentActions } from '../../store/actions/treatment.actions';
import Loader from '../Loader';

const SuccessIcon = () => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 130.2 130.2'
    className='fade-in'
    style={{ height: '200px' }}
  >
    <circle
      className='path circle'
      fill='none'
      stroke='#73AF55'
      strokeWidth='6'
      strokeMiterlimit='10'
      cx='65.1'
      cy='65.1'
      r='62.1'
    />
    <polyline
      className='path check'
      fill='none'
      stroke='#73AF55'
      strokeWidth='6'
      strokeLinecap='round'
      strokeMiterlimit='10'
      points='100.2,40.2 51.5,88.8 29.8,67.5 '
    />
  </svg>
);

const mapStateToProps = (state) => ({
  ...state,
});

const baseStyle = {
  top: 'calc(50% - 18px)',
  position: 'absolute',
  zIndex: 1,
  padding: 12,
  lineHeight: '18px',
  borderRadius: '8px',
  boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
  border: '1px solid rgb(148 163 184)',
};

const StepContainer = ({
  messageOne,
  messageTwo,
  onNext,
  onPrevious,
  currentStep,
  showLoader,
  setIsTroubleShooting,
  imgUrl = '/assets/Step',
  i18n,
  skipButton,
}) => {
  const canPressPrevious = currentStep < 4 && currentStep > 1;
  const canPressNext = currentStep < 3;
  const image = `${imgUrl} ${currentStep}.gif`;
  return (
    <div className='message-container fade-in steps'>
      <div className='mb-4 relative'>
        <button
          type='button'
          style={{
            ...baseStyle,
            left: 0,
            marginLeft: '-60px',
            display: canPressPrevious ? 'block' : 'none',
          }}
          onClick={onPrevious}
        >
          <FontAwesomeIcon size='lg' icon='arrow-left' />
        </button>
        <img className='fade-in' alt='not-found' src={image} />
        <button
          type='button'
          style={{
            ...baseStyle,
            right: 0,
            marginRight: '-60px',
            display: canPressNext ? 'block' : 'none',
          }}
          onClick={onNext}
        >
          <FontAwesomeIcon size='lg' icon='arrow-right' />
        </button>
      </div>
      <div
        style={{ height: '100px' }}
        className='error-message text-center step'
      >
        {messageOne}
        <br />
        <span style={{ fontSize: 12, lineHeight: '12px' }}>{messageTwo}</span>
      </div>
      {showLoader && (
        <div style={{ margin: '0 auto', marginTop: '48px' }}>
          <Loader />
        </div>
      )}
      <div className='flex mt-16' style={{ height: '70px' }}>
        <button
          type='button'
          className='focus:ring-2 focus:ring-offset-2 inline-flex sm:ml-3 items-center justify-center px-6 py-0 bg-gray-300 hover:bg-gray-200 focus:outline-none rounded mr-4'
          onClick={() => setIsTroubleShooting(true)}
        >
          {i18n.t('device-connection.troubleshoot')}
        </button>
        {skipButton}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(
  ({ idToCheck, treatments, i18n, isError, history, useFallBack }) => {
    const { isReceived: received, deviceTreatmentConnection } =
      treatments || {};
    const {
      device: { name: deviceName },
      client: { name: clientName },
    } = deviceTreatmentConnection || {
      device: { name: 'not found' },
      client: { name: 'not found' },
    };
    const [step, setStep] = useState(1);
    const errorMessage = i18n.t('treatments.configuration.error_message');
    const fallbackMessage = i18n.t('treatments.configuration.fallback_message');
    const connectingMessage = i18n.t('device-connection.connecting', {
      deviceName,
    });
    const [isTroubleShooting, setIsTroubleShooting] = useState(false);

    const nextStep = () => {
      setStep((prevState) => prevState + 1);
    };

    const prevStep = () => {
      setStep((prevState) => prevState - 1);
    };

    const renderDefaultButton = useMemo(
      () => (
        <button
          type='button'
          className='btn btn-secondary max-w-xs'
          style={{ textDecoration: 'underline' }}
          onClick={() => useFallBack(idToCheck)}
        >
          {i18n.t('device-connection.skip-1')}
        </button>
      ),
      [useFallBack, idToCheck],
    );

    const handleSuccess = () => {
      treatmentActions.resetDeviceTreatmentConnectionId();
      history.push('admin/treatments');
    };

    const renderSuccess = useMemo(
      () => (
        <div className='message-container message-container--error fade-in'>
          <SuccessIcon />
          <div className='error-message text-center'>
            {i18n.t('device-connection.success-part-1', { clientName })}
            <br />
            <br />
            {i18n.t('device-connection.success-part-2')}
          </div>
          <button
            type='button'
            className='login100-form-btn'
            onClick={handleSuccess}
          >
            {i18n.t('device-connection.continue')}
          </button>
        </div>
      ),
      [history, i18n, clientName],
    );

    const renderError = useMemo(
      () => (
        <div className='message-container message-container--error steps'>
          <div className='error-message step'>{errorMessage}</div>
          <button type='button' className='login100-form-btn'>
            {fallbackMessage}
          </button>
        </div>
      ),
      [errorMessage, fallbackMessage],
    );

    const renderDefaultPage = useMemo(() => {
      if (isError) {
        return renderError;
      }
      return (
        <div className='message-container steps'>
          <div className='error-message text-center step'>
            {i18n.t('device-connection.step-0-part-1')}
            <br />
            <br />
            {i18n.t('device-connection.step-0-part-2')}
          </div>
          <button
            type='button'
            className='btn btn-primary mt-16 max-w-lg'
            onClick={nextStep}
          >
            {i18n.t('device-connection.continue')}
          </button>
        </div>
      );
    }, [isError, renderError, nextStep]);

    const renderStepOne = useMemo(() => {
      if (isError) {
        return renderError;
      }
      return (
        <StepContainer
          messageOne={i18n.t('device-connection.step-1')}
          messageTwo={connectingMessage}
          onNext={nextStep}
          onPrevious={prevStep}
          currentStep={step}
          skipButton={renderDefaultButton}
          i18n={i18n}
          setIsTroubleShooting={setIsTroubleShooting}
          showLoader
        />
      );
    }, [isError, renderError, connectingMessage, renderDefaultButton, step]);

    const renderStepTwo = useMemo(() => {
      if (isError) {
        return renderError;
      }
      return (
        <StepContainer
          messageOne={i18n.t('device-connection.step-2')}
          messageTwo={connectingMessage}
          onNext={nextStep}
          onPrevious={prevStep}
          currentStep={step}
          skipButton={renderDefaultButton}
          setIsTroubleShooting={setIsTroubleShooting}
          i18n={i18n}
          showLoader
        />
      );
    }, [isError, renderError, connectingMessage, renderDefaultButton, step]);

    const renderStepThree = useMemo(() => {
      if (isError) {
        return renderError;
      }
      return (
        <StepContainer
          messageOne={i18n.t('device-connection.step-3')}
          messageTwo={connectingMessage}
          skipButton={renderDefaultButton}
          onNext={nextStep}
          onPrevious={prevStep}
          currentStep={step}
          setIsTroubleShooting={setIsTroubleShooting}
          i18n={i18n}
          showLoader
        />
      );
    }, [isError, renderError, connectingMessage, renderDefaultButton, step]);

    const renderTroubleShooting = useMemo(() => {
      if (!isTroubleShooting) {
        return undefined;
      }
      return (
        <div className='message-container fade-in steps'>
          <div className='error-message text-center step'>
            {i18n.t('treatments.troubleshoot.step_1')}
            <br />
            {i18n.t('treatments.troubleshoot.step_2')}
            <br />
            {i18n.t('treatments.troubleshoot.step_3')}
            <br />
          </div>
          <div className='mt-16' style={{ fontSize: 12, lineHeight: '12px' }}>
            {connectingMessage}
          </div>
          <div style={{ margin: '0 auto', marginTop: '32px' }}>
            <Loader />
          </div>
          <div className='flex mt-16' style={{ height: '70px' }}>
            <button
              type='button'
              className='focus:ring-2 focus:ring-offset-2 inline-flex sm:ml-3 items-center justify-center px-6 py-0 bg-gray-300 hover:bg-gray-200 focus:outline-none rounded mr-4'
              onClick={() => setIsTroubleShooting(false)}
            >
              {i18n.t('device-connection.show-instructions')}
            </button>
            {renderDefaultButton}
          </div>
        </div>
      );
    }, [isTroubleShooting, i18n.language]);

    const renderCorrectPage = useMemo(() => {
      if (received) {
        return renderSuccess;
      }
      if (isTroubleShooting) {
        return renderTroubleShooting;
      }
      switch (step) {
        case 0:
          return renderDefaultPage;
        case 1:
          return renderStepOne;
        case 2:
          return renderStepTwo;
        case 3:
          return renderStepThree;
        default:
          return undefined;
      }
    }, [
      received,
      renderDefaultPage,
      renderStepOne,
      renderStepTwo,
      renderSuccess,
      step,
      renderStepThree,
      isTroubleShooting,
    ]);

    return (
      <div className='device-configuration-loader'>{renderCorrectPage}</div>
    );
  },
);
