import 'airbnb-js-shims';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import i18n from './i18n';

import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store/store';

import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';

const url = window.location.href;
// Monitoring
if (!url.includes('localhost')) {
  // Prod ID
  let sentryId = 'https://aea1dd1a8af6420fb1b4fc8634be36e6@o420530.ingest.sentry.io/5353338';
  if (!url.includes('psylaris')) {
    // ACC ID
    sentryId = 'https://924925bd3c7343f880610d6e0f06c424@o420530.ingest.sentry.io/5344362';
  }
  Sentry.init({ dsn: sentryId });
}

const app = ReactDOM.createRoot(document.getElementById('app'));
app.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
          <App />
      </ThemeProvider>
    </Provider>
  </I18nextProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
