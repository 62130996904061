import { clientConstants } from '../constants/client.constants';
import sortByProperty from '../../utils/sort-by-property';

export function clients(state = { clients: [], totalClients: 0 }, action) {
  switch (action.type) {
    case clientConstants.GETALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case clientConstants.GETALL_SUCCESS:
      const { clients, count } = action;
      return {
        ...state,
        clients,
        isLoading: false,
        totalClients: +count,
      };
    case clientConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case clientConstants.COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case clientConstants.COUNT_SUCCESS:
      return {
        ...state,
        totalClients: action.count,
      };
    case clientConstants.COUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case clientConstants.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case clientConstants.CREATE_SUCCESS: {
      const newClients = state.clients || [];
      newClients.push(action.client);
      return {
        ...state,
        clients: sortByProperty(newClients, 'name'),
        isLoading: false,
      };
    }
    case clientConstants.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case clientConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case clientConstants.UPDATE_SUCCESS: {
      const fetchedClients = action.client;
      const newClients = state.clients.filter(
        (item) => item.id !== fetchedClients.id,
      );
      return {
        ...state,
        clients: sortByProperty([...newClients, fetchedClients], 'name'),
        isLoading: false,
      };
    }
    case clientConstants.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case clientConstants.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case clientConstants.DELETE_SUCCESS: {
      const { id } = action;
      const filteredDevices = state.clients.filter((item) => item.id !== id);
      return {
        ...state,
        clients: sortByProperty(filteredDevices, 'name'),
        isLoading: false,
      };
    }
    case clientConstants.DELETE_FAILURE:
      return {
        ...state,
        error: true,
      };
    case clientConstants.CLEAR_CLIENTS:
      return {
        ...state,
        clients: [],
      };
    default:
      return state;
  }
}
