import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { ArrowDropUpRounded, ArrowDropDownRounded } from '@mui/icons-material';
import { Box } from '@mui/material';

const CustomDateSortHeader = (props) => {
  const { currentSort, label, type, handleSort } = props;

  /**
   * Sort icon component based on a current sort
   */
  const sortArrow = useMemo(() => {
    switch (currentSort) {
      case 'default':
        return (
          <Box component='span' className='default-sort'>
            <ArrowDropUpRounded className='icon icon-top' />
            <ArrowDropDownRounded className='icon icon-bottom' />
          </Box>
        );
      case 'down':
        return <ArrowDropDownRounded className='icon simple icon-bottom' />;
      case 'up':
        return <ArrowDropUpRounded className='icon simple icon-top' />;
    }
  }, [currentSort]);

  return (
    <th
      className='MuiTableCell-root MUIDataTableHeadCell-root-333 MUIDataTableHeadCell-fixedHeader-334 MuiTableCell-head custom-sort'
      onClick={() => handleSort(type)}
      scope='col'
    >
      <Box className='label-wrapper'>
        <span>{label}</span>
        {sortArrow}
      </Box>
    </th>
  );
};

export default connect()(memo(CustomDateSortHeader));
