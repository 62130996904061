import { memo, useEffect, useRef, useState } from 'react';
import { withTranslation  } from 'react-i18next';
import { connect } from 'react-redux';

import { Box, Typography, useMediaQuery } from '@mui/material';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { LogoLoader } from '../../../UI/LogoLoader/LogoLoader';
import StepItem from './StepItem/StepItem';
import { CustomLeftArrow, CustomRightArrow, responsive, steps } from './consts';
import { deviceConnectionStyles } from './style';

function DeviceConnection(props) {
  const { treatments, t } = props;
  const { deviceConnection } = deviceConnectionStyles;
  const isTablet = useMediaQuery('(max-width:768px)');
  const [currentItem, setCurrentItem] = useState(!isTablet ? 2 : 1);
  const [syncSteps, setsyncSteps] = useState([...steps]);
  const carouselRef = useRef(null);

  // const
  const { deviceTreatmentConnection } = treatments || {};

  const {
    device: { name: deviceName },
  } = deviceTreatmentConnection || {
    device: { name: 'not found' },
  };

  useEffect(() => {
    // Change slides order depends on view
    !isTablet
      ? setsyncSteps([...steps])
      : setsyncSteps((prev) => [
          ...prev.sort((a, b) => a.stepNumber - b.stepNumber),
        ]);
    // When swap between tablet and desktop view current slide change because of centered mode.
    // This condition fixes it
    if (isTablet && carouselRef.current.state.currentSlide !== steps.length - 1)
      carouselRef.current.goToSlide(steps.length - 1);
    if (!isTablet && carouselRef.current.state.currentSlide !== steps.length)
      carouselRef.current.goToSlide(steps.length);
  }, [isTablet]);

  return (
    <Box sx={deviceConnection} className='step-container'>
      {/* https://www.npmjs.com/package/react-multi-carousel?activeTab=readme carousel doc */}
      <Carousel
        responsive={responsive}
        autoPlay={false}
        autoPlaySpeed={0}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        additionalTransfrom={0}
        arrows
        infinite
        centerMode={isTablet}
        keyBoardControl
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        rewind={false}
        rewindWithAnimation={false}
        shouldResetAutoplay={false}
        showDots={false}
        beforeChange={(nextSlide) => setCurrentItem(nextSlide - 1)}
        afterChange={(previousSlide, { currentSlide }) => {
          // Go to the 1st slide if next slide is before 1st
          if (currentSlide < steps.length && !isTablet)
            carouselRef.current.goToSlide(steps.length);
          // Tablet flow
          if (currentSlide < steps.length - 1 && isTablet)
            carouselRef.current.goToSlide(steps.length - 1);

          // Go to last slide if next slide is after last
          if (currentSlide >= steps.length * 2 && !isTablet)
            carouselRef.current.goToSlide(steps.length * 2 - 1);
          // Tablet flow
          if (currentSlide > steps.length + 1 && isTablet)
            carouselRef.current.goToSlide(steps.length + 1);
        }}
        ref={(el) => {
          if (el) carouselRef.current = el;
        }}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {syncSteps?.map((step, index) => {
          const isStepActive =
            index + 1 === currentItem ||
            index + 1 === currentItem % steps.length;

          return (
            <StepItem active={isStepActive} key={step.stepNumber} {...step} />
          );
        })}
      </Carousel>
      <Box className='loading-status'>
        <LogoLoader />
        <Typography variant='body1' className='desc'>
          {t('device-connection.connecting', { deviceName })}
        </Typography>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withTranslation ()(
  connect(mapStateToProps)(memo(DeviceConnection)),
);
