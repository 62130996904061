import { createTheme } from '@mui/material/styles';
import { laptopRatio, mobileRatio, theme } from '../theme';
import { getResponsiveSize } from '.';

const getMuiTheme = (rowAdditions = {}, headAdditions = {}) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.sessions .MuiTableHead-root tr th': {
              '&:nth-of-type(4), &:nth-of-type(5)': {
                width: getResponsiveSize(250), // 250px
              },
            },
            '&.loading, &.no-data': {
              "& .MuiTableBody-root .MuiTableRow-root, [class*='-MUIDataTableFooter-root']":
                {
                  backgroundColor: 'transparent',
                  ...theme.palette.propVars.easeInOutTransition,
                },
            },
            '&.scrollable.vertical': {
              "& [class*='-MUIDataTable-responsiveBase']": {
                marginRight: '0',
                '@-moz-document url-prefix()': {
                  paddingRight: getResponsiveSize(16), // 16px
                },
              },
              "& [class*='-MUIDataTableFooter-root']": {
                width: `calc(100% - ${getResponsiveSize(16)} - 16px)`,
              },
            },
            '& .graph-wrapper': {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
              gap: getResponsiveSize(4), // 4px
              '& .label': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                border: '1px solid',
                borderRadius: '4px',
                height: getResponsiveSize(22), // 22px
                width: 'auto',
                padding: `0 ${getResponsiveSize(5)}`, // 0 5px
                marginBottom: getResponsiveSize(-5), // -5px
                '& .MuiTypography-root': {
                  fontSize: getResponsiveSize(14), // 14px
                  fontFamily: theme.typography.mainFontFamily,
                  ...theme.palette.propVars.boldFontWeight,
                },
              },
            },
            '&.dashboard': {
              width: '60dvw',
              maxWidth: '60dvw',
            },
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              '&.loading .MuiTableCell-root': {
                padding: 0,
              },
              '&.scrollable.vertical': {
                '& .MuiToolbar-root': {
                  marginRight: '16px',
                },
              },
              '&.dashboard': {
                width: '65dvw',
                maxWidth: '65dvw',
              },
              '& .graph-wrapper': {
                gap: getResponsiveSize(4, laptopRatio), // 4px
                '& .label': {
                  height: getResponsiveSize(20, laptopRatio), // 20px
                  padding: `0 ${getResponsiveSize(3, laptopRatio)}`, // 0 3px
                  marginBottom: getResponsiveSize(-5, laptopRatio), // -5px
                  '& .MuiTypography-root': {
                    fontSize: getResponsiveSize(12, laptopRatio), // 12px
                  },
                },
              },
              '& .apexcharts-point-annotations': {
                '& rect ': {
                  transform: 'translate(-2%, 5%)',
                },
                '& .apexcharts-text': {
                  transform: 'translate(-25%, 25%)',
                  fontSize: getResponsiveSize(11, laptopRatio), // 11px
                },
              },
            },
            '@media screen and (max-width: 920px)': {
              '&.dashboard': {
                width: 'auto',
                maxWidth: 'calc(100dvw - 350px)',
              },
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              '&.scrollable.vertical': {
                '& .MuiToolbar-root, .filters-row': {
                  marginRight: '13px',
                },
                "& [class*='-MUIDataTableFooter-root']": {
                  width: `calc(100% - 13px)`,
                },
                "&.filters [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: 'calc(58dvh - 36px) !important',
                },
              },
              '&.dashboard': {
                width: 'calc(100vw - 124px)',
                maxWidth: 'unset',
              },
              // Remove if button exists on page header
              '&.sessions.search-open': {
                "&.filters [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: 'calc(62dvh - 36px) !important',
                },
                "& [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: '62dvh !important',
                },
              },
              '&.sessions:not(.search-open)': {
                '.MuiToolbar-root ': {
                  gap: 0,
                },
                "&.filters [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: 'calc(68dvh - 36px) !important',
                },
                "& [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: '68dvh !important',
                },
              },
              "& [class*='-MUIDataTable-responsiveBase']": {
                maxHeight: '58dvh !important',
              },
            },
            // Mobile screen
            [theme.breakpoints.media.mobile]: {
              '&.scrollable.vertical.filters': {
                "& [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: 'calc(65dvh - 36px) !important',
                },
              },
              "& [class*='-MUIDataTable-responsiveBase']": {
                maxHeight: '65dvh !important',
              },
              // Remove if button exists on page header
              '&.sessions.search-open': {
                "&.filters [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: 'calc(68dvh - 36px) !important',
                },
                "& [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: '68dvh !important',
                },
              },
              '&.sessions:not(.search-open)': {
                "&.filters [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: 'calc(73dvh - 36px) !important',
                },
                "& [class*='-MUIDataTable-responsiveBase']": {
                  maxHeight: '73dvh !important',
                },
              },
            },
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          responsiveBase: {
            marginRight: getResponsiveSize(16), // 16px
            marginLeft: getResponsiveSize(16), // 16px
            '&::-webkit-scrollbar': {
              width: '16px',
            },
            '&::-webkit-scrollbar:horizontal': {
              height: getResponsiveSize(8), // 8px
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 14px 14px transparent',
              marginTop: getResponsiveSize(60), // 60px
            },
            '&::-webkit-scrollbar-track:horizontal': {},
            '&::-webkit-scrollbar-thumb': {
              boxShadow: `inset 0 0 14px 14px ${theme.palette.myPrimary.lightBlue20}`,
              border: 'solid 5px transparent',
              borderRadius: '14px',
            },
            '&::-webkit-scrollbar-thumb:horizontal': {
              background: theme.palette.myPrimary.darkBlue100,
              border: 'none',
              boxShadow: 'none',
            },
            '@-moz-document url-prefix()': {
              scrollbarColor: `${theme.palette.myPrimary.lightBlue20} transparent`,
              scrollbarWidth: 'thin',
            },
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              '&::-webkit-scrollbar-thumb': {
                border: 'solid 6px transparent',
              },
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              margin: 0,
              paddingRight: 2,
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 14px 14px transparent',
                marginTop: 0,
              },
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                border: 'solid 3px transparent',
              },
            },
          },
          root: {
            backgroundColor: theme.palette.myPrimary.white,
            '&.search-open': {
              '& .MuiToolbar-regular:not(.MuiTablePagination-toolbar)': {
                width: 'auto',
                marginLeft: getResponsiveSize(16), // 16px
                // Table simple responsive screen
                [theme.breakpoints.media.tableResponsive]: {
                  marginLeft: 0,
                },
              },
            },
          },
          paper: {
            boxShadow: 'none',
            paddingBottom: '1em',
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            background: theme.palette.myPrimary.lightGrey,
            borderRadius: getResponsiveSize(12), // 12px
            padding: `${getResponsiveSize(8)} ${getResponsiveSize(12)}`, // 8px 12px
            marginBottom: getResponsiveSize(16), // 16px
            minHeight: getResponsiveSize(65), // 65px
            width: 'fit-content',
            marginRight: getResponsiveSize(16), // 16px
            marginLeft: 'auto',
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              borderRadius: getResponsiveSize(12, laptopRatio), // 12px
              padding: `${getResponsiveSize(
                8,
                laptopRatio,
              )} ${getResponsiveSize(12, laptopRatio)}`, // 8px 12px
              minHeight: getResponsiveSize(50, laptopRatio), // 50px
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              display: 'flex',
              flexDirection: 'column',
              gap: getResponsiveSize(8, mobileRatio), // 8px
              borderRadius: getResponsiveSize(12, mobileRatio), // 12px
              padding: `${getResponsiveSize(
                8,
                mobileRatio,
              )} ${getResponsiveSize(12, mobileRatio)}`, // 8px 12px
              marginRight: 0,
              marginBottom: getResponsiveSize(8, mobileRatio), // 8px
              minHeight: getResponsiveSize(50, mobileRatio), // 50px
            },
          },
          left: {
            flex: '0.5',
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              flex: '0.75',
            },
            // Tablet XL screen
            [theme.breakpoints.media.tabletXL]: {
              flex: '4',
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              padding: 0,
              width: '100%',
            },
          },
          actions: {
            '& span': {
              display: 'flex',
            },
            display: 'flex',
            justifyContent: 'flex-end',
            gap: getResponsiveSize(8), // 8px
            // Toolbar button style
            '& .MuiButtonBase-root': {
              backgroundImage: `linear-gradient(to left, ${theme.palette.myPrimary.lightBlue100} 50%, ${theme.palette.myPrimary.darkBlue100} 50%)`,
              color: theme.palette.myPrimary.white,
              padding: `${getResponsiveSize(9)} ${getResponsiveSize(12)}`, // 9px 12px
              height: getResponsiveSize(38), // 38px
              fontSize: getResponsiveSize(16), // 16px
              lineHeight: getResponsiveSize(22), // 22px
              fontWeight: 500,
              justifyContent: 'center',
              alignItems: 'center',
              textTransform: 'capitalize',
              borderRadius: getResponsiveSize(8), // 8px
              fontFamily: 'Ubuntu, Actor, Open Sans',
              ...theme.palette.propVars.btnBoxShadow,
              ...theme.palette.propVars.semiBoldFontWeight,

              // slide color effect
              backgroundSize: '200%',
              backgroundPosition: ' right bottom',
              transition:
                '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',
              '&.Mui-disabled': {
                opacity: 0.5,
              },
              '&:hover': {
                backgroundPosition: 'left bottom',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
              },
              '&:active': {
                boxShadow: 'none',
              },
              '& .label': {
                fontFamily: 'Ubuntu, Actor, Open Sans',
              },
              '& svg': {
                fontSize: getResponsiveSize(22), // 22px
                marginRight: getResponsiveSize(6), // 6px
                marginLeft: getResponsiveSize(-4), // -4px
                '&.custom-icon': {
                  width: getResponsiveSize(22), // 22px
                  height: getResponsiveSize(22), // 22px
                },
              },
              '& .MuiTouchRipple-ripple': {
                width: '110% !important',
                left: '-5% !important',
                '& .MuiTouchRipple-child': {
                  backgroundColor: 'currentcolor',
                },
              },
              '&.toolbar-btn': {
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
              },
            },
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              gap: getResponsiveSize(8, laptopRatio), // 8px
              '& .MuiButtonBase-root': {
                padding: `${getResponsiveSize(
                  7,
                  laptopRatio,
                )} ${getResponsiveSize(9, laptopRatio)}`, // 7px 9px
                height: getResponsiveSize(38, laptopRatio), // 38px
                fontSize: getResponsiveSize(14, laptopRatio), // 14px
                lineHeight: getResponsiveSize(22, laptopRatio), // 22px
                borderRadius: getResponsiveSize(8, laptopRatio), // 8px
                '& svg': {
                  fontSize: getResponsiveSize(22, laptopRatio), // 22px
                  marginRight: getResponsiveSize(4, laptopRatio), // 4px
                  marginLeft: getResponsiveSize(-2, laptopRatio), // -2px
                  '&.custom-icon': {
                    width: getResponsiveSize(22, laptopRatio), // 22px
                    height: getResponsiveSize(22, laptopRatio), // 22px
                  },
                },
              },
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              gap: getResponsiveSize(8, mobileRatio), // 8px
              '& .MuiButtonBase-root': {
                padding: `${getResponsiveSize(7, mobileRatio)}`, // 7px
                height: getResponsiveSize(32, mobileRatio), // 32px
                width: getResponsiveSize(32, mobileRatio), // 32px
                minWidth: getResponsiveSize(32, mobileRatio), // 32px
                fontSize: 0,
                lineHeight: getResponsiveSize(22, mobileRatio), // 22px
                borderRadius: getResponsiveSize(8, mobileRatio), // 8px
                '& .label': {
                  display: 'none',
                },
                '& svg': {
                  fontSize: getResponsiveSize(22, mobileRatio), // 22px
                  margin: 0,
                  '&.custom-icon': {
                    width: getResponsiveSize(22, mobileRatio), // 22px
                    height: getResponsiveSize(22, mobileRatio), // 22px
                  },
                },
              },
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            ...headAdditions,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '&:after': {
              content: "''",
              display: 'table-row',
              width: '100%',
              height: getResponsiveSize(8), // 8px,
            },
            '& tr': {
              zIndex: '2',
              background: theme.palette.myPrimary.white,
              top: getResponsiveSize(-8), // -8px
              position: 'sticky',
              boxShadow: `0 ${getResponsiveSize(12)} ${getResponsiveSize(1)} ${
                theme.palette.myPrimary.white
              }`,
              height: getResponsiveSize(48), // 48px
              '& th': {
                border: 0,
                top: '0 ',
                background: theme.palette.myPrimary.lightGrey,
                position: 'sticky',
                fontSize: getResponsiveSize(16), // 16px
                color: theme.palette.myPrimary.lightBlue100,
                textAlign: 'left',
                padding: `${getResponsiveSize(12)} ${getResponsiveSize(16)}`, // 12px 16px
                fontWeight: '600',
                letterSpacing: '0.7px',
                lineHeight: '1.3',
                textWrap: 'nowrap',
                whiteSpace: 'nowrap',
                ...theme.palette.propVars.easeInOutTransition,
                '&:first-of-type': {
                  borderTopLeftRadius: getResponsiveSize(12), // 12px
                },
                '&:last-of-type': {
                  borderTopRightRadius: getResponsiveSize(12), // 12px
                  textAlign: 'right',
                },
                '&.custom-sort': {
                  cursor: 'pointer',
                  '& .label-wrapper': {
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    position: 'relative',
                    ...theme.palette.propVars.easeInOutTransition,
                    '& .icon': {
                      fontSize: getResponsiveSize(26), // 26px
                      minHeight: 22,
                      minWidth: 22,
                      marginLeft: getResponsiveSize(-2), // -2px
                      ...theme.palette.propVars.easeInOutTransition,
                      '&.simple': {
                        marginTop: '-1px',
                      },
                    },
                    '& .default-sort': {
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'absolute',
                      right: getResponsiveSize(-24), // 24px
                      top: '45%',
                      transform: 'translateY(-50%)',
                      '& .icon': {
                        marginLeft: 0,
                        '&.icon-top': {
                          marginBottom: getResponsiveSize(-10), // -10px
                        },
                        '&.icon-bottom': {
                          marginTop: getResponsiveSize(-10), // -10px
                        },
                      },
                    },
                  },
                  '&:hover': {
                    color: theme.palette.myPrimary.darkBlue100,
                    '& .label-wrapper': {
                      '& .icon-top': {
                        transform: 'translateY(-2px)',
                      },
                      '& .icon-bottom': {
                        transform: 'translateY(2px)',
                      },
                      '& .default-sort': {
                        '& .icon': {
                          '&.icon-top': {
                            marginBottom: getResponsiveSize(-9), // -8px
                          },
                          '&.icon-bottom': {
                            marginTop: getResponsiveSize(-9), // -8px
                          },
                        },
                      },
                    },
                  },
                },
                '& span, div': {
                  fontFamily: theme.typography.secondaryFontFamily,
                },
              },
            },
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              '& tr': {
                height: getResponsiveSize(44, laptopRatio), // 44px
                '& th': {
                  fontSize: getResponsiveSize(13, laptopRatio), // 13px
                  '&:first-of-type': {
                    borderTopLeftRadius: getResponsiveSize(12, laptopRatio), // 12px
                  },
                  '&:last-of-type': {
                    borderTopRightRadius: getResponsiveSize(12, laptopRatio), // 12px
                  },
                  '&.custom-sort': {
                    padding: `${getResponsiveSize(
                      12,
                      laptopRatio,
                    )} ${getResponsiveSize(16, laptopRatio)}`, // 12px 16px
                    '& .label-wrapper .default-sort': {
                      right: getResponsiveSize(-24, laptopRatio), // 24px
                      '& .icon.icon-top': {
                        marginBottom: getResponsiveSize(-8, laptopRatio), // -8px
                      },
                      '& .icon.icon-bottom': {
                        marginTop: getResponsiveSize(-8, laptopRatio), // -8px
                      },
                    },
                    '&:hover': {
                      '& .label-wrapper': {
                        '& .default-sort': {
                          '& .icon.icon-top': {
                            marginBottom: getResponsiveSize(-8, laptopRatio), // -8px
                          },
                          '& .icon.icon-bottom': {
                            marginTop: getResponsiveSize(-8, laptopRatio), // -8px
                          },
                        },
                        '& .icon-top': {
                          transform: `translateY(-1px)`,
                        },
                        '& .icon-bottom': {
                          transform: `translateY(1px)`,
                        },
                      },
                    },
                  },
                },
              },
            },
            // Tablet XL screen
            [theme.breakpoints.media.tabletXL]: {
              '& tr th.custom-sort': {
                '& .label-wrapper .default-sort': {
                  '& .icon.icon-top': {
                    marginBottom: getResponsiveSize(-7, laptopRatio), // -7px
                  },
                  '& .icon.icon-bottom': {
                    marginTop: getResponsiveSize(-7, laptopRatio), // -7px
                  },
                },
                '&:hover': {
                  '& .label-wrapper': {
                    '& .default-sort': {
                      '& .icon.icon-top': {
                        marginBottom: getResponsiveSize(-7, laptopRatio), // -7px
                      },
                      '& .icon.icon-bottom': {
                        marginTop: getResponsiveSize(-7, laptopRatio), // -7px
                      },
                    },
                    '& .icon-top': {
                      transform: `translateY(-1px)`,
                    },
                    '& .icon-bottom': {
                      transform: `translateY(1px)`,
                    },
                  },
                },
              },
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            '& .MuiTableRow-root': {
              borderTop: `${getResponsiveSize(5)} solid ${
                theme.palette.myPrimary.white
              }`,
              borderBottom: `${getResponsiveSize(5)} solid ${
                theme.palette.myPrimary.white
              }`,
              height: getResponsiveSize(57), // 57px,
              backgroundColor: theme.palette.myPrimary.lightBlue10,
              ...theme.palette.propVars.easeInOutTransition,
              '&:hover': {
                backgroundColor: theme.palette.myPrimary.lightBlue50,
              },
            },
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              '& .MuiTableRow-root': {
                borderTop: `${getResponsiveSize(5, laptopRatio)} solid ${
                  theme.palette.myPrimary.white
                }`,
                borderBottom: `${getResponsiveSize(5, laptopRatio)} solid ${
                  theme.palette.myPrimary.white
                }`,
                height: getResponsiveSize(42, laptopRatio), // 57px,
              },
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              display: 'flex',
              flexDirection: 'column',
              gap: getResponsiveSize(8, mobileRatio), // 8px
              '& .MuiTableRow-root': {
                display: 'flex',
                flexDirection: 'column',
                borderRadius: getResponsiveSize(12, mobileRatio), // 12px
                gap: getResponsiveSize(8, mobileRatio), // 8px
                height: 'auto',
                borderTop: 0,
                borderBottom: 0,
              },
            },
            // Mobile screen
            [theme.breakpoints.media.mobile]: {
              '& .MuiTableRow-root': {
                maxWidth: '100%',
              },
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            borderBottom: '0px',
            padding: getResponsiveSize(16), // 16px
            paddingBottom: 0,
            paddingTop: 0,
            '& .data-cell': {
              fontFamily: theme.typography.mainFontFamily,
              fontSize: getResponsiveSize(14), // 14px
              color: theme.palette.neutral.grey80,
              textOverflow: 'ellipsis',
              textWrap: 'nowrap',
              overflow: 'hidden',
              '&.primary': {
                ...theme.palette.propVars.semiBoldFontWeight,
              },
              '&.secondary': {
                fontStyle: 'italic',
              },
              '&.numerics': {},
            },
            '&:first-of-type': {
              padding: `${getResponsiveSize(6)} ${getResponsiveSize(16)}`, // 6px 16px
            },
            '&:last-of-type': {
              padding: 0,
            },
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              '& .data-cell': {
                fontSize: getResponsiveSize(12, laptopRatio), // 12px
              },
              '&:first-of-type': {
                padding: `${getResponsiveSize(
                  3,
                  laptopRatio,
                )} ${getResponsiveSize(10, laptopRatio)}`, // 3px 10px
              },
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              padding: getResponsiveSize(16, mobileRatio), // 16px
              paddingBottom: 0,
              paddingTop: 0,
              "& [class*='-MUIDataTableBodyCell-simpleHeader']": {
                padding: '0 !important',
                color: theme.palette.myPrimary.lightBlue100,
                fontFamily: theme.typography.mainFontFamily,
                fontSize: getResponsiveSize(15, mobileRatio), // 15px
                marginBottom: getResponsiveSize(6, mobileRatio), // 6px
                letterSpacing: 'normal',
                textTransform: 'capitalize',
                '&.action': {
                  display: 'none',
                },
              },
              '&:first-of-type': {
                padding: `${getResponsiveSize(
                  12,
                  mobileRatio,
                )} ${getResponsiveSize(16, mobileRatio)}`, // 12px 16px
                paddingBottom: 0,
              },
              '&:last-of-type': {
                padding: `${getResponsiveSize(
                  12,
                  mobileRatio,
                )} ${getResponsiveSize(16, mobileRatio)}`, // 12px 16px
                paddingTop: 0,
              },
              "& [class*='-MUIDataTableBodyCell-simpleCell']": {
                padding: 0,
              },
              '& .data-cell': {
                fontSize: getResponsiveSize(14, mobileRatio), // 14px
              },
            },
            // Mobile screen
            [theme.breakpoints.media.mobile]: {
              '&:first-of-type': {
                paddingRight: '4px',
              },
            },
            ...rowAdditions,
          },
        },
      },
      MUIDataTableFooter: {
        styleOverrides: {
          root: {
            background: theme.palette.myPrimary.lightGrey,
            marginLeft: getResponsiveSize(16), // 16px
            marginTop: getResponsiveSize(8), // 8px,
            borderBottomRightRadius: getResponsiveSize(12), // 12px
            borderBottomLeftRadius: getResponsiveSize(12), // 12px
            width: `calc(100% - ${getResponsiveSize(32)})`,
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              borderBottomRightRadius: getResponsiveSize(12, laptopRatio), // 12px
              borderBottomLeftRadius: getResponsiveSize(12, laptopRatio), // 12px
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              marginLeft: 0,
              marginTop: getResponsiveSize(8, mobileRatio), // 8px,
              width: '100%',
              borderBottomRightRadius: getResponsiveSize(12, mobileRatio), // 12px
              borderBottomLeftRadius: getResponsiveSize(12, mobileRatio), // 12px
            },
          },
        },
      },
      MUIDataTablePagination: {
        styleOverrides: {
          root: {
            width: '100%',
            overflowX: 'auto',
            backgroundColor: theme.palette.neutral.white,
            border: 'none',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            '&#menu-rows-per-page': {
              '& .MuiPaper-root': {
                marginTop: getResponsiveSize(-44), // -44px
                '& .MuiList-root': {
                  minWidth: 'unset',
                  marginBottom: 0,
                  '& .MuiButtonBase-root': {
                    display: 'flex',
                  },
                },
              },
            },
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              '&#menu-rows-per-page .MuiPaper-root': {
                marginTop: getResponsiveSize(-38, laptopRatio), // -38px
                '& .MuiButtonBase-root': {
                  fontSize: getResponsiveSize(14, laptopRatio), // 14px
                },
              },
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              '&#menu-rows-per-page .MuiPaper-root': {
                marginTop: getResponsiveSize(-38, mobileRatio), // -38px
                '& .MuiButtonBase-root': {
                  fontSize: getResponsiveSize(13, mobileRatio), // 13px
                },
              },
            },
          },
          paper: {
            boxShadow: '6px 20px 36px 0px rgba(28, 29, 34, 0.10)',
            marginTop: getResponsiveSize(15), // 15px
            marginLeft: getResponsiveSize(-70), // -70px
            borderRadius: getResponsiveSize(12), // 12px
            "& .MuiButtonBase-root, [class*='-MUIDataTableToolbar-filterCloseIcon']":
              {
                display: 'none',
              },
            '& fieldset': {
              padding: `${getResponsiveSize(12)} ${getResponsiveSize(10)}`, // 12px 10px
              '& .MuiFormGroup-root': {
                marginTop: 0,
                marginLeft: getResponsiveSize(4), // 4px
                marginRight: getResponsiveSize(8), // 8px
                gap: getResponsiveSize(4), // 4px
                '& .MuiFormControlLabel-root': {
                  margin: 0,
                  padding: `${getResponsiveSize(6)} ${getResponsiveSize(12)}`, // 6px 12px
                  borderRadius: getResponsiveSize(8), // 8px
                  backgroundImage: `linear-gradient(to left, transparent 50%, ${theme.palette.myPrimary.lightBlue20} 50%)`,
                  backgroundPosition: ' right bottom',
                  backgroundSize: '200%',
                  transition:
                    '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',

                  '&: hover': {
                    backgroundPosition: 'left bottom',
                  },
                  '& .MuiCheckbox-root': {
                    display: 'inline-flex',
                    width: 'auto',
                    height: 'auto',
                    color: theme.palette.myPrimary.lightBlue70,
                    ...theme.palette.propVars.linearTransition,
                    '&.Mui-checked': {
                      color: theme.palette.myPrimary.lightBlue70,
                    },
                    '&:hover': {
                      backgroundColor: theme.palette.myPrimary.lightBlue20,
                    },
                    '& svg': {
                      width: getResponsiveSize(24), // 24px
                      height: getResponsiveSize(24), // 24px
                      fontSize: theme.typography.fs24, // 24px
                      minHeight: 22,
                      minWidth: 22,
                    },
                  },
                  '& .MuiTypography-root': {
                    fontSize: getResponsiveSize(15), // 15px
                    color: theme.palette.neutral.grey70,
                    userSelect: 'none !important',
                    fontFamily: theme.typography.mainFontFamily,
                  },
                },
              },
            },
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              marginTop: getResponsiveSize(10, laptopRatio), // 10px
              marginLeft: getResponsiveSize(-65, laptopRatio), // -35px
              borderRadius: getResponsiveSize(12, laptopRatio), // 12px
              '& fieldset': {
                padding: `${getResponsiveSize(
                  10,
                  laptopRatio,
                )} ${getResponsiveSize(8.5, laptopRatio)}`, // 10px 8.5px
                '& .MuiFormGroup-root': {
                  marginLeft: getResponsiveSize(4, laptopRatio), // 4px
                  marginRight: getResponsiveSize(8, laptopRatio), // 8px
                  gap: getResponsiveSize(4, laptopRatio), // 4px
                  '& .MuiFormControlLabel-root': {
                    padding: `${getResponsiveSize(
                      6,
                      laptopRatio,
                    )} ${getResponsiveSize(12, laptopRatio)}`, // 6px 12px
                    borderRadius: getResponsiveSize(8, laptopRatio), // 8px
                    '& .MuiCheckbox-root svg': {
                      fontSize: getResponsiveSize(22, laptopRatio), // 22px
                    },
                    '& .MuiTypography-root': {
                      fontSize: getResponsiveSize(13, laptopRatio), // 13px
                    },
                  },
                },
              },
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              marginTop: getResponsiveSize(10, mobileRatio), // 10px
              marginLeft: 0,
              borderRadius: getResponsiveSize(12, mobileRatio), // 12px
              '& fieldset': {
                padding: `${getResponsiveSize(
                  10,
                  mobileRatio,
                )} ${getResponsiveSize(8.5, mobileRatio)}`, // 10px 8.5px
                '& .MuiFormGroup-root': {
                  marginLeft: getResponsiveSize(4, mobileRatio), // 4px
                  marginRight: getResponsiveSize(8, mobileRatio), // 8px
                  gap: getResponsiveSize(4, mobileRatio), // 4px
                  '& .MuiFormControlLabel-root': {
                    padding: `${getResponsiveSize(
                      6,
                      mobileRatio,
                    )} ${getResponsiveSize(12, mobileRatio)}`, // 6px 12px
                    borderRadius: getResponsiveSize(8, mobileRatio), // 8px
                    '& .MuiCheckbox-root svg': {
                      fontSize: getResponsiveSize(22, mobileRatio), // 22px
                    },
                    '& .MuiTypography-root': {
                      fontSize: getResponsiveSize(13, mobileRatio), // 13px
                    },
                  },
                },
              },
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: getResponsiveSize(11), // 11px
            fontFamily: theme.typography.mainFontFamily,
            maxWidth: getResponsiveSize(300), // 300px
            padding: `${getResponsiveSize(4)} ${getResponsiveSize(8)}`, // 4px 8px
            // Laptop screen
            [theme.breakpoints.media.laptop]: {
              fontSize: getResponsiveSize(11, laptopRatio), // 11px
              maxWidth: getResponsiveSize(300, laptopRatio), // 300px
              padding: `${getResponsiveSize(
                4,
                laptopRatio,
              )} ${getResponsiveSize(8, laptopRatio)}`, // 4px 8px
            },
            // Table simple responsive screen
            [theme.breakpoints.media.tableResponsive]: {
              fontSize: getResponsiveSize(11, mobileRatio), // 11px
              maxWidth: getResponsiveSize(300, mobileRatio), // 300px
              padding: `${getResponsiveSize(
                4,
                mobileRatio,
              )} ${getResponsiveSize(8, mobileRatio)}`, // 4px 8px
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
            '& input[type=number]::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '& input[type=number]::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
          },
        },
      },
    },
  });
export default getMuiTheme;
