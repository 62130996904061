import { useHistory } from 'react-router-dom';
import { getParameterByName } from '../utils/history';

function usePasswordResetToken() {
  const history = useHistory();
  const passwordResetToken = getParameterByName(
    'password-reset',
    history.location.url,
  );
  return passwordResetToken;
}

export default usePasswordResetToken;
