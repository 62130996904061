import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class TemplatesSelect extends React.Component {
  render() {
    const { templates, i18n, selectedId } = this.props;
    let value = null;
    const options = templates
        .map((item) => ({ value: item.id, label: `${i18n.t(`devices.templates.${  (item.name || '').toLowerCase()}`)}`, template: item }))
        .sort((a, b) => a.value - b.value);

    if (selectedId) {
      value = options.find((item) => item.value === selectedId);
      this.props.handleChange(value);
    } else {
      value = options[0];
      this.props.handleChange(options[0]);
    }

    return (
      <>
        <label className='select-label'>{this.props.label || this.state.label}</label>
        <Select
          className='select-filter'
          options={options}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          placeholder={this.props.templates.isLoading ? i18n.t('devices.profile.loading') : i18n.t('devices.profile.select')}
          isDisabled={this.props.templates.isLoading}
          onChange={this.props.handleChange}
          value={value}
          inputProps={{
            name: 'product',
            id: 'select-product',
          }}
        />
      </>
    );
  }
}

export default withTranslation()(connect(
)(TemplatesSelect));
