import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import Select from 'react-select';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { deviceActions } from '../../store/actions/device.actions';

const DevicesSelect = ({
  getAllDevices,
  getDevicesByOrganizationId,
  devices,
  selectedId,
  organizationId,
  i18n,
  disabled,
  label,
  handleChange,
  isFullWidth = false,
  showDeviceNumber = false,
}) => {
  const fallback = 'devices.title';
  const { isLoading } = devices;
  const [selectedValue, setSelectedValue] = useState(null);

  useLayoutEffect(() => {
    organizationId
      ? getDevicesByOrganizationId(organizationId)
      : getAllDevices(false);
  }, [organizationId, getAllDevices, getDevicesByOrganizationId]);

  const options = useMemo(
    () =>
      devices.devices.map((item) => ({
        value: item.id,
        label: item.name,
        number:
          item?.generated_uuid?.split('_')[1] ||
          item?.generated_UUID?.split('_')[1],
      })),
    [devices],
  );

  const formatOptionLabel = useCallback(
    ({ value, label, number }) => (
      <div className='devices-option'>
        <div className='label'>{label}</div>
        {showDeviceNumber && (
          <div className='number'>
            <span className='label'>
              {i18n.t('devices.select-hover-serial-label')}:
            </span>
            {number}
          </div>
        )}
      </div>
    ),
    [i18n, showDeviceNumber],
  );

  const customFilter = useCallback(
    (option, searchText) => {
      const labelFilter = option.data?.label
        ?.toLowerCase()
        .includes(searchText.toLowerCase());
      const numberFilter = option.data?.number
        ?.toLowerCase()
        .includes(searchText.toLowerCase());
      const filter = showDeviceNumber
        ? labelFilter || numberFilter
        : labelFilter;
      if (filter) return true;
      else return false;
    },
    [showDeviceNumber],
  );

  useEffect(() => {
    if (!selectedId) {
      setSelectedValue(null);
      return;
    }

    const selectedOption = options.find((item) => item.value == selectedId);

    if (selectedOption) setSelectedValue(selectedOption);
  }, [selectedId, options]);

  return (
    <>
      <label className='select-label'>
        {label ? i18n.t(label) : i18n.t(fallback)}
      </label>
      <Select
        // menuIsOpen
        className='select-filter device'
        options={options}
        formatOptionLabel={formatOptionLabel}
        filterOption={customFilter}
        classNames={{
          menuPortal: () => 'select-device-portal',
        }}
        isClearable
        styles={{
          menuPortal: (base) => ({
            ...base,
            width: isFullWidth ? base.width : '100%',
            zIndex: 9999,
          }),
          menu: (base) => ({
            ...base,
            width: isFullWidth ? base.width : 'auto',
          }),
        }}
        menuPortalTarget={document.body}
        placeholder={
          isLoading ? i18n.t('devices.loading') : i18n.t('devices.select')
        }
        isDisabled={isLoading || disabled}
        onChange={handleChange}
        value={selectedValue}
        inputProps={{
          name: 'device',
          id: 'select-device',
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllDevices: deviceActions.getAllDevices,
  getDevicesByOrganizationId: deviceActions.getDevicesByOrganizationId,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DevicesSelect),
);
