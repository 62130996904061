import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const pillIconStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: getResponsiveSize(14), // 14px
    fontFamily: theme.typography.mainFontFamily,
    color: theme.palette.neutral.grey80,
    ...theme.palette.propVars.semiBoldFontWeight,

    '& svg': {
      fontSize: getResponsiveSize(16), // 16px
      minHeight: 16,
      minWidth: 16,
    },

    '& .remote': {
      width: getResponsiveSize(16), // 16px
      height: getResponsiveSize(16), // 16px
      marginRight: getResponsiveSize(4), // 4px
      path: {
        fill: theme.palette.myPrimary.darkBlue50,
      },
    },

    '& .plus': {
      width: getResponsiveSize(13), // 13px
      height: getResponsiveSize(13), // 13px
      marginRight: getResponsiveSize(5), // 5px
      path: {
        fill: theme.palette.myPrimary.green,
      },
    },
    '& .vr': {
      width: getResponsiveSize(16), // 16px
      height: getResponsiveSize(16), // 16px
      marginRight: getResponsiveSize(4), // 4px

      path: {
        fill: theme.palette.myPrimary.darkAquamarine,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      fontSize: getResponsiveSize(12, laptopRatio), // 12px
      '& .remote': {
        marginRight: getResponsiveSize(4, laptopRatio), // 4px
      },
      '& .plus': {
        marginRight: getResponsiveSize(5, laptopRatio), // 5px
      },
      '& .vr': {
        marginRight: getResponsiveSize(4, laptopRatio), // 4px
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      fontSize: getResponsiveSize(14, mobileRatio), // 14px
      '& .remote': {
        marginRight: getResponsiveSize(4, mobileRatio), // 4px
      },
      '& .plus': {
        marginRight: getResponsiveSize(4, mobileRatio), // 4px
      },
      '& .vr': {
        marginRight: getResponsiveSize(4, mobileRatio), // 4px
      },
    },
  },
};
