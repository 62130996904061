import { memo, useCallback, useMemo, useState, useEffect } from 'react';
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
} from '@mui/icons-material';
import {
  Box,
  Input as MuiInput,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { withTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import { classNames } from '../../../../utils';

import { ButtonUi } from '../../Button/Button';
import SelectUI from '../../Select/Select';
import { LoadingSkeleton } from '../LoadingSkeleton/LoadingSkeleton';
import { WizardError } from '../../../Treatments/CreateTreatmentWizard/WizardFooter/WizardError/WizardError';
import { customMuiDatatableFooter } from './style';
import { useCallbackDebounce } from '../../../../hooks/useCallbackDebounce';

const CustomMuiDatatableFooter = (props) => {
  const {
    isPaginationVissible = true,
    count,
    page,
    isLoading,
    isEmpty,
    rowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels,
    rowsPerPageOptions,
    t,
  } = props;

  const isTableResponsive = useMediaQuery('(max-width: 601px)');
  const [inputPage, setInputPage] = useState(1);
  const [prevValidValue, setPrevValidValue] = useState(1);
  const [isError, setIsError] = useState(false);
  const { footer, inputStyle } = customMuiDatatableFooter;

  /**
   * Current page
   */
  const currentPage = useMemo(() => page + 1, [page]);

  /**
   * Total pages amount
   */
  const pagesAmount = useMemo(
    () => Math.ceil(count / rowsPerPage),
    [count, rowsPerPage],
  );

  /**
   * Total pages label
   */
  const pagesLabel = useMemo(() => {
    const label =
      pagesAmount === 1
        ? t('mui-datatables.footer.page')
        : t('mui-datatables.footer.pages');
    return ` ${textLabels.displayRows} ${pagesAmount} ${
      !isTableResponsive ? label : ''
    }`;
  }, [isTableResponsive, pagesAmount, t, textLabels.displayRows]);

  /**
   * From to results label
   */
  const rowsResultsLabel = useMemo(() => {
    const potentialToAmount = currentPage * rowsPerPage;
    const fromAmount = page === 0 ? 1 : page * rowsPerPage;
    const toAmount = potentialToAmount >= count ? count : potentialToAmount;

    return `${fromAmount}-${toAmount} ${textLabels.displayRows} ${count}`;
  }, [count, currentPage, page, rowsPerPage, textLabels.displayRows]);

  const rowsOptions = useMemo(
    () =>
      rowsPerPageOptions.map((option) => ({
        text: option,
        value: option,
      })),
    [rowsPerPageOptions],
  );

  /**
   * Set rows per page
   */
  const handleRowChange = useCallback(
    ({ value }) => {
      changeRowsPerPage(value);
    },
    [changeRowsPerPage],
  );

  /**
   * Set page
   */
  const handlePageChange = useCallback(
    (page) => {
      changePage(page);
    },
    [changePage],
  );

  /**
   * Shows an error
   */
  const showError = () => {
    setIsError(true);
    setTimeout(() => {
      setIsError(false);
    }, 2000);
  };

  /**
   * Validates input data and sets the page
   * @param value input data
   */
  const validateInputData = useCallback(
    (value) => {
      if (value > pagesAmount || value < 1) {
        showError();
        setInputPage(parseFloat(prevValidValue));
        return;
      }
      setPrevValidValue(value);
      changePage(value - 1);
    },
    [changePage, pagesAmount, prevValidValue],
  );

  /**
   * Debounced input change handler
   */
  const debouncedInputHandler = useCallbackDebounce(validateInputData, 500);

  /**
   * Handles input changes and debounced call input validation
   * @param event native event
   */
  const handleInputChange = (event) => {
    const page = event.target.value === '' ? '' : Number(event.target.value);
    setInputPage(page);
    debouncedInputHandler(+page);
  };

  /**
   * Adds label to selected value
   */
  const renderSelectedValue = useCallback(
    (value) => `${value} ${t('mui-datatables.footer.per-page')}`,
    [t],
  );

  useEffect(() => {
    setInputPage(currentPage);
  }, [currentPage]);

  /**
   * Returns footer loading skeleton
   */
  if (isLoading && isPaginationVissible)
    return <LoadingSkeleton variant='footer' />;

  /**
   * Don't show pagination if pagination option is false
   */
  if (!isPaginationVissible || isEmpty) return null;

  return (
    <TableFooter sx={footer}>
      <TableRow>
        <TableCell className='left'>
          <Box className='pages'>
            <Box className='error-wrapper'>
              <Transition
                in={isError}
                // in={true}
                timeout={300}
                mountOnEnter
                unmountOnExit
                appear={true}
              >
                {(state) => (
                  <WizardError
                    className={classNames('error', {}, [
                      'visible',
                      'slide-top',
                      `slide-in-${state}`,
                    ])}
                  >
                    {t('errors.validation.pagination')}
                  </WizardError>
                )}
              </Transition>
              <MuiInput
                value={inputPage}
                size='small'
                name='paginator'
                onChange={handleInputChange}
                sx={inputStyle}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: pagesAmount,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Box>
            <Typography variant='body1'>{pagesLabel}</Typography>
          </Box>

          <Box className='total-results'>
            <Typography variant='body1'>{rowsResultsLabel}</Typography>
          </Box>
        </TableCell>
        <TableCell className='right'>
          <Box className='rows-per-page'>
            <SelectUI
              idPrefix='rows-per-page'
              size='medium'
              name='rows-per-page'
              selectValue={rowsPerPage}
              onChangeHandler={handleRowChange}
              options={rowsOptions}
              renderValue={renderSelectedValue}
              dropdownDirection='bottom'
              hideHints
            />
          </Box>

          <Box className='pagination'>
            <ButtonUi
              isIconBtn
              disabled={page === 0}
              className='prev'
              size='small'
              onClickHandler={() => handlePageChange(page - 1)}
            >
              <NavigateBeforeRounded />
            </ButtonUi>
            <ButtonUi
              isIconBtn
              disabled={currentPage >= pagesAmount}
              className='next'
              size='small'
              onClickHandler={() => handlePageChange(page + 1)}
            >
              <NavigateNextRounded />
            </ButtonUi>
          </Box>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
export default withTranslation()(memo(CustomMuiDatatableFooter));
