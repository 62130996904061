import { memo, useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Popper,
  Typography,
  Zoom,
  Input as MuiInput,
  Tooltip,
  ClickAwayListener,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import {
  ExpandMoreOutlined,
  DragHandleRounded as EqualIcon,
  KeyboardArrowRightRounded as GreaterIcon,
  KeyboardArrowLeftRounded as LessIcon,
} from '@mui/icons-material';

import { Transition } from 'react-transition-group';

import { useCallbackDebounce } from '../../../hooks/useCallbackDebounce';

import { classNames } from '../../../utils';

import { ButtonUi } from '../Button/Button';
import { DatePickerUi } from '../DatePicker/DatePicker';
import { WizardError } from '../../Treatments/CreateTreatmentWizard/WizardFooter/WizardError/WizardError';

import { logicCheckStyle } from './style';

const MIN_INPUT = -99;
const MAX_INPUT = 99;

const poperModifier = [
  {
    name: 'offset',
    options: {
      offset: [0, 8],
    },
  },
];

export const LogicCheckFilterItem = memo((props) => {
  const {
    label,
    loading,
    touched,
    type, // number | calendar
    onChange,
    min = MIN_INPUT,
    max = MAX_INPUT,
    filter,
    className,
  } = props;
  const { logicCheck, inputStyle } = logicCheckStyle;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sign, setSign] = useState(null);
  const [value, setValue] = useState(0);
  const [prevValidValue, setPrevValidValue] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isTouched, setIsTouched] = useState(null);

  /**
   * Handles options popper open-close state
   * @param event native event
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  /**
   * Handles options popper close when it clicks out of component
   */
  const handleClickAway = () => setOpen(false);

  /**
   * Returns sign depends on selected condition option
   */
  const renderActiveSign = useMemo(() => {
    switch (sign) {
      case 'lt':
        return <LessIcon />;
      case 'eq':
        return <EqualIcon />;
      case 'gt':
        return <GreaterIcon />;
      default:
        return <ExpandMoreOutlined />;
    }
  }, [sign]);

  /**
   * Checks does option is selected
   * @param option selected option
   */
  const isOptionSelected = (option) => sign === option;

  /**
   * Handles condition option select
   * @param option selected option
   */
  const handleOptionSelect = (option) => {
    setSign(option);
    onChange({ value, sign: option });
    setOpen((previousOpen) => !previousOpen);
  };

  /**
   * Shows an error
   */
  const showError = () => {
    setIsError(true);
    setTimeout(() => {
      setIsError(false);
    }, 2000);
  };

  /**
   * Validates input data
   * @param value input data
   */
  const validateInputData = (value) => {
    if (value > max || value < min) {
      showError();
      setValue(parseFloat(prevValidValue));
      return;
    }
    setPrevValidValue(value);
    // Sets the sign value to equal if filter firstly changed
    if (!isTouched) {
      setIsTouched(true);
      if (!sign) onChange({ value, sign: 'eq' });
    } else onChange({ value, sign });
  };

  /**
   * Debounced input change handler
   */
  const debouncedInputHandler = useCallbackDebounce(validateInputData, 500);

  /**
   * Handles input changes and debounced call input validation
   * @param event native event
   */
  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
    debouncedInputHandler(+event.target.value);
  };

  /**
   * Date change hander
   * @param value selected date
   */
  const onDateChange = useCallback(
    (value) => {
      // Sets the sign value to equal if filter firstly changed
      if (!isTouched) {
        setIsTouched(true);
        if (!sign) onChange({ value, sign: 'eq' });
      } else onChange({ value, sign });
    },
    [isTouched, onChange, sign],
  );

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  /**
   * Condition button optional class names
   */
  const conditionBtnMods = { selected: sign || open, open: open && !sign };

  /**
   * Init sign and value after reset the filter data
   */
  useEffect(() => {
    setSign(filter?.sign);
    setValue(filter?.value || 0);
    setIsTouched(touched || false);
    setPrevValidValue(filter?.value || 0);
  }, [filter, touched]);

  return (
    <Box
      sx={logicCheck}
      className={classNames('logic-filter', {}, [className])}
    >
      <Typography variant='body2' className='label'>
        {t(label)}
      </Typography>
      <ButtonUi
        isIconBtn
        className={classNames('active-sign', conditionBtnMods)}
        size='small'
        onClickHandler={handleClick}
      >
        {renderActiveSign}
      </ButtonUi>
      <Popper
        disablePortal
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        modifiers={poperModifier}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Zoom {...TransitionProps} timeout={300}>
              <Box className='option-wrapper'>
                <ButtonUi
                  isIconBtn
                  tooltipTitle={t('buttons.tooltips.less_then')}
                  tooltipPlacement='bottom'
                  size='small'
                  className={classNames('option', {
                    selected: isOptionSelected('lt'),
                  })}
                  disabled={isOptionSelected('lt')}
                  onClickHandler={() => handleOptionSelect('lt')}
                >
                  <LessIcon />
                </ButtonUi>
                <ButtonUi
                  isIconBtn
                  tooltipTitle={t('buttons.tooltips.equal')}
                  tooltipPlacement='bottom'
                  size='small'
                  className={classNames('option', {
                    selected: isOptionSelected('eq'),
                  })}
                  disabled={isOptionSelected('eq')}
                  onClickHandler={() => handleOptionSelect('eq')}
                >
                  <EqualIcon />
                </ButtonUi>
                <ButtonUi
                  isIconBtn
                  tooltipTitle={t('buttons.tooltips.greater_then')}
                  tooltipPlacement='bottom'
                  size='small'
                  className={classNames('option', {
                    selected: isOptionSelected('gt'),
                  })}
                  disabled={isOptionSelected('gt')}
                  onClickHandler={() => handleOptionSelect('gt')}
                >
                  <GreaterIcon />
                </ButtonUi>
              </Box>
            </Zoom>
          </ClickAwayListener>
        )}
      </Popper>
      {type === 'number' ? (
        <Box className='error-wrapper'>
          <Transition
            in={isError}
            timeout={300}
            mountOnEnter
            unmountOnExit
            appear={true}
          >
            {(state) => (
              <WizardError
                className={classNames('error', {}, [
                  'visible',
                  'slide-top',
                  `slide-in-${state}`,
                ])}
              >
                {t('errors.validation.range_input', { min, max })}
              </WizardError>
            )}
          </Transition>
          <MuiInput
            value={value}
            size='small'
            onChange={handleInputChange}
            sx={inputStyle}
            inputProps={{
              step: '1',
              min: { min },
              max: { max },
              type: 'number',
            }}
          />
        </Box>
      ) : (
        <DatePickerUi value={value} handleDateChange={onDateChange} />
      )}
    </Box>
  );
});
