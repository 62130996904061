import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { users } from './users.reducer';
import { authentication } from './authentication.reducer';
import { sessions } from './session.reducer';
import { organizations } from './organization.reducer';
import { teams } from './team.reducer';
import { treatments } from './treatment.reducer';
import { practitioners } from './practitioner.reducer';
import { devices } from './device.reducer';
import { clients } from './client.reducer';
import { dashboard } from './dashboard.reducer';
import { products } from './product.reducer';
import { treatmentsRemote } from './treatment-remote.reducer';
import { createTreatmentWizard } from './create-treatment-wizard.reducer';

const appReducer = combineReducers({
  alert,
  users,
  authentication,
  sessions,
  organizations,
  teams,
  treatments,
  practitioners,
  devices,
  clients,
  dashboard,
  products,
  treatmentsRemote,
  createTreatmentWizard,
});

const rootReducer = (state, action) => {
  // Clear state on logout.
  if (action.type === 'USERS_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
