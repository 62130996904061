import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}/teams`;

const getAllTeams = (optionalFilter = {}, organizationId = null) => {
  const deleteFilter = { where: { is_deleted: false } };
  if (organizationId) {
    deleteFilter.where.organizationId = organizationId;
  }
  const filter = {
    include: ['organization'],
    ...optionalFilter,
    ...deleteFilter,
  };
  return axios.get(`${baseURL}${authUrl()}&filter=${JSON.stringify(filter)}`);
};

const getTeamsOverview = (
  offset = 0,
  limit = 10,
  order = 'sessions DESC',
  orgId = null,
  searchQuery = '',
  monthlySession = null,
  totalSessions = null,
  activeClients = null,
) => {
  const organizationId = orgId ? `&organizationId=${orgId}` : '';
  const clients = activeClients ? `&clients=${activeClients}` : '';
  const sessions = totalSessions ? `&sessions=${totalSessions}` : '';
  const monthly_sessions = monthlySession
    ? `&monthly_sessions=${monthlySession}`
    : '';

  const filterString = `&offset=${offset}&limit=${limit}&order=${order}&query=${searchQuery}${organizationId}${clients}${sessions}${monthly_sessions}`;
  return axios.get(`${baseURL}/overview${authUrl()}&${filterString}`);
};

const getTeamsCount = (filter = '') =>
  axios.get(`${BASEURL}/teams/count${authUrl()}${filter}`).catch((error) => {
    console.error(error);
    return null;
  });

const findTeamById = (teamId) => {
  const filter = { include: ['clients', 'therapists', 'organization'] };
  return axios.get(
    `${baseURL}/${teamId}${authUrl()}&filter=${JSON.stringify(filter)}`,
  );
};

// Not needed to be an action because it is not used from components.
const getCountOfClients = (teamId) => {
  const filter = `&filter[where][active]=true&[where][teamId]=${teamId}`;
  return axios
    .get(`${BASEURL}/clients/count${authUrl()}${filter}`)
    .catch((error) => {
      console.error(error);
      return null;
    });
};

const getPractitionersOfTeam = (teamId) =>
  axios.get(`${baseURL}/${teamId}/therapists${authUrl()}`, {});

const getClientsOfTeam = (teamId) =>
  axios.get(`${baseURL}/${teamId}/clients${authUrl()}`, {});

const createTeam = (client) => {
  const { name, organizationId } = client;

  return axios.post(baseURL + authUrl(), {
    name,
    organizationId,
  });
};

const updateTeam = (team) => {
  const { id } = team;
  const newTeam = JSON.parse(JSON.stringify(team));
  delete newTeam.id;
  return axios.patch(`${baseURL}/${id}${authUrl()}`, newTeam);
};

const linkClientToTeam = (teamId, clientId) =>
  axios.patch(`${BASEURL}/clients/${clientId}${authUrl()}`, { teamId });

const removeClientFromTeam = (teamId, clientId) =>
  axios.patch(`${BASEURL}/clients/${clientId}${authUrl()}`, { teamId: null });

const linkPractitionerToTeam = (teamId, therapistId) =>
  axios.put(`${baseURL}/${teamId}/therapists/rel/${therapistId}${authUrl()}`);

const RemovePractitionerFromTeam = (teamId, therapistId) =>
  axios.delete(
    `${baseURL}/${teamId}/therapists/rel/${therapistId}${authUrl()}`,
  );

const deleteTeamById = (id) => {
  if (!id) return null;
  return updateTeam({
    id,
    is_deleted: true,
  });
};

export const teamService = {
  getAllTeams,
  findTeamById,
  getCountOfClients,
  getPractitionersOfTeam,
  getClientsOfTeam,
  createTeam,
  updateTeam,
  linkClientToTeam,
  linkPractitionerToTeam,
  removeClientFromTeam,
  RemovePractitionerFromTeam,
  deleteTeamById,
  getTeamsCount,
  getTeamsOverview,
};
