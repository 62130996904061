import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}/clients`;

const getAllClients = (filterString = '') => {
  if (filterString) {
    filterString = `&${filterString}`;
  }
  return axios.get(`${baseURL}${authUrl()}${filterString}`);
};

const getClientsCount = (teamId) => {
  const filter = '&filter[where][active]=true';
  return axios.get(
    `${BASEURL}/teams/${teamId}/clients/count${authUrl()}${filter}`,
  );
};

const getAllClientsWithStats = (
  offset = 0,
  limit = 10,
  order = 'name DESC',
  searchQuery = '%',
  anonymousInclude,
  startScore = null,
  endScore = null,
  totalSessions = null,
  monthlySession = null,
  sudDecrease = null,
) => {
  const start_score = startScore ? `&start_score_range=[${startScore}]` : '';
  const end_score = endScore ? `&end_score_range=[${endScore}]` : '';
  const decrease = sudDecrease ? `&decrease_range=[${sudDecrease}]` : '';
  const sessions = totalSessions ? `&sessions=${totalSessions}` : '';
  const monthly_sessions = monthlySession
    ? `&monthly_sessions=${monthlySession}`
    : '';
  const filterString = `&offset=${offset}&limit=${limit}&order=${order}&query=${searchQuery}&anonymous=${anonymousInclude}${start_score}${end_score}${decrease}${sessions}${monthly_sessions}`;
  return axios.get(`${baseURL}/findWithStats${authUrl()}${filterString}`);
};

const createClient = (client) => {
  const { name, teamId } = client;

  return axios.post(baseURL + authUrl(), {
    name,
    teamId,
    active: true,
  });
};

const findClientById = (id) => axios.get(`${baseURL}/${id}${authUrl()}`, {});

const updateClient = (client) => {
  const { id } = client;
  delete client.id;
  return axios.patch(`${baseURL}/${id}${authUrl()}`, client);
};

const deleteClientById = (clientId) => {
  if (!clientId) {
    return null;
  }
  return axios.delete(`${baseURL}/${clientId}${authUrl()}`);
};

export const clientService = {
  getAllClients,
  createClient,
  findClientById,
  updateClient,
  getAllClientsWithStats,
  deleteClientById,
  getClientsCount,
};
