import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';
import AutocompleteUi from '../UI/Autocomplete/Autocomplete';

export const TeamSelect = memo((props) => {
  const { handleChange, selectedId } = props;
  const { t } = useTranslation();
  const { getAllTeamsWithoutMeta: getAllTeams } = useActions();
  const { teams, isLoading } = useSelector((state) => state.teams);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const options = useMemo(
    () =>
      teams.map((team) => ({
        text: team.name,
        value: team.id,
      })),
    [teams],
  );

  /**
   * Handle Autocomplete`s options render
   * @param optionProps option props
   * @param obj option text and value
   * @returns ReactNode for each Autocomplete option
   */
  const handleAutocompleteRenderOptions = useCallback(
    (optionProps, { text, value }) => (
      <li {...optionProps} key={value}>
        {text}
      </li>
    ),
    [],
  );

  /**
   * Handle Autocomplete change event
   * (option selection, clear value, close...)
   * @param event
   * @param value selected option value
   */
  const handleAutocompleteChange = useCallback(
    (event, value) => {
      setSelectedTeam(value);
      handleChange(value);
    },
    [handleChange],
  );

  /**
   * Fetches all teams if they are not defined
   * Sets autocomplete value to selectedId
   */
  useEffect(() => {
    if (teams.length <= 0) getAllTeams();
    if (selectedId)
      setSelectedTeam(options.find((item) => item.value === selectedId));
  }, []);

  /**
   * Sets autocomplete value to default team
   */
  useEffect(() => {
    if (!selectedId && options.length > 0) {
      setSelectedTeam(options[0]);
      handleChange(options[0]);
    }
  }, [options]);

  return (
    <AutocompleteUi
      className='team-select'
      label={t('teams.select')}
      placeholder=''
      autocompleteValue={selectedTeam}
      onAutocompleteChangeHandler={handleAutocompleteChange}
      onAutocompleteRenderOptionsHandler={handleAutocompleteRenderOptions}
      options={options}
      loading={isLoading}
    />
  );
});
