import { useEffect, useState, memo } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

import { ReactComponent as LogoIcon } from '../../../../shared/assets/icons/logo.svg';

import { AppImage } from '../../AppImage/AppImage';
import { organizationImageStyle } from './style';

export const OrganizationImage = memo((props) => {
  const { className, orgName, img } = props;
  const { organizationImage } = organizationImageStyle;

  /**
   * Image loading fallback
   */
  const fallback = <Skeleton variant='rounded' width='100%' height='100%' />;

  /**
   * Image loading error fallback
   */
  const errorFallback = <LogoIcon className='no-image-fallback' />;

  return (
    <Box sx={organizationImage} className={className}>
      <AppImage
        fallback={fallback}
        errorFallback={errorFallback}
        src={img}
        className='img'
        alt='organization image'
      />
      <Typography variant='body2'>{orgName}</Typography>
    </Box>
  );
});
