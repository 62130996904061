import { Box, Typography } from '@mui/material';
import { memo, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  CloseRounded,
  DragHandleRounded as EqualIcon,
  KeyboardArrowRightRounded as GreaterIcon,
  KeyboardArrowLeftRounded as LessIcon,
} from '@mui/icons-material';

import { ReactComponent as DoneIcon } from '../../../../shared/assets/icons/circle_check.svg';

import { ButtonUi } from '../../Button/Button';
import { CustomMuiDataTableComplete } from '../CustomMuiDataTableComplete/CustomMuiDataTableComplete';
import { filterListStyle } from './style';

export const CustomMuiDataTableFilterList = memo((props) => {
  const { filters, setFilters } = props;
  const { filterList } = filterListStyle;
  const { shortOrganizations: organizationOptions } = useSelector(
    (state) => state.organizations,
  );
  const { t } = useTranslation();

  const onDeleteFilter = useCallback(
    (filter) => {
      setFilters((current) => {
        const { [filter]: removedFilter, ...rest } = current;
        return rest;
      });
    },
    [setFilters],
  );

  /**
   * Gets organization name by id
   * @param organizationId organization id
   */
  const getOrganizationNameById = useCallback(
    (organizationId) =>
      organizationOptions.find(
        (organization) => organization.id === organizationId,
      )?.name,
    [organizationOptions],
  );

  /**
   * Transforms filter key name into the readable name
   * @param key filter name
   */
  const getFilterKeyTranslation = useCallback(
    (key) => {
      switch (key) {
        case 'generated_uuid':
          return t('forms.uuid');
        case 'organizationId':
          return t('forms.organization_name');
        case 'monthly_sessions':
          return t('forms.total.monthly_sessions');
        case 'decrease':
          return t('forms.delta');
        case 'clients':
          return t('forms.total.active_clients');
        case 'sessions':
          return t('forms.total.sessions');
        case 'device_name':
          return t('forms.device');
        case 'treatment_type':
          return t('forms.type');
        case 'start_score':
          return t('forms.begin_score');
        case 'therapists':
          return t('forms.total.therapists');
        default:
          return t(`forms.${key}`);
      }
    },
    [t],
  );

  /**
   * Transforms filtres value
   * @param filter filter object
   * @param key filter name
   */
  const getFiltervValue = useCallback(
    (filter, key) => {
      const hasSign = filter?.hasOwnProperty('sign');
      const lowVal = filter?.value[0] || 0;
      const highVal = filter?.value[1] || 0;

      if (hasSign) {
        let sign = <EqualIcon />;
        switch (filter.sign) {
          case 'gt':
            sign = <GreaterIcon />;
            break;
          case 'lt':
            sign = <LessIcon />;
            break;
        }
        return (
          <Typography variant='body2' className='value'>
            <Box component='span' className='condition'>
              {sign}
              {filter.value}
            </Box>
          </Typography>
        );
      }
      if (key === 'organizationId')
        return (
          <Typography variant='body2' className='value'>
            {getOrganizationNameById(filter?.value)}
          </Typography>
        );
      if (key === 'completed')
        return (
          <Box component='span' className='value'>
            <CustomMuiDataTableComplete result={filter?.value === 'true'} />
          </Box>
        );
      if (key === 'show_deleted')
        return (
          <Box component='span' className='value'>
            <DoneIcon className='check' />
          </Box>
        );

      return (
        <Typography variant='body2' className='value'>
          {Array.isArray(filter?.value)
            ? t('mui-datatables.filters.chips-list', {
                lowVal,
                highVal,
              })
            : filter?.value}
        </Typography>
      );
    },
    [getOrganizationNameById, t],
  );

  /**
   * Renders active filters chips
   */
  const renderFilterChips = useMemo(
    () =>
      Object.keys(filters)?.map((key) => {
        const isFilterDefined =
          filters[key]?.hasOwnProperty('value') && filters[key]?.value !== null;
        if (!isFilterDefined) return null;
        return (
          <Box className='chip' key={key}>
            <Typography variant='body2' className='label'>
              {getFilterKeyTranslation(key)}:
            </Typography>
            {getFiltervValue(filters[key], key)}
            <ButtonUi
              isIconBtn
              className='delete'
              size='small'
              onClickHandler={() => onDeleteFilter(key)}
            >
              <CloseRounded />
            </ButtonUi>
          </Box>
        );
      }),
    [filters, getFilterKeyTranslation, getFiltervValue, onDeleteFilter],
  );

  /**
   * Returns null if there is no filters
   */
  if (Object.keys(filters)?.length === 0) return null;

  return (
    <Box className='filters-row' sx={filterList}>
      {renderFilterChips}
    </Box>
  );
});
