import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const durationStyle = {
  container: {
    '& .time': {
      display: 'flex',
      alignItems: 'baseline',
      fontSize: getResponsiveSize(14), // 14px
      fontFamily: theme.typography.mainFontFamily,
      color: theme.palette.neutral.grey80,
      ...theme.palette.propVars.semiBoldFontWeight,
      '& .time-symbols': {
        fontSize: getResponsiveSize(12), // 12px
        fontFamily: theme.typography.mainFontFamily,
        color: theme.palette.myPrimary.darkBlue100,
        lineHeight: getResponsiveSize(19), // 19px
        mr: getResponsiveSize(3), // 3px
        ...theme.palette.propVars.semiBoldFontWeight,
      },
    },

    '& svg': {
      width: getResponsiveSize(16), // 16px,
      height: getResponsiveSize(16), // 16px,
      minHeight: 16,
      minWidth: 16,
      fill: theme.palette.myPrimary.lightBlue70,
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .time': {
        fontSize: getResponsiveSize(12, laptopRatio), // 12px
        '& .time-symbols': {
          fontSize: getResponsiveSize(10, laptopRatio), // 10px
          lineHeight: getResponsiveSize(18, laptopRatio), // 18px
          mr: getResponsiveSize(3, laptopRatio), // 3px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '& .time': {
        fontSize: getResponsiveSize(14, mobileRatio), // 14px
        '& .time-symbols': {
          fontSize: getResponsiveSize(14, mobileRatio), // 14px
          lineHeight: getResponsiveSize(24, mobileRatio), // 24px
          mr: getResponsiveSize(4, mobileRatio), // 4px
        },
      },
    },
  },
};
