import { getI18n } from 'react-i18next';
import moment from 'moment';
import { teamService } from '../../services/teams';
import { statisticsService } from '../../services/statistics';
import { teamConstants } from '../constants/team.constants';
import { alertActions } from './alert.actions';

function getAllTeams(
  offset = 0,
  limit = 10,
  order,
  organizationId = null,
  searchQuery = '',
  monthlySession,
  totalSessions,
  activeClients,
) {
  function request() {
    return { type: teamConstants.GETALL_REQUEST };
  }

  function success(teams) {
    return { type: teamConstants.GETALL_SUCCESS, teams };
  }

  function failure(error) {
    return { type: teamConstants.GETALL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    return teamService
      .getTeamsOverview(
        offset,
        limit,
        order,
        organizationId,
        searchQuery,
        monthlySession,
        totalSessions,
        activeClients,
      )
      .then(
        (result) => {
          const { teams = [], totalCount = 0 } = result?.data || {};
          dispatch(
            success({
              data: teams.map((team) => getTeamWithMetadataAsTableRow(team)),
              totalCount,
            }),
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(getI18n().t('errors.get.teams')));
        },
      );
  };
}

function getTeamsCount(filter) {
  const request = () => ({ type: teamConstants.COUNT_REQUEST });
  const success = (count) => ({ type: teamConstants.COUNT_SUCCESS, count });
  const failure = (error) => ({ type: teamConstants.COUNT_FAILURE, error });
  return (dispatch) => {
    dispatch(request());
    return teamService.getTeamsCount(filter).then(
      (result) => {
        const { count } = result.data;
        dispatch(success(count));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.teams')));
      },
    );
  };
}

function getAllTeamsWithoutMeta() {
  return (dispatch) => {
    dispatch(request());
    return teamService.getAllTeams().then(
      (result) => {
        const teams = result.data;
        dispatch(success({ data: teams }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.teams')));
      },
    );
  };

  function request() {
    return { type: teamConstants.GETALL_REQUEST };
  }

  function success(teams) {
    return { type: teamConstants.GETALL_SUCCESS, teams };
  }

  function failure(error) {
    return { type: teamConstants.GETALL_FAILURE, error };
  }
}

function findTeamById(id) {
  return (dispatch) => {
    dispatch(request({ id }));
    return teamService.findTeamById(id).then(
      (team) => {
        dispatch(success(team.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request(team) {
    return { type: teamConstants.GET_REQUEST, team };
  }

  function success(team) {
    return { type: teamConstants.GET_SUCCESS, team };
  }

  function failure(error) {
    return { type: teamConstants.GET_FAILURE, error };
  }
}

function createTeam(teamToCreate) {
  return (dispatch) => {
    dispatch(request(teamToCreate));
    return teamService.createTeam(teamToCreate).then(
      (result) => {
        const createdTeam = result.data;
        const team = getTeamWithMetadataAsTableRow(createdTeam);
        dispatch(success({ team }));
        return team;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.add.team')));
      },
    );
  };

  function request(team) {
    return { type: teamConstants.CREATE_REQUEST, team };
  }

  function success(team) {
    return { type: teamConstants.CREATE_SUCCESS, team: team.team };
  }

  function failure(error) {
    return { type: teamConstants.CREATE_FAILURE, error };
  }
}

function updateTeam(team) {
  return (dispatch) => {
    dispatch(request(team));
    return teamService
      .updateTeam(team)
      .then(() => {
        const teamRow = getTeamWithMetadataAsTableRow(team);
        dispatch(success({ team: teamRow }));
        return teamRow;
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.update.team')));
      });
  };

  function request(team) {
    return { type: teamConstants.UPDATE_REQUEST, team };
  }

  function success(team) {
    return { type: teamConstants.UPDATE_SUCCESS, team: team.team };
  }

  function failure(error) {
    return { type: teamConstants.UPDATE_FAILURE, error };
  }
}

function linkClientToTeam(teamId, clientId) {
  return (dispatch) => {
    dispatch(request(teamId));
    return teamService.linkClientToTeam(teamId, clientId).then(
      () =>
        teamService.findTeamById(teamId).then((result) => {
          dispatch(success({ team: result.data }));
        }),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.add.team-client')));
      },
    );
  };

  function request(team) {
    return { type: teamConstants.UPDATE_REL_REQUEST, team };
  }

  function success(team) {
    return { type: teamConstants.UPDATE_REL_SUCCESS, team: team.team };
  }

  function failure(error) {
    return { type: teamConstants.UPDATE_REL_FAILURE, error };
  }
}

function linkPractitionerToTeam(teamId, clientId) {
  return (dispatch) => {
    dispatch(request(teamId));
    return teamService.linkPractitionerToTeam(teamId, clientId).then(
      () =>
        teamService.findTeamById(teamId).then((result) => {
          dispatch(success({ team: result.data }));
        }),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(
          alertActions.error(getI18n().t('errors.add.team-practitioner')),
        );
      },
    );
  };

  function request(team) {
    return { type: teamConstants.UPDATE_REL_REQUEST, team };
  }

  function success(team) {
    return { type: teamConstants.UPDATE_REL_SUCCESS, team: team.team };
  }

  function failure(error) {
    return { type: teamConstants.UPDATE_REL_FAILURE, error };
  }
}

function removeClientFromTeam(teamId, clientId) {
  return (dispatch) => {
    dispatch(request(teamId));
    return teamService.removeClientFromTeam(teamId, clientId).then(
      () =>
        teamService.findTeamById(teamId).then((result) => {
          dispatch(success({ team: result.data }));
        }),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.delete.team-client')));
      },
    );
  };

  function request(team) {
    return { type: teamConstants.UPDATE_REL_REQUEST, team };
  }

  function success(team) {
    return { type: teamConstants.UPDATE_REL_SUCCESS, team: team.team };
  }

  function failure(error) {
    return { type: teamConstants.UPDATE_REL_FAILURE, error };
  }
}

function deleteTeam(teamId) {
  return (dispatch) => {
    dispatch(request(teamId));
    return teamService.deleteTeamById(teamId).then(
      () => {
        dispatch(success(teamId));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.delete.team')));
      },
    );
  };

  function request(team) {
    return { type: teamConstants.DELETE_REQUEST, team };
  }

  function success(id) {
    return { type: teamConstants.DELETE_SUCCESS, id };
  }

  function failure(error) {
    return { type: teamConstants.DELETE_FAILURE, error };
  }
}

const getTeamWithMetadataAsTableRow = (team) => createTeamRow(team);

const createTeamRow = (team) => {
  const {
    clients,
    id,
    org_name,
    name,
    organizationid: organizationId,
    total_sessions: totalSessions,
    monthly_sessions: monthlySessions,
  } = team;
  return {
    id,
    organizationId,
    name,
    organizationName: org_name,
    numberOfActiveClients: clients || 0,
    monthlySessions: monthlySessions || 0,
    sessionsTotal: totalSessions || 0,
  };
};

function removePractitionerFromTeam(teamId, clientId) {
  return (dispatch) => {
    dispatch(request(teamId));
    return teamService.RemovePractitionerFromTeam(teamId, clientId).then(
      () =>
        teamService.findTeamById(teamId).then((result) => {
          dispatch(success({ team: result.data }));
        }),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(
          alertActions.error(getI18n().t('errors.delete.team-therapist')),
        );
      },
    );
  };

  function request(team) {
    return { type: teamConstants.UPDATE_REL_REQUEST, team };
  }

  function success(team) {
    return { type: teamConstants.UPDATE_REL_SUCCESS, team: team.team };
  }

  function failure(error) {
    return { type: teamConstants.UPDATE_REL_FAILURE, error };
  }
}

const getStatsOfTeamById = (id) => {
  if (!id) {
    return { sum: 0, monthSum: 0 };
  }
  const filter = `&teamId=${id}`;
  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  const currentMonth = moment().month();
  const currentYear = moment().year();

  return statisticsService.getAllStatistics(filter).then((result) => {
    const { data } = result;
    let sum = 0;
    let monthSum = 0;

    if (data && data.length) {
      const weekAmounts = data.map((item) => item.count);
      const filteredSessions = data
        .filter(
          (item) =>
            moment(item.week).month() === currentMonth &&
            moment(item.week).year() === currentYear,
        )
        .map((item) => parseInt(item.count || 0));
      if (filteredSessions && filteredSessions.length > 0) {
        monthSum = filteredSessions.reduce(reducer);
      }
      sum = weekAmounts.reduce(reducer);
    }
    return { sum, monthSum };
  });
};

function clearTeams() {
  return (dispatch) => {
    dispatch({
      type: teamConstants.CLEAR_TEAMS,
    });
  };
}

export const teamActions = {
  getAllTeams,
  findTeamById,
  createTeam,
  updateTeam,
  getAllTeamsWithoutMeta,
  linkClientToTeam,
  linkPractitionerToTeam,
  removeClientFromTeam,
  removePractitionerFromTeam,
  getStatsOfTeamById,
  deleteTeam,
  getTeamsCount,
  clearTeams,
};
