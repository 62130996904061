import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import { Box } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';

import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { organizationActions } from '../../store/actions/organization.actions';
import { practitionerActions } from '../../store/actions/practitioner.actions';

import { ReactComponent as DeleteIcon } from '../../shared/assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../shared/assets/icons/edit.svg';
import { ReactComponent as AddPractitionertIcon } from '../../shared/assets/icons/sidenav/practitioners.svg';

import useDebounce from '../../hooks/useDebounce';
import { useTableFilterConfig } from '../../hooks/useTableFilterConfig';

import { classNames, getTransformedLogicTableFilterValue } from '../../utils';
import getMuiTheme from '../../utils/MUI-Style';
import muiComponents from '../../utils/MUI-table-components';
import tableConfig from '../../utils/MUI-table-config';
import { muiTableLoadingSkeleton } from '../../utils/MUI-table-loading-skaleton';

import { CreateEditPractitionerForm } from './CreateEditPractitionerForm';
import CustomDateSortHeader from '../Sessions/CustomDateSortHeader';
import { ModalUI } from '../UI/Modal/Modal';
import { FormModal } from '../UI/FormModal/FormModal';
import TableHeader from '../UI/TableHeader/TableHeader';
import { DataCell } from '../UI/MuiDatatable/DataCell/DataCell';
import { deleteConfirmationStyle } from '../UI/SimpleModalContent/style';
import { ClientAvatar } from '../UI/MuiDatatable/ClientAvatar/ClientAvatar';
import SimpleModalContent from '../UI/SimpleModalContent/SimpleModalContent';
import { CustomMuiDataTableFilter } from '../UI/MuiDatatable/CustomMuiDataTableFilter/CustomMuiDataTableFilter';
import { CustomMuiSessionsPerMonth } from '../UI/MuiDatatable/CustomMuiSessionsPerMonth/CustomMuiSessionsPerMonth';
import { CustomMuiDataTableBodyButton } from '../UI/MuiDatatable/CustomMuiDataTableBodyButton/CustomMuiDataTableBodyButton';

const organizationFields = { fields: ['id', 'name'] };

const PractitionersTable = ({
  practitioners: practitionersReducer,
  authentication,
  organizations,
  location,
  getAllPractitioners,
  clearPractitioners,
  deletePractitioner,
  getAllOrganizations,
  history,
}) => {
  const { t } = useTranslation();
  const { deleteConfirmation } = deleteConfirmationStyle;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentSort, setCurrentSort] = useState('default');
  const [currentSortType, setCurrentSortType] = useState('');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  const [filterOpen, setFilterOpen] = useState(false);
  const [practitionerFilters, setPractitionerFilters] = useState();
  const tableRef = useRef();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [practitionerToEdit, setPractitionerToEdit] = useState(null);
  const [practitionerToDelete, setPractitionerToDelete] = useState(null);

  const {
    practitioners,
    isLoading: isPractitionersLoading,
    totalPractitioners,
  } = practitionersReducer;
  const { userIsAdmin, isLogisticsAdmin, organizationId } = authentication;
  const {
    shortOrganizations: organizationOptions,
    isLoading: isOrganizationsLoading,
  } = organizations;

  const hasOrganizationAccess = isLogisticsAdmin || userIsAdmin;

  const isLoading = isPractitionersLoading || isOrganizationsLoading;

  /**
   * Opens the filter's modal and grabs data for the filters
   */
  const onOpenFilterModal = useCallback(() => {
    if (organizationOptions.length <= 0 && hasOrganizationAccess)
      getAllOrganizations();
    setFilterOpen(true);
  }, [getAllOrganizations, hasOrganizationAccess, organizationOptions]);

  /**
   * Generates filters
   */
  const practitionerFilter = useMemo(() => {
    let order = '';
    let direction = '';
    let searchQuery = '';
    const orgId = hasOrganizationAccess
      ? practitionerFilters?.organizationId?.value || ''
      : organizationId;

    const monthlySession = getTransformedLogicTableFilterValue(
      practitionerFilters,
      'monthly_sessions',
    );
    const totalSessions = getTransformedLogicTableFilterValue(
      practitionerFilters,
      'sessions',
    );
    const activeClients = getTransformedLogicTableFilterValue(
      practitionerFilters,
      'clients',
    );

    switch (currentSort) {
      case 'up':
        direction = 'DESC';
        break;
      case 'down':
        direction = 'ASC';
        break;
      default:
        break;
    }

    if (currentSort && currentSortType && direction !== 'default')
      order = `${currentSortType} ${direction}`;

    if (debouncedSearchTerm)
      searchQuery = `${debouncedSearchTerm.toLowerCase()}`;

    return {
      page: page * limit || 0,
      limit,
      order,
      orgId,
      searchQuery,
      monthlySession,
      totalSessions,
      activeClients,
    };
  }, [
    page,
    limit,
    currentSort,
    currentSortType,
    hasOrganizationAccess,
    organizationId,
    debouncedSearchTerm,
    practitionerFilters,
  ]);

  /**
   * Gets all Practitioners on filters changes
   */
  useEffect(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      orgId,
      monthlySession,
      totalSessions,
      activeClients,
    } = practitionerFilter;
    getAllPractitioners(
      page,
      limit,
      order,
      orgId,
      searchQuery,
      monthlySession,
      totalSessions,
      activeClients,
    );
  }, [getAllPractitioners, practitionerFilter]);

  /**
   * Refresh practitioner data
   */
  const refreshCurrentData = useCallback(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      orgId,
      monthlySession,
      totalSessions,
      activeClients,
    } = practitionerFilter;
    getAllPractitioners(
      page,
      limit,
      order,
      orgId,
      searchQuery,
      monthlySession,
      totalSessions,
      activeClients,
    );
  }, [getAllPractitioners, practitionerFilter]);

  /**
   * Open edit / create pratitioner modal
   */
  const openModal = () => {
    setModalIsOpen(true);
  };

  /**
   * Close edit / create pratitioner modal
   */
  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    refreshCurrentData();
    setPractitionerToEdit(null);
  }, [refreshCurrentData]);

  /**
   * Open delete modal
   */
  const onOpenDeleteModal = useCallback((event, id) => {
    event.stopPropagation();
    setPractitionerToDelete(id);
    setDeleteModalIsOpen(true);
  }, []);

  /**
   * Close delete modal
   */
  const onCloseDeleteModal = useCallback(() => setDeleteModalIsOpen(false), []);

  /**
   * Open edit practitioner
   * @param event native event
   * @param id team's to edit id
   */
  const editPractitioner = useCallback(
    (event, id) => {
      event.stopPropagation();
      setPractitionerToEdit(practitioners.find((item) => item.id === id));
      setModalIsOpen(true);
    },
    [practitioners],
  );

  /**
   * Delete practitioner handler
   */
  const onDeletePractitioner = useCallback(async () => {
    await deletePractitioner(practitionerToDelete);
    refreshCurrentData();
    setDeleteModalIsOpen(false);
  }, [deletePractitioner, practitionerToDelete, refreshCurrentData]);

  /**
   * Sort handler
   * @param type sort property
   */
  const handleSort = useCallback(
    (type) => {
      let nextSort;
      if (currentSort === 'down') nextSort = 'up';
      else if (currentSort === 'up') nextSort = 'default';
      else if (currentSort === 'default') nextSort = 'down';

      setCurrentSortType(type);
      setCurrentSort(nextSort);
    },
    [currentSort],
  );

  /**
   * Init table's options
   */
  const options = useCallback(
    () => ({
      serverSide: true,
      page,
      rowsPerPage: limit,
      count: totalPractitioners,
      onRowClick: (rowData) => {
        const id = rowData[0]?.props?.children;
        history.push({
          pathname: `/admin/practitioners/${id}`,
          state: {},
        });
      },
      onChangePage: (currentPage) => {
        setPage(currentPage);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        const { page: offset, limit } = practitionerFilter;
        const showedRows = offset + limit;
        const pagesLimit = Math.ceil(totalPractitioners / numberOfRows);
        if (showedRows <= numberOfRows && page !== 0) {
          const newPage = Math.floor(numberOfRows / showedRows) - 1;
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        } else if (showedRows > numberOfRows) {
          const newPage = Math.floor(showedRows / numberOfRows);
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        }
        setLimit(numberOfRows);
      },
      onSearchChange: (text) => setSearchText(text),
      searchText,
      searchAlwaysOpen: true,
    }),
    [page, limit, totalPractitioners, searchText, history, practitionerFilter],
  );

  /**
   * Gets organization name by id
   * @param organizationId organization id
   */
  const getOrganizationNameById = useCallback(
    (organizationId) =>
      organizationOptions.find(
        (organization) => organization.id === organizationId,
      )?.name,
    [organizationOptions],
  );

  /**
   * Generates table rows
   */
  const dataToShow = (practitioners || []).map((item) => ({
    id: <DataCell>{item.id}</DataCell>,
    [t('forms.firstname')]: (
      <DataCell>
        <ClientAvatar clientName={item.firstname} seedId={item.id} />
      </DataCell>
    ),
    [t('forms.lastname')]: <DataCell>{item.lastname}</DataCell>,
    [t('forms.email')]: <DataCell>{item?.email}</DataCell>,
    ...(hasOrganizationAccess && {
      [t('forms.organization_name')]: (
        <DataCell type='secondary'>
          {getOrganizationNameById(item?.organizationid)}
        </DataCell>
      ),
    }),
    [t('forms.total.active_clients')]: (
      <DataCell type='numerics'>{item.clients}</DataCell>
    ),
    [t('forms.total.sessions_this_month')]: (
      <CustomMuiSessionsPerMonth
        sum={item.sessions}
        monthSum={item.monthly_sessions}
      />
    ),
    organizationId: <DataCell type='numerics'>{item.organizationid}</DataCell>,
  }));

  /**
   * Generates export CSV data
   * @param data server data to transform
   */
  const exportData = (practitioners || []).map((item) => ({
    id: item.id,
    [t('forms.firstname')]: item.firstname,
    [t('forms.lastname')]: item.lastname,
    [t('forms.email')]: item?.email,
    ...(hasOrganizationAccess && {
      [t('forms.organization_name')]: getOrganizationNameById(
        item?.organizationid,
      ),
    }),
    [t('forms.total.active_clients')]: item.clients,
    [t('forms.total.sessions_this_month')]: `${item.sessions || 0} (${
      item.monthly_sessions || 0
    })`,
    organizationId: item.organizationid,
  }));

  /**
   * Render talbe haed
   */
  const renderHead = useCallback(
    (mappedSortTypes, key) => {
      const sortType = mappedSortTypes[key];
      return (
        <CustomDateSortHeader
          key={`sort-header-${key}`}
          currentSort={currentSortType === sortType ? currentSort : 'default'}
          label={key}
          type={sortType}
          handleSort={handleSort}
        />
      );
    },
    [currentSort, currentSortType, handleSort],
  );

  /**
   * Remder talbe actions
   */
  const renderActions = useCallback(
    (value, tableMeta) => (
      <CustomMuiDataTableBodyButton
        firstButtonIcon={<EditIcon />}
        secondButtonIcon={<DeleteIcon />}
        onPrimaryActionButton={(e) =>
          editPractitioner(e, tableMeta.rowData[0]?.props?.children)
        }
        onSecondaryActionButton={(e) =>
          onOpenDeleteModal(e, tableMeta.rowData[0]?.props?.children)
        }
      />
    ),
    [editPractitioner, onOpenDeleteModal],
  );

  /**
   * Generates table attributes
   */
  let attributes = [];
  if (dataToShow.length > 0) {
    const mappedSortTypes = {
      [t('forms.firstname')]: 'firstname',
      [t('forms.lastname')]: 'lastname',
      [t('forms.email')]: 'email',
      ...(hasOrganizationAccess && {
        [t('forms.organization_name')]: 'organizationId',
      }),
      [t('forms.total.active_clients')]: 'clients',
      [t('forms.total.sessions_this_month')]: 'sessions',
    };
    attributes = Object.keys(dataToShow[0]).map((key) => {
      if (key === 'id' || key === 'organizationId') {
        return {
          name: key,
          options: { display: false, sort: false },
        };
      }
      return {
        name: key,
        options: {
          customHeadRender: () => renderHead(mappedSortTypes, key),
        },
      };
    });
    attributes.push({
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => renderActions(value, tableMeta),
      },
    });
  }

  /**
   * Generates the table filter's config
   */
  const filtersConfig = useTableFilterConfig('practitioner');

  /**
   * Closes the filter's modal and save data for the filters
   */
  const onSaveFilters = useCallback(
    (filters) => setPractitionerFilters(filters),
    [],
  );

  /**
   * Checks does table have data
   */
  const isDataTableEmpty = dataToShow.length <= 0;

  /**
   * Flag to checks does filters active
   */
  const isFilters = Object.keys(practitionerFilters || {})?.length > 0;

  /**
   * mui datatable optional class names
   */
  const muiTableMods = {
    loading: isLoading,
    'no-data': isDataTableEmpty,
    filters: isFilters,
  };

  /**
   * Table's query handler
   */
  useEffect(() => {
    if (location.search) {
      setModalIsOpen(true);
    }
  }, [location]);

  /**
   * Reset page for search if it's not the first page and there is no results
   */
  useEffect(() => {
    const pagesLimit = Math.ceil(totalPractitioners / limit);
    if (page > pagesLimit && debouncedSearchTerm) setPage(0);
  }, [debouncedSearchTerm, limit, page, totalPractitioners]);

  /**
   * Reset page for existings filters if it's not the first page and there is no results
   */
  useEffect(() => {
    if (isFilters && dataToShow.length <= 0 && page > 0) setPage(0);
  }, [dataToShow.length, isFilters, page]);

  /**
   * Gets organizations
   */
  useEffect(() => {
    if (organizationOptions.length <= 0 && hasOrganizationAccess)
      getAllOrganizations(organizationFields);
  }, [getAllOrganizations, hasOrganizationAccess, organizationOptions.length]);

  /**
   * Clears practitioners on leaving the page
   */
  useEffect(
    () => () => {
      clearPractitioners();
    },
    [],
  );

  return (
    <>
      <TableHeader
        isButton={true}
        buttonTitle='buttons.create.practitioner'
        title='practitioners.title'
        subTitle='practitioners.subtitle'
        onClickCallback={openModal}
        btnIcon={<AddPractitionertIcon className='practitioner-icon' />}
      />
      <Box className='table-container'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={getMuiTheme(
              { cursor: 'pointer' },
              {
                '&:nth-of-type(5)': {
                  width: 50,
                },
              },
            )}
          >
            <MUIDataTable
              ref={tableRef}
              data={isLoading ? muiTableLoadingSkeleton('body') : dataToShow}
              columns={
                isLoading ? muiTableLoadingSkeleton('header') : attributes
              }
              options={tableConfig(
                options(),
                isLoading,
                isDataTableEmpty,
                tableRef,
                isFilters,
                onOpenFilterModal,
                exportData,
              )}
              components={muiComponents(
                practitionerFilters,
                setPractitionerFilters,
              )}
              className={classNames('mui-data-table search-open', muiTableMods)}
              title=''
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
      {/* Edit / create organization modal */}
      {modalIsOpen && (
        <FormModal
          isOpen={modalIsOpen}
          title={
            practitionerToEdit
              ? t('buttons.edit.practitioner')
              : t('buttons.create.practitioner')
          }
          setOpen={setModalIsOpen}
          onClose={closeModal}
        >
          <CreateEditPractitionerForm
            closeModal={closeModal}
            practitionerToEdit={practitionerToEdit}
          />
        </FormModal>
      )}
      {/* Delete confirmation modal */}
      <ModalUI
        modalSx={deleteConfirmation}
        open={deleteModalIsOpen}
        isCloseBtn={false}
        onCloseHandler={onCloseDeleteModal}
        className='confirmation-modal'
        modalTitle={t('alerts.titles.delete')}
      >
        <SimpleModalContent
          content={['alerts.confirm.practitioner']}
          mainBtn='buttons.delete'
          onMainBtnClick={onDeletePractitioner}
          secondaryBtn='buttons.cancel'
          onSecondaryBtnClick={onCloseDeleteModal}
        />
      </ModalUI>
      {/* Filter modal */}
      {filterOpen && (
        <CustomMuiDataTableFilter
          isOpen={filterOpen}
          setFilterState={setFilterOpen}
          config={filtersConfig}
          filters={practitionerFilters}
          onSaveHandler={onSaveFilters}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllPractitioners: practitionerActions.getAllPractitioners,
  getPractitionersCount: practitionerActions.getPractitionersCount,
  deletePractitioner: practitionerActions.deletePractitioner,
  clearPractitioners: practitionerActions.clearPractitioners,
  getAllOrganizations: organizationActions.getShortOrganizationsWithoutMetadata,
};

export default connect(mapStateToProps, mapDispatchToProps)(PractitionersTable);
