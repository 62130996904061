import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const customMuiDatatableFooter = {
  footer: {
    '& .left, .right': {
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: getResponsiveSize(48), // 48px
    },
    '& .left': {
      width: '80%',
      pl: getResponsiveSize(16), // 16px
      '& .pages': {
        display: 'flex',
        width: '20%',
        alignItems: 'center',
        position: 'relative',
      },
      '& .total-results': {
        width: '80%',
        textAlign: 'center',
      },
    },
    '& .right': {
      width: '20%',
      pr: getResponsiveSize(16), // 16px
      display: 'inline-flex',
      justifyContent: 'flex-end',
      gap: getResponsiveSize(24), // 24px
      '& .pagination ': {
        display: 'flex',
        gap: getResponsiveSize(8), // 8px
        '& .MuiButtonBase-root': {
          p: getResponsiveSize(5), // 5px
          height: getResponsiveSize(38), // 38px
          width: getResponsiveSize(38), // 38px
          borderRadius: getResponsiveSize(8), // 8px
          ...theme.palette.propVars.btnBoxShadow,
          ...theme.palette.propVars.semiBoldFontWeight,
          // slide color effect
          backgroundSize: '200%',
          backgroundPosition: ' right bottom',
          transition:
            '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',
          '&.next': {
            backgroundImage: `linear-gradient(to left, ${theme.palette.myPrimary.lightBlue100} 50%, ${theme.palette.myPrimary.darkBlue100} 50%)`,
            color: theme.palette.myPrimary.white,
            '&:hover': {
              backgroundPosition: 'left bottom',
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
            },
            '&:active': {
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              background: theme.palette.myPrimary.lightBlue100,
            },
          },
          '&.prev': {
            backgroundImage: `linear-gradient(to left, transparent 50%, ${theme.palette.myPrimary.lightBlue40} 50%)`,
            border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
            color: theme.palette.myPrimary.lightBlue100,
            '&:hover': {
              backgroundPosition: 'left bottom',
            },
            '&:active': {
              color: theme.palette.myPrimary.lightBlue100,
            },
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
            },
          },
          '& svg': {
            fontSize: getResponsiveSize(24), // 24px
          },
        },
      },
    },
    '& td': {
      border: 0,
      p: 0,
      '& .MuiTypography-root': {
        fontFamily: theme.typography.secondaryFontFamily,
        fontSize: getResponsiveSize(16), // 16px
        color: theme.palette.myPrimary.lightBlue70,
        fontWeight: '600',
      },
      '& .rows-per-page': {
        '& .MuiFormControl-root ': {
          '& .MuiInputBase-root': {
            height: getResponsiveSize(38), // 38px
            width: getResponsiveSize(140), // 140px
            '& .MuiSelect-select ': {
              fontSize: getResponsiveSize(14), // 38px
              py: getResponsiveSize(5), // 5px
              pl: getResponsiveSize(16), // 16px
              pr: `${getResponsiveSize(24)} !important`, // 24px
              fontFamily: theme.typography.additionalFontFamily,
              ...theme.palette.propVars.semiBoldFontWeight,
            },
          },
          '& .chevron': {
            width: getResponsiveSize(24), // 24px
            height: getResponsiveSize(24), // 24px
            right: `calc(${getResponsiveSize(24)} * 0.5)`,
            '& svg': {
              fontSize: getResponsiveSize(18), // 18px,
            },
          },
        },
      },
    },
    '& .error-wrapper': {
      position: 'relative',
      '& .error.visible': {
        visibility: 'visible',
        position: 'absolute',
        padding: getResponsiveSize(5.75), // 5.75px
        minWidth: getResponsiveSize(255), // 255px
        width: 'max-content',
        bottom: '100%',
        left: 0,
        right: 'unset',
        '& .MuiTypography-root': {
          fontSize: theme.typography.fs19,
          margin: 0,
        },
      },
    },
    // Animations
    '& .slide-in-entering': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(0, 10%)',
      },
    },
    '& .slide-in-entered': {
      opacity: 1,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(0, -20%)',
      },
    },
    '& .slide-in-exiting': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(0, 10%)',
      },
    },
    '& .slide-in-exited': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .left, .right': {
        height: getResponsiveSize(44, laptopRatio), // 44px
      },
      '& .right': {
        gap: getResponsiveSize(24, laptopRatio), // 24px
        '& .pagination ': {
          gap: getResponsiveSize(8, laptopRatio), // 8px
          '& .MuiButtonBase-root': {
            p: getResponsiveSize(5, laptopRatio), // 5px
            height: getResponsiveSize(34, laptopRatio), // 34px
            width: getResponsiveSize(34, laptopRatio), // 34px
            borderRadius: getResponsiveSize(8, laptopRatio), // 8px
            '& svg': {
              fontSize: getResponsiveSize(24, laptopRatio), // 24px
            },
          },
        },
      },
      '& td': {
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(13, laptopRatio), // 13px
        },
        '& .rows-per-page': {
          '& .MuiFormControl-root ': {
            '& .MuiInputBase-root': {
              height: getResponsiveSize(34, laptopRatio), // 34px
              width: getResponsiveSize(130, laptopRatio), // 130px
              '& .MuiSelect-select ': {
                fontSize: getResponsiveSize(13, laptopRatio), // 13px
                py: getResponsiveSize(5, laptopRatio), // 5px
                pl: getResponsiveSize(9, laptopRatio), // 9px
                pr: `${getResponsiveSize(20, laptopRatio)} !important`, // 20px
              },
            },
            '& .chevron': {
              width: getResponsiveSize(22, laptopRatio), // 22px
              height: getResponsiveSize(22, laptopRatio), // 22px
              right: `calc(${getResponsiveSize(20, laptopRatio)} * 0.5)`,
              p: getResponsiveSize(5, laptopRatio), // 5px
              '& svg': {
                fontSize: getResponsiveSize(16, laptopRatio), // 15px,
              },
            },
          },
        },
      },
      '& .error-wrapper': {
        '& .error.visible': {
          padding: getResponsiveSize(3.75, laptopRatio), // 3.75px
          minWidth: getResponsiveSize(180, laptopRatio), // 180px
          borderRadius: getResponsiveSize(8, laptopRatio), // 8px
          '& .MuiTypography-root': {
            fontSize: getResponsiveSize(14, laptopRatio), // 14px
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '& .left, .right': {
        height: getResponsiveSize(44, mobileRatio), // 44px
        width: '50%',
      },
      '& .left ': {
        pl: getResponsiveSize(12, mobileRatio), // 12px '& .left': {
        '& .pages': {
          width: '35%',
        },
      },
      '& .right ': {
        pr: getResponsiveSize(12, mobileRatio), // 12px
      },
      '& .right': {
        gap: getResponsiveSize(16, mobileRatio), // 16px
        '& .pagination ': {
          gap: getResponsiveSize(6, mobileRatio), // 6px
          '& .MuiButtonBase-root': {
            p: getResponsiveSize(5, mobileRatio), // 5px
            height: getResponsiveSize(28, mobileRatio), // 28px
            width: getResponsiveSize(28, mobileRatio), // 28px
            borderRadius: getResponsiveSize(8, mobileRatio), // 8px
            '& svg': {
              fontSize: getResponsiveSize(20, mobileRatio), // 20px
            },
          },
        },
      },
      '& td': {
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(13, mobileRatio), // 13px
          textWrap: 'nowrap',
        },
        '& .rows-per-page': {
          '& .MuiFormControl-root ': {
            '& .MuiInputBase-root': {
              height: getResponsiveSize(28, mobileRatio), // 28px
              width: getResponsiveSize(100, mobileRatio), // 100px
              borderRadius: getResponsiveSize(8, mobileRatio), // 8px
              '& .MuiSelect-select ': {
                fontSize: getResponsiveSize(13, mobileRatio), // 13px
                py: getResponsiveSize(5, mobileRatio), // 5px
                pl: getResponsiveSize(9, mobileRatio), // 9px
                pr: `${getResponsiveSize(20, mobileRatio)} !important`, // 20px
              },
            },
          },
        },
      },
      '& .error-wrapper': {
        '& .error.visible': {
          padding: getResponsiveSize(3.75, mobileRatio), // 3.75px
          minWidth: getResponsiveSize(195, mobileRatio), // 195px
          borderRadius: getResponsiveSize(8, mobileRatio), // 8px
          background: 'rgba(255, 255, 255, 0.8)',
          left: 0,
          '& .MuiTypography-root': {
            fontSize: getResponsiveSize(13, mobileRatio), // 13px
          },
        },
      },
    },
    // Mobile screen
    [theme.breakpoints.media.mobile]: {
      '& .left ': {
        width: '30%',
        '& .pages ': {
          display: 'none',
        },
        '& .total-results ': {
          width: '100%',
          textAlign: 'left',
        },
      },
      '& .right ': {
        width: '70%',
      },
    },
  },
  inputStyle: {
    backgroundColor: '#17607A1F',
    width: getResponsiveSize(36), // 36px
    borderRadius: theme.palette.propVars.borderRadius,
    height: getResponsiveSize(38), // 38px
    p: getResponsiveSize(4.5), // 4.5px
    mr: getResponsiveSize(4), // 4px
    '& input::selection': {
      backgroundColor: '#7FA8B7',
    },
    '&.Mui-focused': {
      '&:after': {
        content: 'none',
      },
    },

    '& .MuiInput-input': {
      textAlign: 'center',
      p: 0,
      fontSize: `${getResponsiveSize(16)} !important`,
    },
    '&:before': {
      content: 'none',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      width: getResponsiveSize(32, laptopRatio), // 32px
      height: getResponsiveSize(32, laptopRatio), // 32px
      borderRadius: getResponsiveSize(8, laptopRatio), // 8px
      p: getResponsiveSize(3, laptopRatio), // 3px
      '& .MuiInput-input': {
        fontSize: `${getResponsiveSize(15, laptopRatio)} !important`, // 15px
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      width: getResponsiveSize(28, mobileRatio), // 28px
      height: getResponsiveSize(28, mobileRatio), // 28px
      borderRadius: getResponsiveSize(8, mobileRatio), // 8px
      p: getResponsiveSize(3, mobileRatio), // 3px
      '& .MuiInput-input': {
        fontSize: `${getResponsiveSize(14, mobileRatio)} !important`, // 14px
      },
    },
  },
};
