import { theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const noResutlsStyle = {
  noResults: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: getResponsiveSize(20), // 20px
    '& svg': {
      color: theme.palette.myPrimary.lightGrey,
      fontSize: getResponsiveSize(450), // 450px
    },
    '& .MuiTypography-root': {
      fontSize: getResponsiveSize(32), // 32px
      color: theme.palette.myPrimary.lightBlue70,
      fontFamily: theme.typography.mainFontFamily,
      ...theme.palette.propVars.semiBoldFontWeight,
    },
    // Animations
    '&.slide-entering': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      transform: 'translateY(40%)',
    },
    '&.slide-entered': {
      opacity: 1,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      transform: 'translateY(0%)',
    },
    '&.slide-exiting': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      transform: 'translateY(40%)',
    },
    '&.slide-exited': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
    },
  },
};
