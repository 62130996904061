import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '40%',
  },
  paper: {
    backgroundColor: '#f4f4f4',
    padding: 0,
  },
}));

export default function CustomBreadcrumbs(props) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink color="primary" to={props.backNav.route}>
          {props.backNav.title}
        </NavLink>
        {props.currentTitle && <Typography color="textPrimary">{props.currentTitle}</Typography>}
      </Breadcrumbs>
    </Paper>
  );
}
