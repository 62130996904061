import React from 'react';
import Grid from '@mui/material/Grid';

import { connect } from 'react-redux';
import ClientsSelect from '../Clients/ClientsSelect';
import { teamActions } from '../../store/actions/team.actions';

class AddClientToTeamForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      teamId: props.teamId,
      selectedClientId: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSelect(value) {
    if (!value) {
      this.setState({ selectedClientId: null });
      return;
    }
    this.setState({ selectedClientId: value.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { teamId, selectedClientId } = this.state;
    this.props.addClientToTeam(teamId, selectedClientId);
    this.props.closeModal();
  }

  render() {
    return (
      <>
        <form className="create-form validate-form" onSubmit={this.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <ClientsSelect selectedId={this.state.selectedClientId} handleChange={this.handleSelect} />
            </Grid>
            <Grid item xs={12}>
              <div className="container-login100-form-btn">
                <div className="wrap-login100-form-btn">
                  <div className="login100-form-bgbtn" />
                  <button className="login100-form-btn">Add client</button>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapDispatchToProps = {
  addClientToTeam: teamActions.linkClientToTeam,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddClientToTeamForm);
