import { treatmentsRemoteConstants } from '../constants/treatment-remote.constants';

export function treatmentsRemote(
  state = {
    remoteTreatmentUUID: null,
    remoteSessionUUID: null,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case treatmentsRemoteConstants.CREATE_SESSION_REQUEST:
      return {
        ...state,
      };
    case treatmentsRemoteConstants.CREATE_SESSION_SUCCESS: {
      const { treatmentUUID, sessionUUID } = action.session;
      return {
        ...state,
        remoteTreatmentUUID: treatmentUUID,
        remoteSessionUUID: sessionUUID,
      };
    }
    case treatmentsRemoteConstants.CREATE_SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case treatmentsRemoteConstants.CREATE_CONFIG_REQUEST:
      return {
        ...state,
      };
    case treatmentsRemoteConstants.CREATE_CONFIG_SUCCESS: {
      const { sessionUUID, treatmentUUID, deviceId, received } = action.config;
      return {
        ...state,
        remoteTreatmentUUID: treatmentUUID,
        remoteSessionUUID: sessionUUID,
        deviceId,
        received,
      };
    }
    case treatmentsRemoteConstants.CREATE_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case treatmentsRemoteConstants.PATCH_CONFIG_REQUEST:
      return {
        ...state,
      };
    case treatmentsRemoteConstants.PATCH_CONFIG_FAILURE:
      return {
        ...state,
      };
    case treatmentsRemoteConstants.PATCH_CONFIG_SUCCESS:
      // Clean-up holding page
      return {
        ...state,
        received: false,
      };

    case treatmentsRemoteConstants.GET_ALL_CONFIG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case treatmentsRemoteConstants.GET_ALL_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case treatmentsRemoteConstants.GET_ALL_CONFIG_SUCCESS:
      const { attributes, data } = action.remoteTreatments;
      return {
        ...state,
        columns: attributes,
        data,
        isLoading: false,
      };

    case treatmentsRemoteConstants.GET_CONFIG_REQUEST:
      return {
        ...state,
      };
    case treatmentsRemoteConstants.GET_CONFIG_FAILURE:
      return {
        ...state,
      };
    case treatmentsRemoteConstants.GET_CONFIG_SUCCESS: {
      const { generated_UUID, received, deviceId, clientId, id } = action.data;
      return {
        ...state,
        remoteTreatmentUUID: generated_UUID,
        received,
        deviceId,
        clientId,
        treatmentId: id,
      };
    }

    case treatmentsRemoteConstants.DELETE_TREATMENT:
      return {
        ...state,
      };
    case treatmentsRemoteConstants.DELETE_TREATMENT_FAILURE:
      return {
        ...state,
      };
    case treatmentsRemoteConstants.DELETE_TREATMENT_SUCCESS: {
      const { id } = action;
      const { data } = state;
      const filteredTreatments = data.filter((item) => item.id !== id);
      return {
        ...state,
        data: filteredTreatments,
      };
    }

    case treatmentsRemoteConstants.RESUME_SESSION: {
      const { remoteTreatmentUUID, remoteSessionUUID } = action.data;
      return {
        ...state,
        remoteTreatmentUUID,
        remoteSessionUUID,
      };
    }
    case treatmentsRemoteConstants.CLEAR_STATE: {
      return {
        ...state,
        remoteTreatmentUUID: null,
        remoteSessionUUID: null,
        received: false,
        remoteConfig: null,
      };
    }

    default:
      return state;
  }
}
