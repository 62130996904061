export const sessionConstants = {
  GETALL_REQUEST: 'SESSIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'SESSIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'SESSIONS_GETALL_FAILURE',

  COUNT_REQUEST: 'SESSIONS_COUNT_REQUEST',
  COUNT_SUCCESS: 'SESSIONS_COUNT_SUCCESS',
  COUNT_FAILURE: 'SESSIONS_COUNT_FAILURE',

  GET_REQUEST: 'SESSION_GET_REQUEST',
  GET_SUCCESS: 'SESSION_GET_SUCCESS',
  GET_FAILURE: 'SESSION_GET_FAILURE',

  DELETE_REQUEST: 'SESSIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'SESSIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'SESSIONS_DELETE_FAILURE',

  CLEAR_SESSIONSDATA: 'CLEAR_SESSIONSDATA',
};
