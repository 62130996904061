import { laptopRatio, mobileRatio, theme } from '../../theme';
import { getResponsiveSize } from '../../utils';

export const deviceFormStyle = {
  deviceForm: {
    mr: getResponsiveSize(6), // 6px
    '& .form-wrapper': {
      py: getResponsiveSize(31.5), // 31.5px
      pl: getResponsiveSize(36), // 36px
      pr: getResponsiveSize(30), // 36px
      gap: getResponsiveSize(18), // 18px
      height: 'auto',
      maxHeight: '56dvh',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: getResponsiveSize(6), // 6px
      },
      '&::-webkit-scrollbar-track': {
        margin: `${getResponsiveSize(8)} auto`, // 8px auto
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '100px',
        background: theme.palette.myPrimary.lightBlue20,
      },
    },
    '& .organization-select, .device-profile-select': {
      width: '100%',
    },
    '& .device-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& .name': {
        mb: getResponsiveSize(12), // 12px
        ml: getResponsiveSize(4), // 4px
      },
      '& .device-formfields': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        width: '100%',
        gap: getResponsiveSize(18), // 18px
        '& .field-wrapper': {
          display: 'flex',
          flex: '1',
          gap: getResponsiveSize(18), // 18px
        },
        '& .action-buttons': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& .MuiButtonBase-root': {
            width: getResponsiveSize(28), // 28px
            height: getResponsiveSize(28), // 28px
            transition: '.45s background cubic-bezier(0.4, 0, 0.2, 1)',
            '& svg': {
              transition: '.45s all cubic-bezier(0.4, 0, 0.2, 1)',
            },
            '&.add-device': {
              color: theme.palette.myPrimary.lightBlue70,
              '& svg': {
                width: getResponsiveSize(20), // 20px
                height: getResponsiveSize(20), // 20px
                minWidth: 20,
                minHeight: 20,
              },
            },
            '&.remove-device': {
              color: theme.palette.neutral.errorDark,
              '& svg': {
                width: getResponsiveSize(18), // 18px
                height: getResponsiveSize(18), // 18px
                minWidth: 18,
                minHeight: 18,
                fill: theme.palette.neutral.errorDark,
              },
            },
          },
        },
      },
    },
    '& .modal-footer': {
      width: `calc(100% + ${getResponsiveSize(6)})`,
      justifyContent: 'flex-end',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .device-wrapper .device-formfields .action-buttons .MuiButtonBase-root':
        {
          width: getResponsiveSize(24, laptopRatio), // 24px
          height: getResponsiveSize(24, laptopRatio), // 24px
        },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '& .form-wrapper': {
        gap: getResponsiveSize(12, mobileRatio), // 12px
      },
      '& .device-wrapper': {
        '& .name': {
          mb: getResponsiveSize(12, mobileRatio), // 12px
        },
        '.device-formfields': {
          alignItems: 'center',
          '& .field-wrapper': {
            flexDirection: 'column',
            gap: getResponsiveSize(12, mobileRatio), // 12px
          },
          '& .action-buttons .MuiButtonBase-root': {
            width: getResponsiveSize(24, mobileRatio), // 24px
            height: getResponsiveSize(24, mobileRatio), // 24px
          },
        },
      },
    },
  },
};

export const selectDeviceStyles = {
  selectDevice: {
    width: getResponsiveSize(240),
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      width: getResponsiveSize(240, mobileRatio), // 240px
      '& .MuiFormLabel-root': {
        fontSize: getResponsiveSize(13, mobileRatio), // 13px
      },
      '& legend': {
        fontSize: '10px',
      },
    },
    wrapper: {
      position: 'relative',
      '& .serial-number-hint': {
        position: 'absolute',
        display: 'flex',
        background: '#17121999',
        px: getResponsiveSize(6),
        py: getResponsiveSize(4),
        borderRadius: getResponsiveSize(8),
        color: '#ececec',
        fontSize: getResponsiveSize(14),
        left: '50%',
        top: '100%',
        textWrap: 'nowrap',
        opacity: '0',
        visibility: 'hidden',
        transform: 'translateX(-50%)',
        ...theme.palette.propVars.easeInOutTransition,
        '&::after ': {
          content: "''",
          position: 'absolute',
          bottom: '100%',
          left: '50%',
          borderWidth: getResponsiveSize(5),
          borderStyle: 'solid',
          borderColor: 'transparent transparent #17121999 transparent',
        },
        '& .label': {
          fontWeight: '700',
          mr: getResponsiveSize(5),
        },
      },
      '&:hover': {
        '& .serial-number-hint': {
          top: `calc(100% + ${getResponsiveSize(10)})`,
          opacity: '1',
          visibility: 'visible',
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      pr: `${getResponsiveSize(84)} !important`, // 84px
    },
    popperSx: {
      '& .MuiPaper-root': {
        width: `${getResponsiveSize(440)} !important`,
      },
      hintsSx: {
        width: getResponsiveSize(440),
      },
      // Tablet screen
      [theme.breakpoints.media.tablet]: {
        '& .MuiPaper-root': {
          width: `100% !important`,
        },
        hintsSx: {
          width: '100%',
        },
      },
    },
  },
};
