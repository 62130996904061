import React from 'react';
import Select from 'react-select';

import { connect } from 'react-redux';
import { practitionerActions } from '../../store/actions/practitioner.actions';

class PractitionersSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: 'Practitioner',
    };
  }

  componentDidMount() {
    this.props.getAllPractitioners();
  }

  render() {
    const { practitioners, selectedId } = this.props;
    const options = practitioners.practitioners.map((item) => ({ value: item.id, label: `${item.lastname} ${item.firstname}` }));
    let value = null;
    if (selectedId) {
      value = options.find((item) => item.value === selectedId);
    }

    return (
      <>
        <label className="select-label">{this.props.label || this.state.label}</label>
        <Select
          className="select-filter"
          options={options}
          isClearable
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          placeholder={
            this.props.practitioners.isLoading ? 'Loading practitioners..' : 'Select practitioner'
          }
          isDisabled={this.props.practitioners.isLoading}
          onChange={this.props.handleChange}
          value={value}
          inputProps={{
            name: 'practitioner',
            id: 'select-practitioner',
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllPractitioners: practitionerActions.getAllPractitioners,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PractitionersSelect);
