import React, { memo, useState, useCallback, useEffect } from 'react';

import { Box, FormControl, FormHelperText, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useActions } from '../../hooks/useActions';

import { TeamSelect } from '../Teams/TeamSelect';
import { TextFieldUi } from '../UI/TextField/TextField';
import { ButtonUi } from '../UI/Button/Button';
import { createClientFormStyle } from './style';

export const EditClientForm = memo((props) => {
  const { closeModal, clientToEdit } = props;
  const { createClientForm } = createClientFormStyle;
  const { t } = useTranslation();

  const { updateClient, getAllTeamsWithoutMeta: getAllTeams } = useActions();

  const [id, setId] = useState(clientToEdit?.id);
  const [name, setName] = useState(clientToEdit?.name);
  const [selectedTeamId, setSelectedTeamId] = useState(clientToEdit?.teamId);
  const [submitted, setSubmitted] = useState(false);
  const [teamError, setTeamError] = useState(null);

  /**
   * Sets value to the textfield
   * @param event native event
   */
  const onChange = useCallback((event) => {
    setName(event.target.value);
    setSubmitted(false);
  }, []);

  /**
   * Sets value to the autocomplete
   * @param value selected value
   */
  const onSelect = useCallback((value) => {
    setSubmitted(false);
    if (!value || !value.value) {
      setSelectedTeamId(null);
      return;
    }
    setSelectedTeamId(value.value);
  }, []);

  /**
   * Creates client on form submition
   * @param event native submit event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedTeamId) {
      setSubmitted(true);
      setTeamError(t('errors.select.team'));
      return;
    }

    await updateClient({
      id,
      name,
      teamId: selectedTeamId,
    });
    closeModal();
  };

  return (
    <>
      <Box
        component='form'
        className='create-form validate-form'
        onSubmit={handleSubmit}
      >
        <Grid container sx={createClientForm}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <TeamSelect handleChange={onSelect} selectedId={selectedTeamId} />
              {submitted && teamError ? (
                <FormHelperText error>{teamError}</FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextFieldUi
              className='simple-text-field'
              label={t('forms.name')}
              autoFocus
              name='name'
              fullWidth
              required
              value={name}
              handleChange={onChange}
            />
          </Grid>
        </Grid>
        <Box className='modal-footer justify-end'>
          <ButtonUi
            className='action-btn accept'
            variant='contained'
            type='primary'
            size='small'
            btnType='submit'
          >
            {t('buttons.save')}
          </ButtonUi>
        </Box>
      </Box>
    </>
  );
});
