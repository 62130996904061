import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}/sessions`;

const getAllSessions = (
  limit,
  offset,
  order,
  searchQuery = '%',
  anonymousInclude,
  sessionType = null,
  sessionCompleted = null,
  sessionDecrease = null,
  startDate = null,
) => {
  const anonymous = anonymousInclude ? `&anonymous=${anonymousInclude}` : '';
  const session_type = sessionType ? `&session_type=${sessionType}` : '';
  const completed = sessionCompleted ? `&completed=${sessionCompleted}` : '';
  const decrease = sessionDecrease
    ? `&decrease_range=[${sessionDecrease}]`
    : '';
  const start_date = startDate ? `&start_date=${startDate}` : '';
  const filterString = `&offset=${offset}&limit=${limit}&order=${order}&query=${searchQuery}${anonymous}${session_type}${completed}${decrease}${start_date}`;
  return axios.get(`${baseURL}/getWithSort${authUrl()}${filterString}`);
};

const getSessionsCount = (filter = '') =>
  axios.get(`${baseURL}/count${authUrl()}${filter}`);

const findSessionById = (sessionId) => {
  const filter = { include: ['therapist', 'organization', 'team', 'device'] };
  return axios.get(
    `${baseURL}/${sessionId}${authUrl()}&filter=${JSON.stringify(filter)}`,
    {},
  );
};

const getAllSessionsByTreatmentUUID = (treatmentUUID) =>
  axios
    .get(
      `${baseURL}?filter[where][treatmentUUID]=${treatmentUUID}${authUrl().replace(
        '?',
        '&',
      )}`,
    )
    .then((result) => result.data)
    .catch(() => []);

export const sessionService = {
  getAllSessions,
  findSessionById,
  getAllSessionsByTreatmentUUID,
  getSessionsCount,
};
