export const practitionerConstants = {
  GETALL_REQUEST: 'PRACTITIONERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'PRACTITIONERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'PRACTITIONERS_GETALL_FAILURE',

  COUNT_REQUEST: 'PRACTITIONERS_COUNT_REQUEST',
  COUNT_SUCCESS: 'PRACTITIONERS_COUNT_SUCCESS',
  COUNT_FAILURE: 'PRACTITIONERS_COUNT_FAILURE',

  GET_REQUEST: 'PRACTITIONER_GET_REQUEST',
  GET_SUCCESS: 'PRACTITIONER_GET_SUCCESS',
  GET_FAILURE: 'PRACTITIONER_GET_FAILURE',

  DELETE_REQUEST: 'PRACTITIONERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'PRACTITIONERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PRACTITIONERS_DELETE_FAILURE',

  CREATE_REQUEST: 'CREATE_PRACTITIONER_REQUEST',
  CREATE_SUCCESS: 'CREATE_PRACTITIONER_SUCCESS',
  CREATE_FAILURE: 'CREATE_PRACTITIONER_FAILURE',

  EDIT_REQUEST: 'EDIT_PRACTITIONER_REQUEST',
  EDIT_SUCCESS: 'EDIT_PRACTITIONER_SUCCESS',
  EDIT_FAILURE: 'EDIT_PRACTITIONER_FAILURE',

  CLEAR_PRACTITIONERS: 'CLEAR_PRACTITIONERS',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
};
