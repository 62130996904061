import axios from 'axios';
import { AUTH_SERVER_URL, AUTH_REALM } from '../utils/config';

const baseURL = `${AUTH_SERVER_URL}/${AUTH_REALM}`;

const getSsoToken = (code) => {
  const data = {
    grant_type: 'authorization_code',
    client_id: 'admin-portal',
    code,
    redirect_uri: `${window.location.origin}/sso/callback`,
  };
  const params = new URLSearchParams(data);
  return axios.post(
    `${baseURL}/protocol/openid-connect/token`,
    params.toString(),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};

export const getSsoTokenWithRefreshToken = (refreshToken) => {
  const data = {
    grant_type: 'refresh_token',
    client_id: 'admin-portal',
    refresh_token: refreshToken,
  };
  const params = new URLSearchParams(data);
  return axios.post(
    `${baseURL}/protocol/openid-connect/token`,
    params.toString(),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};

export const authService = {
  getSsoToken,
};
