import { laptopRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const noProductsAvailableStyle = {
  noProductsAvailable: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    height: '100%',
    '& .icon': {
      mb: getResponsiveSize(40), // 40px
      width: getResponsiveSize(550), // 550px
      height: getResponsiveSize(401), // 401px
    },
    '& .default-setting-title': {
      fontWeight: 700,
      color: '#4054B2',
      mb: getResponsiveSize(40), // 40px
    },
    '& .instructions': {
      mb: getResponsiveSize(40), // 40px
      '& .instructions-item': {
        display: 'flex',
        alignItems: 'center',
        listStyle: 'none',
        gap: getResponsiveSize(24), // 24px
        color: theme.palette.neutral.black,
        fontFamily: theme.typography.mainFontFamily,
        ...theme.palette.propVars.regularFontWeight,
        '&:not(:last-of-type)': {
          mb: getResponsiveSize(12), // 12px
        },
        '& .text': {
          ...theme.palette.propVars.regularFontWeight,
        },
        '& svg': {
          width: getResponsiveSize(39), // 39px
          height: getResponsiveSize(38), // 38px
          minWidth: 36,
          minHeight: 35,
        },
      },
    },
    '& .btn': {
      width: getResponsiveSize(260), // 260px
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .btn': {
        height: getResponsiveSize(36, laptopRatio), // 36px
      },
    },
  },
};
