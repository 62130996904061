import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const sessionsStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: getResponsiveSize(100),

    '& .item': {
      fontSize: getResponsiveSize(14), // 14px
      fontFamily: theme.typography.mainFontFamily,
      color: theme.palette.neutral.grey80,
      ...theme.palette.propVars.semiBoldFontWeight,
      display: 'flex',
      alignItems: 'center',
      '&.sum': {
        mr: getResponsiveSize(5), // 5px
        '& svg': {
          width: getResponsiveSize(22), // 22px
          height: getResponsiveSize(22), // 22px
          minWidth: 22,
          minHeight: 22,
          stroke: theme.palette.myPrimary.darkBlue50,
          strokeWidth: getResponsiveSize(1.2),
        },
      },
      '&.months': {
        '& svg': {
          mr: getResponsiveSize(4), // 4px
          width: getResponsiveSize(18), // 18px
          height: getResponsiveSize(18), // 18px
          minWidth: 18,
          minHeight: 18,
          fill: theme.palette.myPrimary.darkBlue50,
        },
      },
    },
    // desktopXXL screen
    [theme.breakpoints.media.desktopXXLToDesktopXL]: {
      '& .item': {
        '&.sum': {
          '& svg': {
            strokeWidth: getResponsiveSize(0.6),
          },
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      maxWidth: getResponsiveSize(100, laptopRatio), // 100px
      '& .item': {
        fontSize: getResponsiveSize(12, laptopRatio), // 12px
        '&.sum': {
          mr: getResponsiveSize(5, laptopRatio), // 5px
          '& svg': {
            strokeWidth: getResponsiveSize(1.2, laptopRatio),
          },
        },
        '&.months svg': {
          mr: getResponsiveSize(4, laptopRatio), // 4px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      maxWidth: getResponsiveSize(80, mobileRatio), // 100px
      '& .item': {
        fontSize: getResponsiveSize(14, mobileRatio), // 14px
        '&.sum': {
          mr: getResponsiveSize(5, mobileRatio), // 5px
          '& svg': {
            strokeWidth: getResponsiveSize(1.2, mobileRatio),
          },
        },
        '&.months svg': {
          mr: getResponsiveSize(4, mobileRatio), // 4px
        },
      },
    },
  },
};
