import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { ButtonUi } from '../Button/Button';
import { headerTablePage } from './style';
import { classNames } from '../../../utils';

const TableHeader = (props) => {
  const {
    onClickCallback,
    title,
    subTitle,
    isButton = true,
    buttonTitle,
    btnIcon,
    btnClassName,
    t,
  } = props;
  const { headerTable } = headerTablePage;
  return (
    <Box sx={headerTable}>
      <Box className='title-group'>
        <Box className='title-row'>
          <Typography variant='h1' className='main-title'>
            {t(title)}
          </Typography>
          {isButton ? (
            <ButtonUi
              className={classNames('action-btn', {}, [btnClassName])}
              onClickHandler={onClickCallback}
              startIcon={btnIcon}
            >
              {t(buttonTitle)}
            </ButtonUi>
          ) : null}
        </Box>
        <Box className='desc-wrapper'>
          <Typography variant='body1' className='title-description'>
            {t(subTitle)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(memo(TableHeader));
