import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import { Fade, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { customMuiDatatableSearch } from './style';

const CustomMuiDatatableSearch = (props) => {
  const { classes, onSearch, searchText, options, t } = props;
  const { searchPlaceholder } = options;
  const { search } = customMuiDatatableSearch;

  /**
   * Handle search text changes
   * @param event typing event
   */
  const handleTextChange = (event) => {
    onSearch(event.target.value);
  };

  /**
   * Clear search field
   */
  const clearSearch = () => onSearch('');

  return (
    <Fade in={true} timeout={300} mountOnEnter unmountOnExit>
      <TextField
        sx={search}
        placeholder={t(searchPlaceholder)}
        size='medium'
        className={classes?.searchText}
        value={searchText || ''}
        onChange={handleTextChange}
        fullWidth
        type='search'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchOutlined />
            </InputAdornment>
          ),
          endAdornment: searchText?.length > 0 && (
            <IconButton onClick={clearSearch}>
              <ClearOutlined />
            </IconButton>
          ),
        }}
      />
    </Fade>
  );
};

export default withTranslation()(memo(CustomMuiDatatableSearch));
