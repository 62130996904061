import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const clientAvatarStyle = {
  clientAvatar: {
    display: 'flex',
    gap: getResponsiveSize(8), // 8px
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& .avatar': {
      width: getResponsiveSize(38), // 38px
    },
    '& .MuiTypography-root': {
      fontSize: getResponsiveSize(14), // 14px
      fontFamily: theme.typography.mainFontFamily,
      color: theme.palette.neutral.grey80,
      ...theme.palette.propVars.semiBoldFontWeight,
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      gap: getResponsiveSize(8, laptopRatio), // 8px
      '& .avatar': {
        width: getResponsiveSize(30, laptopRatio), // 30px
      },
      '& .MuiTypography-root': {
        fontSize: getResponsiveSize(12, laptopRatio), // 12px
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      gap: getResponsiveSize(8, mobileRatio), // 8px
      '& .avatar': {
        width: getResponsiveSize(32, mobileRatio), // 32px
      },
      '& .MuiTypography-root': {
        fontSize: getResponsiveSize(14, mobileRatio), // 12px
      },
    },
  },
};
