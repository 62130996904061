import { laptopRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const combineToggleStyles = {
  combineToggle: {
    display: 'flex',
    '& .mask-box': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'nowrap',
      gap: getResponsiveSize(8.5), // 8.5px
      width: '100%',
      position: 'relative',
      borderRadius: theme.palette.propVars.borderRadius,
      border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
      py: getResponsiveSize(6), // 6px
      px: getResponsiveSize(7), // 7px
      background: theme.palette.myPrimary.white,
      ...theme.palette.propVars.btnBoxShadow,
      '& .mask': {
        position: 'absolute',
        height: getResponsiveSize(48.5), // 48.5px
        borderRadius: theme.palette.propVars.borderRadius,
        background: theme.palette.myPrimary.lightBlue,
        boxShadow: `${getResponsiveSize(2)} ${getResponsiveSize(
          2,
        )} ${getResponsiveSize(5)} rgba(0, 0, 0, 0.15)`,
        transition: 'all 0.5s ease',
        transform: 'translateX(0)',
        left: getResponsiveSize(7), // 7px
        '&.right': {
          left: `calc(50% + ${getResponsiveSize(3.5)})`,
        },
      },
      '& .MuiButton-root': {
        borderRadius: theme.palette.propVars.borderRadius,
        color: '#393B3780',
        width: getResponsiveSize(153), // 153px,
        fontSize: theme.typography.fs19,
        height: getResponsiveSize(48.5), // 48.5px
        padding: getResponsiveSize(13), // 13px,
        textTransform: 'none',
        ...theme.palette.propVars.regularFontWeight,
        ...theme.palette.propVars.easeInOutTransition,
        '&.active': {
          color: theme.palette.myPrimary.white,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '&:hover:not(.active) ': {
          opacity: '0.8',
          backgroundColor: theme.palette.myPrimary.lightBlue20,
        },
      },
      '& .dropdown-wrapper': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: getResponsiveSize(29), // 29px
        alignItems: 'center',
        pl: getResponsiveSize(14.5), // 14.5px
        cursor: 'pointer',
        zIndex: 1,
        borderRadius: theme.palette.propVars.borderRadius,
        ...theme.palette.propVars.easeInOutTransition,
        '&.active': {
          '& .dropdown-title': {
            color: theme.palette.myPrimary.white,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '&:hover:not(.active) ': {
          opacity: '0.8',
          backgroundColor: theme.palette.myPrimary.lightBlue20,
        },
        '& .dropdown-title': {
          fontSize: theme.typography.fs19,
          color: theme.palette.neutral.greyRGBA50,
          ...theme.palette.propVars.regularFontWeight,
        },
        '& .MuiInputBase-root': {
          width: getResponsiveSize(113), // 113px
          height: getResponsiveSize(40), // 40px
        },
        '& .MuiSelect-select': {
          py: getResponsiveSize(11), // 11px
          px: getResponsiveSize(13), // 13px
        },
        '& .chevron': {
          right: `calc(${getResponsiveSize(33)} * 0.5)`,
          width: getResponsiveSize(20), // 20px
          height: getResponsiveSize(20), // 20px
          '& svg': {
            fontSize: getResponsiveSize(19), // 19px
          },
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      width: getResponsiveSize(270, laptopRatio), // 270px
      '& .mask-box': {
        gap: getResponsiveSize(5.5, laptopRatio), // 5.5px
        py: getResponsiveSize(6, laptopRatio), // 6px
        px: getResponsiveSize(7, laptopRatio), // 7px
        '& .mask': {
          height: getResponsiveSize(38.5, laptopRatio), // 38.5px
          boxShadow: `${getResponsiveSize(2, laptopRatio)} ${getResponsiveSize(
            2,
            laptopRatio,
          )} ${getResponsiveSize(5, laptopRatio)} rgba(0, 0, 0, 0.15)`,
          left: getResponsiveSize(7, laptopRatio), // 7px
          '&.right': {
            left: `calc(50% + ${getResponsiveSize(3.5, laptopRatio)})`,
          },
        },
        '& .MuiButton-root': {
          width: getResponsiveSize(125, laptopRatio), // 125px
          fontSize: getResponsiveSize(15, laptopRatio), // 15px
          height: getResponsiveSize(38.5, laptopRatio), // 38.5px
          padding: getResponsiveSize(13, laptopRatio), // 13px,
        },
      },
    },
  },
};
