import { tabletRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const wizardContentBoxStyles = {
  wizardContentBox: {
    background: theme.palette.myPrimary.lightBlue50,
    py: getResponsiveSize(20), // 20px
    px: getResponsiveSize(75), // 75px
    position: 'relative',
    overflow: 'hidden',
    borderRadius: getResponsiveSize(16), // 16px
    ...theme.palette.propVars.linearTransition,
    '&:before': {
      content: "''",
      position: 'absolute',
      left: `calc(${getResponsiveSize(10)} * -1)`, // -10px
      opacity: 0,
      visibility: 'hidden',
      top: 0,
      height: '100%',
      width: getResponsiveSize(7), // 7px
      background: theme.palette.myPrimary.lightBlue100,
      ...theme.palette.propVars.linearTransition,
    },
    '&:not(:last-child)': {
      mb: getResponsiveSize(32), // 32px
    },
    '& .box-heading': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: getResponsiveSize(23), // 23px
      '& .wizard-box-title': {
        mb: 0,
      },
    },
    '& h3.wizard-box-title': {
      color: theme.palette.neutral.grey70,
      lineHeight: getResponsiveSize(28), // 28px
      mb: getResponsiveSize(20), // 20px
      ...theme.palette.propVars.regularFontWeight,
      ...theme.palette.propVars.actorFamily,
      '&.centred': {
        textAlign: 'center',
        mb: 0,
      },
    },
    '& h5.wizard-box-subtitle': {
      color: theme.palette.neutral.grey70,
      lineHeight: getResponsiveSize(28), // 28px
      ...theme.palette.propVars.actorFamily,
      mb: getResponsiveSize(20), // 20px
      ...theme.palette.propVars.regularFontWeight,
    },
    '&.active': {
      '&:before': {
        left: 0,
        opacity: 1,
        visibility: 'visible',
      },
    },
    '&.disabled': { opacity: 0.5, pointerEvents: 'none' },
    '&.notification': {
      mt: 'auto',
      mb: getResponsiveSize(38), // 38px
    },
    '&.skeleton': {
      '& .wizard-box-title': {
        mb: getResponsiveSize(20), // 20px
      },

      '& .hStack': {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    // Tablet screen
    [theme.breakpoints.media.tablet]: {
      py: getResponsiveSize(20, tabletRatio), // 20px
      '& h3.wizard-box-title': {
        mb: getResponsiveSize(16, tabletRatio), // 16px
        lineHeight: '1.2',
      },
    },
  },
};
