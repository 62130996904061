import { productConstants } from '../constants/product.constants';

export function products(
  state = {
    products: [],
    availableProducts: [],
    isLoading: true,
    hasFetched: false,
  },
  action,
) {
  switch (action.type) {
    case productConstants.GETALL_REQUEST:
      return {
        ...state,
        hasFetched: false,
        isLoading: true,
      };
    case productConstants.GETALL_SUCCESS:
      const unsortedProducts = action.products || [];
      return {
        ...state,
        hasFetched: true,
        products: unsortedProducts.reverse(),
        isLoading: false,
        error: null,
      };
    case productConstants.GETALL_FAILURE:
      return {
        ...state,
        hasFetched: true,
        error: action.error,
        isLoading: false,
      };
    case productConstants.GET_AVAILABLE_REQUEST:
      return {
        ...state,
        hasFetched: false,
        isLoading: true,
      };
    case productConstants.GET_AVAILABLE_SUCCESS:
      const availableProducts = action.products || [];
      return {
        ...state,
        hasFetched: true,
        availableProducts,
        isLoading: false,
        error: null,
      };
    case productConstants.GET_AVAILABLE_FAILURE:
      return {
        ...state,
        hasFetched: true,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
}
