import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { WebAssetOffOutlined } from '@mui/icons-material';

import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { noResutlsStyle } from './style';

export const CustomMuiDataTableNoResults = memo((props) => {
  const { visible } = props;
  const { t } = useTranslation();
  const { noResults } = noResutlsStyle;

  return (
    <Transition
      in={visible}
      timeout={300}
      mountOnEnter
      unmountOnExit
      appear={true}
    >
      {(state) => (
        <Box sx={noResults} className={`slide-${state}`}>
          <WebAssetOffOutlined />
          <Typography variant='h1'>{t('mui-datatables.no-results')}</Typography>
        </Box>
      )}
    </Transition>
  );
});
