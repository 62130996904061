import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../hooks/useActions';
import { wizardErrors } from '../wizardErrors';

/**
 * Set error if device isn't selected
 */
export const useWizardDeviceError = () => {
  const { selectedDevice, selectedDeviceConfig, selectedProduct } = useSelector(
    (state) => state.createTreatmentWizard,
  );
  const { setError } = useActions();

  useEffect(() => {
    if (!selectedDevice) setError(wizardErrors.DEVICE);
    else if (selectedProduct?.device_settings_needed && !selectedDeviceConfig)
      setError(wizardErrors.PRODUCT_CONFIGURATION);
    else setError(null);
    return () => {
      setError(null);
    };
  }, [setError, selectedDevice, selectedProduct, selectedDeviceConfig, wizardErrors]);
};
