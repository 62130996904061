import { Box } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../hooks/useActions';

import AutocompleteUi from '../UI/Autocomplete/Autocomplete';
import { selectDeviceStyles } from './style';

export const DevicesSelect = memo((props) => {
  const {
    selectedId,
    organizationId,
    handleChange,
    showDeviceNumber = false,
  } = props;
  const { t } = useTranslation();
  const { getAllDevices, getDevicesByOrganizationId } = useActions();
  const { devices, isLoading } = useSelector((state) => state.devices);
  const { selectDevice } = selectDeviceStyles;
  const [selectedValue, setSelectedValue] = useState(null);

  /**
   * Generates autocomplete options
   */
  const options = useMemo(
    () =>
      devices.map((item) => ({
        value: item.id,
        text: item.name,
        number:
          item?.generated_uuid?.split('_')[1] ||
          item?.generated_UUID?.split('_')[1],
      })),
    [devices],
  );

  /**
   * Handle Autocomplete change event
   * (option selection, clear value, close...)
   * @param event
   * @param value selected option value
   */
  const handleAutocompleteChange = (event, value) => {
    if (!value) handleChange(null);
    handleChange(value);
    setSelectedValue(value);
  };

  /**
   * Changes option layout
   * @param props component props
   * @param option option
   */
  const formatOptionLabel = useCallback(
    (props, option) => {
      const { 'data-option-index': optionIdx } = props;
      const { text, number } = option;
      return (
        <Box component='li' {...props} key={`${optionIdx} - ${number}`}>
          {text}
          {showDeviceNumber && (
            <Box component='span' className='option-hint'>
              {number}
            </Box>
          )}
        </Box>
      );
    },
    [showDeviceNumber],
  );

  /**
   * Handle Autocomplete serach event
   * @param options Autocomplete options
   * @param params search string
   * @returns filtered Autocomplete options by search
   */
  const customFilter = useCallback(
    (options, { inputValue: searchText }) => {
      const filteredOptions = options.filter((option) => {
        const includesText = option.text
          ?.toLowerCase()
          .includes(searchText.toLowerCase());
        const includesNumber = option.number
          ?.toLowerCase()
          .includes(searchText.toLowerCase());
        if (includesText) return true;
        else if (includesNumber && showDeviceNumber) return true;
        else return false;
      });

      return filteredOptions;
    },
    [showDeviceNumber],
  );

  const renderSelectedDeviceSerialTooltip = useMemo(
    () => (
      <Box className='serial-number-hint'>
        <Box component='span' className='label'>
          {t('devices.select-hover-serial-label')}:
        </Box>
        {selectedValue?.number}
      </Box>
    ),
    [selectedValue?.number, t],
  );

  /**
   * Sets previously selected option
   */
  useEffect(() => {
    if (!selectedId) {
      setSelectedValue(null);
      return;
    }

    const selectedOption = options.find((item) => item.value == selectedId);

    if (selectedOption) setSelectedValue(selectedOption);
  }, [selectedId, options]);

  /**
   * Gets devices
   */
  useEffect(() => {
    organizationId
      ? getDevicesByOrganizationId(organizationId)
      : getAllDevices(false);
  }, [organizationId]);

  return (
    <Box sx={selectDevice.wrapper}>
      <AutocompleteUi
        selfSx={selectDevice}
        className='device-select'
        label={t('devices.select')}
        placeholder={t('forms.device')}
        autocompleteValue={selectedValue}
        onAutocompleteChangeHandler={handleAutocompleteChange}
        onAutocompleteRenderOptionsHandler={formatOptionLabel}
        loading={isLoading}
        options={options || []}
        onAutocompleteFilterOptionHandler={customFilter}
      />
      {selectedValue && renderSelectedDeviceSerialTooltip}
    </Box>
  );
});
