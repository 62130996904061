import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BASE_PRACTITIONER_URL, CLIENT_PAGE, EMDR_REMOTE_URL } from '../../../../utils/config';

const BASE_CLIENT_URL = `${EMDR_REMOTE_URL}/${CLIENT_PAGE}`;

/**
 * Generate client and prationer urls for created treatment
 */
export const useTreatmentUrl = () => {
  const { treatmentsRemote, authentication } = useSelector((state) => state);
  const { remoteTreatmentUUID, remoteSessionUUID, deviceId } = treatmentsRemote;
  const { user } = authentication || {};

  const url = useMemo(
    () =>
      `${BASE_CLIENT_URL}?treatmentUUID=${remoteTreatmentUUID}&sessionUUID=${remoteSessionUUID}&deviceId=${
        deviceId || ''
      }`,
    [remoteTreatmentUUID, remoteSessionUUID, deviceId],
  );

  const practitionerUrl = useMemo(
    () =>
      `${BASE_PRACTITIONER_URL}?treatmentUUID=${remoteTreatmentUUID}&sessionUUID=${remoteSessionUUID}&token=${
        user?.id
      }&deviceId=${deviceId || ''}`,
    [remoteTreatmentUUID, remoteSessionUUID, user, deviceId],
  );

  return { url, practitionerUrl };
};
