import { laptopRatio, tabletRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const devicesStepStyles = {
  deviceSelect: {
    '& .device-selection-row': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      gap: getResponsiveSize(32), // 32px
      '& .device-image': {
        display: 'flex',
        background: '#AFDDF0',
        borderRadius: getResponsiveSize(16), // 16px
        width: getResponsiveSize(280), // 280px
        position: 'relative',
        overflow: 'hidden',
        '& .default': {
          width: getResponsiveSize(280), // 280px
          height: 'auto',
          maxHeight: getResponsiveSize(185), // 280px
        },
        '& .transition-group': {
          width: '100%',
          height: '100%',
          '& .img-wrapper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            '&.slide-in-entering': {
              transform: 'translateX(-20%)',
            },
            '& .slide-in-entered': {
              transform: 'translateX(0%)',
            },
            '& .slide-in-exiting': {
              transform: 'translateX(20%)',
            },
          },
        },
      },
      '& .device-selection': {
        width: '80%',
        ml: 'auto',
        '& .wizard-box-title': {
          mb: getResponsiveSize(14), // 14px
        },
        '& .wizard-box-subtitle': {
          mb: getResponsiveSize(24), // 24px
        },
      },
    },
    '& .taxation-content-box': {
      mt: 'auto',
      mb: getResponsiveSize(28), // 28px
      '& .config-btn': {
        height: getResponsiveSize(40), // 40px,
        fontSize: theme.typography.fs18, // 18px
        ...theme.palette.propVars.regularFontWeight,
        '&:active': {
          color: theme.palette.myPrimary.lightBlue100,
        },
        '& .MuiButton-startIcon': {
          ml: 0,
          mr: getResponsiveSize(16), // 16px
          '& svg': {
            width: getResponsiveSize(20), // 20px
            height: getResponsiveSize(20), // 20px
          },
        },
      },
    },
    '& .checkbox-card-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      gap: getResponsiveSize(44), // 44px
      '& .taxation-checkbox': {
        width: getResponsiveSize(440), // 440px
        minWidth: 'auto',
        '& .taxation-icon': {
          width: getResponsiveSize(47), // 47px
          height: getResponsiveSize(28), // 28px
          '&.low': {
            fill: theme.palette.myPrimary.darkBlue50,
          },
          '&.avarage': {
            fill: theme.palette.myPrimary.green,
          },
          '&.high': {
            fill: theme.palette.myPrimary.red,
          },
        },
      },
    },
    // Tablet screen
    [theme.breakpoints.media.tablet]: {
      '& .device-selection-row': {
        mb: getResponsiveSize(20, tabletRatio), // 20px,
      },
      '& .taxation-content-box': {
        '& .box-heading': {
          gap: getResponsiveSize(20, tabletRatio), // 20px,
          mb: getResponsiveSize(22, tabletRatio), // 22px,
        },
        '& .config-btn': {
          height: 'auto',
          maxHeight: getResponsiveSize(40, tabletRatio), // 40px,
          fontSize: getResponsiveSize(14, tabletRatio), // 14px,
          lineHeight: '1.2',
          '& .MuiButton-startIcon svg': {
            width: getResponsiveSize(20, tabletRatio), // 20px
            height: getResponsiveSize(20, tabletRatio), // 20px
          },
        },
      },
      '& .checkbox-card-wrapper': {
        flexDirection: 'column',
        '& .taxation-checkbox': {
          width: '100%',
        },
      },
    },
  },
  // Advanced configuration modal wrapper
  advancedConfigModal: {
    '&.advenced-config-modal': {
      '& .modal-wrapper': {
        width: getResponsiveSize(1010), // 1010px
        '& .modal-header': {
          background:
            'linear-gradient(90deg, #2392BA0D 0%, #2392BA0D 32%, rgba(255, 255, 255, 0.8) 32%)',
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '&.advenced-config-modal': {
        '& .modal-wrapper': {
          width: getResponsiveSize(680, laptopRatio), // 680px
        },
      },
    },
  },
};
