import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import { withTranslation } from 'react-i18next';

const CustomSlider = ({
  selectedValue,
  variable,
  handleVariableValueChanged,
  variableTranslation,
  i18n,
}) => {
  const { ui_configuration: config, name } = variable;
  const {
    slider_min: sliderMin,
    slider_max: sliderMax,
    slider_step: sliderStep,
  } = config;

  const [displayValue, setDisplayValue] = useState(sliderMin);

  useEffect(() => {
    if (selectedValue) {
      setDisplayValue(parseFloat(selectedValue));
    }
  }, [selectedValue]);

  const handleSliderChange = (event, newValue) => {
    setDisplayValue(newValue);
  };

  const commitSliderChange = () => {
    handleVariableValueChanged({
      variable: variable.name,
      value: displayValue,
    });
  };

  const handleInputChange = (event) => {
    handleVariableValueChanged({
      variable: variable.name,
      value: event.target.value === '' ? '' : Number(event.target.value),
    });
  };

  return (
    <div>
      <Typography id='track-false-slider' gutterBottom>
        {variableTranslation || i18n.t(name.toLowerCase())}
      </Typography>
      <Grid container spacing={2} alignItems='center' className='slider-container'>
        <Grid item>
          <Slider
            track='normal'
            valueLabelDisplay='auto'
            marks={[
              {
                value: sliderMin,
                label: sliderMin,
              },
              {
                value: sliderMax,
                label: sliderMax,
              },
            ]}
            min={sliderMin}
            max={sliderMax}
            step={sliderStep}
            value={typeof displayValue === 'number' ? displayValue : 0}
            onChange={handleSliderChange}
            onChangeCommitted={commitSliderChange}
            aria-labelledby='input-slider'
          />
        </Grid>
        <Grid item className='slider-input'>
          <Input
            value={displayValue}
            margin='dense'
            onChange={handleInputChange}
            inputProps={{
              step: sliderStep,
              min: sliderMin,
              max: sliderMax,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(connect()(CustomSlider));
