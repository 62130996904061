import React from 'react';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductSelect from '../ProductSelect';
import TemplatesSelect from '../TemplatesSelect';
import ClientsSelect from '../Clients/ClientsSelect';
import DevicesSelect from '../Devices/DevicesSelect';

import { treatmentActions } from '../../store/actions/treatment.actions';
import { productsService } from '../../services/products';

import {
  getAllSelectedValuesByVariable,
  setDefaultValuesForVariables,
} from '../../utils/treatment-helpers';

class FallbackCreateTreatmentForm extends React.Component {
  constructor(props) {
    super(props);
    const { deviceTreatmentConnection } = this.props.treatments;
    const { clientId, deviceId, configurationdefinitionId, treatmentconfigurationId, templateId } =
      deviceTreatmentConnection;
    this.state = {
      label: 'Organization',
      selectedProduct: null,
      selectedProductId: configurationdefinitionId,
      selectedClient: { value: clientId },
      selectedClientId: clientId,
      selectedDevice: { value: deviceId },
      selectedDeviceId: deviceId,
      selectedTemplate: null,
      selectedTemplateId: templateId,
      treatmentconfigurationId,

      fetchingTemplates: false,
      templates: [],
      variables: [],
      starts_at: new Date(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleTemplateSelect = this.handleTemplateSelect.bind(this);
  }

  async componentDidMount() {
    const result = await productsService.getConfigurationTemplatesOfProductId(
      this.state.selectedProductID,
    );
    this.setState({ templates: result.data, fetchingTemplates: false });
  }

  async handleSelect(value) {
    if (!value) {
      this.setState({
        fetchingTemplates: false,
        selectedProduct: null,
        variables: [],
        productError: null,
        deviceError: null,
        clientError: null,
        submitted: false,
      });
      return;
    }

    this.setState({
      fetchingTemplates: true,
      selectedProduct: value,
      variables: setDefaultValuesForVariables(value.variables),
    });
    const result = await productsService
      .getConfigurationTemplatesOfProductId(value.value)
      .catch((err) => console.log(err));
    this.setState({ templates: result.data, fetchingTemplates: false });
  }

  handleTemplateSelect(value) {
    if (!value || !value.template) {
      return;
    }
    const { template } = value;
    const { values: templateValues } = template;
    const possibleVariables = this.state.variables;

    templateValues.forEach((templateValue) => {
      const foundItem = possibleVariables.find((item) => item.name === templateValue.name);
      if (!foundItem) {
        return;
      }

      const indexOf = possibleVariables.indexOf(foundItem);
      foundItem.selectedValue = JSON.parse(JSON.stringify(templateValue.value));
      possibleVariables[indexOf] = foundItem;
      this.setState({ variables: possibleVariables });
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { selectedProduct, variables, selectedTemplate, treatmentconfigurationId } = this.state;
    const { i18n } = this.props;
    if (!selectedProduct) {
      this.setState({ submitted: true, productError: i18n.t('errors.select.product') });
      return;
    }

    if (!variables || !variables.length) {
      return;
    }
    const selectedValues = getAllSelectedValuesByVariable(variables);
    const selectedProductId = (selectedProduct || {}).value;
    const selectedTemplateId = (selectedTemplate || {}).value;
    const therapist = this.props.authentication.practitioner;

    if (!therapist) {
      // only therapists are allowed to create treatments;
      return;
    }

    const configuration = {
      values: selectedValues,
      configurationdefinitionId: selectedProductId,
      templateId: selectedTemplateId,
    };
    this.props.patchTreatmentConfiguration(treatmentconfigurationId, configuration);
  }

  render() {
    const {
      selectedProduct,
      templates,
      submitted,
      productError,
      deviceError,
      clientError,
      selectedProductId,
      selectedClientId,
      selectedTemplateId,
      selectedDeviceId,
    } = this.state;

    const { i18n } = this.props;

    const { practitioner } = this.props.authentication;
    if (!practitioner) {
      return;
    }
    const filter = `filter[where][organizationId]=${practitioner.organizationId}`;
    const productId = (selectedProduct || {}).value || selectedProductId;

    return (
      <form className='create-form validate-form create-treatment' onSubmit={this.handleSubmit}>
        <div className='margin-bottom warning-container'>
          <div className='warning-icon'>
            <FontAwesomeIcon icon='exclamation-circle' />
          </div>
          {i18n.t('treatments.fallback_title')}
        </div>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <ProductSelect selectedId={productId} handleChange={this.handleSelect} />
            {submitted && productError && <FormHelperText error>{productError}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid className='pt-2' item xs={12} sm={4}>
          <FormControl fullWidth>
            <ClientsSelect filter={filter} selectedId={selectedClientId} disabled />
            {submitted && clientError ? <FormHelperText error>{clientError}</FormHelperText> : ''}
          </FormControl>
        </Grid>
        <Grid className='pt-2' item xs={12} sm={4}>
          <FormControl fullWidth>
            <DevicesSelect selectedId={selectedDeviceId} disabled />
            {submitted && deviceError && <FormHelperText error>{deviceError}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid className='pt-2' item xs={12} sm={4}>
          {templates.length > 0 && (
            <TemplatesSelect
              label={i18n.t('devices.profile.title')}
              templates={templates}
              selectedId={selectedTemplateId}
              handleChange={this.handleTemplateSelect}
            />
          )}
        </Grid>
        <button type='submit' className='login100-form-btn max-w-xs mt-4'>
          {i18n.t('buttons.create.treatment')}
        </button>
      </form>
    );
  }
}

const mapDispatchToProps = {
  patchTreatmentConfiguration: treatmentActions.patchTreatmentConfiguration,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default withTranslation ()(
  connect(mapStateToProps, mapDispatchToProps)(FallbackCreateTreatmentForm),
);
