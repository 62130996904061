import { getI18n } from 'react-i18next';
import { Typography } from '@mui/material';
import { FilterListRounded } from '@mui/icons-material';
import CustomMuiDatatableFooter from '../components/UI/MuiDatatable/CustomMuiDatatableFooter/CustomMuiDatatableFooter';
import CustomMuiDatatableSearch from '../components/UI/MuiDatatable/CustomMuiDatatableSearch/CustomMuiDatatableSearch';
import { CustomMuiDataTableNoResults } from '../components/UI/MuiDatatable/CustomMuiDataTableNoResults/CustomMuiDataTableNoResults';
import { getResponsiveSize } from '.';
import { ButtonUi } from '../components/UI/Button/Button';

const rowsPerPageOptions = [10, 25, 50, 100];

const tableConfig = (
  customOptions = {},
  isLoading = false,
  isEmpty = false,
  tableRef,
  isFilters = false,
  openFilter,
  exportData,
) => {
  const tableBodyMaxHeight = isFilters
    ? `calc(65.5dvh - ${getResponsiveSize(32)})`
    : '65.5dvh';

  /**
   * Render talbe toolbar filter button
   */
  const renderToolbarFilter = () => (
    <ButtonUi
      className='toolbar-btn'
      variant='contained'
      type='primary'
      size='small'
      disabled={isLoading}
      tooltipTitle={getI18n().t('mui-datatables.toolbar.filterTable')}
      startIcon={<FilterListRounded />}
      onClickHandler={openFilter}
    >
      <Typography className='label' variant='body'>
        {getI18n().t('buttons.table.filter')}
      </Typography>
    </ButtonUi>
  );

  return {
    selectableRowsOnClick: false,
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: 'simple', // vertical | standard | simple
    tableBodyMaxHeight,
    rowsPerPageOptions,
    search: false,
    filter: false,
    print: false,
    searchPlaceholder: 'mui-datatables.toolbar.searchPlaceholder',
    downloadOptions: {
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns) => {
      const bodyRow = exportData.map((row) => {
        const rowData = [];
        columns.forEach((column) => {
          rowData.push(row[column?.name]);
        });
        return { data: rowData };
      });

      return `\uFEFF${buildHead(columns)}${buildBody(bodyRow)}`;
    },
    onTableChange: () => {
      if (!tableRef) return;
      setTimeout(() => {
        const tableViewWidth =
          tableRef?.current?.tableRef?.offsetParent?.clientWidth;
        const tableHeadHeight =
          tableRef?.current?.tableRef?.children[1]?.clientHeight;
        const tableViewHeight =
          tableRef?.current?.tableRef?.offsetParent?.clientHeight -
          tableHeadHeight;
        const dataRowWidth =
          tableRef?.current?.tableRef?.lastChild?.clientWidth;
        const dataRowHeight =
          tableRef?.current?.tableRef?.lastChild?.clientHeight;
        if (tableRef?.current?.tableContent) {
          const scrollHorizontalClass = ' scrollable horizontal';
          const scrollVerticalClass = ' scrollable vertical';
          let tableClassName =
            tableRef?.current?.tableContent?.current?.className;

          // Horizontal class check
          if (dataRowWidth > tableViewWidth) {
            if (!tableClassName.includes(scrollHorizontalClass)) {
              tableClassName = `${tableClassName}${scrollHorizontalClass}`;
              tableRef.current.tableContent.current.className = tableClassName;
            }
          } else {
            tableClassName = tableClassName.replace(scrollHorizontalClass, '');
            tableRef.current.tableContent.current.className = tableClassName;
          }

          // Vertical class check
          if (dataRowHeight > tableViewHeight) {
            if (!tableClassName.includes(scrollVerticalClass)) {
              tableClassName = `${tableClassName}${scrollVerticalClass}`;
              tableRef.current.tableContent.current.className = tableClassName;
            }
          } else {
            tableClassName = tableClassName.replace(scrollVerticalClass, '');
            tableRef.current.tableContent.current.className = tableClassName;
          }
        }
      }, 100);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => (
      <CustomMuiDatatableSearch
        searchText={searchText}
        onSearch={handleSearch}
        options={options}
      />
    ),
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => (
      <CustomMuiDatatableFooter
        isPaginationVissible={customOptions?.pagination}
        count={count}
        page={page}
        isLoading={isLoading}
        isEmpty={isEmpty}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        textLabels={textLabels}
        rowsPerPageOptions={
          customOptions.rowsPerPageOptions
            ? customOptions.rowsPerPageOptions
            : rowsPerPageOptions
        }
      />
    ),
    ...(openFilter && { customToolbar: (props) => renderToolbarFilter(props) }),
    viewColumns: isLoading ? 'disabled' : true,
    download: isLoading ? 'disabled' : true,
    textLabels: {
      filter: {
        all: 'All filters',
        title: 'Filters',
        reset: 'Clear all filters',
      },
      pagination: {
        next: '',
        previous: '',
        rowsPerPage: '',
        displayRows: getI18n().t('mui-datatables.footer.of'),
      },
      selectedRows: {
        text: 'Row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
      viewColumns: {
        title: '',
      },
      toolbar: {
        downloadCsv: getI18n().t('mui-datatables.toolbar.download'),
        viewColumns: getI18n().t('mui-datatables.toolbar.viewColumns'),
      },
      body: {
        noMatch: <CustomMuiDataTableNoResults visible={isEmpty} />,
      },
    },
    ...customOptions,
  };
};

export default tableConfig;
