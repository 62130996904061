import { memo, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import {
  CalendarTodayOutlined,
  ErrorOutlineOutlined,
  AccessTimeOutlined,
} from '@mui/icons-material';

import moment from 'moment';
import { withTranslation  } from 'react-i18next';

import { classNames } from '../../../../utils';
import { dateTimeStyle } from './style';

const CustomDateTimeLayout = (props) => {
  const { date, isTime = false, className, t } = props;
  const { dateTimePlate } = dateTimeStyle;

  /**
   * Formats date
   */
  const formattedDate = useMemo(() => {
    if (!date) return null;
    const isSameYear = moment().format('YY') === moment(date).format('YY');
    if (isSameYear) return moment(date).format('MMM D');
    return moment(date).format('MMM D, YY');
  }, [date]);

  /**
   * Generates date time plate 
   */
  const datePlate = useMemo(
    () => (
      <>
        <Box className={classNames('date', { error: !date })}>
          {date ? (
            <>
              <CalendarTodayOutlined />
              <Typography variant='body2'>{formattedDate}</Typography>
            </>
          ) : (
            <>
              <ErrorOutlineOutlined />
              <Typography variant='body2'>
                {t('errors.data-tables.no-date')}
              </Typography>
            </>
          )}
        </Box>
        {isTime && date && (
          <Box className='time'>
            <AccessTimeOutlined />
            <Typography variant='body2'>
              {moment(date).format('HH:mm')}
            </Typography>
          </Box>
        )}
      </>
    ),
    [date, formattedDate, isTime, t],
  );

  return (
    <Box sx={dateTimePlate} className={classNames(className, {})}>
      {datePlate}
    </Box>
  );
};

export default withTranslation ()(memo(CustomDateTimeLayout));
