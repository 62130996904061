import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';

import { laptopRatio, mobileRatio, theme } from '../theme';

import { getResponsiveSize } from '../utils';

const AntSwitch = withStyles((styles) => ({
  root: {
    width: getResponsiveSize(28),
    height: getResponsiveSize(16),
    padding: getResponsiveSize(5),
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      width: getResponsiveSize(28, laptopRatio),
      height: getResponsiveSize(16, laptopRatio),
      padding: getResponsiveSize(5, laptopRatio),
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      width: getResponsiveSize(28, mobileRatio),
      height: getResponsiveSize(16, mobileRatio),
      padding: getResponsiveSize(5, mobileRatio),
    },
  },
  switchBase: {
    padding: getResponsiveSize(1.5),
    color: theme.palette.myPrimary.lightBlue40,
    opacity: 1,

    '&$checked': {
      transform: `translateX(${getResponsiveSize(12)})`,
      color: theme.palette.myPrimary.lightBlue,
      opacity: 1,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.myPrimary.lightBlue70,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      padding: getResponsiveSize(1.5, laptopRatio),
      '&$checked': {
        transform: `translateX(${getResponsiveSize(12, laptopRatio)})`,
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      padding: getResponsiveSize(1.5, mobileRatio),
      '&$checked': {
        transform: `translateX(${getResponsiveSize(12, mobileRatio)})`,
      },
    },
  },
  thumb: {
    width: getResponsiveSize(12),
    height: getResponsiveSize(12),
    boxShadow: 'none',
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      width: getResponsiveSize(12, laptopRatio),
      height: getResponsiveSize(12, laptopRatio),
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      width: getResponsiveSize(12, mobileRatio),
      height: getResponsiveSize(12, mobileRatio),
    },
  },
  track: {
    backgroundColor: theme.palette.myPrimary.lightBlue100,
  },
  checked: {},
}))(Switch);

const CustomToggle = ({
  variableTranslation,
  selectedValue,
  variable,
  handleVariableValueChanged,
  hideLabel,
}) => {
  const { t } = useTranslation();
  const [displayValue, setDisplayValue] = useState(false);

  useEffect(() => {
    // Parses string to boolean
    if (selectedValue) setDisplayValue(JSON.parse(selectedValue));
  }, [selectedValue]);

  const handleToggleChange = (event, newValue) => {
    handleVariableValueChanged({
      variable: variable.name,
      value: newValue,
      id: variable.id,
    });
  };

  return (
    <div>
      {hideLabel ? (
        ''
      ) : (
        <Typography id='track-false-slider' gutterBottom>
          {variableTranslation || t(variable.name.toLowerCase())}
        </Typography>
      )}
      <Typography component='div'>
        <Grid component='label' container alignItems='center' spacing={1}>
          <Grid item>
            <AntSwitch checked={displayValue} onChange={handleToggleChange} />
          </Grid>
        </Grid>
      </Typography>
    </div>
  );
};

export default connect()(CustomToggle);
