import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const organizationImageStyle = {
  organizationImage: {
    display: 'flex',
    gap: getResponsiveSize(8), // 8px
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& .img': {
      aspectRatio: '1',
      borderRadius: '50%',
      objectFit: 'cover',
      height: getResponsiveSize(32), // 32px
      width: getResponsiveSize(32), // 32px
    },
    '& .no-image-fallback': {
      height: getResponsiveSize(32), // 32px
      width: getResponsiveSize(32), // 32px
      fill: theme.palette.neutral.grey30,
    },
    '& .MuiTypography-root': {
      fontSize: getResponsiveSize(14), // 14px
      fontFamily: theme.typography.mainFontFamily,
      color: theme.palette.neutral.grey80,
      ...theme.palette.propVars.semiBoldFontWeight,
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
      overflow: 'hidden',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      gap: getResponsiveSize(8, laptopRatio), // 8px
      '& .img, & .no-image-fallback': {
        height: getResponsiveSize(30, laptopRatio), // 30px
        width: getResponsiveSize(30, laptopRatio), // 30px
      },
      '& .MuiTypography-root': {
        fontSize: getResponsiveSize(12, laptopRatio), // 12px
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      gap: getResponsiveSize(8, mobileRatio), // 8px
      '& .img, & .no-image-fallback': {
        height: getResponsiveSize(30, mobileRatio), // 30px
        width: getResponsiveSize(30, mobileRatio), // 30px
      },
      '& .MuiTypography-root': {
        fontSize: getResponsiveSize(14, mobileRatio), // 14px
      },
    },
  },
};
