import { Popper } from '@mui/material';
import AutocompleteSelectHints from '../../AutocompleteSelectHints/AutocompleteSelectHints';

export const AutocomletePoper = (props) => {
  const { hintsSx } = props.sx || {};

  return (
    <Popper {...props} placement='bottom'>
      {props.children}
      <AutocompleteSelectHints hintsSx={hintsSx} />
    </Popper>
  );
};
