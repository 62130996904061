import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}/therapists`;

const getAllPractitioners = (
  offset = 0,
  limit = 10,
  order = 'sessions DESC',
  orgId = null,
  searchQuery = '',
  monthlySession = null,
  totalSessions = null,
  activeClients = null,
) => {
  const organizationId = orgId ? `&organizationId=${orgId}` : '';
  const clients = activeClients ? `&clients=${activeClients}` : '';
  const sessions = totalSessions ? `&sessions=${totalSessions}` : '';
  const monthly_sessions = monthlySession
    ? `&monthly_sessions=${monthlySession}`
    : '';
  const filterString = `&offset=${offset}&limit=${limit}&order=${order}&query=${searchQuery}${organizationId}${clients}${sessions}${monthly_sessions}`;
  return axios.get(`${baseURL}/overview${authUrl()}${filterString}`);
};

const getPractitionersCount = (filter = '&filter[where][is_deleted]=false') =>
  axios.get(`${baseURL}/count${authUrl()}${filter}`);

const createPractitioner = (practitioner) => {
  const { email, firstname, lastname, username, password, organizationId } =
    practitioner;

  return axios.post(`${baseURL}/register${authUrl()}`, {
    email,
    firstname,
    lastname,
    username,
    password,
    organizationId,
  });
};

const updatePractitioner = (practitioner) => {
  const { id, userId, username, email, ...updatePractitioner } = practitioner;
  return axios.patch(`${baseURL}/${id}${authUrl()}`, updatePractitioner);
};

const getUserByPractitionerId = (practitionerId) =>
  axios.get(`${baseURL}/${practitionerId}/user/${authUrl()}`);

const findPractitionerById = (practitionerId) => {
  const filter = '&filter[include]=teams';
  return axios.get(`${baseURL}/${practitionerId}${authUrl()}${filter}`);
};

const deletePractitionerById = (id) => {
  if (!id) return null;
  return updatePractitioner({
    id,
    is_deleted: true,
  });
};

export const practitionerService = {
  getAllPractitioners,
  getUserByPractitionerId,
  createPractitioner,
  findPractitionerById,
  updatePractitioner,
  deletePractitionerById,
  getPractitionersCount,
};
