import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alertActions as alertActionsStore } from '../../store/actions/alert.actions';
import { treatmentRemoteActions as treatmentRemoteActionsStore } from '../../store/actions/treatment-remote.actions';
import { sessionService } from '../../services/sessions';

import {
  CLIENT_PAGE,
  EMDR_REMOTE_URL,
  PRACTITIONER_PAGE,
} from '../../utils/config';

const buttonStyle = {
  padding: 40,
  height: 336,
  fontWeight: 'bold',
  fontSize: 20,
  marginBottom: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  alertActions: bindActionCreators(alertActionsStore, dispatch),
  treatmentRemoteActions: bindActionCreators(
    treatmentRemoteActionsStore,
    dispatch,
  ),
});

const findCorrectSessionUUID = async (treatmentId) => {
  try {
    const foundSessions = await sessionService.getAllSessionsByTreatmentUUID(
      treatmentId,
    );
    if (foundSessions && foundSessions.length) {
      const foundSession = foundSessions[0];
      return foundSession.sessionUUID;
    }
    return null;
  } catch (err) {
    console.error('Failed to fetch sessions.', err);
    return null;
  }
};

const BASE_CLIENT_URL = `${EMDR_REMOTE_URL}/${CLIENT_PAGE}`;
const BASE_PRACTITIONER_URL = `${EMDR_REMOTE_URL}/${PRACTITIONER_PAGE}`;

const HoldingPage = function ({
  alertActions,
  i18n,
  treatmentsRemote,
  treatmentRemoteActions,
  authentication,
}) {
  let divRef = null;
  const { remoteTreatmentUUID, remoteSessionUUID, received, deviceId } =
    treatmentsRemote;
  const isEMDRPlus = Boolean(deviceId);
  const [moveDirectlyToRemote, setMoveDirectlyToRemote] = useState(undefined);
  const [sessionId, setSessionId] = useState(remoteSessionUUID);
  const [treatmentId] = useState(remoteTreatmentUUID);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [firstPollCompleted, setFirstPollCompleted] = useState(false);
  const { user } = authentication || {};

  const renderSteps = !isEMDRPlus || moveDirectlyToRemote !== undefined;

  useEffect(() => {
    setTimeout(() => {
      setFirstPollCompleted(true);
    }, 500);
  }, []);

  useEffect(() => {
    const newUrl = `${BASE_CLIENT_URL}?treatmentUUID=${treatmentId}&sessionUUID=${sessionId}&deviceId=${
      deviceId || ''
    }`;
    if (url !== newUrl) {
      setUrl(newUrl);
    }
  }, [deviceId, treatmentId, sessionId]);

  const practitionerUrl = `${BASE_PRACTITIONER_URL}?treatmentUUID=${treatmentId}&sessionUUID=${sessionId}&token=${
    user?.id
  }&deviceId=${deviceId || ''}`;

  const handleCopy = () => {
    divRef.select();
    document.execCommand('copy');
    alertActions.sendSuccessMessage(
      i18n.t('treatments.configuration.copy_success'),
    );
  };

  useEffect(() => {
    if (treatmentId && (!sessionId || sessionId === 'null')) {
      setIsLoading(true);
      findCorrectSessionUUID(treatmentId).then((result) => {
        setSessionId(result);
        setIsLoading(false);
      });
    }
  }, [treatmentId, sessionId]);

  useEffect(() => {
    if (isLoading || !sessionId || !treatmentId) {
      return;
    }
    const actualSearchParams = `?treatmentUUID=${treatmentId}&sessionUUID=${sessionId}`;
    if (window.location.search !== actualSearchParams) {
      window.location.search = actualSearchParams;
    }
  }, [sessionId, treatmentId, isLoading]);

  useEffect(() => {
    treatmentRemoteActions.blockTreatmentRemote(treatmentId);

    return () => {
      treatmentRemoteActions.clearTreatmentRemoteState();
    };
  }, [treatmentRemoteActions, treatmentId]);

  const renderQuestion = useMemo(() => {
    if (renderSteps) {
      return undefined;
    }
    return (
      <div
        className='flex-col w-100 md:flex-row'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <a
          href={practitionerUrl}
          className='btn-primary w-full mr-0 md:mr-4 md:w-3/5'
          target='_blank'
          rel='noreferrer'
          style={buttonStyle}
        >
          <img
            src='/assets/treatment_room_white@72x.png'
            alt=''
            width={200}
            style={{ marginBottom: 44 }}
          />
          {i18n.t('holding-page.open-session-directly')}
        </a>
        <button
          type='button'
          onClick={() => setMoveDirectlyToRemote(false)}
          className='btn-primary w-full mr-0 md:mr-4 md:w-3/5'
          style={buttonStyle}
        >
          <img
            src='/assets/remote_white@72x.png'
            alt=''
            width={200}
            style={{ marginBottom: 44 }}
          />

          {i18n.t('holding-page.share-with-client')}
        </button>
      </div>
    );
  }, [practitionerUrl, i18n, renderSteps]);

  if (isLoading) {
    return null;
  }

  return (
    <div className='main-container-center steps'>
      {renderQuestion}
      {renderSteps && (
        <>
          <div className='step'>
            <div className='step-title'>
              <div>{i18n.t('treatments.configuration.step_1')}</div>
              <div>{i18n.t('treatments.configuration.copy_url')}</div>
            </div>
            <div className='step-title'>
              {/* Empty div to fix the padding */}
              <div />
              <textarea
                className='step-url'
                ref={(reference) => {
                  divRef = reference;
                  return divRef;
                }}
                defaultValue={url}
              />
            </div>
          </div>
          <div className='step--button-container center'>
            <button
              type='button'
              disabled={!firstPollCompleted}
              className='btn-primary btn-icon'
              onClick={handleCopy}
            >
              {i18n.t('treatments.configuration.copy_url_button')}
              <FontAwesomeIcon className='icon-img' icon='copy' />
            </button>
          </div>
          <div className='step'>
            <div className='step-title'>
              <div>{i18n.t('treatments.configuration.step_2')}</div>
              <div>{i18n.t('treatments.configuration.send_url_to_client')}</div>
            </div>
          </div>
          <div className='step'>
            <div className='step-title'>
              <div>{i18n.t('treatments.configuration.step_3')}</div>
              <div>{i18n.t('treatments.configuration.wait_for_client')}</div>
            </div>
          </div>
          <div className='step--button-container'>
            <a
              href={practitionerUrl}
              className={`btn-primary ${!received ? 'disabled' : ''}`}
              target='_blank'
              rel='noreferrer'
            >
              {received
                ? i18n.t('treatments.configuration.join_session')
                : i18n.t('treatments.configuration.loading_session')}
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(HoldingPage),
);
