import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const buttonStyles = {
  button: {
    py: getResponsiveSize(12), // 12px
    px: getResponsiveSize(16), // 16px
    height: getResponsiveSize(46), // 46px
    fontSize: theme.typography.fs19, // 19px
    lineHeight: getResponsiveSize(22), // 22px
    textTransform: 'none',
    borderRadius: theme.palette.propVars.borderRadius,
    ...theme.palette.propVars.btnBoxShadow,
    ...theme.palette.propVars.semiBoldFontWeight,
    // slide color effect
    backgroundSize: '200%',
    backgroundPosition: ' right bottom',
    transition:
      '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    '&.primaryBtn': {
      backgroundImage: `linear-gradient(to left, ${theme.palette.myPrimary.lightBlue100} 50%, ${theme.palette.myPrimary.darkBlue100} 50%)`,
      color: theme.palette.myPrimary.white,
      '&:hover': {
        backgroundPosition: 'left bottom',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        background: theme.palette.myPrimary.lightBlue100,
      },
    },
    '&.secondaryBtn': {
      backgroundImage: `linear-gradient(to left, transparent 50%, ${theme.palette.myPrimary.lightBlue40} 50%)`,
      border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
      color: theme.palette.myPrimary.lightBlue100,
      '&:hover': {
        backgroundPosition: 'left bottom',
      },
      '&:active': {
        color: theme.palette.myPrimary.lightBlue100,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
      },
    },
    '&.inputBtn': {
      backgroundColor: theme.palette.myPrimary.lightBlue100,
      backgroundImage: `linear-gradient(to left, ${theme.palette.myPrimary.lightBlue70} 50%, ${theme.palette.myPrimary.lightBlue100} 50%)`,
      color: theme.palette.myPrimary.white,
      '&:hover': {
        backgroundPosition: 'left bottom',
      },
      '&.Mui-disabled': {
        background: theme.palette.myPrimary.lightBlue100,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      height: getResponsiveSize(38, laptopRatio), // 38px
      py: getResponsiveSize(12, laptopRatio), // 12px
      px: getResponsiveSize(16, laptopRatio), // 16px
      fontSize: getResponsiveSize(15, laptopRatio), // 15px
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      height: getResponsiveSize(32, mobileRatio), // 32px
      py: getResponsiveSize(8, mobileRatio), // 8px
      px: getResponsiveSize(12, mobileRatio), // 12px
      fontSize: getResponsiveSize(15, mobileRatio), // 15px
    },
  },
};
