import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const formModalStyle = {
  formModal: {
    '&.form-modal': {
      '& .modal-wrapper': {
        width: getResponsiveSize(1010), // 1010px
        '& .modal-header': {
          backgroundColor: '#2392BA0D',
        },
        '& .Mui-error': {
          fontSize: getResponsiveSize(13), // 13px
          color: theme.palette.neutral.errorDark,
          px: getResponsiveSize(10), // 10px
          mx: 0,
        },
        '& .modal-footer': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#2392BA0D',
          py: getResponsiveSize(15), // 31.5px
          px: getResponsiveSize(36), // 36px
          borderRadius: `0 0 ${theme.palette.propVars.borderRadius} ${theme.palette.propVars.borderRadius}`,
          borderBottom: `1px solid  ${theme.palette.myPrimary.lightBlue20}`,
          '&.justify-end': {
            justifyContent: 'flex-end',
          },
          '& .action-btn': {
            minWidth: getResponsiveSize(120), // 120px
          },
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '&.form-modal .modal-wrapper': {
        width: getResponsiveSize(720, laptopRatio), // 720px
        '& .Mui-error': {
          fontSize: getResponsiveSize(11, laptopRatio), // 11px
          px: getResponsiveSize(10, laptopRatio), // 10px
        },
        '& .MuiAutocomplete-root': {
          '& .MuiFormLabel-root': {
            fontSize: getResponsiveSize(13, laptopRatio), // 13px
            transform: `translate(${getResponsiveSize(
              10,
              laptopRatio,
            )}, ${getResponsiveSize(16, laptopRatio)}) scale(1)`,
            '&.Mui-focused': {
              transform: `translate(${getResponsiveSize(
                14,
                laptopRatio,
              )}, calc(${getResponsiveSize(6, laptopRatio)} * -1)) scale(0.9)`,
              lineHeight: 1,
            },
            '&.MuiFormLabel-filled': {
              transform: `translate(${getResponsiveSize(
                14,
                laptopRatio,
              )}, calc(${getResponsiveSize(6, laptopRatio)} * -1)) scale(0.9)`,
              lineHeight: 1,
            },
          },
          '& .MuiInputBase-root': {
            borderRadius: getResponsiveSize(12, laptopRatio), // 12px
            '& .MuiInputBase-input': {
              pl: `${getResponsiveSize(16, laptopRatio)} !important`, // 16px
              fontSize: `${getResponsiveSize(13, laptopRatio)} !important`, // 13px
            },
          },
          '& fieldset legend': {
            fontSize: getResponsiveSize(10.5, laptopRatio),
            '& span': {
              mr: getResponsiveSize(10, laptopRatio), // 10px
            },
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '&.form-modal .modal-wrapper': {
        width: '85dvw',
        '& .Mui-error': {
          fontSize: getResponsiveSize(12, mobileRatio), // 12px
          px: getResponsiveSize(10, mobileRatio), // 10px
        },
        '& .MuiAutocomplete-root': {
          '& .MuiFormLabel-root': {
            fontSize: getResponsiveSize(13, mobileRatio), // 13px
            transform: `translate(${getResponsiveSize(
              10,
              mobileRatio,
            )}, ${getResponsiveSize(12, mobileRatio)}) scale(1)`,
            '&.Mui-focused': {
              transform: `translate(${getResponsiveSize(
                14,
                mobileRatio,
              )}, calc(${getResponsiveSize(6, mobileRatio)} * -1)) scale(0.9)`,
              lineHeight: 1,
            },
            '&.MuiFormLabel-filled': {
              transform: `translate(${getResponsiveSize(
                14,
                mobileRatio,
              )}, calc(${getResponsiveSize(6, mobileRatio)} * -1)) scale(0.9)`,
              lineHeight: 1,
            },
          },
          '& .MuiInputBase-root': {
            borderRadius: getResponsiveSize(8, mobileRatio), // 12px
            '& .MuiInputBase-input': {
              pl: `${getResponsiveSize(16, mobileRatio)} !important`, // 16px
              fontSize: `${getResponsiveSize(13, mobileRatio)} !important`, // 13px
            },
          },
          '& fieldset legend': {
            fontSize: getResponsiveSize(10.5, mobileRatio),
            '& span': {
              mr: getResponsiveSize(10, mobileRatio), // 10px
            },
          },
        },
      },
    },
  },
};
