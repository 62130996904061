import moment from 'moment';
import { getI18n } from 'react-i18next';
import { deviceService } from '../../services/devices';
import { deviceConstants } from '../constants/device.constants';
import { alertActions } from './alert.actions';
import { statisticsService } from '../../services/statistics';

function getDeviceOverview(
  offset,
  limit,
  order,
  organizationId,
  searchQuery = '',
  generated_uuid,
  type,
  monthly_sessions,
  show_deleted,
) {
  function request() {
    return { type: deviceConstants.GETALL_REQUEST };
  }

  function success(devices, count) {
    return { type: deviceConstants.GETALL_SUCCESS, devices, count };
  }

  function failure(error) {
    return { type: deviceConstants.GETALL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    return deviceService
      .getDevicesOverview(
        offset,
        limit,
        order,
        organizationId,
        searchQuery,
        generated_uuid,
        type,
        monthly_sessions,
        show_deleted,
      )
      .then(
        (result) => {
          const { devices = [], count = 0 } = result?.data || {};
          dispatch(success(devices, count));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(getI18n().t('errors.get.devices')));
        },
      );
  };
}

function getDevicesByOrganizationId(organizationId) {
  function request() {
    return { type: deviceConstants.GETALL_REQUEST };
  }

  function success(devices) {
    return { type: deviceConstants.GETALL_SUCCESS, devices };
  }

  function failure(error) {
    return { type: deviceConstants.GETALL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    return deviceService.getDevicesByOrganizationId(organizationId).then(
      (result) => {
        const devices = ((result || {}).data || {}).devices || [];
        dispatch(success(devices));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.devices')));
      },
    );
  };
}

function getAllDevices(withStats = true) {
  return (dispatch) => {
    dispatch(request());
    return deviceService.getAllDevices().then(
      (result) => {
        const devices = result.data;
        if (devices.length) {
          Promise.all(
            devices.map(async (device) => {
              if (withStats) {
                const { sum, monthSum } = await getStatsOfDeviceById(device.id);
                device.totalSessions = sum;
                device.currentSessionsOfMonth = monthSum;
              }
              return device;
            }),
          ).then((devices) => {
            dispatch(success(devices));
          });
        } else {
          dispatch(success([]));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.devices')));
      },
    );
  };

  function request() {
    return { type: deviceConstants.GETALL_REQUEST };
  }

  function success(devices) {
    return { type: deviceConstants.GETALL_SUCCESS, devices };
  }

  function failure(error) {
    return { type: deviceConstants.GETALL_FAILURE, error };
  }
}

function getAllDevicesNames() {
  return (dispatch) => {
    dispatch(request());
    return deviceService.getDeviceNames().then(
      (result) => {
        const names = result.data;
        if (names.length) dispatch(success(names));
        else dispatch(success([]));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.devices')));
      },
    );
  };

  function request() {
    return { type: deviceConstants.GETALL_NAMES_REQUEST };
  }

  function success(names) {
    return { type: deviceConstants.GETALL_NAMES_SUCCESS, names };
  }

  function failure(error) {
    return { type: deviceConstants.GETALL_NAMES_FAILURE, error };
  }
}

function getAllDevicesGeneratedUUID() {
  return (dispatch) => {
    dispatch(request());
    return deviceService.getDeviceGeneratedUUIDs().then(
      (result) => {
        const devicesSerialNumbers = result.data;
        if (devicesSerialNumbers.length)
          dispatch(success(devicesSerialNumbers));
        else dispatch(success([]));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.devices')));
      },
    );
  };

  function request() {
    return { type: deviceConstants.GETALL_SERIALS_REQUEST };
  }

  function success(devicesSerialNumbers) {
    return {
      type: deviceConstants.GETALL_SERIALS_SUCCESS,
      devicesSerialNumbers,
    };
  }

  function failure(error) {
    return { type: deviceConstants.GETALL_SERIALS_FAILURE, error };
  }
}

function getAllDeviceProfiles() {
  return (dispatch) => {
    dispatch(request());
    return deviceService.getDeviceProfiles().then(
      (result) => {
        const deviceProfiles = result.data;

        if (deviceProfiles.length > 0) {
          dispatch(success(deviceProfiles));
        } else {
          dispatch(success([]));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.device-profiles')));
      },
    );
  };

  function request() {
    return { type: deviceConstants.GETALL_PROFILE_REQUEST };
  }

  function success(deviceProfiles) {
    return { type: deviceConstants.GETALL_PROFILE_SUCCESS, deviceProfiles };
  }

  function failure(error) {
    return { type: deviceConstants.GETALL_PROFILE_FAILURE, error };
  }
}

function createDevice(device) {
  return (dispatch) => {
    dispatch(request(device));
    return deviceService.createDevice(device).then(
      (result) => {
        dispatch(success({ ...device, ...result.data }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.create.device')));
      },
    );
  };

  function request(device) {
    return { type: deviceConstants.CREATE_REQUEST, device };
  }

  function success(device) {
    return { type: deviceConstants.CREATE_SUCCESS, device };
  }

  function failure(error) {
    return { type: deviceConstants.CREATE_FAILURE, error };
  }
}

function createFewDevices(devices) {
  return (dispatch) => {
    dispatch(request());
    const requestDevicesCreation = devices.map((device) =>
      deviceService.createDevice(device),
    );
    return Promise.all(requestDevicesCreation).then(
      (result) => {
        dispatch(success([...devices]));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.create.device')));
      },
    );
  };

  function request() {
    return { type: deviceConstants.CREATE_REQUEST };
  }

  function success(devices) {
    return { type: deviceConstants.CREATE_FEW_SUCCESS, devices };
  }

  function failure(error) {
    return { type: deviceConstants.CREATE_FAILURE, error };
  }
}

function updateDevice(device) {
  return (dispatch) => {
    const { id } = device;
    dispatch(request(device));
    return deviceService.updateDevice(device).then(
      (result) => {
        dispatch(success({ device: result.data }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.update.device')));
      },
    );
  };

  function request(device) {
    return { type: deviceConstants.UPDATE_REQUEST, device };
  }

  function success(device) {
    return { type: deviceConstants.UPDATE_SUCCESS, device: device.device };
  }

  function failure(error) {
    return { type: deviceConstants.UPDATE_FAILURE, error };
  }
}

function updateFewDevices(devices) {
  return (dispatch) => {
    dispatch(request());
    return deviceService.updateFewDevices(devices).then(
      (result) => {
        const updatedDevices = result.map((item) => item.data);
        dispatch(success({ devices: updatedDevices }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.update.device')));
      },
    );
  };

  function request() {
    return { type: deviceConstants.UPDATE_FEW_REQUEST };
  }

  function success(devices) {
    return {
      type: deviceConstants.UPDATE_FEW_SUCCESS,
      devices: devices.devices,
    };
  }

  function failure(error) {
    return { type: deviceConstants.UPDATE_FEW_FAILURE, error };
  }
}

function deleteDevice(deviceId) {
  return (dispatch) => {
    dispatch(request(deviceId));
    return deviceService.deleteDeviceById(deviceId).then(
      () => {
        dispatch(success(deviceId));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.delete.device')));
      },
    );
  };

  function request(device) {
    return { type: deviceConstants.DELETE_REQUEST, device };
  }

  function success(id) {
    return { type: deviceConstants.DELETE_SUCCESS, id };
  }

  function failure(error) {
    return { type: deviceConstants.DELETE_FAILURE, error };
  }
}

function getDeviceById(id) {
  return (dispatch) => {
    dispatch(request(id));
    return deviceService.getDeviceById(id).then(
      (result) => {
        dispatch(success(result.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.device')));
      },
    );
  };

  function request(id) {
    return { type: deviceConstants.GET_REQUEST, id };
  }

  function success(device) {
    return { type: deviceConstants.GET_SUCCESS, device };
  }

  function failure(error) {
    return { type: deviceConstants.GET_FAILURE, error };
  }
}

function getDevicesCount(filter) {
  function request() {
    return { type: deviceConstants.COUNT_REQUEST };
  }

  function success(count) {
    return {
      type: deviceConstants.COUNT_SUCCESS,
      count,
    };
  }

  function failure(error) {
    return {
      type: deviceConstants.COUNT_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    return deviceService.getDevicesCount(filter).then(
      (result) => {
        const { count } = result.data;
        dispatch(success(count));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.devices')));
      },
    );
  };
}

const getStatsOfDeviceById = (id) => {
  const filter = `&deviceId=${id}`;
  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  const currentMonth = moment().month();
  const currentYear = moment().year();

  return statisticsService.getAllStatistics(filter).then((result) => {
    const { data } = result;
    let sum = 0;
    let monthSum = 0;

    if (data && data.length) {
      const weekAmounts = data.map((item) => item.count);
      const filteredSessions = data
        .filter(
          (item) =>
            moment(item.week).month() === currentMonth &&
            moment(item.week).year() === currentYear,
        )
        .map((item) => parseInt(item.count || 0));
      if (filteredSessions && filteredSessions.length > 0) {
        sum = weekAmounts.reduce(reducer);
        monthSum = filteredSessions.reduce(reducer);
      }
    }
    return { sum, monthSum, data };
  });
};

function clearDevices() {
  return (dispatch) => {
    dispatch({
      type: deviceConstants.CLEAR_DEVICES,
    });
  };
}

export const deviceActions = {
  getAllDevices,
  getAllDevicesNames,
  getAllDevicesGeneratedUUID,
  getAllDeviceProfiles,
  createDevice,
  createFewDevices,
  updateDevice,
  updateFewDevices,
  getDeviceById,
  getStatsOfDeviceById,
  deleteDevice,
  getDeviceOverview,
  getDevicesByOrganizationId,
  getDevicesCount,
  clearDevices,
};
