import React from 'react';
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';
import ModalWrapper from '../components/ModalWrapper';
import AddClientToTeamForm from '../components/Teams/AddClientToTeamForm';
import AddPractitionerToTeamForm from '../components/Teams/AddPractitionerToTeamForm';
import SimpleTable from '../components/SimpleTable';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';

import { teamActions } from '../store/actions/team.actions';

class TeamDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teamId: props.match.params.id,
      foundPractitioners: [],
      foundClients: [],
      practitionersIsLoading: true,
      clientsIsLoading: true,
      openedModalId: null,
      isAddingPractitioner: false,
      isAddingClient: false,
    };

    this.togglePopover = this.togglePopover.bind(this);
    this.handleAddPractitioner = this.handleAddPractitioner.bind(this);
    this.handleDeletePractitioner = this.handleDeletePractitioner.bind(this);
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleAddPractitioner(event) {
    this.setState({
      title: this.props.i18n.t('teams.practitioner-team'),
      isAddingClient: false,
      isAddingPractitioner: true,
    });
  }

  handleDeletePractitioner(event, row) {
    if (row && row.id) {
      this.props.deletePractitioner(this.state.teamId, row.id);
    }
  }

  handleAddClient(event) {
    this.setState({
      title: this.props.i18n.t('teams.client-team'),
      isAddingClient: true,
      isAddingPractitioner: false,
    });
  }

  handleDeleteClient(event, row) {
    if (row && row.id) {
      this.props.deleteClient(this.state.teamId, row.id);
    }
  }

  handleCloseModal() {
    this.setState({
      title: null,
      isAddingClient: false,
      isAddingPractitioner: false,
    });
  }

  togglePopover(id) {
    const { openedModalId } = this.state;

    if (openedModalId === id) {
      this.setState({ openedModalId: null });
      return;
    }

    this.setState({ openedModalId: id });
  }

  componentDidMount() {
    const { teamId } = this.state;
    if (!teamId) {
      return;
    }
    this.props.findTeamById(teamId);
  }

  render() {
    const { selectedTeam } = this.props.teams;
    const { i18n } = this.props;
    const {
      openedModalId, teamId, title, isAddingClient, isAddingPractitioner,
    } = this.state;
    const teamToDisplay = selectedTeam || {};
    const backNav = { title: 'Teams overview', route: '/admin/teams' };
    return (
      <>
        <div className="title">
          <CustomBreadcrumbs backNav={backNav} currentTitle={teamToDisplay.name} />
          <p className="title-description">
            {i18n.t('teams.detail.subtitle')}
            {' '}
            {teamToDisplay.id}
          </p>
        </div>

        <div key="table-container" className="team-detail-container">
          <SimpleTable
            key="practitioner-table"
            title="Practitioners"
            rows={teamToDisplay.therapists}
            openedModalId={openedModalId}
            isLoading={!teamToDisplay.therapists}
            togglePopover={this.togglePopover}
            createButtonText={i18n.t('buttons.create.practitioner-team')}
            createAction={this.handleAddPractitioner}
            deleteAction={this.handleDeletePractitioner}
          />
          <SimpleTable
            key="clients-table"
            title="Clients"
            rows={teamToDisplay.clients}
            openedModalId={openedModalId}
            isLoading={!teamToDisplay.clients}
            togglePopover={this.togglePopover}
            createButtonText={i18n.t('buttons.create.client-team')}
            createAction={this.handleAddClient}
            deleteAction={this.handleDeleteClient}
          />
        </div>
        <ModalWrapper
          title={title}
          modalIsOpen={isAddingClient || isAddingPractitioner}
          closeModal={this.handleCloseModal}
        >
          {isAddingClient ? (
            <AddClientToTeamForm closeModal={this.handleCloseModal} teamId={teamId} />
          ) : isAddingPractitioner ? (
            <AddPractitionerToTeamForm closeModal={this.handleCloseModal} teamId={teamId} />
          ) : (
            ''
          )}
        </ModalWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  findTeamById: teamActions.findTeamById,
  deletePractitioner: teamActions.removePractitionerFromTeam,
  deleteClient: teamActions.removeClientFromTeam,
};

export default TeamDetail = withTranslation ()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamDetail));
