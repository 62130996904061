import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ArrowRightAltRounded } from '@mui/icons-material';
import { ButtonUi } from '../UI/Button/Button';
import { simpleModalContentStyle } from '../UI/SimpleModalContent/style';
import { classNames } from '../../utils';

export const ConfirmationModal = memo((props) => {
  const {
    text,
    onConfirm,
    onDeclain,
    confirmBtn = 'buttons.confirm',
    declainBtn = 'buttons.cancel',
    existsDevices,
  } = props;

  const { modalContent } = simpleModalContentStyle;
  const { t } = useTranslation();
  const { shortOrganizations: organizations } = useSelector(
    (state) => state.organizations,
  );

  /**
   * Get device organization data
   * @param id organization id
   * @returns organization data
   */
  const getDeviceOrganization = (id) =>
    organizations.find((org) => org.id === id);

  return (
    <Box sx={modalContent} className='expanded-confirm'>
      <Typography variant='body1' className='content-item'>
        {text}
      </Typography>
      <Box className='data-wrapper'>
        {existsDevices?.map((device) => (
          <Box
            className={classNames('confirm-modal-data', {
              divider: existsDevices.length > 1,
            })}
            key={`device-${device.name}`}
          >
            <Box className='label-value-row'>
              <Typography variant='caption' className='data-label'>
                {t('forms.name')}:
              </Typography>
              <Typography variant='body1' className='data-value'>
                {device.name}
              </Typography>
              <ArrowRightAltRounded />
              <Typography variant='body1' className='data-value'>
                {device.newName}
              </Typography>
            </Box>
            <Box className='label-value-row'>
              <Typography variant='caption' className='data-label'>
                {t('billing.organization')}:
              </Typography>
              <Typography variant='body1' className='data-value'>
                {getDeviceOrganization(device.organizationId)?.name}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box className='content-footer'>
        <ButtonUi
          className='main-btn'
          variant='outlined'
          type='input'
          size='small'
          onClickHandler={onConfirm}
        >
          {t(confirmBtn)}
        </ButtonUi>
        <ButtonUi
          className='secondary-btn'
          variant='outlined'
          type='secondary'
          size='small'
          onClickHandler={onDeclain}
        >
          {t(declainBtn)}
        </ButtonUi>
      </Box>
    </Box>
  );
});
