import { dashboardConstants } from '../constants/dashboard.constants';

export function dashboard(state = { stats: [], isLoading: true }, action) {
  switch (action.type) {
    case dashboardConstants.GETALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dashboardConstants.GETALL_SUCCESS:
      return {
        ...state,
        stats: action.stats,
        isLoading: false,
      };
    case dashboardConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
}
