import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';

import Select from 'react-select';

const SelectComponent = ({
  value,
  label,
  loadingText,
  selectText,
  options,
  isLoading,
  isClearable,
  handleChange,
  isOptionsWidth = false,
  isFullWidth = false,
  maxMenuHeight = '300px',
  className,
  i18n,
}) => {
  const hasIcons = useMemo(() => (options || []).find((option) => option.icon), []);
  const renderLabel = ({ icon, label: labelToRender }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon && <img className='icon' height={20} width={20} src={icon}
alt='' />}
      <span style={{ marginLeft: 5 }}>{labelToRender}</span>
    </div>
  );

  return (
    <>
      <label className='select-label'>{i18n.t(label)}</label>
      <Select
        isSearchable
        // menuIsOpen
        className={`select-filter ${className}`}
        options={options}
        isClearable={isClearable}
        styles={{
          menuPortal: (base) => {
            let width;
            isOptionsWidth && (width = '100%');
            isFullWidth && (width = base.width);
            return {
              ...base,
              width,
              zIndex: 9999,
            };
          },
          menu: (base) => {
            let width;
            isOptionsWidth && (width = 'auto');
            isFullWidth && (width = base.width);
            return {
              ...base,
              width,
            };
          },
        }}
        menuPortalTarget={document.body}
        placeholder={isLoading ? i18n.t(loadingText) : i18n.t(selectText)}
        isDisabled={isLoading}
        onChange={handleChange}
        maxMenuHeight={maxMenuHeight}
        value={value}
        getOptionLabel={hasIcons ? renderLabel : undefined}
        inputProps={{
          name: 'product',
          id: 'select-product',
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default withTranslation ()(connect(mapStateToProps)(SelectComponent));
