import {
  Suspense,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { withTranslation  } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { useActions } from '../../../../../../hooks/useActions';

import { advancedConfigurationStyle } from './style';
import { useAdvancedSettingsGroupsData } from '../../../hooks/useAdvancedSettingsGroupsData';
import { TabPanel } from '../../../../../UI/TabPanel/TabPanel';
import ConfigurationTab from './ConfigurationTab/ConfigurationTab';
import Loader from '../../../../../Loader';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function AdvancedConfiguration(props) {
  const { t } = props;

  const { selectedDeviceConfig, selectedProduct } = useSelector(
    (state) => state.createTreatmentWizard,
  );

  const { advancedConfig } = advancedConfigurationStyle;
  const { setDeviceConfig } = useActions();

  const [activeTab, setActiveTab] = useState(0);
  const changedVariables = useRef([]);

  /**
   * Handle tab change
   * @param event native event
   * @param tab new active tab index
   */
  const handleChange = (event, tab) => setActiveTab(tab);

  /**
   * Advenced settings groups with group settings data
   */
  const settingsGroupsData = useAdvancedSettingsGroupsData();

  /**
   * Translate setting variable name
   * @param name variable name
   * @returns translated variable name
   */
  const getVariableTranslation = useCallback(
    (name) =>
      t(`configurations.${selectedProduct.name}.variables.${name}`, name) ||
      t(name.toLowerCase()),
    [t, selectedProduct],
  );

  /**
   * Gets configuration`s group variable value by variable name
   * @param name configuration`s group variable name
   */
  const getConfigVariableValueByName = useCallback(
    (name) =>
      selectedDeviceConfig.values.find((variable) => variable.name === name)
        .value,
    [selectedDeviceConfig],
  );

  /**
   * Handle advanced settings variable changes
   * @param value selected value
   */
  const handleVariableChange = useCallback(
    (value) => {
      const existingVariable = changedVariables?.current?.find(
        (variable) => variable.name === value.variable,
      );
      let updatedVariable;
      if (existingVariable) {
        updatedVariable = { ...existingVariable, value: value?.value };
        changedVariables.current = [
          ...changedVariables.current.map((variable) =>
            variable.name === updatedVariable.name ? updatedVariable : variable,
          ),
        ];
      } else {
        updatedVariable = {
          ...selectedDeviceConfig.values.find(
            (variable) => variable.name === value.variable,
          ),
          value: value?.value,
        };
        changedVariables.current = [
          ...changedVariables.current,
          { ...updatedVariable },
        ];
      }
    },
    [changedVariables, selectedDeviceConfig],
  );

  /**
   * Update selected configuration variables
   */
  const updateSelectedConfig = useCallback(() => {
    const newVariables = selectedDeviceConfig.values.map((variable) => {
      const changedVariable = changedVariables?.current?.find(
        (changedVar) => variable.name === changedVar.name,
      );
      return changedVariable || variable;
    });

    setDeviceConfig({
      ...selectedDeviceConfig,
      values: [...newVariables],
    });
  }, [setDeviceConfig, changedVariables, selectedDeviceConfig]);

  return (
    <Box sx={advancedConfig}>
      <Tabs
        orientation='vertical'
        value={activeTab}
        variant='standard'
        onChange={handleChange}
        aria-label='advanced-settings-tabs'
      >
        {settingsGroupsData.map((group, index) => (
          <Tab
            icon={group.Icon}
            key={group.groupName}
            label={t(
              `configurations.${selectedProduct.name}.groups.${group.groupName}`,
              group.groupName,
            )}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      <Suspense fallback={<Loader />}>
        {settingsGroupsData.map((group, index) => (
          <TabPanel
            className='tab-wrapper'
            key={group.groupName}
            value={activeTab}
            index={index}
          >
            <ConfigurationTab
              handleVariableChange={handleVariableChange}
              getVariableTranslation={getVariableTranslation}
              getConfigVariableValueByName={getConfigVariableValueByName}
              updateSelectedConfig={updateSelectedConfig}
              group={group}
            />
          </TabPanel>
        ))}
      </Suspense>
    </Box>
  );
}

export default withTranslation ()(memo(AdvancedConfiguration));
