import { wizardSteps } from '../../components/Treatments/CreateTreatmentWizard/wizardSteps';
import { createTreatmentWizardConstants } from '../constants/create-treatment-wizard.constants';

const initialState = {
  activeStep: wizardSteps[0],
  steps: wizardSteps,
  productConfigTemplates: [],
  selectedProduct: null,
  selectedClient: null,
  anonymousClient: null,
  selectedDevice: null,
  selectedDeviceConfig: null,
  canGoNext: false,
  location: null,
  isLoading: false,
  error: null,
  isTimeout: false,
  isAnonymousSession: null,
  isPreviousSession: false,
  showNotification: false,
};

export const createTreatmentWizard = (state = initialState, action) => {
  switch (action.type) {
    case createTreatmentWizardConstants.SEND_REQUEST:
      return { ...state, isLoading: true };
    case createTreatmentWizardConstants.GET_PRODUCT_CONFIG_TEMPLATES_SUCCESS:
      return { ...state, productConfigTemplates: [...action.templates], isLoading: false };
    case createTreatmentWizardConstants.GET_PRODUCT_CONFIG_TEMPLATES_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    case createTreatmentWizardConstants.SET_ERROR:
      return { ...state, error: action.payload };
    case createTreatmentWizardConstants.SET_SHOWNOTIFICATION:
      return { ...state, showNotification: action.payload };
    case createTreatmentWizardConstants.SET_ANONYMOUSSESSION:
      return { ...state, isAnonymousSession: action.payload };
    case createTreatmentWizardConstants.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case createTreatmentWizardConstants.SET_SELECTED_PRODUCT:
      return { ...state, selectedProduct: action.payload };
    case createTreatmentWizardConstants.SET_SELECTED_CLIENT:
      return { ...state, selectedClient: action.payload };
    case createTreatmentWizardConstants.SET_ANONYMOUS_CLIENT:
      return { ...state, anonymousClient: action.payload };
    case createTreatmentWizardConstants.SET_SELECTED_DEVICE:
      return { ...state, selectedDevice: action.payload };
    case createTreatmentWizardConstants.SET_SELECTED_DEVICE_CONFIGURATION:
      return { ...state, selectedDeviceConfig: action.payload };
    case createTreatmentWizardConstants.SET_NEXT_BUTTON:
      return { ...state, canGoNext: action.payload };
    case createTreatmentWizardConstants.SET_TIMEOUT:
      return { ...state, isTimeout: action.payload };
    case createTreatmentWizardConstants.SET_ISPREVIOUSSESSION:
      return { ...state, isPreviousSession: action.payload };
    case createTreatmentWizardConstants.SET_LOCATION:
      return { ...state, location: action.payload };
    case createTreatmentWizardConstants.ADD_STEP:
      return { ...state, steps: [...state.steps, action.payload] };
    case createTreatmentWizardConstants.REMOVE_STEP:
      return {
        ...state,
        steps: state.steps.filter((step) => step.stepId !== action.payload.stepId),
      };
    case createTreatmentWizardConstants.SET_DEFAULT_STEPS:
      return { ...state, steps: [...wizardSteps] };
    case createTreatmentWizardConstants.RESET_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
};
