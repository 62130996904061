import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as RemoteIcon } from '../../../../shared/assets/icons/therapies/remote-session.svg';
import { ReactComponent as TherapyRoomIcon } from '../../../../shared/assets/icons/therapies/therapy-room.svg';
import { REMOTE_THERAPY, THERAPY_ROOM } from '../../../../utils/config';

export const therapyTypeData = {
  THERAPY_ROOM: {
    type: THERAPY_ROOM,
    checked: false,
    title: 'holding-page.open-session-directly',
    desc: 'create-treatment-wizard.select-therapy-type.THERAPY_ROOM.description',
    Icon: <TherapyRoomIcon />,
  },
  REMOTE_SESSION: {
    type: REMOTE_THERAPY,
    checked: false,
    title: 'holding-page.share-with-client',
    desc: 'create-treatment-wizard.select-therapy-type.REMOTE_THERAPY.description',
    Icon: <RemoteIcon />,
  },
};

export const useFetchTherapyType = () => {
  const [therapyType, setTherapyType] = useState(therapyTypeData);
  const { location } = useSelector((state) => state.createTreatmentWizard);

  useEffect(() => {
    switch (location) {
      case THERAPY_ROOM:
        setTherapyType((prev) => ({
          THERAPY_ROOM: { ...prev.THERAPY_ROOM, checked: true },
          REMOTE_SESSION: { ...prev.REMOTE_SESSION, checked: false },
        }));
        break;
      case REMOTE_THERAPY:
        setTherapyType((prev) => ({
          THERAPY_ROOM: { ...prev.THERAPY_ROOM, checked: false },
          REMOTE_SESSION: { ...prev.REMOTE_SESSION, checked: true },
        }));
        break;
    }
  }, [location, setTherapyType]);

  return [therapyType, setTherapyType];
};
