import { Checkbox } from '@mui/material';
import { classNames } from '../../../utils';
import { checkboxStyle } from './style';

export const CheckboxUi = (props) => {
  const { checked, onChangeHandler, className } = props;
  const { checkbox } = checkboxStyle;

  return (
    <Checkbox
      sx={checkbox}
      className={classNames(`${className}`)}
      checked={checked}
      onChange={onChangeHandler}
    />
  );
};
