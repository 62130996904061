import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getParameterByName } from '../utils/history';

import { AUTH_URL } from '../utils/config';

const constructUrl = (idpHint) => {
  const redirectUri = encodeURI(`${window.location.origin}/sso/callback`);
  const url = `${AUTH_URL}?client_id=admin-portal&redirect_uri=${redirectUri}&response_type=code&kc_idp_hint=${idpHint}`;
  return url;
};

function useSsoHint() {
  const history = useHistory();
  const idpHint = getParameterByName('idp_hint', history.location.url);

  useEffect(() => {
    if (idpHint) {
      const url = constructUrl(idpHint);
      window.location.href = url;
    }
  }, [idpHint]);

  return idpHint;
}

export default useSsoHint;
