import { laptopRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const checkboxCardStyles = {
  checkboxCard: {
    display: 'flex',
    minWidth: getResponsiveSize(440), // 440px
    borderRadius: theme.palette.propVars.borderRadius,
    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '& .MuiCardActionArea-root': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      py: getResponsiveSize(7), // 7px
      px: getResponsiveSize(19), // 19px
      background: theme.palette.myPrimary.white,
      borderRadius: theme.palette.propVars.borderRadius,
      border: `${getResponsiveSize(2)} solid transparent`,
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '& .MuiCardActionArea-focusHighlight': {
        backgroundColor: '#000000',
      },
      '&:hover': {
        transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderColor: '#0000000A',
      },
      '& .icon svg': {
        mr: getResponsiveSize(28), // 28px
        width: getResponsiveSize(46), // 46px
        height: getResponsiveSize(46), // 46px
        minHeight: 36,
        minWidth: 36,
      },
      '& .MuiCardContent-root': {
        p: 0,
        '& .card-title': {
          color: theme.palette.neutral.grey70,
          m: 0,
          fontWeight: 700,
          lineHeight: getResponsiveSize(22), // 22px,
          border: 0,
        },
        '& .desc': {
          color: theme.palette.neutral.greyRGBA80,
          lineHeight: getResponsiveSize(18), // 18px
          ...theme.palette.propVars.regularFontWeight,
        },
      },
      '& .checkbox': {
        ml: 'auto',
        ...theme.palette.propVars.easeInOutTransition,
      },
    },
    // detailed checkbox card
    '&.column': {
      width: getResponsiveSize(440), // 440px,
      '& .MuiCardActionArea-root': {
        pl: getResponsiveSize(16), // 16px,
        '& .icon svg': {
          mr: getResponsiveSize(8), // 8px,
          width: 'auto',
          height: 'auto',
        },
        '& .MuiCardContent-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '100%',
          width: '100%',
          pt: getResponsiveSize(17), // 17px,
          gap: getResponsiveSize(20), // 20px,
        },
        '& .checkbox': {
          position: 'absolute',
          right: getResponsiveSize(10), // 10px,
          top: getResponsiveSize(12), // 12px,
        },
      },
    },
    '&.disabled': {
      pointerEvents: 'none',
    },
    // Checked
    '&.checked': {
      boxShadow: `0px ${getResponsiveSize(5)} ${getResponsiveSize(
        20,
      )} rgba(0, 0, 0, 0.05)`,
      '& .MuiCardActionArea-root': {
        border: `${getResponsiveSize(2)} solid ${
          theme.palette.myPrimary.lightBlue100
        }`,
        ...theme.palette.propVars.easeInOutTransition,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .MuiCardActionArea-root ': {
        border: `${getResponsiveSize(2, laptopRatio)} solid transparent`,
        '& .MuiCardContent-root': {
          '& .card-title': {
            lineHeight: 'unset',
          },
          '& .desc': {
            lineHeight: '1.2',
          },
        },
      },
      '&.checked': {
        '& .MuiCardActionArea-root': {
          border: `${getResponsiveSize(2, laptopRatio)} solid ${
            theme.palette.myPrimary.lightBlue100
          }`,
        },
      },
    },
    // Tablet screen
    [theme.breakpoints.media.tablet]: {
      width: getResponsiveSize(375, laptopRatio), // 375px
      '& .MuiCardActionArea-root': {
        py: getResponsiveSize(7, laptopRatio), // 7px
        px: getResponsiveSize(19, laptopRatio), // 19px
        '& .icon svg': {
          mr: getResponsiveSize(20, laptopRatio), // 20px
          width: getResponsiveSize(42, laptopRatio), // 42px
          height: getResponsiveSize(42, laptopRatio), // 42px
        },
      },
    },
  },
};
