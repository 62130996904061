export const deviceConstants = {
  GETALL_REQUEST: 'DEVICES_GETALL_REQUEST',
  GETALL_SUCCESS: 'DEVICES_GETALL_SUCCESS',
  GETALL_FAILURE: 'DEVICES_GETALL_FAILURE',

  GET_REQUEST: 'DEVICE_GET_REQUEST',
  GET_SUCCESS: 'DEVICE_GET_SUCCESS',
  GET_FAILURE: 'DEVICE_GET_FAILURE',

  GETALL_NAMES_REQUEST: 'DEVICE_GET_NAMES_REQUEST',
  GETALL_NAMES_SUCCESS: 'DEVICE_GET_NAMES_SUCCESS',
  GETALL_NAMES_FAILURE: 'DEVICE_GET_NAMES_FAILURE',

  GETALL_SERIALS_REQUEST: 'DEVICE_GET_SERIALS_REQUEST',
  GETALL_SERIALS_SUCCESS: 'DEVICE_GET_SERIALS_SUCCESS',
  GETALL_SERIALS_FAILURE: 'DEVICE_GET_SERIALS_FAILURE',

  DELETE_REQUEST: 'DEVICES_DELETE_REQUEST',
  DELETE_SUCCESS: 'DEVICES_DELETE_SUCCESS',
  DELETE_FAILURE: 'DEVICES_DELETE_FAILURE',

  GETALL_PROFILE_REQUEST: 'DEVICES_PROFILE_GETALL_REQUEST',
  GETALL_PROFILE_SUCCESS: 'DEVICES_PROFILE_GETALL_SUCCESS',
  GETALL_PROFILE_FAILURE: 'DEVICES_PROFILE_GETALL_FAILURE',

  CREATE_REQUEST: 'CREATE_DEVICE_REQUEST',
  CREATE_SUCCESS: 'CREATE_DEVICE_SUCCESS',
  CREATE_FEW_SUCCESS: 'CREATE_FEW_DEVICES_SUCCESS',
  CREATE_FAILURE: 'CREATE_DEVICE_FAILURE',

  UPDATE_REQUEST: 'UPDATE_DEVICE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_DEVICE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_DEVICE_FAILURE',

  UPDATE_FEW_REQUEST: 'UPDATE_FEW_DEVICES_REQUEST',
  UPDATE_FEW_SUCCESS: 'UPDATE_FEW_DEVICES_SUCCESS',
  UPDATE_FEW_FAILURE: 'UPDATE_FEW_DEVICES_FAILURE',

  COUNT_REQUEST: 'DEVICES_COUNT_REQUEST',
  COUNT_SUCCESS: 'DEVICES_COUNT_SUCCESS',
  COUNT_FAILURE: 'DEVICES_COUNT_FAILURE',

  CLEAR_DEVICES: 'CLEAR_DEVICES',
};
