export const clientConstants = {
  GETALL_REQUEST: 'CLIENTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CLIENTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'CLIENTS_GETALL_FAILURE',

  COUNT_REQUEST: 'CLIENTS_COUNT_REQUEST',
  COUNT_SUCCESS: 'CLIENTS_COUNT_SUCCESS',
  COUNT_FAILURE: 'CLIENTS_COUNT_FAILURE',

  GET_REQUEST: 'CLIENT_GET_REQUEST',
  GET_SUCCESS: 'CLIENT_GET_SUCCESS',
  GET_FAILURE: 'CLIENT_GET_FAILURE',

  DELETE_REQUEST: 'CLIENTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CLIENTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CLIENTS_DELETE_FAILURE',

  CREATE_REQUEST: 'CREATE_CLIENT_REQUEST',
  CREATE_SUCCESS: 'CREATE_CLIENT_SUCCESS',
  CREATE_FAILURE: 'CREATE_CLIENT_FAILURE',

  UPDATE_REQUEST: 'UPDATE_CLIENT_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_CLIENT_FAILURE',

  CLEAR_CLIENTS: 'CLEAR_CLIENTS',
};
