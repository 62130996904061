import { laptopRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const sessionsModalStyle = {
  sessionsModal: {
    '&.sessions-modal': {
      '& .modal-wrapper': {
        '& .modal-header': {
          backgroundColor: '#2392BA0D',
        },
        '& .actions': {
          display: 'flex',
          justifyContent: 'center',
          py: getResponsiveSize(31.5), // 31.5px
          px: getResponsiveSize(36), // 36px
          gap: getResponsiveSize(24), // 24px
          '& .resume-session svg': {
            width: getResponsiveSize(28), // 28px,
            height: getResponsiveSize(28), // 28px,
            stroke: theme.palette.myPrimary.white,
            transform: 'scale(1.3)',
            path: {
              fill: 'none',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: getResponsiveSize(24), // 24px,
            },
          },
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '&.sessions-modal': {
        '& .modal-wrapper ': {
          width: getResponsiveSize(460, laptopRatio), // 460px
        },
        '& .actions': {
          gap: getResponsiveSize(20, laptopRatio), // 20px
        },
      },
    },
  },
};
