import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../hooks/useActions';

import AutocompleteUi from '../UI/Autocomplete/Autocomplete';

export const DeviceProfilesSelect = memo((props) => {
  const { handleChange, selectedId } = props;
  const { t } = useTranslation();

  const { getAllDeviceProfiles } = useActions();
  const { deviceProfiles, isLoading } = useSelector((state) => state.devices);

  const [selectedDeviceProfile, setSelectedDeviceProfile] = useState(null);

  const options = useMemo(
    () =>
      deviceProfiles.map((profile) => ({
        text: `${profile.type} - ${profile.version}`,
        value: profile.id,
      })),
    [deviceProfiles],
  );

  /**
   * Handle Autocomplete`s options render
   * @param optionProps option props
   * @param obj option text and value
   * @returns ReactNode for each Autocomplete option
   */
  const handleAutocompleteRenderOptions = useCallback(
    (optionProps, { text, value }) => (
      <li {...optionProps} key={value}>
        {text}
      </li>
    ),
    [],
  );

  /**
   * Handle Autocomplete change event
   * (option selection, clear value, close...)
   * @param event
   * @param value selected option value
   */
  const handleAutocompleteChange = useCallback(
    (event, value) => {
      setSelectedDeviceProfile(value);
      handleChange(value);
    },
    [handleChange],
  );

  /**
   * Sets autocomplete value to selectedId
   */
  useEffect(() => {
    if (selectedId)
      setSelectedDeviceProfile(
        options?.find((item) => item.value === selectedId) || null,
      );
  }, [options]);

  /**
   * Fetches all device profiles if they are not defined
   */
  useEffect(() => {
    if (deviceProfiles.length <= 0) getAllDeviceProfiles();
  }, []);

  return (
    <AutocompleteUi
      className='device-profile-select'
      label={t('devices.profile.title')}
      placeholder={t('devices.profile.select')}
      autocompleteValue={selectedDeviceProfile}
      onAutocompleteChangeHandler={handleAutocompleteChange}
      onAutocompleteRenderOptionsHandler={handleAutocompleteRenderOptions}
      options={options}
      loading={isLoading}
      withTransition
    />
  );
});
