import { keyframes } from '@emotion/react';

// Animations
export const linearAnimation = keyframes`
  0% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(80%);
  }
`;

export const ellipseAnimation = keyframes`
  to {
    offset-distance: 100%;
	}
`;

export const infiniteAnimation = keyframes`
  0% {
    transform: translatex(0);
  }
  2.08% {
    transform: translate(2.5%, 15%)
  }
  4.16% {
    transform: translate(5%, 30%)
  }
  6.24% {
    transform: translate(7.5%, 40%)
  }
  8.32% {
    transform: translate(10%, 47%)
  }
  10.4% {
    transform: translate(12.5%, 50%)
  }
  12.48% {
    transform: translate(15%, 45%)
  }
  14.56% {
    transform: translate(17.5%, 37.5%)
  }
  16.64% {
    transform: translate(20%, 30%)
  }
  18.72% {
    transform: translate(25%, 22.5%)
  }
  20.8% {
    transform: translate(30%, 15%)
  }
  22.88% {
    transform: translate(35%, 7.5%)
  }
  25% {
    transform: translate(40%, 0%)
  }

   27.08% {
    transform: translate(45%, -7.5%)
  }
  29.16% {
    transform: translate(50%, -15%);
  }
  31.24% {
    transform: translate(55%, -22.5%);
  }
  33.32% {
    transform: translate(60%, -30%)
  }
  35.4% {
    transform: translate(62.5%, -37.5%)
  }
  37.48% {
    transform: translate(65%, -45%)
  }
  39.56% {
    transform: translate(67.5%, -50%)
  }
  41.64% {
    transform: translate(70%, -47%)
  }
  43.72% {
    transform: translate(72.5%, -40%)
  }
  45.8% {
    transform: translate(75%, -30%)
  }
  47.88% {
    transform: translate(77.5%, -15%)
  }
  50% {
    transform: translate(80%, 0%)
  }

  52.08% {
    transform: translate(77.5%, 15%)
  }
  54.16% {
    transform: translate(75%, 30%)
  }
  56.24% {
    transform: translate(72.5%, 40%)
  }
  58.32% {
    transform: translate(70%, 47%)
  }
  60.4% {
    transform: translate(67.5%, 50%)
  }
  62.48% {
    transform: translate(65%, 45%)
  }
  64.56% {
    transform: translate(62.5%, 37.5%)
  }
  66.64% {
    transform: translate(60%, 30%)
  }
  68.72% {
    transform: translate(55%, 22.5%)
  }
  70.8% {
    transform: translate(50%, 15%);
  }
  72.88% {
    transform: translate(45%, 7.5%)
  }
  75% {
    transform: translate(40%, 0%)
  }

  77.08% {
    transform: translate(35%, -7.5%)
  }
  79.16% {
    transform: translate(30%, -15%);
  }
  81.24% {
    transform: translate(25%, -22.5%);
  }
  83.32% {
    transform: translate(20%, -30%)
  }
  85.4% {
    transform: translate(17.5%, -37.5%)
  }
  87.48% {
    transform: translate(15%, -45%)
  }
  89.56% {
    transform: translate(12.5%, -50%)
  }
  91.64% {
    transform: translate(10%, -47%)
  }
  93.72% {
    transform: translate(7.5%, -40%)
  }
  95.8% {
    transform: translate(5%, -30%)
  }
  97.88% {
    transform: translate(2.5%, -15%)
  }
  100% {
    transform: translate(0)
  }
`;

export const bouncingAnimation = keyframes`
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(28%, 75%);
  }

  25% {
    transform: translate(40%, 62.5%);
  }
  50% {
    transform: translate(80%, -2%);
  }

  55% {
    transform: translate(68.25%, -2%);
  }
  70% {
    transform: translate(33%, -2%);
  }

  75% {
    transform: translate(40.83%, 10%);
  }
  100% {
    transform: translate(80%, 60%)
  }
`;
