import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}/sessions`;

const getAllStatistics = (filter = '') => {
  let filterString = '';
  if (filter) {
    filterString = filter;
  }
  return axios.get(`${baseURL}/stats${authUrl()}${filterString}`);
};

export const statisticsService = {
  getAllStatistics,
};
