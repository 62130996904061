import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CreateTreatmentForm from '../components/Treatments/CreateTreatmentForm';
import FallbackCreateTreatmentForm from '../components/Treatments/FallbackCreateTreatmentPage';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';

import DeviceConnection from '../components/Treatments/DeviceConnection';
import { treatmentActions } from '../store/actions/treatment.actions';
import { treatmentRemoteActions } from '../store/actions/treatment-remote.actions';
import HoldingPage from '../components/Treatments/HoldingPage';
import NoProductsAvailable from '../components/Billing/NoProductsAvailable/NoProductsAvailable';
import useAdminConfig from '../hooks/useAdminConfig';
import { adminService } from '../services/admin';
import { ConnectionError } from '../components/ConnectionError/ConnectionError';

const MAX_TRIES = 120000;
const INTERVAL_TIME_IN_MS = 3000;

class CreateTreatmentPage extends React.Component {
  constructor(props) {
    super(props);
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const treatmentUUID = params.get('treatmentUUID');
    const sessionUUID = params.get('sessionUUID');
    let clientId = params.get('clientId');
    let productId = params.get('productId');
    const quickStart = params.get('quickStart');
    if (clientId) {
      clientId = parseInt(clientId);
    }
    if (productId) {
      productId = parseInt(productId);
    }

    this.state = {
      isVerifying: false,
      isConnected: false,
      shouldStartPolling: true,
      shouldStartPollingRemote: true,
      isTimeout: false,
      isBillingEnabled: false,
      sessionUUID,
      treatmentUUID,
      clientId,
      productId,
      quickStart,
    };

    this.remoteInterval = null;
    this.setStartPolling = this.setStartPolling.bind(this);
    this.setStartPollingRemote = this.setStartPollingRemote.bind(this);
    this.useFallBack = this.useFallBack.bind(this);
  }

  componentDidMount() {
    const { treatmentUUID, sessionUUID } = this.state;
    if (treatmentUUID && sessionUUID) {
      this.props.resumePreviousSession(treatmentUUID, sessionUUID);
    }
    this.getBillingStatus();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.remoteInterval);
    this.props.resetDeviceConnectionState(); // TODO: clear state for the current session
    this.props.clearTreatmentRemoteState(); // TODO: clear state for the current session
  }

  async getBillingStatus() {
    try {
      const result = await adminService.getFirstAdminConfig();
      this.setState({ isBillingEnabled: result.takeBillingIntoAccount });
    } catch (e) {
      console.error(e);
    }
  }

  setStartPolling(value) {
    this.setState({ shouldStartPolling: value });
  }

  setStartPollingRemote(value) {
    this.setState({ shouldStartPollingRemote: value });
  }

  useFallBack(id) {
    this.props.useFallBackAction(id);
  }

  startPollingForData() {
    const { shouldStartPolling } = this.state;
    const { deviceTreatmentConnectionId } = this.props.treatments;
    let count = 0;
    if (shouldStartPolling) {
      this.props.fetchConfig(deviceTreatmentConnectionId);
      this.setState({ shouldStartPolling: false });
      this.interval = setInterval(() => {
        if (deviceTreatmentConnectionId) {
          if (count > MAX_TRIES) {
            // Timeout after X tries
            this.setState({ isTimeout: true });
          }
          this.props.fetchConfig(deviceTreatmentConnectionId);
          count++;
        }
      }, INTERVAL_TIME_IN_MS);
    }
  }

  startPollingForRemoteData() {
    const { shouldStartPollingRemote } = this.state;
    const { remoteTreatmentUUID } = this.props.treatmentsRemote;
    let count = 0;
    if (shouldStartPollingRemote) {
      this.props.fetchRemoteConfig(remoteTreatmentUUID);
      this.setState({ shouldStartPollingRemote: false });
      this.remoteInterval = setInterval(() => {
        if (remoteTreatmentUUID) {
          if (count > MAX_TRIES) {
            // Timeout after X tries
            this.setState({ isTimeout: true });
          }
          this.props.fetchRemoteConfig(remoteTreatmentUUID);
          count++;
        }
      }, INTERVAL_TIME_IN_MS);
    }
  }

  createTreatmentPage = () => {
    const { i18n } = this.props;
    const { clientId, productId, quickStart } = this.state;
    const backNav = {
      title: i18n.t('treatments.title'),
      route: '/admin/treatments',
    };

    return (
      <>
        <div className='title'>
          <CustomBreadcrumbs
            backNav={backNav}
            currentTitle={i18n.t('treatments.create.title')}
          />
          <p className='title-description' />
        </div>
        <CreateTreatmentForm
          clientId={clientId}
          productId={productId}
          quickStart={JSON.parse(quickStart)}
          startPolling={this.setStartPolling}
        />
      </>
    );
  };

  createBasicTreatmentPage = () => {
    const { i18n } = this.props;
    const backNav = {
      title: i18n.t('treatments.title'),
      route: '/admin/treatments',
    };

    return (
      <React.Fragment key={'basic-treatment-page-'}>
        <div className='title'>
          <CustomBreadcrumbs
            backNav={backNav}
            currentTitle={i18n.t('treatments.create.title')}
          />
          <p className='title-description' />
        </div>
        <FallbackCreateTreatmentForm startPolling={this.setStartPolling} />
      </React.Fragment>
    );
  };

  createHoldingPage = () => {
    setTimeout(() => this.startPollingForRemoteData(), 0);
    return <HoldingPage setStartPollingRemote={this.setStartPollingRemote} />;
  };

  createDeviceConnectionComponent = () => {
    const { treatments, i18n, history } = this.props;
    const { deviceTreatmentConnectionId } = treatments;
    const { isTimeout } = this.state;
    this.startPollingForData();

    return (
      <DeviceConnection
        idToCheck={deviceTreatmentConnectionId}
        isError={isTimeout}
        i18n={i18n}
        history={history}
        useFallBack={this.useFallBack}
      />
    );
  };

  render() {
    const { deviceTreatmentConnectionId, isReceived, isNotConnected } =
      this.props.treatments;
    const {
      hasFetched,
      products,
      error: getProductsError,
    } = this.props.products;
    const { remoteTreatmentUUID, remoteSessionUUID } =
      this.props.treatmentsRemote;
    const { isTimeout, isBillingEnabled } = this.state;
    const showNoProductsAvailableInstructions =
      hasFetched && products.length === 0 && isBillingEnabled;
    const showFetchingProductsErrorScreen = getProductsError && hasFetched;

    if (isReceived || isTimeout) {
      clearInterval(this.interval);
    }
    if (!remoteTreatmentUUID || !remoteSessionUUID) {
      clearInterval(this.remoteInterval);
    }
    if (showNoProductsAvailableInstructions) {
      return <NoProductsAvailable text={'No products available.'} />;
    }
    if (showFetchingProductsErrorScreen) {
      return <ConnectionError />;
    }
    if (!deviceTreatmentConnectionId && !remoteTreatmentUUID) {
      return this.createTreatmentPage();
    }
    if (deviceTreatmentConnectionId && isNotConnected) {
      return this.createBasicTreatmentPage();
    }
    if (remoteTreatmentUUID) {
      return this.createHoldingPage();
    }
    return this.createDeviceConnectionComponent();
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  fetchConfig: treatmentActions.checkIfDeviceConfigurationIsReceived,
  fetchRemoteConfig: treatmentRemoteActions.checkIfConfigurationIsReceived,
  resetDeviceConnectionState: treatmentActions.resetDeviceConnectionState,
  clearTreatmentRemoteState: treatmentRemoteActions.clearTreatmentRemoteState,
  useFallBackAction: treatmentActions.useDefaultDeviceConfiguration,
  resumePreviousSession: treatmentRemoteActions.resumePreviousSession,
};

export default CreateTreatmentPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateTreatmentPage),
);
