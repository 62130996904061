import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import { Box } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';

import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { deviceActions } from '../../store/actions/device.actions';
import { organizationActions } from '../../store/actions/organization.actions';

import { ReactComponent as DeleteIcon } from '../../shared/assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../shared/assets/icons/edit.svg';
import { ReactComponent as RestoreIcon } from '../../shared/assets/icons/restore.svg';
import { ReactComponent as AddDeviceIcon } from '../../shared/assets/icons/sidenav/devices.svg';

import useDebounce from '../../hooks/useDebounce';
import { useTableFilterConfig } from '../../hooks/useTableFilterConfig';

import { classNames, getTransformedLogicTableFilterValue } from '../../utils';
import getMuiTheme from '../../utils/MUI-Style';
import tableConfig from '../../utils/MUI-table-config';
import muiComponents from '../../utils/MUI-table-components';
import { muiTableLoadingSkeleton } from '../../utils/MUI-table-loading-skaleton';

import { CreateEditDeviceForm } from './CreateEditDeviceForm';
import CustomDateSortHeader from '../Sessions/CustomDateSortHeader';
import { ModalUI } from '../UI/Modal/Modal';
import TableHeader from '../UI/TableHeader/TableHeader';
import { FormModal } from '../UI/FormModal/FormModal';
import { deleteConfirmationStyle } from '../UI/SimpleModalContent/style';
import { DataCell } from '../UI/MuiDatatable/DataCell/DataCell';
import SimpleModalContent from '../UI/SimpleModalContent/SimpleModalContent';
import { CustomMuiDataTableFilter } from '../UI/MuiDatatable/CustomMuiDataTableFilter/CustomMuiDataTableFilter';
import { CustomMuiSessionsPerMonth } from '../UI/MuiDatatable/CustomMuiSessionsPerMonth/CustomMuiSessionsPerMonth';
import { CustomMuiDataTableBodyButton } from '../UI/MuiDatatable/CustomMuiDataTableBodyButton/CustomMuiDataTableBodyButton';
import { getDevicesByUUID, getDevicesByUUIDs } from '../../services/devices';
import { ConfirmationModal } from './ConfirmationModal';

const organizationFields = { fields: ['id', 'name'] };

const DevicesTable = ({
  history,
  location,
  getAllDevices,
  deleteDevice,
  clearDevices,
  getShortOrganizations,
  getAllDeviceProfiles,
  getAllDevicesGeneratedUUID,
  updateFewDevices,
  updateDevice,
  // reducers
  authentication,
  devices: devicesReducer,
  organizations,
}) => {
  const { t } = useTranslation();
  const { deleteConfirmation } = deleteConfirmationStyle;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentSort, setCurrentSort] = useState('default');
  const [currentSortType, setCurrentSortType] = useState('');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [deviceToEdit, setDeviceToEdit] = useState(null);
  const [deviceToDelete, setDeviceToDelete] = useState(null);
  const {
    devices,
    isLoading: isDeviceLoading,
    totalDevices,
    deviceProfiles: deviceTypes,
    devicesNames,
    devicesSerialNumbers,
  } = devicesReducer;
  const {
    shortOrganizations: organizationOptions,
    isLoading: isOrganizationsLoading,
  } = organizations;
  const { userIsAdmin, organizationId, isDeviceAdmin } = authentication;
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);
  const [searchOpen, setSearchOpen] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [deviceFilters, setDeviceFilters] = useState();

  const [restorationDevice, setRestorationDevice] = useState();
  const [existsDevices, setExistsDevices] = useState([]);
  const [isRestorationConfirmation, setIsRestorationConfirmation] =
    useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const tableRef = useRef();

  const hasOrganizationAccess = isDeviceAdmin || userIsAdmin;

  const isLoading = isDeviceLoading || isOrganizationsLoading;

  /**
   * Opens the filter's modal and grabs data for the filters
   */
  const onOpenFilterModal = useCallback(() => {
    if (organizationOptions.length <= 0 && hasOrganizationAccess)
      getShortOrganizations();
    if (devicesSerialNumbers.length <= 0) getAllDevicesGeneratedUUID();
    if (deviceTypes.length <= 0) getAllDeviceProfiles();
    setFilterOpen(true);
  }, [
    devicesSerialNumbers,
    getAllDevicesGeneratedUUID,
    deviceTypes,
    getAllDeviceProfiles,
    hasOrganizationAccess,
    organizationOptions,
    getShortOrganizations,
  ]);

  /**
   * Generates filters
   */
  const deviceFilter = useMemo(() => {
    let order = '';
    let direction = '';
    let searchQuery = '';
    const serialNumber = deviceFilters?.generated_uuid?.value || '';
    const type = deviceFilters?.type?.value || '';
    const showDeleted = deviceFilters?.show_deleted?.value || '';
    const orgId = hasOrganizationAccess
      ? deviceFilters?.organizationId?.value || ''
      : organizationId;

    const monthlySession = getTransformedLogicTableFilterValue(
      deviceFilters,
      'monthly_sessions',
    );

    switch (currentSort) {
      case 'up':
        direction = 'DESC';
        break;
      case 'down':
        direction = 'ASC';
        break;
      default:
        break;
    }

    if (currentSort && currentSortType && direction !== 'default') {
      order = `${currentSortType} ${direction}`;
    }

    if (debouncedSearchTerm)
      searchQuery = `${debouncedSearchTerm.toLowerCase()}`;

    if (debouncedSearchTerm || serialNumber) setPage(0);

    return {
      page: page * limit || 0,
      limit,
      order,
      organizationToFilter: orgId,
      searchQuery,
      serialNumber,
      type,
      monthlySession,
      showDeleted,
    };
  }, [
    page,
    limit,
    currentSort,
    currentSortType,
    hasOrganizationAccess,
    organizationId,
    debouncedSearchTerm,
    deviceFilters,
  ]);

  /**
   * Refresh devices data
   */
  const refreshCurrentData = useCallback(() => {
    const {
      page,
      limit,
      order,
      organizationToFilter,
      searchQuery,
      serialNumber,
      type,
      monthlySession,
      showDeleted,
    } = deviceFilter;
    getAllDevices(
      page,
      limit,
      order,
      organizationToFilter,
      searchQuery,
      serialNumber,
      type,
      monthlySession,
      showDeleted,
    );
  }, [deviceFilter, getAllDevices]);

  /**
   * Gets all devices on filters changes
   */
  useEffect(() => {
    const {
      page,
      limit,
      order,
      organizationToFilter,
      searchQuery,
      serialNumber,
      type,
      monthlySession,
      showDeleted,
    } = deviceFilter;
    getAllDevices(
      page,
      limit,
      order,
      organizationToFilter,
      searchQuery,
      serialNumber,
      type,
      monthlySession,
      showDeleted,
    );
  }, [deviceFilter, getAllDevices]);

  /**
   * Opens edit / create modal
   */
  const openModal = () => {
    setModalIsOpen(true);
  };

  /**
   * CLoses edit | create modal
   */
  const closeModal = () => {
    setModalIsOpen(false);
    setDeviceToEdit(null);
  };

  /**
   * CLoses restore confirmation modal
   */
  const closeConfirmModal = () => {
    setConfirmationModalIsOpen(false);
    setRestorationDevice(null);
  };

  /**
   * Open delete modal
   * @param event native event
   * @param id device id
   */
  const onOpenDeleteModal = useCallback((event, id) => {
    event.stopPropagation();
    setDeviceToDelete(id);
    setIsRestorationConfirmation(false);
    setDeleteModalIsOpen(true);
  }, []);

  /**
   * Close delete / restore modal
   */
  const onCloseDeleteRestoreModal = useCallback(
    () => setDeleteModalIsOpen(false),
    [],
  );

  /**
   * Edit the device by id
   * @param event native event
   * @param id device id
   */
  const editDevice = useCallback(
    (event, id) => {
      event.stopPropagation();
      setDeviceToEdit(devices.find((item) => item.id === id));
      setModalIsOpen(true);
    },
    [devices],
  );

  /**
   * Restore device
   */
  const restoreDevice = useCallback(async () => {
    // If device(s) already exists mark them as deleted
    if (existsDevices)
      updateFewDevices(
        existsDevices.map(({ newName, ...device }) => ({
          ...device,
          is_deleted: true,
        })),
      );

    await updateDevice({ ...restorationDevice, is_deleted: false });

    closeConfirmModal();
    onCloseDeleteRestoreModal();
    setIsRestorationConfirmation(false);
    refreshCurrentData();
  }, [
    existsDevices,
    onCloseDeleteRestoreModal,
    refreshCurrentData,
    restorationDevice,
    updateDevice,
    updateFewDevices,
  ]);

  /**
   * Open confirmation modal
   */
  const openConfirmationModal = useCallback(() => {
    setConfirmationModalIsOpen(true);
  }, [setConfirmationModalIsOpen]);

  /**
   * Call device restore after modal confirmation
   */
  const onConfirmDeviceRestore = useCallback(() => {
    restoreDevice();
  }, [restoreDevice]);

  /**
   * Restore the device
   * @param event native event
   * @param device device data
   */
  const restoreDeviceRequest = useCallback(async (event, device) => {
    event.stopPropagation();
    const deviceId = device[0]?.props?.children;
    const deviceGenerated_UUID = device[1]?.props?.children;
    const deviceName = device[2]?.props?.children;
    const deviceProfileFk = device[6];

    setRestorationDevice({
      id: deviceId,
      device_profile_fk: deviceProfileFk,
      generated_UUID: deviceGenerated_UUID,
      name: deviceName,
    });

    try {
      const response = await getDevicesByUUID(deviceGenerated_UUID);
      const existsDevices = response?.data
        .filter((device) => device.id !== deviceId)
        .map((device) => ({
          ...device,
          newName: deviceName,
        }));
      setDeleteModalIsOpen(true);
      setIsRestorationConfirmation(true);
      if (existsDevices?.length > 0) setExistsDevices(() => existsDevices);
    } catch (error) {
      console.log('error: ', error);
    }
  }, []);

  const confirmDeviceRestoration = useCallback(() => {
    if (existsDevices?.length > 0) openConfirmationModal();
    else restoreDevice();
  }, [existsDevices?.length, openConfirmationModal, restoreDevice]);

  /**
   * Delete device handler
   */
  const onDeleteDevice = useCallback(async () => {
    await deleteDevice(deviceToDelete);
    refreshCurrentData();
    setDeleteModalIsOpen(false);
  }, [deleteDevice, deviceToDelete, refreshCurrentData]);

  /**
   * Sort handler
   * @param type sort property
   */
  const handleSort = useCallback(
    (type) => {
      let nextSort;
      if (currentSort === 'down') nextSort = 'up';
      else if (currentSort === 'up') nextSort = 'default';
      else if (currentSort === 'default') nextSort = 'down';

      setCurrentSortType(type);
      setCurrentSort(nextSort);
    },
    [currentSort],
  );

  /**
   * Init table's options
   */
  const options = useMemo(
    () => ({
      onRowClick: (rowData) => {
        const id = rowData[0]?.props?.children;
        history.push({
          pathname: `/admin/devices/${id}`,
          state: {},
        });
      },
      onChangePage: (currentPage) => {
        setPage(currentPage);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        const { page: offset, limit } = deviceFilter;
        const showedRows = offset + limit;
        const pagesLimit = Math.ceil(totalDevices / numberOfRows);
        if (showedRows <= numberOfRows && page !== 0) {
          const newPage = Math.floor(numberOfRows / showedRows) - 1;
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        } else if (showedRows > numberOfRows) {
          const newPage = Math.floor(showedRows / numberOfRows);
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        }
        setLimit(numberOfRows);
      },
      onSearchChange: (text) => {
        setSearchText(text);
      },
      serverSide: true,
      page,
      rowsPerPage: limit,
      count: totalDevices,
      searchText,
      searchOpen,
      searchAlwaysOpen: true,
    }),
    [page, limit, totalDevices, searchText, searchOpen, history, deviceFilter],
  );

  /**
   * Gets organization name by id
   * @param organizationId organization id
   */
  const getOrganizationNameById = useCallback(
    (organizationId) =>
      organizationOptions.find(
        (organization) => organization.id === organizationId,
      )?.name,
    [organizationOptions],
  );

  /**
   * Render talbe haed
   */
  const renderHead = useCallback(
    (mappedSortTypes, key) => {
      const sortType = mappedSortTypes[key];
      return (
        <CustomDateSortHeader
          key={`sort-header-${key}`}
          currentSort={currentSortType === sortType ? currentSort : 'default'}
          label={key}
          type={sortType}
          handleSort={handleSort}
        />
      );
    },
    [currentSort, currentSortType, handleSort],
  );

  /**
   * Remder talbe actions
   */
  const renderActions = useCallback(
    (value, tableMeta) => {
      const isDeviceDeleted = tableMeta.rowData[10];
      return (
        <CustomMuiDataTableBodyButton
          firstButtonIcon={
            isDeviceDeleted ? <RestoreIcon className='restore' /> : <EditIcon />
          }
          isSecondary={!isDeviceDeleted}
          secondButtonIcon={isDeviceDeleted ? null : <DeleteIcon />}
          onPrimaryActionButton={(e) =>
            isDeviceDeleted
              ? restoreDeviceRequest(e, tableMeta.rowData)
              : editDevice(e, tableMeta.rowData[0]?.props?.children)
          }
          onSecondaryActionButton={(e) =>
            onOpenDeleteModal(e, tableMeta.rowData[0]?.props?.children)
          }
        />
      );
    },
    [editDevice, onOpenDeleteModal, restoreDeviceRequest],
  );

  let attributes = [];
  let devicesToShow = devices || [];
  let exportData = [];

  /**
   * Generates table rows and attributes
   */
  if (devicesToShow.length) {
    exportData = devicesToShow.map((device) => ({
      id: device.id,
      [t('forms.uuid')]: device.generated_uuid,
      [t('forms.name')]: device.name,
      ...(hasOrganizationAccess && {
        [t('forms.organization_name')]: getOrganizationNameById(
          device?.organizationid,
        ),
      }),
      [t('forms.type')]: device.type,
      [t('forms.version')]: device.version,
      device_profile_fk: device.device_profile_fk,
      [t('forms.total.monthly_sessions')]: device.monthly_sessions || 0,
      [t('forms.total.sessions')]: device.sessions || 0,
      organizationId: device.organizationid,
    }));

    devicesToShow = devicesToShow.map((device) => ({
      id: <DataCell>{device.id}</DataCell>,
      [t('forms.uuid')]: <DataCell>{device.generated_uuid}</DataCell>,
      [t('forms.name')]: <DataCell type='secondary'>{device.name}</DataCell>,
      ...(hasOrganizationAccess && {
        [t('forms.organization_name')]: (
          <DataCell type='secondary'>
            {getOrganizationNameById(device?.organizationid)}
          </DataCell>
        ),
      }),
      [t('forms.type')]: <DataCell type='secondary'>{device.type}</DataCell>,
      [t('forms.version')]: (
        <DataCell type='secondary'>{device.version}</DataCell>
      ),
      device_profile_fk: device.device_profile_fk,
      [t('forms.total.monthly_sessions')]: (
        <CustomMuiSessionsPerMonth
          isSum={false}
          monthSum={device.monthly_sessions || 0}
        />
      ),
      [t('forms.total.sessions')]: (
        <CustomMuiSessionsPerMonth
          isMonthSum={false}
          sum={device.sessions || 0}
        />
      ),
      organizationId: <DataCell>{device.organizationid}</DataCell>,
      isDeleted: device.is_deleted,
    }));

    const mappedSortTypes = {
      [t('forms.uuid')]: 'generated_uuid',
      [t('forms.name')]: 'name',
      ...(hasOrganizationAccess && {
        [t('forms.organization_name')]: 'organizationId',
      }),
      [t('forms.type')]: 'type',
      [t('forms.version')]: 'version',
      [t('forms.total.monthly_sessions')]: 'monthly_sessions',
      [t('forms.total.sessions')]: 'sessions',
    };
    const keysToHide = ['id', 'device_profile_fk', 'organizationId'];
    const excludedKeys = ['isDeleted'];
    attributes = Object.keys(devicesToShow[0]).map((key) => {
      if (keysToHide.includes(key)) {
        return {
          name: key,
          options: { display: false, sort: false },
        };
      }
      if (excludedKeys.includes(key))
        return {
          name: key,
          options: {
            display: 'excluded',
            sort: false,
          },
        };
      return {
        name: key,
        options: {
          customHeadRender: () => renderHead(mappedSortTypes, key),
        },
      };
    });
    if (hasOrganizationAccess) {
      attributes.push({
        name: '',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) =>
            renderActions(value, tableMeta, updateValue),
        },
      });
    }
  }

  /**
   * Generates the table filter's config
   */
  const filtersConfig = useTableFilterConfig('device');

  /**
   * Closes the filter's modal and save data for the filters
   */
  const onSaveFilters = useCallback((filters) => {
    setDeviceFilters(filters);
    setSearchText(filters?.name?.value);
  }, []);

  /**
   * Checks does table have data
   */
  const isDataTableEmpty = devicesToShow.length <= 0;

  /**
   * Flag to checks does filters active
   */
  const isFilters = Object.keys(deviceFilters || {})?.length > 0;

  /**
   * mui datatable optional class names
   */
  const muiTableMods = {
    'search-open': searchOpen,
    loading: isLoading,
    'no-data': isDataTableEmpty,
    filters: isFilters,
  };

  /**
   * Table's query handler
   */
  useEffect(() => {
    if (location.search) {
      setModalIsOpen(true);
    }
  }, [location]);

  /**
   * Reset page for existings filters if it's not the first page and there is no results
   */
  useEffect(() => {
    if (isFilters && devicesToShow.length <= 0 && page > 0) setPage(0);
  }, [devicesToShow, isFilters, page]);

  /**
   * Gets organizations
   */
  useEffect(() => {
    if (organizationOptions.length <= 0 && hasOrganizationAccess)
      getShortOrganizations(organizationFields);
  }, [
    getShortOrganizations,
    hasOrganizationAccess,
    organizationOptions.length,
  ]);

  /**
   * Clears devices on leaving the page
   */
  useEffect(
    () => () => {
      clearDevices();
    },
    [],
  );

  return (
    <>
      <TableHeader
        buttonTitle='buttons.create.device'
        isButton={hasOrganizationAccess}
        onClickCallback={openModal}
        title='devices.title'
        subTitle='devices.subtitle'
        btnIcon={<AddDeviceIcon className='device-icon' />}
      />
      <Box className='table-container'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={getMuiTheme(
              { cursor: 'pointer' },
              {
                '&:nth-of-type(7)': {
                  width: 100,
                },
              },
            )}
          >
            <MUIDataTable
              ref={tableRef}
              data={isLoading ? muiTableLoadingSkeleton('body') : devicesToShow}
              columns={
                isLoading ? muiTableLoadingSkeleton('header') : attributes
              }
              options={tableConfig(
                options,
                isLoading,
                isDataTableEmpty,
                tableRef,
                isFilters,
                onOpenFilterModal,
                exportData,
              )}
              components={muiComponents(deviceFilters, setDeviceFilters)}
              className={classNames('mui-data-table', muiTableMods)}
              title=''
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
      {/* Edit / create organization modal */}
      {modalIsOpen && (
        <FormModal
          isOpen={modalIsOpen}
          title={
            deviceToEdit ? t('buttons.edit.device') : t('buttons.create.device')
          }
          setOpen={setModalIsOpen}
          onClose={closeModal}
        >
          <CreateEditDeviceForm
            closeModal={closeModal}
            deviceToEdit={deviceToEdit}
            reloadData={refreshCurrentData}
          />
        </FormModal>
      )}
      {/* Delete / restrore confirmation modal */}
      <ModalUI
        modalSx={deleteConfirmation}
        open={deleteModalIsOpen}
        isCloseBtn={false}
        onCloseHandler={onCloseDeleteRestoreModal}
        className='confirmation-modal'
        modalTitle={
          isRestorationConfirmation
            ? t('alerts.titles.restore')
            : t('alerts.titles.delete')
        }
      >
        <SimpleModalContent
          content={
            isRestorationConfirmation
              ? ['alerts.confirm.devices-restore']
              : ['alerts.confirm.devices-delete']
          }
          mainBtn={
            isRestorationConfirmation ? 'buttons.restore' : 'buttons.delete'
          }
          onMainBtnClick={
            isRestorationConfirmation
              ? confirmDeviceRestoration
              : onDeleteDevice
          }
          secondaryBtn='buttons.cancel'
          onSecondaryBtnClick={onCloseDeleteRestoreModal}
        />
      </ModalUI>
      {/* Restore confirmation modal */}
      <ModalUI
        modalSx={deleteConfirmation}
        open={confirmationModalIsOpen}
        isCloseBtn={false}
        onCloseHandler={closeConfirmModal}
        className='confirmation-modal'
        modalTitle={t('alerts.titles.device-exists')}
      >
        <ConfirmationModal
          text={t('alerts.confirm.device-exists')}
          confirmBtn='Restore'
          existsDevices={existsDevices}
          onConfirm={onConfirmDeviceRestore}
          onDeclain={closeConfirmModal}
        />
      </ModalUI>
      {filterOpen && (
        <CustomMuiDataTableFilter
          isOpen={filterOpen}
          setFilterState={setFilterOpen}
          config={filtersConfig}
          filters={deviceFilters}
          onSaveHandler={onSaveFilters}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllDevices: deviceActions.getDeviceOverview,
  deleteDevice: deviceActions.deleteDevice,
  clearDevices: deviceActions.clearDevices,
  getAllDevicesGeneratedUUID: deviceActions.getAllDevicesGeneratedUUID,
  getAllDeviceProfiles: deviceActions.getAllDeviceProfiles,
  getShortOrganizations:
    organizationActions.getShortOrganizationsWithoutMetadata,
  updateFewDevices: deviceActions.updateFewDevices,
  updateDevice: deviceActions.updateDevice,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DevicesTable),
);
