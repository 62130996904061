import { useCallback, useState, useEffect, useMemo } from 'react';
import { Box, Paper, Popper } from '@mui/material';

import {
  LocalizationProvider,
  DatePicker,
  deDE,
  nlNL,
  enUS,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import { ExpandMoreRounded } from '@mui/icons-material';
import { ReactComponent as CalendarIcon } from '../../../shared/assets/icons/sidenav/session.svg';

import { classNames } from '../../../utils';

import { DatePickerInput } from './DatePickerInput';
import { WizardError } from '../../Treatments/CreateTreatmentWizard/WizardFooter/WizardError/WizardError';
import { datePickerStyles } from './style';
import { useCallbackDebounce } from '../../../hooks/useCallbackDebounce';

export const DatePickerUi = (props) => {
  const {
    className,
    disabled,
    readOnly,
    helperText,
    minDate,
    tooltip,
    maxDate = moment(),
    views, // ['year', 'month', 'day'] | ['year', 'month'] | ['day']
    label = 'DD-MM-YYYY',
    format = 'DD-MM-YYYY',
    value,
    handleDateChange,
  } = props;
  const { datePicker, datePickerPopper, mobilePicker, error } =
    datePickerStyles;
  const { t, i18n } = useTranslation();
  const [dateValue, setDateValue] = useState(null);
  const [prevValidValue, setPrevValidValue] = useState(null);
  const [isError, setIsError] = useState(false);

  /**
   * Shows an error
   */
  const showError = () => {
    setIsError(true);
    setTimeout(() => {
      setIsError(false);
      setDateValue(prevValidValue);
    }, 2000);
  };

  /**
   * Validates date
   * @param date filled date
   */
  const validateDatePicker = (date) => {
    if (!date['_isValid'] || maxDate < date) {
      setIsError(true);
      showError();
    } else {
      const formatedDate = moment(date).format('YYYY-MM-DD');
      setPrevValidValue(date);
      handleDateChange(formatedDate);
    }
    setDateValue(date);
  };

  /**
   * Debounced datepicher change handler
   */
  const debouncedInputHandler = useCallbackDebounce(validateDatePicker, 500);

  /**
   * Renders datepicker input field
   * @param props input props
   */
  const renderInput = useCallback(
    (props) => <DatePickerInput {...props} tooltip={tooltip} />,
    [tooltip],
  );

  /**
   * Renders datepicker popper
   */
  const customPopper = (props) => (
    <Popper sx={datePickerPopper} {...props}>
      {props.children}
    </Popper>
  );
  /**
   * Renders mobile paper
   */
  const customMobilePaper = (props) => (
    <Paper sx={mobilePicker} {...props}>
      {props.children}
    </Paper>
  );

  /**
   * Icon displayed in the SwitchViewButton. Rotated by 180° when the open view is 'year'.
   */
  const viewIcon = (props) => <ExpandMoreRounded {...props} />;

  /**
   * Sets date picker text locale
   */
  const localeText = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
      case 'de':
        return deDE.components.MuiLocalizationProvider.defaultProps.localeText;
      case 'nl':
        return nlNL.components.MuiLocalizationProvider.defaultProps.localeText;
    }
  }, [i18n]);

  /**
   * Sets selected value to datepicker
   */
  useEffect(() => {
    if (!!value && !dateValue) setDateValue(moment(value, 'YYYY-MM-DD'));
    if (!value) setDateValue(null);
  }, [value, dateValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} localeText={localeText}>
      <Box sx={error}>
        <Transition
          in={isError}
          timeout={300}
          mountOnEnter
          unmountOnExit
          appear={true}
        >
          {(state) => (
            <WizardError
              className={classNames('error', {}, [
                'visible',
                'slide-top',
                `slide-in-${state}`,
              ])}
            >
              {t('errors.validation.date')}
            </WizardError>
          )}
        </Transition>
        <DatePicker
          className={className}
          sx={datePicker}
          value={dateValue}
          disabled={disabled}
          localeText={{
            okButtonLabel: t('buttons.save'),
          }}
          readOnly={readOnly}
          slotProps={{
            textField: {
              helperText,
            },
          }}
          minDate={minDate}
          maxDate={maxDate}
          views={views}
          format={format}
          label={label}
          slots={{
            openPickerIcon: CalendarIcon,
            ...(tooltip && { textField: (props) => renderInput(props) }),
            popper: customPopper,
            switchViewIcon: viewIcon,
            mobilePaper: customMobilePaper,
          }}
          onChange={(newDate) => debouncedInputHandler(newDate)}
        />
      </Box>
    </LocalizationProvider>
  );
};
