import { Box, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import { classNames } from '../../../../../../../utils';
import { mapIconsNameToIcon } from '../../../../../../../utils/wizard-helper';
import CheckboxCard from '../../../../../../UI/CheckboxCard/CheckboxCard';
import CombineToggle from '../../../../../../UI/CombineToggle/CombineToggle';
import RangeInput from '../../../../../../UI/RangeInput/RangeInput';
import SelectUI from '../../../../../../UI/Select/Select';
import SelectionCardGroup from '../../../../../../UI/SelectionCardGroup/SelectionCardGroup';

function ConfigurationTab(props) {
  const {
    group,
    getVariableTranslation,
    getConfigVariableValueByName,
    handleVariableChange,
    updateSelectedConfig,
    t,
  } = props;
  const { variables } = group;
  const singleSetting = variables.length <= 1;

  /**
   * Mutate property in object with it's translation
   * @param obj to mutate property in
   * @param property to mutate
   * @returns mutated object
   */
  const getTranslatedObjByProperty = useCallback(
    (obj, property) =>
      obj?.map((item) => ({
        ...item,
        [property]: t(`configurations.EMDR-VR.values.${item[property]}`),
      })),
    [t],
  );

  /**
   * Handle checkbox toggle change and set variable value
   * @param value variable toggle value
   */
  const onCheckboxChange = useCallback(
    (name, value) => {
      handleVariableChange({
        variable: name,
        value,
      });
    },
    [handleVariableChange],
  );

  /**
   * Generates configuration slider component for variable
   * @param variable configuration variable
   * @returns configuration slider component for variable
   */
  const getSlider = (variable) => {
    const min = variable?.ui_configuration?.slider_min;
    const max = variable?.ui_configuration?.slider_max;
    const step = variable?.ui_configuration?.slider_step;
    const isPercentage = variable?.ui_configuration?.percentage;
    const label = variable?.ui_configuration?.slider_label
      ? variable?.ui_configuration?.slider_label
      : 'min';
    const sliderRange = max - min;

    // Slider marks
    const marks = [
      // min
      {
        value: min,
        label: (
          <span className='first'>
            {min} {label}
          </span>
        ),
      },
      // 1/4
      {
        value: sliderRange / 4 + min,
      },
      // 1/2
      {
        value: Math.round(sliderRange / 2 + min),
        label: `${Math.round(sliderRange / 2 + min)} ${label}`,
      },
      // 3/4
      {
        value: sliderRange * 0.75 + min,
      },
      // max
      {
        value: max,
        label: (
          <span className='last'>
            {max} {label}
          </span>
        ),
      },
    ];

    return (
      <Box
        key={variable?.name}
        className={classNames('setting-wrapper', { divider: !singleSetting }, [
          'slider',
        ])}
      >
        <Typography variant='h3' className='title'>
          {getVariableTranslation(variable?.name)}
        </Typography>
        <RangeInput
          rangeValue={+getConfigVariableValueByName(variable.name)}
          inputValue={+getConfigVariableValueByName(variable.name)}
          min={min}
          max={max}
          step={step ? step : 1}
          name={variable?.name}
          isPercentage={isPercentage}
          marks={marks}
          onSliderChangeCommittedHandler={handleVariableChange}
        />
      </Box>
    );
  };

  /**
   * Generates configuration dropdown component for variable
   * @param variable configuration variable
   * @returns configuration dropdown component for variable
   */
  const getDropdown = (variable) => {
    const dropdownOptions = variable?.ui_configuration.values;
    return (
      <Box
        key={variable?.name}
        className={classNames('setting-wrapper', { divider: !singleSetting })}
      >
        <Typography variant='h3' className='title'>
          {getVariableTranslation(variable?.name)}
        </Typography>

        <SelectUI
          idPrefix={variable?.name}
          size='medium'
          name={variable?.name}
          selectValue={getConfigVariableValueByName(variable.name)}
          onChangeHandler={handleVariableChange}
          options={dropdownOptions.map((option) => ({
            text: option,
            value: option,
          }))}
        />
      </Box>
    );
  };

  /**
   * Generates configuration combine toggle component for variable
   * @param variable configuration variable
   * @returns configuration combine toggle component for variable
   */
  const getCombineToggle = (variable) => (
    <Box
      key={variable?.name}
      className={classNames('setting-wrapper', { divider: !singleSetting })}
    >
      <Typography variant='h3' className='title'>
        {getVariableTranslation(variable?.name)}
      </Typography>
      <CombineToggle
        name={variable?.name}
        className={variable?.name}
        selectValue={getConfigVariableValueByName(variable?.name)}
        options={getTranslatedObjByProperty(
          variable.ui_configuration.toggle_options,
          'content',
        )}
        onChangeHandler={handleVariableChange}
        getVariableTranslation={getVariableTranslation}
      />
    </Box>
  );

  /**
   * Generates configuration toggle checkebox component for variable
   * @param variable configuration variable
   * @returns configuration toggle checkebox component for variable
   */
  const getToggle = (variable) => {
    const checkboxItem = {
      title: getVariableTranslation(variable?.name),
      desc: `configurations.EMDR-VR.values.${variable?.ui_configuration?.desc}`,
      checked: Boolean(getConfigVariableValueByName(variable?.name)),
      Icon: mapIconsNameToIcon(variable?.ui_configuration?.iconName),
    };

    return (
      <CheckboxCard
        className={classNames('toggle', {}, [variable?.name])}
        key={variable?.name}
        checkboxItem={checkboxItem}
        onCardSelectHandler={(value) => onCheckboxChange(variable?.name, value)}
        handleFromCheckbox
      />
    );
  };

  /**
   * Generates configuration selection card group component for variable
   * @param variable configuration variable
   * @returns configuration selection card group component for variable
   */
  const getSelectionCardGroup = (variable) => (
    <Box
      key={variable?.name}
      className={classNames('setting-wrapper', { divider: !singleSetting })}
    >
      <Typography variant='h3' className='title'>
        {/* TODO: replace Trajectory to variable?.name */}
        {getVariableTranslation('Trajectory')}
      </Typography>
      <SelectionCardGroup
        name={variable?.name}
        className={variable?.name}
        options={getTranslatedObjByProperty(
          variable.ui_configuration?.options,
          'content',
        )}
        selectValue={getConfigVariableValueByName(variable?.name)}
        onChangeHandler={handleVariableChange}
      />
    </Box>
  );

  /**
   * Generates configuration component based on variable
   * @param variable configuration variable
   * @returns configuration component
   */
  const getVariableLayout = (variable) => {
    const { type } = variable.ui_configuration;
    switch (type) {
      case 'slider':
        return getSlider(variable);
      case 'dropdown':
        return getDropdown(variable);
      case 'combine-toggle':
        return getCombineToggle(variable);
      case 'toggle':
        return getToggle(variable);
      case 'selection-card-group':
        return getSelectionCardGroup(variable);
    }
  };

  /**
   * Generates configuration group layout based on group's variables
   * @returns group layout
   */
  const groupLayout = useMemo(
    () =>
      variables
        .sort((a, b) => a?.ui_configuration?.order - b?.ui_configuration?.order)
        .map((variable) => getVariableLayout(variable)),
    [variables],
  );

  useEffect(
    () => () => {
      updateSelectedConfig();
    },
    [],
  );

  return <>{groupLayout}</>;
}

export default withTranslation()(memo(ConfigurationTab));
