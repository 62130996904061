import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const filterListStyle = {
  filterList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    ml: getResponsiveSize(16), // 16px
    mb: getResponsiveSize(16), // 16px
    gap: getResponsiveSize(8), // 8px
    '& .chip': {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: theme.palette.myPrimary.lightGrey,
      borderRadius: getResponsiveSize(8), // 8px
      py: getResponsiveSize(4), // 4px
      pl: getResponsiveSize(8), // 8px
      pr: getResponsiveSize(3), // 3px
      '& .MuiTypography-root': {
        fontSize: getResponsiveSize(13), // 13px
        fontFamily: theme.typography.additionalFontFamily,
        color: theme.palette.neutral.grey80,
        ...theme.palette.propVars.semiBoldFontWeight,
        '&.label': {
          mr: getResponsiveSize(6), // 6px
          color: theme.palette.myPrimary.darkBlue100,
          ...theme.palette.propVars.boldFontWeight,
        },
        '& .condition': {
          display: 'flex',
          alignItems: 'center',
          fontSize: getResponsiveSize(15), // 15px
          fontFamily: theme.typography.additionalFontFamily,
          color: theme.palette.neutral.grey80,
          ...theme.palette.propVars.semiBoldFontWeight,
          '& svg': {
            fontSize: getResponsiveSize(24), // 24px
            color: theme.palette.myPrimary.lightBlue70,
          },
        },
      },
      '& .value .check': {
        width: getResponsiveSize(16), // 16px
        height: getResponsiveSize(16), // 16px
        minHeight: '16px',
        minWidth: '16px',
        '& path': {
          fill: theme.palette.myPrimary.green,
        },
      },
      '& .btn-wrapper': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiButtonBase-root ': {
          width: getResponsiveSize(24), // 24px
          height: getResponsiveSize(24), // 24px
          p: getResponsiveSize(5), // 5px
          ml: getResponsiveSize(4), // 4px
          color: theme.palette.myPrimary.darkBlue100,
          '& svg': {
            fontSize: getResponsiveSize(20), // 20px
          },
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      gap: getResponsiveSize(8, laptopRatio), // 8px
      '& .chip': {
        borderRadius: getResponsiveSize(8, laptopRatio), // 8px
        py: getResponsiveSize(4, laptopRatio), // 4px
        pl: getResponsiveSize(8, laptopRatio), // 8px
        pr: getResponsiveSize(3, laptopRatio), // 3px
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(12, laptopRatio), // 12px
          '&.label': {
            mr: getResponsiveSize(6, laptopRatio), // 6px
          },
          '& .condition': {
            fontSize: getResponsiveSize(12, laptopRatio), // 12px
            '& svg': {
              fontSize: getResponsiveSize(22, laptopRatio), // 22px
            },
          },
        },
        '& .btn-wrapper .MuiButtonBase-root': {
          width: getResponsiveSize(20, laptopRatio), // 20px
          height: getResponsiveSize(20, laptopRatio), // 20px
          p: getResponsiveSize(5, laptopRatio), // 5px
          ml: getResponsiveSize(4, laptopRatio), // 4px
          '& svg': {
            fontSize: getResponsiveSize(18, laptopRatio), // 18px
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      ml: 0,
      mb: 0,
      mr: 0,
      overflow: 'auto',
      pb: getResponsiveSize(8, mobileRatio), // 8px
      gap: getResponsiveSize(8, mobileRatio), // 8px
      '& .chip': {
        borderRadius: getResponsiveSize(8, mobileRatio), // 8px
        py: getResponsiveSize(4, mobileRatio), // 4px
        pl: getResponsiveSize(8, mobileRatio), // 8px
        pr: getResponsiveSize(3, mobileRatio), // 3px
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(12, mobileRatio), // 12px
          textWrap: 'nowrap',
          '&.label': {
            mr: getResponsiveSize(6, mobileRatio), // 6px
          },
          '& .condition': {
            fontSize: getResponsiveSize(12, mobileRatio), // 12px
            '& svg': {
              fontSize: getResponsiveSize(22, mobileRatio), // 22px
            },
          },
        },
        '& .btn-wrapper .MuiButtonBase-root': {
          width: getResponsiveSize(20, mobileRatio), // 20px
          height: getResponsiveSize(20, mobileRatio), // 20px
          p: getResponsiveSize(5, mobileRatio), // 5px
          ml: getResponsiveSize(4, mobileRatio), // 4px
          '& svg': {
            fontSize: getResponsiveSize(18, mobileRatio), // 18px
          },
        },
      },
    },
  },
};
