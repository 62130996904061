import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import { array, object, reach, string } from 'yup';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AddRounded } from '@mui/icons-material/';
import { ReactComponent as DeleteIcon } from '../../shared/assets/icons/delete.svg';

import { useActions } from '../../hooks/useActions';
import { getDevicesByUUID, getDevicesByUUIDs } from '../../services/devices';
import { OrganizationSelect } from '../Organizations/OrganizationSelect';
import { ButtonUi } from '../UI/Button/Button';
import { ModalUI } from '../UI/Modal/Modal';
import { deleteConfirmationStyle } from '../UI/SimpleModalContent/style';
import { TextFieldUi } from '../UI/TextField/TextField';
import { ConfirmationModal } from './ConfirmationModal';
import { DeviceProfilesSelect } from './DeviceProfilesSelect';
import { deviceFormStyle } from './style';

const DEVICE_PREFIX = 'psylaris_';

const defaultDeviceData = {
  device_profile_fk: null,
  organizationId: null,
  devices: [
    {
      generated_UUID: '',
      name: '',
    },
  ],
};

export const CreateEditDeviceForm = (props) => {
  const { closeModal, reloadData, deviceToEdit } = props;
  const { t } = useTranslation();
  const { deviceForm } = deviceFormStyle;
  const { deleteConfirmation } = deleteConfirmationStyle;

  const { createFewDevices, updateFewDevices, updateDevice } = useActions();
  const { deviceProfiles } = useSelector((state) => state.devices);

  const { id, generated_uuid, device_profile_fk, name } = deviceToEdit || {};

  const [deviceData, setDeviceData] = useState(
    !deviceToEdit
      ? defaultDeviceData
      : {
          device_profile_fk,
          devices: [
            {
              generated_UUID: generated_uuid,
              name,
            },
          ],
        },
  );

  const [validationErrors, setValidationErrors] = useState({});

  // Device serial validation error message & regular expression
  const deviceSerialValidation = useRef();

  const [existsDevices, setExistsDevices] = useState([]);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const submitBtnLabel = deviceToEdit
    ? 'buttons.save'
    : 'buttons.create.create';

  const isSubmitionDisabled = Object.keys(validationErrors).length > 0;

  /**
   * Get Validation schema for the form fields
   */
  const getValidationSchema = useCallback(
    () =>
      object({
        device_profile_fk: string().required(t('errors.select.device-profile')),
        organizationId: !deviceToEdit
          ? string().required(t('errors.select.organisation'))
          : string().notRequired(),
        devices: array(
          object({
            generated_UUID: string()
              .transform((value) => value.replace(DEVICE_PREFIX, ''))
              .matches(
                deviceSerialValidation?.current?.validationRule || undefined,
                {
                  excludeEmptyString: true,
                  message: t(deviceSerialValidation?.current?.messageKey),
                },
              )
              .required(t('errors.validation.required')),
            name: string().required(t('errors.validation.required')),
          }),
        ),
      }),
    [deviceToEdit, t],
  );

  /**
   * Validates form fields and sets error
   * @control field control name
   * @value field value
   * @idx index of nested device
   */
  const validateControl = useCallback(
    async (control, value, idx) => {
      const checkValue = value;
      const isGenerated_UUIDControl = control === 'generated_UUID';
      const isNameControl = control === 'name';
      const validationSchema = getValidationSchema();

      try {
        const reachControl =
          isGenerated_UUIDControl || isNameControl
            ? `devices[${idx}].${control}`
            : control;
        await reach(validationSchema, reachControl).validate(checkValue, {
          abortEarly: false,
        });
        setValidationErrors((prev) => {
          if (idx || idx === 0) {
            const { devices, ...restValidatorErrors } = prev;
            if (devices?.[idx]) {
              const { [control]: validControl, ...rest } = devices?.[idx] || {};
              if (Object.keys(rest).length <= 0) delete devices[idx];
              else devices[idx] = { ...rest };
            }
            if (Object.keys(devices || {}).length <= 0)
              return { ...restValidatorErrors };
            else return { ...prev, devices };
          } else {
            const { [control]: validControl, ...rest } = prev;
            return rest;
          }
        });
      } catch (error) {
        const { message } = error?.inner?.[0] || {};
        setValidationErrors((prev) => {
          let errors;
          if (idx || idx === 0)
            errors = {
              ...prev,
              devices: {
                ...prev.devices,
                [idx]: {
                  ...prev?.devices?.[idx],
                  [control]: message,
                },
              },
            };
          else
            errors = {
              ...prev,
              [control]: message,
            };
          return {
            ...errors,
          };
        });
      }
    },
    [getValidationSchema],
  );

  /**
   * Adds new device controls
   */
  const addNewDevice = useCallback(() => {
    setDeviceData((current) => {
      const newDevice = {
        generated_UUID: '',
        name: '',
      };

      return {
        ...current,
        devices: [...current.devices, newDevice],
      };
    });
  }, []);

  /**
   * Removes device control
   * @param idx device index
   */
  const removeDevice = useCallback((idx) => {
    setDeviceData((current) => ({
      ...current,
      devices: [...current.devices.filter((device, index) => index !== idx)],
    }));
    setValidationErrors((current) => {
      const { devices, ...restValidatorErrors } = current;
      if (devices?.[idx]) delete devices[idx];
      if (Object.keys(devices || {}).length <= 0)
        return { ...restValidatorErrors };
      else return { ...current, devices };
    });
  }, []);

  /**
   * Sets value to the autocomplete
   * @param value selected value
   */
  const onSelect = useCallback(
    (value, selectType) => {
      setDeviceData((current) => ({
        ...current,
        [selectType]: value?.value,
      }));
      validateControl(selectType, value?.value);

      if (selectType === 'device_profile_fk' && value) {
        // Set validation config for device profile
        const selectedProfile = deviceProfiles.find(
          (profile) => profile.id === value?.value,
        );

        const serialValidation = {
          messageKey: selectedProfile.serialValidationMessageKey
            ? `errors.device-serial.${selectedProfile.serialValidationMessageKey}`
            : 'errors.device-serial.default',
          validationRule:
            selectedProfile.serialValidationRule !== null
              ? new RegExp(selectedProfile.serialValidationRule)
              : selectedProfile.serialValidationRule,
        };
        deviceSerialValidation.current = serialValidation;
        deviceSerialValidation;
        deviceData.devices.forEach((device, index) => {
          validateControl('generated_UUID', device?.generated_UUID, index);
        });
      }
    },
    [deviceData, deviceProfiles, validateControl],
  );

  /**
   * Sets device contols data by index
   * @param control control name to change
   * @param value value to set
   * @param idx devices index
   * @param isPrefix is generatedUUID prefix exists
   */
  const setDeviceDataByIndex = useCallback(
    (name, value, idx, isPrefix = true) => {
      setDeviceData((current) => {
        const devices = [...current.devices];
        const changedDeviceItem = {
          ...devices[idx],
          [name]: isPrefix ? value : DEVICE_PREFIX + value,
        };
        devices[idx] = changedDeviceItem;
        return {
          ...current,
          devices: [...devices],
        };
      });
    },
    [],
  );

  /**
   * Sets value to the textfield
   * @param event native event
   * @param idx devices index
   */
  const onChange = useCallback(
    (event, idx) => {
      const { name, value } = event.target;
      const isGenerated_UUIDControl = name === 'generated_UUID';
      const isNameControl = name === 'name';

      if (isGenerated_UUIDControl && !value) {
        setDeviceDataByIndex(name, value, idx);
        validateControl(name, value, idx);
        return;
      }

      if (isGenerated_UUIDControl && value.includes(DEVICE_PREFIX))
        setDeviceDataByIndex(name, value, idx);
      else if (isGenerated_UUIDControl && !value.includes(DEVICE_PREFIX))
        setDeviceDataByIndex(name, value, idx, false);
      else if (isNameControl) setDeviceDataByIndex(name, value, idx);
      else setDeviceData((current) => ({ ...current, [name]: value }));

      validateControl(name, value, idx);
    },
    [setDeviceDataByIndex, validateControl],
  );

  /**
   * Shows form control field error message
   * @control field control name
   * @idx device index to show validation errors
   */
  const showValidationErrors = useCallback(
    (control, idx) => {
      if (idx || idx === 0) {
        const controlError = validationErrors?.devices?.[idx]?.[control];
        if (controlError)
          return (
            <FormHelperText key={`${controlError}${idx}`} error>
              {controlError}
            </FormHelperText>
          );
        return null;
      } else {
        if (validationErrors[control])
          return (
            <FormHelperText key={control} error>
              {validationErrors?.[control]}
            </FormHelperText>
          );
        return null;
      }
    },
    [validationErrors],
  );

  /**
   * Clsoe confirmation modal
   */
  const onDeclainDeviceCreation = useCallback(() => {
    setConfirmationModalIsOpen(false);
  }, [setConfirmationModalIsOpen]);

  /**
   * Open confirmation modal
   */
  const openConfirmationModal = useCallback(() => {
    setConfirmationModalIsOpen(true);
  }, [setConfirmationModalIsOpen]);

  /**
   * Create / edit device
   */
  const onCreateEditDevice = useCallback(async () => {
    // If device(s) already exists mark them as deleted
    if (existsDevices)
      updateFewDevices(
        existsDevices.map((device) => ({ ...device, is_deleted: true })),
      );

    deviceToEdit
      ? await updateDevice({
          id,
          device_profile_fk: deviceData.device_profile_fk,
          generated_UUID: deviceData.devices[0].generated_UUID,
          name: deviceData.devices[0].name,
        })
      : await createFewDevices(
          deviceData.devices.map((device) => ({
            device_profile_fk: deviceData.device_profile_fk,
            organizationId: deviceData.organizationId,
            generated_UUID: device.generated_UUID,
            name: device.name,
          })),
        );

    closeModal();
    reloadData();
  }, [
    closeModal,
    deviceData,
    deviceToEdit,
    existsDevices,
    id,
    reloadData,
    createFewDevices,
    updateDevice,
    updateFewDevices,
  ]);

  /**
   * Shows form control field error message
   * @returns boolean value is form valid
   */
  const validateSubmitiion = async () => {
    let valid = true;

    Object.keys(deviceData).forEach((control) => {
      if (control == 'devices') {
        deviceData.devices.map((device, idx) => {
          Object.keys(device).forEach((deviceControl) => {
            validateControl(deviceControl, device[deviceControl], idx);
          });
        });
      } else validateControl(control, deviceData[control]);
    });

    const validationSchema = getValidationSchema();

    try {
      await validationSchema.validate(deviceData, { abortEarly: false });
      valid = true;
    } catch (error) {
      valid = false;
    }

    return valid;
  };

  /**
   * Handle form submnition and shows confirmation modal if device alredy exists
   * @param event native button event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const valid = await validateSubmitiion();
    if (!valid) return;

    const devicesGenerated_UUIDs = deviceData?.devices.map(
      ({ generated_UUID }) => generated_UUID,
    );

    try {
      const response =
        devicesGenerated_UUIDs.length > 1
          ? await getDevicesByUUIDs(devicesGenerated_UUIDs)
          : await getDevicesByUUID(devicesGenerated_UUIDs[0]);
      const existsDevices = deviceToEdit
        ? response?.data
            .filter((device) => device.id !== id)
            .map((device) => ({
              ...device,
              newName: deviceData.devices[0].name,
            }))
        : response?.data.map((device) => ({
            ...device,
            newName: deviceData.devices.filter(
              (newDevice) => newDevice.generated_UUID === device.generated_UUID,
            )[0].name,
          }));
      if (existsDevices?.length > 0) {
        setExistsDevices(() => existsDevices);
        openConfirmationModal();
      } else onCreateEditDevice();
    } catch (error) {
      console.log('error: ', error);
    }
  };

  /**
   * Call device creation / update after modal confirmation
   */
  const onConfirmDeviceCreation = useCallback(() => {
    onCreateEditDevice();
  }, [onCreateEditDevice]);

  /**
   * Set validation config for device profile on opens edit device
   */
  useEffect(() => {
    if (deviceToEdit && device_profile_fk && !deviceSerialValidation.current) {
      const selectedProfile = deviceProfiles.find(
        (profile) => profile.id === device_profile_fk,
      );

      if (selectedProfile) {
        const serialValidation = {
          messageKey: selectedProfile?.serialValidationMessageKey
            ? `errors.device-serial.${selectedProfile?.serialValidationMessageKey}`
            : 'errors.device-serial.default',
          validationRule:
            selectedProfile?.serialValidationRule !== null
              ? new RegExp(selectedProfile?.serialValidationRule)
              : null,
        };
        deviceSerialValidation.current = serialValidation;
      }
    }
  }, [deviceProfiles]);

  return (
    <>
      <Box
        component='form'
        className='create-form validate-form'
        onSubmit={handleSubmit}
        sx={deviceForm}
      >
        <Grid container className='form-wrapper'>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <DeviceProfilesSelect
                selectedId={deviceData?.device_profile_fk}
                handleChange={(value) => onSelect(value, 'device_profile_fk')}
              />
              {showValidationErrors('device_profile_fk')}
            </FormControl>
          </Grid>
          {!deviceToEdit && (
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <OrganizationSelect
                  selectedId={deviceData?.organizationId}
                  handleChange={(value) => onSelect(value, 'organizationId')}
                />
                {showValidationErrors('organizationId')}
              </FormControl>
            </Grid>
          )}
          {deviceData.devices.map((device, idx) => (
            <Box className='device-wrapper' key={`device-num-${idx}`}>
              {!deviceToEdit && (
                <Typography variant='h6' className='name'>
                  {device.name
                    ? device.name
                    : `${t('forms.device')} ${idx + 1}`}
                </Typography>
              )}
              <Box className='device-formfields'>
                <Box className='field-wrapper'>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <TextFieldUi
                        className='simple-text-field'
                        label={t('forms.uuid')}
                        autoFocus
                        name='generated_UUID'
                        fullWidth
                        value={device?.generated_UUID || ''}
                        handleChange={(event) => onChange(event, idx)}
                      />
                    </FormControl>
                    {showValidationErrors('generated_UUID', idx)}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <TextFieldUi
                        className='simple-text-field'
                        label={t('forms.name')}
                        autoFocus
                        name='name'
                        fullWidth
                        value={device?.name || ''}
                        handleChange={(event) => onChange(event, idx)}
                      />
                    </FormControl>
                    {showValidationErrors('name', idx)}
                  </Grid>
                </Box>
                {!deviceToEdit && (
                  <Box className='action-buttons'>
                    <ButtonUi
                      isIconBtn
                      className='add-device'
                      size='small'
                      onClickHandler={addNewDevice}
                    >
                      <AddRounded />
                    </ButtonUi>
                    <ButtonUi
                      disabled={deviceData.devices.length === 1}
                      isIconBtn
                      className='remove-device'
                      size='small'
                      onClickHandler={() => removeDevice(idx)}
                    >
                      <DeleteIcon />
                    </ButtonUi>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Grid>
        <Box className='modal-footer justify-end'>
          <ButtonUi
            className='action-btn accept'
            variant='contained'
            type='primary'
            size='small'
            btnType='submit'
            disabled={isSubmitionDisabled}
          >
            {t(submitBtnLabel)}
          </ButtonUi>
        </Box>
      </Box>
      {/* Create / edit confirmation modal */}
      <ModalUI
        modalSx={deleteConfirmation}
        open={confirmationModalIsOpen}
        isCloseBtn={false}
        onCloseHandler={closeModal}
        className='confirmation-modal'
        modalTitle={t('alerts.titles.device-exists')}
      >
        <ConfirmationModal
          text={t('alerts.confirm.device-exists')}
          confirmBtn={submitBtnLabel}
          existsDevices={existsDevices}
          onConfirm={onConfirmDeviceCreation}
          onDeclain={onDeclainDeviceCreation}
        />
      </ModalUI>
    </>
  );
};
