import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../hooks/useActions';

import AutocompleteUi from '../UI/Autocomplete/Autocomplete';

const organizationFields = { fields: ['id', 'name'] };

export const OrganizationSelect = memo((props) => {
  const { handleChange, selectedId, excludeIds = [], selfSx } = props;
  const { t } = useTranslation();
  const { getShortOrganizationsWithoutMetadata: getAllOrganizations } =
    useActions();
  const { organizations: organizationsReducer, authentication } = useSelector(
    (state) => state,
  );
  const { shortOrganizations, isLoading } = organizationsReducer;
  const { userIsAdmin, isDeviceAdmin, isLogisticsAdmin } = authentication;

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const hasNoAccessToAllOrganizations =
    !userIsAdmin && !isDeviceAdmin && !isLogisticsAdmin;

  const options = useMemo(() => {
    let tempOptions = shortOrganizations
      .filter((org) => !excludeIds?.includes(org?.id))
      .map((organization) => ({
        text: organization.name,
        value: organization.id,
      }));
    if (hasNoAccessToAllOrganizations) {
      const { manageableOrganizationIds } = authentication;
      if (!manageableOrganizationIds) tempOptions = [];
      else
        tempOptions = tempOptions.filter((option) =>
          manageableOrganizationIds.includes(parseInt(option.value)),
        );
    }
    return tempOptions;
  }, [
    shortOrganizations,
    hasNoAccessToAllOrganizations,
    excludeIds,
    authentication,
  ]);

  /**
   * Handle Autocomplete`s options render
   * @param optionProps option props
   * @param obj option text and value
   * @returns ReactNode for each Autocomplete option
   */
  const handleAutocompleteRenderOptions = useCallback(
    (optionProps, { text, value }) => (
      <li {...optionProps} key={value}>
        {text}
      </li>
    ),
    [],
  );

  /**
   * Handle Autocomplete change event
   * (option selection, clear value, close...)
   * @param event
   * @param value selected option value
   */
  const handleAutocompleteChange = useCallback(
    (event, value) => {
      setSelectedOrganization(value);
      handleChange(value);
    },
    [handleChange],
  );

  /**
   * Fetches all organizations if they are not defined
   * Sets autocomplete value to selectedId
   */
  useEffect(() => {
    if (shortOrganizations.length <= 0) getAllOrganizations(organizationFields);
    if (selectedId)
      setSelectedOrganization(
        options.find((item) => item.value === selectedId),
      );
  }, [selectedId]);

  return (
    <AutocompleteUi
      selfSx={selfSx}
      className='organization-select'
      label={t('organizations.select')}
      placeholder=''
      autocompleteValue={selectedOrganization}
      onAutocompleteChangeHandler={handleAutocompleteChange}
      onAutocompleteRenderOptionsHandler={handleAutocompleteRenderOptions}
      options={options}
      loading={isLoading}
      withTransition
    />
  );
});
