import { laptopRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const inputStyle = {
  input: {
    width: getResponsiveSize(428), // 428px
    '& .MuiInputLabel-root': {
      left: getResponsiveSize(12), // 12px
      color: theme.palette.neutral.greyRGBA80,
      fontSize: getResponsiveSize(14), // 14px
      transform: `translate(${getResponsiveSize(14)}, ${getResponsiveSize(
        16,
      )}) scale(1)`,
      '&.Mui-focused': {
        left: '0',
        color: theme.palette.neutral.grey80,
        transform: `translate(${getResponsiveSize(
          14,
        )}, calc(${getResponsiveSize(9)}* -1)) scale(0.75)`,
      },
      '&.MuiFormLabel-filled': {
        left: 0,
        transform: `translate(${getResponsiveSize(
          14,
        )}, calc(${getResponsiveSize(9)}* -1)) scale(0.75)`,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.myPrimary.white,
      borderRadius: theme.palette.propVars.borderRadius,
      pl: getResponsiveSize(10), // 10px
      pr: getResponsiveSize(24), // 24px
      '& input': {
        py: getResponsiveSize(16.5), // 16.5px
        pl: getResponsiveSize(14), // 14px
        height: getResponsiveSize(20), // 20px
        color: theme.palette.neutral.grey70,
        fontSize: `${theme.typography.fs14} !important`, // 14px
      },
      '& fieldset': {
        border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.myPrimary.lightBlue100,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.myPrimary.lightBlue100,
        },
      },
    },
    '& .input-btn': {
      height: getResponsiveSize(40), // 40px
      width: getResponsiveSize(113), // 113px
      fontSize: theme.typography.fs16, // 16px
      ...theme.palette.propVars.regularFontWeight,
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      width: getResponsiveSize(325, laptopRatio), // 325px
      '& .MuiInputLabel-root': {
        left: getResponsiveSize(12, laptopRatio), // 12px
        fontSize: getResponsiveSize(14, laptopRatio), // 14px
        transform: `translate(${getResponsiveSize(
          14,
          laptopRatio,
        )}, ${getResponsiveSize(16, laptopRatio)}) scale(1)`,
        '&.Mui-focused': {
          transform: `translate(${getResponsiveSize(
            14,
            laptopRatio,
          )}, calc(${getResponsiveSize(9, laptopRatio)}* -1)) scale(0.75)`,
        },
        '&.MuiFormLabel-filled': {
          transform: `translate(${getResponsiveSize(
            14,
            laptopRatio,
          )}, calc(${getResponsiveSize(9, laptopRatio)}* -1)) scale(0.75)`,
        },
      },
      '& .MuiInputBase-root': {
        px: getResponsiveSize(10, laptopRatio), // 10px
        // pr: getResponsiveSize(24, laptopRatio), // 24px
        '& input': {
          py: getResponsiveSize(16.125, laptopRatio), // 16.125px
          pl: getResponsiveSize(14, laptopRatio), // 14px
          height: getResponsiveSize(16, laptopRatio), // 16px
          fontSize: `${getResponsiveSize(14, laptopRatio)} !important`, // 14px
        },
      },
      '& .input-btn': {
        height: getResponsiveSize(36, laptopRatio), // 36px
        width: getResponsiveSize(85, laptopRatio), // 85px
        fontSize: getResponsiveSize(14, laptopRatio), // 16px
      },
    },
  },
};
