import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers/reducers';

const initializeStore = () => {
  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
  });
  return createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
      composeEnhancers(applyMiddleware(thunk)),
    ),
  );
};

export const store = initializeStore();
