export const treatmentConstants = {
  GETALL_REQUEST: 'TREATMENTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'TREATMENTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'TREATMENTS_GETALL_FAILURE',

  GET_REQUEST: 'TREATMENT_GET_REQUEST',
  GET_SUCCESS: 'TREATMENT_GET_SUCCESS',
  GET_FAILURE: 'TREATMENT_GET_FAILURE',

  GET_DEVICE_CONNECTION_STATUS: 'TREATMENT_GET_DEVICE_CONNECTION_STATUS',
  GET_DEVICE_CONNECTION_STATUS_SUCCESS:
    'TREATMENT_GET_DEVICE_CONNECTION_STATUS_SUCCESS',
  GET_DEVICE_CONNECTION_STATUS_FAILURE:
    'TREATMENT_GET_DEVICE_CONNECTION_STATUS_FAILURE',

  RESET_DEVICE_TREATMENT_CONNECTION_ID: 'RESET_DEVICE_TREATMENT_CONNECTION_ID',
  USE_DEFAULT_DEVICE_CONFIGURATION: 'USE_DEFAULT_DEVICE_CONFIGURATION',

  DELETE_REQUEST: 'TREATMENTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'TREATMENTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'TREATMENTS_DELETE_FAILURE',

  CREATE_CONFIG_REQUEST: 'CREATE_TREATMENT_CONFIGURATION_REQUEST',
  CREATE_CONFIG_SUCCESS: 'CREATE_TREATMENT_CONFIGURATION_SUCCESS',
  CREATE_CONFIG_FAILURE: 'CREATE_TREATMENT_CONFIGURATION_FAILURE',

  PATCH_CONFIG_REQUEST: 'PATCH_TREATMENT_CONFIGURATION_REQUEST',
  PATCH_CONFIG_SUCCESS: 'PATCH_TREATMENT_CONFIGURATION_SUCCESS',
  PATCH_CONFIG_FAILURE: 'PATCH_TREATMENT_CONFIGURATION_FAILURE',

  RESET_STATE: 'RESET_STATE',
  CLEAR_TREATMENTS: 'CLEAR_TREATMENTS',
};
