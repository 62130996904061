import { practitionerConstants } from '../constants/practitioner.constants';

export function practitioners(
  state = {
    practitioners: [],
    selectedPractitioner: {
      teams: [],
    },
    totalPractitioners: 0,
  },
  action,
) {
  switch (action.type) {
    case practitionerConstants.GETALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case practitionerConstants.GETALL_SUCCESS:
      const { therapists, totalCount } = action.practitioners;
      return {
        ...state,
        practitioners: therapists,
        totalPractitioners: totalCount,
        isLoading: false,
      };
    case practitionerConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case practitionerConstants.COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case practitionerConstants.COUNT_SUCCESS:
      return {
        ...state,
        totalPractitioners: action.count,
      };
    case practitionerConstants.COUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case practitionerConstants.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case practitionerConstants.CREATE_SUCCESS: {
      const therapists = state.practitioners || [];
      therapists.push(action.practitioner);
      return {
        ...state,
        practitioners: therapists,
        isLoading: false,
        error: null,
      };
    }
    case practitionerConstants.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case practitionerConstants.GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case practitionerConstants.GET_SUCCESS:
      return {
        ...state,
        selectedPractitioner: action.practitioner,
        isLoading: false,
      };
    case practitionerConstants.GET_FAILURE:
      return {
        ...state,
        error: true,
      };
    case practitionerConstants.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case practitionerConstants.DELETE_SUCCESS: {
      const { id } = action;
      const filteredOrganizations = state.practitioners.filter(
        (item) => item.id !== id,
      );
      return {
        ...state,
        practitioners: filteredOrganizations,
        isLoading: false,
      };
    }
    case practitionerConstants.DELETE_FAILURE:
      return {
        ...state,
        error: true,
      };
    case practitionerConstants.EDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case practitionerConstants.EDIT_SUCCESS: {
      const newPractitioners = state.practitioners.filter(
        (item) => item.id !== action.practitioner.id,
      );
      return {
        ...state,
        practitioners: [...newPractitioners, action.practitioner],
        isLoading: false,
      };
    }
    case practitionerConstants.EDIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case practitionerConstants.CLEAR_PRACTITIONERS:
      return {
        ...state,
        practitioners: [],
      };
    case practitionerConstants.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
