import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import LineGraph from '../components/LineGraph';

import {sessionActions} from '../store/actions/session.actions';

let SessionDetail = ({match, findSessionById, i18n, sessions, authentication}) => {
  const [sessionId] = useState(match.params.id);
  const {isAdmin} = authentication || {};

  useEffect(() => {
    findSessionById(sessionId);
  }, [sessionId])

  const {selectedSession} = sessions || {};
  const copiedSession = JSON.parse(JSON.stringify(selectedSession));
  const sessionAttributes = copiedSession.attributes || {};
  delete copiedSession.attributes;
  const combinedSessionObject = {...copiedSession, ...sessionAttributes};
  const backNav = {title: 'Sessions overview', route: '/admin/sessions'};

  const renderOption = (key, val) => (
        <div className='row row-small align-items-left'>
          <p style={{fontWeight: 'bold'}}>{key}</p>
          <p>{val || 'No data found'}</p>
        </div>
    )
  const hasPractitionerCommands = combinedSessionObject.practitioner_commands;
  const hasClientCommands = combinedSessionObject.client_commands;
  const isRemote = hasPractitionerCommands || hasClientCommands;

  const showRemoteValues = useMemo(() => {
    if (!isRemote) {
      return null;
    }
    const startDate = moment(combinedSessionObject.start_date).format('DD/MM/YYYY [at] HH:mm');
    const endDate = combinedSessionObject.end_date ? moment(combinedSessionObject.end_date).format('DD/MM/YYYY [at] HH:mm') : 'No date found';
    const notes = (combinedSessionObject.notes || [])[0] || '';
    const sudScores = (combinedSessionObject.sud_array || []).join(',');
    const vocScores = (combinedSessionObject.validity_of_cognition_array || []).join(',');
    const twilioRoomId = combinedSessionObject.twilio_room_id || 'No Twilio Room ID';
    return (
        <>
          {renderOption('Start date', startDate)}
          {renderOption('End date', endDate)}
          {renderOption('Notes', notes)}
          {renderOption('Voc scores', vocScores)}
          {renderOption('Sud scores', sudScores)}
          {isAdmin ? renderOption('Twilio Room Id', twilioRoomId) : null}
        </>
    );
  }, [combinedSessionObject, isAdmin]);

  const showVRvalues = useMemo(() => {
    if (isRemote) {
      return null;
    }
    const startDate = moment(combinedSessionObject.start_date).format('DD/MM/YYYY [at] HH:mm');
    const endDate = combinedSessionObject.end_date ? moment(combinedSessionObject.end_date).format('DD/MM/YYYY [at] HH:mm') : 'No date found';
    const sudScores = (combinedSessionObject.sud_array || []).join(',');
    const vocScores = (combinedSessionObject.validity_of_cognition_array || []).join(',');
    const bodyPartAffected = combinedSessionObject.body_part_affected;
    const compositeNc = combinedSessionObject.composite_nc;
    const emotionSelect = combinedSessionObject.emotion_select;
    const positiveCognition = combinedSessionObject.positive_cognition;
    const positiveLesson = combinedSessionObject.positive_lesson;
    return (
        <>
          {renderOption('Body part affected', bodyPartAffected)}
          {renderOption('Composite nc', compositeNc)}
          {renderOption('Emotion select', emotionSelect)}
          {renderOption('Positive cognition', positiveCognition)}
          {renderOption('Positive lesson', positiveLesson)}
          {renderOption('Start date', startDate)}
          {renderOption('End date', endDate)}
          {renderOption('Voc scores', vocScores)}
          {renderOption('Sud scores', sudScores)}
        </>
    );
  }, [combinedSessionObject]);

  return (
      <>
        <div className='title'>
          <CustomBreadcrumbs backNav={backNav} currentTitle={selectedSession.generatedUUID}/>
          <p className='title-description'>{i18n.t('session-detail.title')}</p>
          {' '}
        </div>
        <div className='session-detail-container'>
          <div className='chart-container'>
            <LineGraph values={combinedSessionObject.sud_array}/>
          </div>
          <div className='detail-grid'>
            {showRemoteValues}
            {showVRvalues}
          </div>
        </div>
      </>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  findSessionById: sessionActions.findSessionById,
};

export default SessionDetail = withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SessionDetail));
