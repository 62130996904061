import { Box, Typography } from '@mui/material';
import { memo, useMemo } from 'react';

export const DataCell = memo((props) => {
  const {
    type = 'primary', // primary | secondary | numerics
    children,
    emptyLabel = '-',
  } = props;

  const label = useMemo(() => {
    if (children || children === 0) return children;
    else return emptyLabel;
  }, [children, emptyLabel]);

  return <Box className={`data-cell ${type}`}>{label}</Box>;
});
