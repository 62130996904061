import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import { Box } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { CallMergeOutlined } from '@mui/icons-material';
import { ReactComponent as DeleteIcon } from '../../shared/assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../shared/assets/icons/edit.svg';
import { ReactComponent as AddOrganizationIcon } from '../../shared/assets/icons/sidenav/organizations.svg';

import { organizationActions } from '../../store/actions/organization.actions';

import useDebounce from '../../hooks/useDebounce';
import { useTableFilterConfig } from '../../hooks/useTableFilterConfig';

import { classNames, getTransformedLogicTableFilterValue } from '../../utils';
// eslint-disable-next-line import/no-cycle
import { checkRouteAccess } from '../../utils/check-access';
import getMuiTheme from '../../utils/MUI-Style';
import tableConfig from '../../utils/MUI-table-config';
import muiComponents from '../../utils/MUI-table-components';
import { muiTableLoadingSkeleton } from '../../utils/MUI-table-loading-skaleton';

import CustomToggle from '../CustomToggle';
import CustomDateSortHeader from '../Sessions/CustomDateSortHeader';
import { CreateEditOrganizationForm } from './CreateEditOrganizationForm';
import { ModalUI } from '../UI/Modal/Modal';
import { FormModal } from '../UI/FormModal/FormModal';
import TableHeader from '../UI/TableHeader/TableHeader';
import { DataCell } from '../UI/MuiDatatable/DataCell/DataCell';
import { deleteConfirmationStyle } from '../UI/SimpleModalContent/style';
import SimpleModalContent from '../UI/SimpleModalContent/SimpleModalContent';
import { OrganizationImage } from '../UI/MuiDatatable/OrganizationImage/OrganizationImage';
import { CustomMuiDataTableFilter } from '../UI/MuiDatatable/CustomMuiDataTableFilter/CustomMuiDataTableFilter';
import { CustomMuiSessionsPerMonth } from '../UI/MuiDatatable/CustomMuiSessionsPerMonth/CustomMuiSessionsPerMonth';
import { CustomMuiDataTableBodyButton } from '../UI/MuiDatatable/CustomMuiDataTableBodyButton/CustomMuiDataTableBodyButton';
import { ButtonUi } from '../UI/Button/Button';
import { MergeOrganizationDialog } from './MergeOrganizationDialog/MergeOrganizationDialog';

const Organizations = ({
  location,
  // reducers
  authentication,
  history,
  organizations: organizationsReducer,
  // actions
  deleteOrganization,
  getAllOrganizations,
  refreshShortOrganizations,
  updateOrganization,
  clearOrganizations,
}) => {
  const { t } = useTranslation();
  const { deleteConfirmation } = deleteConfirmationStyle;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentSort, setCurrentSort] = useState('default');
  const [currentSortType, setCurrentSortType] = useState('');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  const [filterOpen, setFilterOpen] = useState(false);
  const [organizationFilters, setTeamOrganizationFilters] = useState();
  const tableRef = useRef();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [mergeModalIsOpen, setMergeModalIsOpen] = useState(false);

  const [organizationToEdit, setOrganizationToEdit] = useState(null);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);
  const [organizationFromMerge, setOrganizationFromMerge] = useState(null);

  const { organizations, isLoading, totalOrganizations } = organizationsReducer;

  const ACTIVE_KEY = t('forms.active');
  const PSYLARIS_CARE_OVERRULE_KEY = t('forms.psylaris-care-overrule');
  const EMDR_REMOTE_OVERRULE_KEY = t('forms.emdr-remote-overrule');

  const THERAPISTS_KEY = useMemo(() => t('forms.total.therapists'), [t]);
  const SESSIONS_KEY = useMemo(() => t('forms.total.sessions_this_month'), [t]);

  const TOGGLE_KEYS = [
    ACTIVE_KEY,
    PSYLARIS_CARE_OVERRULE_KEY,
    EMDR_REMOTE_OVERRULE_KEY,
  ];

  /**
   * Check does user has permision to be on this page
   */
  useEffect(() => {
    if (location.search) {
      setModalIsOpen(true);
    }
    const { foundUser, roles, userIsAdmin } = authentication;
    checkRouteAccess(location.pathname, foundUser, roles, userIsAdmin, history);
  }, [location, authentication, history]);

  /**
   * Opens the filter's modal and grabs data for the filters
   */
  const onOpenFilterModal = useCallback(() => {
    setFilterOpen(true);
  }, []);

  /**
   * Generates filters
   */
  const organizationFilter = useMemo(() => {
    let order = '';
    let direction = '';
    let searchQuery = '';

    const totalSessions = getTransformedLogicTableFilterValue(
      organizationFilters,
      'sessions',
    );
    const monthlySession = getTransformedLogicTableFilterValue(
      organizationFilters,
      'monthly_sessions',
    );
    const activeClients = getTransformedLogicTableFilterValue(
      organizationFilters,
      'therapists',
    );

    switch (currentSort) {
      case 'up':
        direction = 'DESC';
        break;
      case 'down':
        direction = 'ASC';
        break;
      default:
        break;
    }

    if (currentSort && currentSortType && direction !== 'default')
      order = `${currentSortType} ${direction}`;

    if (debouncedSearchTerm)
      searchQuery = `${debouncedSearchTerm.toLowerCase()}`;

    return {
      page: page * limit || 0,
      limit,
      order,
      searchQuery,
      totalSessions,
      monthlySession,
      activeClients,
    };
  }, [
    page,
    limit,
    currentSort,
    currentSortType,
    debouncedSearchTerm,
    organizationFilters,
  ]);

  /**
   * Gets all Teams on filters changes
   */
  useEffect(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      totalSessions,
      monthlySession,
      activeClients,
    } = organizationFilter;
    getAllOrganizations(
      page,
      limit,
      order,
      searchQuery,
      totalSessions,
      monthlySession,
      activeClients,
    );
  }, [getAllOrganizations, organizationFilter]);

  /**
   * Refresh teams data
   */
  const refreshCurrentData = useCallback(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      totalSessions,
      monthlySession,
      activeClients,
    } = organizationFilter;
    getAllOrganizations(
      page,
      limit,
      order,
      searchQuery,
      totalSessions,
      monthlySession,
      activeClients,
    );
    refreshShortOrganizations();
  }, [getAllOrganizations, organizationFilter, refreshShortOrganizations]);

  /**
   * Open edit / create organiozation modal
   */
  const openModal = () => {
    setModalIsOpen(true);
  };

  /**
   * Close edit / create organiozation modal
   */
  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    refreshCurrentData();
    setOrganizationToEdit(null);
  }, [refreshCurrentData]);

  /**
   * Open delete modal
   */
  const onOpenDeleteModal = useCallback((id) => {
    setOrganizationToDelete(id);
    setDeleteModalIsOpen(true);
  }, []);

  /**
   * Close delete modal
   */
  const onCloseDeleteModal = useCallback(() => setDeleteModalIsOpen(false), []);

  /**
   * Open merge organization modal
   * @param id organization's to merge id
   */
  const mergeOrganization = useCallback(
    (id) => {
      setMergeModalIsOpen(true);
      const foundOrg = organizations.find((org) => org.id === id);
      setOrganizationFromMerge(foundOrg);
    },
    [organizations],
  );

  /**
   * Close merge modal
   */
  const onCloseMergeModal = useCallback(() => {
    setMergeModalIsOpen(false);
    refreshCurrentData();
    setOrganizationFromMerge(null);
  }, [refreshCurrentData]);

  /**
   * Open edit organization
   * @param event native event
   * @param id organization's to edit id
   */
  const editOrganization = useCallback(
    (event, id) => {
      event.stopPropagation();
      setModalIsOpen(true);
      const foundOrg = organizations.find((org) => org.id === id);
      setOrganizationToEdit(foundOrg);
    },
    [organizations],
  );

  /**
   * Delete organization handler
   */
  const onDeleteOrganization = useCallback(async () => {
    await deleteOrganization(organizationToDelete);
    refreshCurrentData();
    setDeleteModalIsOpen(false);
  }, [deleteOrganization, organizationToDelete, refreshCurrentData]);

  /**
   * Handle table row`s active toggle click
   * @param key column name
   * @param id organization id
   * @param value active column value
   */
  const handleToggleClicked = useCallback(
    (key, id, value) => {
      switch (key) {
        case ACTIVE_KEY:
          return updateOrganization({
            id,
            is_active: value,
          });
        case PSYLARIS_CARE_OVERRULE_KEY:
          return updateOrganization({
            id,
            psylaris_care_overrule: value,
          });
        case EMDR_REMOTE_OVERRULE_KEY:
          return updateOrganization({
            id,
            emdr_remote_overrule: value,
          });
        default:
          return null;
      }
    },
    [
      ACTIVE_KEY,
      EMDR_REMOTE_OVERRULE_KEY,
      PSYLARIS_CARE_OVERRULE_KEY,
      updateOrganization,
    ],
  );

  /**
   * Sort handler
   * @param type sort property
   */
  const handleSort = useCallback(
    (type) => {
      let nextSort;
      if (currentSort === 'down') nextSort = 'up';
      else if (currentSort === 'up') nextSort = 'default';
      else if (currentSort === 'default') nextSort = 'down';

      setCurrentSortType(type);
      setCurrentSort(nextSort);
    },
    [currentSort],
  );

  /**
   * Init table's options
   */
  const options = useMemo(
    () => ({
      searchPlaceholder: 'organizations.filter.search',
      serverSide: true,
      page,
      rowsPerPage: limit,
      count: +totalOrganizations,
      onChangePage: (currentPage) => {
        setPage(currentPage);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        const { page: offset, limit } = organizationFilter;
        const showedRows = offset + limit;
        const pagesLimit = Math.ceil(totalOrganizations / numberOfRows);
        if (showedRows <= numberOfRows && page !== 0) {
          const newPage = Math.floor(numberOfRows / showedRows) - 1;
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        } else if (showedRows > numberOfRows) {
          const newPage = Math.floor(showedRows / numberOfRows);
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        }
        setLimit(numberOfRows);
      },
      onSearchChange: (text) => {
        setSearchText(text);
      },
      searchText,
      searchAlwaysOpen: true,
    }),
    [page, limit, totalOrganizations, searchText, organizationFilter],
  );

  /**
   * Generates table rows
   */
  const createViewItemListForOrganizations = (dataToShow) =>
    dataToShow.map((item) => {
      const {
        id,
        totalsessions,
        monthlysessions,
        therapists,
        is_active: isActive,
        name,
        image,
        contact_telephone,
        contact_email: email,
        psylaris_care_overrule: careOverrule,
        emdr_remote_overrule: remoteOverrule,
      } = item;
      return {
        id: <DataCell>{id}</DataCell>,
        name: (
          <DataCell>
            <OrganizationImage orgName={name} img={image} />
          </DataCell>
        ),
        email: <DataCell>{email}</DataCell>,
        contact_telephone: (
          <DataCell type='numerics'>{contact_telephone}</DataCell>
        ),
        [t('forms.total.sessions_this_month')]: (
          <CustomMuiSessionsPerMonth
            sum={totalsessions || 0}
            monthSum={monthlysessions || 0}
          />
        ),
        [t('forms.total.therapists')]: (
          <DataCell type='numerics'>{therapists}</DataCell>
        ),
        [ACTIVE_KEY]: isActive,
        [PSYLARIS_CARE_OVERRULE_KEY]: careOverrule || false,
        [EMDR_REMOTE_OVERRULE_KEY]: remoteOverrule || false,
      };
    });

  /**
   * Generates export CSV data
   * @param data server data to transform
   */
  const generateExportData = (data) =>
    data.map((item) => {
      const {
        id,
        totalsessions,
        monthlysessions,
        therapists,
        is_active: isActive,
        name,
        contact_telephone,
        contact_email: email,
        psylaris_care_overrule: careOverrule,
        emdr_remote_overrule: remoteOverrule,
      } = item;
      return {
        id,
        name,
        email,
        contact_telephone,
        [t('forms.total.sessions_this_month')]: `${totalsessions || 0} (${
          monthlysessions || 0
        })`,
        [t('forms.total.therapists')]: therapists || 0,
        [ACTIVE_KEY]: isActive,
        [PSYLARIS_CARE_OVERRULE_KEY]: careOverrule || false,
        [EMDR_REMOTE_OVERRULE_KEY]: remoteOverrule || false,
      };
    });

  /**
   * Render talbe haed
   */
  const renderHead = useCallback(
    (key) => {
      let sortType = key;
      let label = t(`forms.${key}`);

      switch (key) {
        case 'email':
          sortType = 'contact_email';
          break;
        case THERAPISTS_KEY:
          sortType = 'therapists';
          label = key;
          break;
        case SESSIONS_KEY:
          sortType = 'totalsessions';
          label = key;
          break;
        case ACTIVE_KEY:
          sortType = 'is_active';
          label = key;
          break;
        case PSYLARIS_CARE_OVERRULE_KEY:
          sortType = 'psylaris_care_overrule';
          label = key;
          break;
        case EMDR_REMOTE_OVERRULE_KEY:
          sortType = 'emdr_remote_overrule';
          label = key;
          break;
        default:
          break;
      }

      return (
        <CustomDateSortHeader
          key={`sort-header-${key}`}
          currentSort={currentSortType === sortType ? currentSort : 'default'}
          label={label}
          type={sortType}
          handleSort={handleSort}
        />
      );
    },
    [
      ACTIVE_KEY,
      EMDR_REMOTE_OVERRULE_KEY,
      PSYLARIS_CARE_OVERRULE_KEY,
      SESSIONS_KEY,
      THERAPISTS_KEY,
      currentSort,
      currentSortType,
      handleSort,
      t,
    ],
  );

  /**
   * Remder talbe actions
   */
  const renderActions = useCallback(
    (value, tableMeta) => (
      <CustomMuiDataTableBodyButton
        firstButtonIcon={<EditIcon />}
        secondButtonIcon={<DeleteIcon />}
        additionalButtons={[
          <ButtonUi
            key='org-merge'
            isIconBtn
            className='table-action-btn primary merge-btn'
            size='small'
            onClickHandler={() =>
              mergeOrganization(tableMeta.rowData[0]?.props?.children)
            }
          >
            <CallMergeOutlined />
          </ButtonUi>,
        ]}
        onPrimaryActionButton={(e) =>
          editOrganization(e, tableMeta.rowData[0]?.props?.children)
        }
        onSecondaryActionButton={() =>
          onOpenDeleteModal(tableMeta.rowData[0]?.props?.children)
        }
      />
    ),
    [editOrganization, onOpenDeleteModal, mergeOrganization],
  );

  /**
   * Remder talbe toogles
   */
  const renderToogleActions = useCallback(
    (value, tableMeta, key) => (
      <CustomToggle
        hideLabel
        selectedValue={value}
        variable={{ id: tableMeta.rowData[0]?.props?.children }}
        handleVariableValueChanged={({ value: newValue, id }) =>
          handleToggleClicked(key, id, newValue)
        }
      />
    ),
    [handleToggleClicked],
  );

  /**
   * Generates table attributes
   */
  const createTableAttributes = (attributes, dataToShow) => {
    attributes = Object.keys(dataToShow[0]).map((key) => {
      const keysToHide = ['id'];
      const keysToSort = [
        'name',
        'email',
        'contact_name',
        'contact_telephone',
        'funds',
        'is_active',
        SESSIONS_KEY,
        THERAPISTS_KEY,
      ];
      if (keysToHide.includes(key)) {
        return {
          name: key,
          options: { display: false, sort: false },
        };
      }

      if (keysToSort.includes(key)) {
        return {
          name: key,
          options: {
            customHeadRender: () => renderHead(key),
          },
        };
      }

      if (TOGGLE_KEYS.includes(key)) {
        return {
          name: key,
          options: {
            display: true,
            customHeadRender: () => renderHead(key),
            customBodyRender: (value, tableMeta) =>
              renderToogleActions(value, tableMeta, key),
          },
        };
      }

      return {
        name: key,
        options: { sort: false },
      };
    });
    attributes.push({
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => renderActions(value, tableMeta),
      },
    });
    return attributes;
  };

  let dataToShow = organizations || [];
  let attributes = [];
  let exportData = [];

  if (dataToShow.length > 0) {
    exportData = generateExportData(dataToShow);
    dataToShow = createViewItemListForOrganizations(dataToShow);
    attributes = createTableAttributes(attributes, dataToShow);
  }

  /**
   * Generates the table filter's config
   */
  const filtersConfig = useTableFilterConfig('organization');

  /**
   * Closes the filter's modal and save data for the filters
   */
  const onSaveFilters = useCallback(
    (filters) => setTeamOrganizationFilters(filters),
    [],
  );

  /**
   * Checks does table have data
   */
  const isDataTableEmpty = dataToShow.length <= 0;

  /**
   * Flag to checks does filters active
   */
  const isFilters = Object.keys(organizationFilters || {})?.length > 0;

  /**
   * mui datatable optional class names
   */
  const muiTableMods = {
    loading: isLoading,
    'no-data': isDataTableEmpty,
    filters: isFilters,
  };

  /**
   * Reset page for search if it's not the first page and there is no results
   */
  useEffect(() => {
    const pagesLimit = Math.ceil(totalOrganizations / limit);
    if (page > pagesLimit && debouncedSearchTerm) setPage(0);
  }, [debouncedSearchTerm, limit, page, totalOrganizations]);

  /**
   * Reset page for existings filters if it's not the first page and there is no results
   */
  useEffect(() => {
    if (isFilters && dataToShow.length <= 0 && page > 0) setPage(0);
  }, [dataToShow.length, isFilters, page]);

  /**
   * Clears organizations on leaving the page
   */
  useEffect(() => () => clearOrganizations(), []);

  return (
    <React.Fragment key='organizations-page'>
      <Box className='organizations-wrapper'>
        <TableHeader
          buttonTitle='buttons.create.organization'
          title='organizations.title'
          subTitle='organizations.subtitle'
          onClickCallback={openModal}
          btnIcon={<AddOrganizationIcon />}
        />
        <div className='table-container'>
          <StyledEngineProvider injectFirst>
            <ThemeProvider
              theme={getMuiTheme(
                {},
                {
                  '&:nth-of-type(7)': {
                    width: 100,
                  },
                },
              )}
            >
              <MUIDataTable
                ref={tableRef}
                data={isLoading ? muiTableLoadingSkeleton('body') : dataToShow}
                columns={
                  isLoading ? muiTableLoadingSkeleton('header') : attributes
                }
                options={tableConfig(
                  options,
                  isLoading,
                  isDataTableEmpty,
                  tableRef,
                  isFilters,
                  onOpenFilterModal,
                  exportData,
                )}
                components={muiComponents(
                  organizationFilters,
                  setTeamOrganizationFilters,
                )}
                className={classNames(
                  'mui-data-table search-open',
                  muiTableMods,
                )}
                title=''
              />
            </ThemeProvider>
          </StyledEngineProvider>
        </div>
      </Box>
      {/* Merge organization modal */}
      <ModalUI
        modalSx={deleteConfirmation}
        open={mergeModalIsOpen}
        isCloseBtn={false}
        onCloseHandler={onCloseMergeModal}
        className='confirmation-modal merge-organization-modal'
        modalTitle={t('alerts.titles.merge-organization')}
      >
        <MergeOrganizationDialog
          closeModal={onCloseMergeModal}
          organizationFromMerge={organizationFromMerge}
        />
      </ModalUI>
      {/* Edit / create organization modal */}
      {modalIsOpen && (
        <FormModal
          isOpen={modalIsOpen}
          title={
            organizationToEdit
              ? t('buttons.edit.organization')
              : t('buttons.create.organization')
          }
          setOpen={setModalIsOpen}
          onClose={closeModal}
        >
          <CreateEditOrganizationForm
            closeModal={closeModal}
            organizationToEdit={organizationToEdit}
          />
        </FormModal>
      )}
      {/* Delete confirmation modal */}
      <ModalUI
        modalSx={deleteConfirmation}
        open={deleteModalIsOpen}
        isCloseBtn={false}
        onCloseHandler={onCloseDeleteModal}
        className='confirmation-modal'
        modalTitle={t('alerts.titles.delete')}
      >
        <SimpleModalContent
          content={['alerts.confirm.organization']}
          mainBtn='buttons.delete'
          onMainBtnClick={onDeleteOrganization}
          secondaryBtn='buttons.cancel'
          onSecondaryBtnClick={onCloseDeleteModal}
        />
      </ModalUI>
      {/* Filter modal */}
      {filterOpen && (
        <CustomMuiDataTableFilter
          isOpen={filterOpen}
          setFilterState={setFilterOpen}
          config={filtersConfig}
          filters={organizationFilters}
          onSaveHandler={onSaveFilters}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllOrganizations: organizationActions.getAllOrganizations,
  createOrganization: organizationActions.createOrganization,
  updateOrganization: organizationActions.updateOrganization,
  deleteOrganization: organizationActions.deleteOrganization,
  clearOrganizations: organizationActions.clearOrganizations,
  refreshShortOrganizations:
    organizationActions.getShortOrganizationsWithoutMetadata,
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
