import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { ReactComponent as CheckIcon } from '../../../../shared/assets/icons/check.svg';
import { classNames } from '../../../../utils';

export const LanguageSelectOptions = memo((props) => {
  const { isActive = false, option, onSelectOption = null } = props;

  /**
   * Select option click handler
   * Pass selected option
   */
  const selectOptionHandler = () => {
    if (onSelectOption) onSelectOption(option);
    return;
  };

  return (
    <Box
      className={classNames('option-wrapper', { 'active-lng': isActive }, [])}
      onClick={selectOptionHandler}
    >
      <li className="lng-option">
        {option?.Icon}
        <Typography variant="h6">{option?.label}</Typography>
        {isActive && <CheckIcon className="check" />}
      </li>
    </Box>
  );
});
