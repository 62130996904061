import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ANON_CLIENT_IDENTIFIER } from '../../../../utils/config';

/**
 * Sets session type and selected client
 * @param setSessionType sectionType setter
 * @param setAutocompleteValue autocompleteValue setter
 */
export const useFetchSessionType = (setSessionType, setAutocompleteValue) => {
  const { isAnonymousSession, selectedClient } = useSelector(
    (state) => state.createTreatmentWizard,
  );

  useEffect(() => {
    if (!isAnonymousSession && selectedClient) {
      setSessionType((prev) => ({
        ANONYMOUS: { ...prev.ANONYMOUS, checked: false },
        CLIENT: { ...prev.CLIENT, checked: true },
      }));
      if (selectedClient?.name !== ANON_CLIENT_IDENTIFIER) {
        setAutocompleteValue(() => ({ text: selectedClient.name, value: selectedClient.id }));
      }
    } else if (isAnonymousSession && selectedClient) {
      setSessionType((prev) => ({
        ANONYMOUS: { ...prev.ANONYMOUS, checked: true },
        CLIENT: { ...prev.CLIENT, checked: false },
      }));
      setAutocompleteValue(null);
    }
  }, [isAnonymousSession, selectedClient, setSessionType, setAutocompleteValue]);
};
