import { getI18n } from 'react-i18next';
import { ANON_CLIENT_IDENTIFIER } from './config';

const translateClientName = (name) => {
  if (name === ANON_CLIENT_IDENTIFIER) {
    return getI18n().t('treatments.anon-client-name');
  }
  return name;
};

export default translateClientName;
