import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../hooks/useActions';
import { wizardErrors } from '../wizardErrors';

/**
 * Set error for Therapy location step
 */
export const useWizardTherapyError = () => {
  const { location } = useSelector((state) => state.createTreatmentWizard);
  const { setError } = useActions();

  useEffect(() => {
    if (!location) setError(wizardErrors.THERAPY_TYPE);
    else setError(null);
    return () => {
      setError(null);
    };
  }, [setError, location, wizardErrors]);
};
