import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const autocompleteStyles = {
  autocompleteBox: {
    transition: '.8s all ease',
    '& .MuiInputBase-root': {
      borderRadius: theme.palette.propVars.borderRadius,
      background: theme.palette.myPrimary.white,
      color: theme.palette.neutral.grey70,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.neutral.greyRGBA80,
      left: getResponsiveSize(12), // 12px
      fontSize: getResponsiveSize(14), // 14px
      transform: `translate(${getResponsiveSize(14)}, ${getResponsiveSize(
        16,
      )}) scale(1)`,
      '&.Mui-focused': {
        left: '0',
        color: theme.palette.neutral.grey80,
        transform: `translate(${getResponsiveSize(
          14,
        )}, calc(${getResponsiveSize(9)}* -1)) scale(0.75)`,
      },
      '&.MuiFormLabel-filled': {
        left: 0,
        transform: `translate(${getResponsiveSize(
          14,
        )}, calc(${getResponsiveSize(9)}* -1)) scale(0.75)`,
      },
    },
    '& input': {
      pl: `${getResponsiveSize(18)} !important`, // 18px
      py: `${getResponsiveSize(7.5)} !important`, // 7.5px
      pr: `${getResponsiveSize(4)} !important`, // 4px
      height: getResponsiveSize(20), // 20px
      fontSize: `${theme.typography.fs14} !important`, // 14px
    },
    '& fieldset': {
      borderColor: theme.palette.myPrimary.lightBlue100,
    },
    '& .MuiOutlinedInput-root': {
      p: `${getResponsiveSize(9)} !important`, // 9px
      pr: `${getResponsiveSize(39)} !important`, // 39px
      fontSize: getResponsiveSize(14), // 14px
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.myPrimary.lightBlue100,
        },
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      right: `${getResponsiveSize(20)} !important`, // 20px
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator': {
        width: getResponsiveSize(38), // 38px
        height: getResponsiveSize(38), // 38px
        fontSize: theme.typography.fs19, // 19px
        p: getResponsiveSize(2), // 2px
        mr: `calc(${getResponsiveSize(2)} * -1)`, // -2px
        ...theme.palette.propVars.easeInOutTransition,
      },
      '& .MuiAutocomplete-clearIndicator svg': {
        width: getResponsiveSize(20), // 20px
        height: getResponsiveSize(20), // 20px
        fontSize: getResponsiveSize(20), // 20px
      },
      '& .MuiAutocomplete-popupIndicator': {
        color: theme.palette.myPrimary.lightBlue70,
      },
    },
    '&:hover': {
      ...theme.palette.propVars.btnBoxShadow,
    },
    '&.Mui-focused': {
      '& .MuiFormLabel-root': {
        left: 0,
      },
      '& input': {
        pl: `${getResponsiveSize(18)} !important`, // 18px
        color: theme.palette.neutral.grey70,
      },
      '& fieldset': {
        borderColor: `${theme.palette.myPrimary.lightBlue100} !important`,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      width: getResponsiveSize(325, laptopRatio), // 325px
      '& .MuiFormLabel-root': {
        color: theme.palette.neutral.greyRGBA80,
        left: getResponsiveSize(12, laptopRatio), // 12px
        fontSize: getResponsiveSize(14, laptopRatio), // 14px
        transform: `translate(${getResponsiveSize(
          14,
          laptopRatio,
        )}, ${getResponsiveSize(16, laptopRatio)}) scale(1)`,
        '&.Mui-focused': {
          transform: `translate(${getResponsiveSize(
            14,
            laptopRatio,
          )}, calc(${getResponsiveSize(9, laptopRatio)}* -1)) scale(0.75)`,
        },
        '&.MuiFormLabel-filled': {
          transform: `translate(${getResponsiveSize(
            14,
            laptopRatio,
          )}, calc(${getResponsiveSize(9, laptopRatio)}* -1)) scale(0.75)`,
        },
      },
      '& input': {
        pl: `${getResponsiveSize(18, laptopRatio)} !important`, // 18px
        py: `${getResponsiveSize(7.5, laptopRatio)} !important`, // 7.5px
        pr: `${getResponsiveSize(4, laptopRatio)} !important`, // 4px
        height: getResponsiveSize(16, laptopRatio), // 16px
        fontSize: `${getResponsiveSize(14, laptopRatio)} !important`, // 14px
      },
      '& .MuiOutlinedInput-root': {
        p: `${getResponsiveSize(9, laptopRatio)} !important`, // 9px
        pr: `${getResponsiveSize(39, laptopRatio)} !important`, // 39px
        fontSize: getResponsiveSize(14, laptopRatio), // 14px
      },
      '& .MuiAutocomplete-endAdornment': {
        right: `${getResponsiveSize(16, laptopRatio)} !important`, // 16px
        '& .MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator': {
          width: getResponsiveSize(28, laptopRatio), // 28px
          height: getResponsiveSize(28, laptopRatio), // 28px
          fontSize: getResponsiveSize(18, laptopRatio), // 18px
          p: 0.25,
          mr: -0.25,
          ...theme.palette.propVars.easeInOutTransition,
        },
        '& .MuiAutocomplete-clearIndicator svg': {
          width: getResponsiveSize(16, laptopRatio), // 16px
          height: getResponsiveSize(16, laptopRatio), // 16px
          fontSize: getResponsiveSize(16, laptopRatio), // 16px
        },
        '& .MuiAutocomplete-popupIndicator svg': {
          width: getResponsiveSize(16, laptopRatio), // 16px
          height: getResponsiveSize(16, laptopRatio), // 16px
          fontSize: getResponsiveSize(16, laptopRatio), // 16px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      width: '100%',
      '& .MuiInputBase-root': {
        borderRadius: getResponsiveSize(8, mobileRatio), // 8px
      },
      '& .MuiFormLabel-root': {
        left: getResponsiveSize(12, mobileRatio), // 12px
        fontSize: getResponsiveSize(13, mobileRatio), // 13px
        transform: `translate(${getResponsiveSize(
          14,
          mobileRatio,
        )}, ${getResponsiveSize(12, mobileRatio)}) scale(1)`,
        '&.Mui-focused': {
          transform: `translate(${getResponsiveSize(
            14,
            mobileRatio,
          )}, calc(${getResponsiveSize(9, mobileRatio)}* -1)) scale(0.75)`,
        },
        '&.MuiFormLabel-filled': {
          transform: `translate(${getResponsiveSize(
            14,
            mobileRatio,
          )}, calc(${getResponsiveSize(9, mobileRatio)}* -1)) scale(0.75)`,
        },
      },
      '& input': {
        pl: `${getResponsiveSize(16, mobileRatio)} !important`, // 16px
        py: `${getResponsiveSize(5.5, mobileRatio)} !important`, // 7.5px
        pr: `${getResponsiveSize(4, mobileRatio)} !important`, // 4px
        height: getResponsiveSize(13, mobileRatio), // 13px
        fontSize: `${getResponsiveSize(13, mobileRatio)} !important`, // 13px
      },
      '& .MuiOutlinedInput-root': {
        p: `${getResponsiveSize(9, mobileRatio)} !important`, // 9px
        pr: `${getResponsiveSize(39, mobileRatio)} !important`, // 39px
        fontSize: getResponsiveSize(13, mobileRatio), // 13px
      },
      '& .MuiAutocomplete-endAdornment': {
        right: `${getResponsiveSize(16, mobileRatio)} !important`, // 16px
        '& .MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator': {
          width: getResponsiveSize(28, mobileRatio), // 28px
          height: getResponsiveSize(28, mobileRatio), // 28px
          fontSize: getResponsiveSize(18, mobileRatio), // 18px
          p: 0.25,
          mr: -0.25,
          visibility: 'visible',
          ...theme.palette.propVars.easeInOutTransition,
        },
        '& .MuiAutocomplete-clearIndicator svg': {
          width: getResponsiveSize(16, mobileRatio), // 16px
          height: getResponsiveSize(16, mobileRatio), // 16px
          fontSize: getResponsiveSize(16, mobileRatio), // 16px
        },
        '& .MuiAutocomplete-popupIndicator svg': {
          width: getResponsiveSize(16, mobileRatio), // 16px
          height: getResponsiveSize(16, mobileRatio), // 16px
          fontSize: getResponsiveSize(16, mobileRatio), // 16px
        },
      },
    },
  },
};
