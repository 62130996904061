import { getI18n } from 'react-i18next';
import { statisticsService } from '../../services/statistics';
import { dashboardConstants } from '../constants/dashboard.constants';
import { alertActions } from './alert.actions';

export const dashboardActions = {
  getAllStatistics,
};

function getAllStatistics(filter) {
  return (dispatch) => {
    dispatch(request());
    return statisticsService.getAllStatistics(filter).then(
      (result) => {
        dispatch(success(result.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.stats')));
      },
    );
  };

  function request() {
    return { type: dashboardConstants.GETALL_REQUEST };
  }
  function success(stats) {
    return { type: dashboardConstants.GETALL_SUCCESS, stats };
  }
  function failure(error) {
    return { type: dashboardConstants.GETALL_FAILURE, error };
  }
}
