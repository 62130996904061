import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}`;

const getAllDevices = () =>
  axios.get(
    `${baseURL}/devices${authUrl()}&filter[include]=deviceprofile&filter[where][is_deleted]=false`,
  );

const getDeviceProfiles = () =>
  axios.get(`${baseURL}/deviceprofiles${authUrl()}`);

const getDeviceNames = () =>
  axios.get(
    `${baseURL}/devices${authUrl()}&filter[fields][name]=true&filter[where][is_deleted]=false`,
  );

const getDeviceGeneratedUUIDs = () =>
  axios.get(
    `${baseURL}/devices${authUrl()}&filter[fields][generated_UUID]=true&filter[where][is_deleted]=false`,
  );

const getDevicesOverview = (
  offset,
  limit,
  order,
  orgId = null,
  searchQuery = '',
  generated_uuid = null,
  deviceType = null,
  monthlySession = null,
  showDeleted = null,
) => {
  const organizationId = orgId ? `&organizationId=${orgId}` : '';
  const serialNumber = generated_uuid
    ? `&generated_uuid=${generated_uuid}`
    : '';
  const type = deviceType ? `&type=${deviceType}` : '';
  const monthly_sessions = monthlySession
    ? `&monthly_sessions=${monthlySession}`
    : '';
  const show_deleted = showDeleted ? `&show_deleted=${showDeleted}` : '';
  const filterString = `&offset=${offset}&limit=${limit}&order=${order}&query=${searchQuery}${organizationId}${serialNumber}${type}${monthly_sessions}${show_deleted}`;
  return axios.get(`${baseURL}/devices/overview${authUrl()}${filterString}`);
};

const getDevicesByOrganizationId = (organizationId) => {
  const filterString = `&organizationId=${organizationId}`;
  return axios.get(`${baseURL}/devices/overview${authUrl()}${filterString}`);
};

export const getDevicesByUUID = (generated_uuid) => {
  const filter = `&filter[where][generated_UUID]=${generated_uuid}&filter[where][is_deleted]=false`;
  return axios.get(`${baseURL}/devices${authUrl()}${filter}`);
};

export const getDevicesByUUIDs = (generated_uuids) => {
  const inqFilter = generated_uuids
    .map((uuid) => `&filter[where][generated_UUID][inq]=${uuid}`)
    .join('');
  const filter = `${inqFilter}&filter[where][is_deleted]=false`;
  return axios.get(`${baseURL}/devices${authUrl()}${filter}`);
};

const createDevice = (device) => {
  const { name, device_profile_fk, organizationId, generated_UUID } = device;

  return axios.post(`${baseURL}/devices${authUrl()}`, {
    name,
    device_profile_fk,
    organizationId,
    generated_UUID,
  });
};

const updateDevice = (device) => {
  const { id } = device;
  delete device.id;
  return axios.patch(`${baseURL}/devices/${id}${authUrl()}`, device);
};

const updateFewDevices = (devices) => {
  const updateRequests = devices.map((device) => updateDevice(device));
  return Promise.all(updateRequests);
};

const getDeviceById = (id) =>
  axios.get(
    `${baseURL}/devices/${id}${authUrl()}&filter[include]=deviceprofile`,
  );

const deleteDeviceById = (id) => {
  if (!id) return;
  return updateDevice({ id, is_deleted: true });
};

const getDevicesCount = (filter = '&where[is_deleted]=false') =>
  axios.get(`${baseURL}/devices/count${authUrl()}${filter}`);

export const deviceService = {
  getAllDevices,
  getDeviceNames,
  getDeviceGeneratedUUIDs,
  getDevicesByOrganizationId,
  getDeviceProfiles,
  createDevice,
  updateDevice,
  updateFewDevices,
  getDeviceById,
  deleteDeviceById,
  getDevicesOverview,
  getDevicesCount,
};
