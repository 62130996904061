import { InputAdornment, TextField } from '@mui/material';
import { memo } from 'react';
import { classNames } from '../../../utils';
import { ButtonUi } from '../Button/Button';
import { inputStyle } from './style';

export const InputUi = memo((props) => {
  const {
    label,
    className,
    value,
    variant = 'outlined',
    onInputChangeHandler,
    onInputFocusHandler,
    onInputBlurHandler,
    name,
    required,
    endButton = false,
    btnText,
    btnVariant,
    btnClassName,
    btnType = 'input',
    btnSize = 'small',
    btnDisabled,
    btnClickHandler,
  } = props;
  const { input } = inputStyle;

  /**
   * Input on change handler
   */
  const onChangeHandler = (event) => {
    onInputChangeHandler(event.target.value);
  };

  return (
    <TextField
      sx={input}
      className={className}
      id="outlined-basic"
      name={name}
      required={required}
      label={label}
      variant={variant}
      value={value}
      onChange={onChangeHandler}
      onFocus={onInputFocusHandler}
      onBlur={onInputBlurHandler}
      InputProps={{
        endAdornment: endButton && (
          <InputAdornment position="end">
            <ButtonUi
              disabled={btnDisabled}
              className={classNames('input-btn', {}, [btnClassName])}
              variant={btnVariant}
              type={btnType}
              size={btnSize}
              onClickHandler={btnClickHandler}
            >
              {btnText}
            </ButtonUi>
          </InputAdornment>
        ),
      }}
    />
  );
});
