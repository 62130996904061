import { mobileRatio, theme } from '../../theme';
import { getResponsiveSize } from '../../utils';

export const practitionerFormStyle = {
  practitionerForm: {
    mr: getResponsiveSize(6), // 6px
    '& .form-wrapper': {
      py: getResponsiveSize(31.5), // 31.5px
      pl: getResponsiveSize(36), // 36px
      pr: getResponsiveSize(30), // 36px
      gap: getResponsiveSize(18), // 18px
      height: 'auto',
      maxHeight: '56dvh',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: getResponsiveSize(6), // 6px
      },
      '&::-webkit-scrollbar-track': {
        margin: `${getResponsiveSize(8)} auto`, // 8px auto
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '100px',
        background: theme.palette.myPrimary.lightBlue20,
      },
    },
    '& .organization-select': {
      width: '100%',
    },
    '& .modal-footer': {
      width: `calc(100% + ${getResponsiveSize(6)})`,
      justifyContent: 'flex-end',
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '& .form-wrapper': {
        gap: getResponsiveSize(12, mobileRatio), // 12px
      },
    },
  },
};
