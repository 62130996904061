import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const customMuiDatatableSearch = {
  search: {
    '& .MuiInputBase-root': {
      borderRadius: getResponsiveSize(12), // 12px
      pl: getResponsiveSize(10), // 10px
      pr: getResponsiveSize(8), // 8px
      background: theme.palette.myPrimary.white,
      '& .MuiInputBase-input': {
        py: getResponsiveSize(16.5), // 16.5px
        height: getResponsiveSize(16), // 16px
        color: theme.palette.neutral.grey70,
        fontSize: `${getResponsiveSize(14)} !important`, // 14px
        background: `${theme.palette.myPrimary.white} !important`,
      },
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 100px ${theme.palette.myPrimary.white} inset`,
        },
      },
      '& fieldset': {
        border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.myPrimary.lightBlue100,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.myPrimary.lightBlue100,
        },
      },
      '& .MuiInputAdornment-root svg': {
        color: theme.palette.myPrimary.lightBlue70,
      },
      '& .MuiButtonBase-root': {
        color: theme.palette.myPrimary.lightBlue70,
        p: getResponsiveSize(8), // 8px
      },
      '& .MuiInputAdornment-root, .MuiButtonBase-root': {
        '& svg': {
          width: getResponsiveSize(24), // 24px
          height: getResponsiveSize(24), // 24px
          fontSize: theme.typography.fs24, // 24px
          minHeight: 22,
          minWidth: 22,
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .MuiInputBase-root': {
        borderRadius: getResponsiveSize(12, laptopRatio), // 12px
        pl: getResponsiveSize(10, laptopRatio), // 10px
        pr: getResponsiveSize(8, laptopRatio), // 8px
        '& .MuiInputBase-input': {
          py: getResponsiveSize(14.5, laptopRatio), // 14.5px
          height: getResponsiveSize(14, laptopRatio), // 14px
          fontSize: `${getResponsiveSize(14, laptopRatio)} !important`, // 14px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '& .MuiInputBase-root': {
        borderRadius: getResponsiveSize(8, mobileRatio), // 8px
        pl: getResponsiveSize(10, mobileRatio), // 10px
        pr: getResponsiveSize(8, mobileRatio), // 8px
        '& .MuiInputBase-input': {
          py: getResponsiveSize(12.5, mobileRatio), // 12.5px
          height: getResponsiveSize(12, mobileRatio), // 12px
          fontSize: `${getResponsiveSize(12, mobileRatio)} !important`, // 12px
        },
      },
    },
  },
};
