export const organizationConstants = {
  GETALL_REQUEST: 'ORGANIZATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'ORGANIZATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'ORGANIZATIONS_GETALL_FAILURE',

  GETSHORT_REQUEST: 'ORGANIZATIONS_GETSHORT_REQUEST',
  GETSHORT_SUCCESS: 'ORGANIZATIONS_GETSHORT_SUCCESS',
  GETSHORT_FAILURE: 'ORGANIZATIONS_GETSHORT_FAILURE',

  GET_REQUEST: 'ORGANIZATION_GET_REQUEST',
  GET_SUCCESS: 'ORGANIZATION_GET_SUCCESS',
  GET_FAILURE: 'ORGANIZATION_GET_FAILURE',

  CREATE_REQUEST: 'CREATE_ORGANIZATION_REQUEST',
  CREATE_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_FAILURE: 'CREATE_ORGANIZATION_FAILURE',

  UPDATE_REQUEST: 'UPDATE_ORGANIZATION_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_ORGANIZATION_FAILURE',

  MERGE_REQUEST: 'MERGE_ORGANIZATION_REQUEST',
  MERGE_SUCCESS: 'MERGE_ORGANIZATION_SUCCESS',
  MERGE_FAILURE: 'MERGE_ORGANIZATION_FAILURE',

  DELETE_REQUEST: 'DELETE_ORGANIZATION_REQUEST',
  DELETE_SUCCESS: 'DELETE_ORGANIZATION_SUCCESS',
  DELETE_FAILURE: 'DELETE_ORGANIZATION_FAILURE',

  COUNT_REQUEST: 'ORGANIZATIONS_COUNT_REQUEST',
  COUNT_SUCCESS: 'ORGANIZATIONS_COUNT_SUCCESS',
  COUNT_FAILURE: 'ORGANIZATIONS_COUNT_FAILURE',

  CLEAR_SELECTED_ORGANIZATION: 'CLEAR_SELECTED_ORGANIZATION',
  CLEAR_ORGANIZATIONS: 'CLEAR_ORGANIZATIONS',
};
