import React from 'react';
import Select from 'react-select';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { productActions } from '../store/actions/product.actions';

class ProductSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: this.props.selectedId,
      label: 'products.title',
    };
  }

  componentDidMount() {
    this.props.getAllProductConfigurations();
  }

  render() {
    const { products, selectedId, i18n, disabled } = this.props;
    const options = products.products.map((item) => ({
      value: item.id,
      label: item.name,
      variables: item.variables,
      device_settings_needed: item.device_settings_needed,
    }));
    let value = null;
    if (selectedId) {
      value = options.find((item) => item.value === selectedId);
      this.props.handleChange(value);
    } else {
      value = options[0];
      this.props.handleChange(options[0]);
    }

    return (
      <>
        <label className='select-label'>
          {this.props.label || i18n.t(this.state.label)}
        </label>
        <Select
          className='select-filter'
          options={options}
          isClearable
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          placeholder={
            this.props.products.isLoading
              ? i18n.t('products.loading')
              : i18n.t('products.select')
          }
          isDisabled={this.props.products.isLoading || disabled}
          onChange={this.props.handleChange}
          value={value}
          inputProps={{
            name: 'product',
            id: 'select-product',
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllProductConfigurations: productActions.getAllProductConfigurations,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProductSelect),
);
