import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const actionButtonsStyle = {
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    pr: getResponsiveSize(11), // 11px,
    gap: getResponsiveSize(8), // 8px,
    zIndex: '1',
    '& .table-action-btn': {
      backgroundColor: 'transparent',
      p: getResponsiveSize(5), // 5px
      height: getResponsiveSize(38), // 38px
      width: getResponsiveSize(38), // 38px
      minWidth: 'unset',
      borderRadius: getResponsiveSize(8), // 8px
      color: theme.palette.myPrimary.white,
      transition:
        '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',
      '& svg': {
        width: getResponsiveSize(20), // 20px
        height: getResponsiveSize(20), // 20px
        minWidth: 18,
        minHeight: 18,
        stroke: theme.palette.myPrimary.lightBlue100,
        transition: '.45s all cubic-bezier(0.4, 0, 0.2, 1)',
        '&.edit': {
          strokeWidth: '2',
        },
        '&.delete': {
          stroke: 'none',
          fill: theme.palette.myPrimary.lightBlue100,
        },
        '&.restore': {
          width: 'auto',
          height: 'auto',
          fill: theme.palette.myPrimary.lightBlue100,
          stroke: 'none',
          transform: 'scale(1.3)',
        },
        '&.play-icon': {
          width: getResponsiveSize(28), // 28px,
          height: getResponsiveSize(28), // 28px,
          minWidth: 24,
          minHeight: 24,
          transform: 'scale(1.3)',
          path: {
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: getResponsiveSize(24), // 24px,
          },
        },
      },
      '& .MuiTouchRipple-ripple': {
        transform: 'scale(1.5)',
        width: '150% !important',
        left: '-25% !important',
        '& .MuiTouchRipple-child': {
          backgroundColor: 'currentcolor',
        },
      },
      '&.primary': {
        '&:hover': {
          backgroundColor: theme.palette.myPrimary.lightBlue100,
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
          color: theme.palette.myPrimary.white,
          '& svg': {
            stroke: theme.palette.myPrimary.white,
            '&.restore': {
              fill: theme.palette.myPrimary.white,
              transition: '.45s all cubic-bezier(0.4, 0, 0.2, 1)',
            },
          },
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      '&.secondary': {
        '&:hover': {
          backgroundColor: theme.palette.myPrimary.lightGrey,
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
          border: `1px solid ${theme.palette.neutral.errorDark}`,
          color: theme.palette.myPrimary.lightBlue100,
          '& svg': {
            fill: theme.palette.neutral.errorDark,
          },
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      '&.merge-btn': {
        '& svg': {
          width: getResponsiveSize(25), // 20px
          height: getResponsiveSize(25), // 20px
          minWidth: 23,
          minHeight: 23,
          fill: theme.palette.myPrimary.lightBlue100,
          stroke: 'none',
        },
        '&:hover svg': {
          fill: theme.palette.myPrimary.white,
          stroke: 'none',
        },
      },
    },
    // desktop XXL to desktop XL screen
    [theme.breakpoints.media.desktopXXLToDesktopXL]: {
      '& .table-action-btn svg.play-icon path': {
        strokeWidth: getResponsiveSize(14), // 14px,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      pr: getResponsiveSize(8, laptopRatio), // 8px,
      gap: getResponsiveSize(8, laptopRatio), // 8px,
      '& .table-action-btn': {
        p: getResponsiveSize(4, laptopRatio), // 4px
        height: getResponsiveSize(32, laptopRatio), // 32px
        width: getResponsiveSize(32, laptopRatio), // 32px
        borderRadius: getResponsiveSize(8, laptopRatio), // 8px
        '& svg.play-icon': {
          path: {
            strokeWidth: getResponsiveSize(24, laptopRatio), // 24px,
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      pr: 0,
      gap: getResponsiveSize(8, mobileRatio), // 8px,
      '& .btn-wrapper ': {
        width: '50%',
        '& .table-action-btn': {
          width: '100%',
          p: getResponsiveSize(8, mobileRatio), // 4px
          height: getResponsiveSize(32, mobileRatio), // 32px
          borderRadius: getResponsiveSize(8, mobileRatio), // 8px
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
          '&.primary': {
            backgroundColor: theme.palette.myPrimary.lightBlue100,
            color: theme.palette.myPrimary.white,
            '& svg': {
              stroke: theme.palette.myPrimary.white,
            },
          },
          '&.secondary': {
            backgroundColor: theme.palette.myPrimary.lightGrey,
            border: `1px solid ${theme.palette.neutral.errorDark}`,
            color: theme.palette.myPrimary.lightBlue100,
            '& svg': {
              fill: theme.palette.neutral.errorDark,
            },
          },
          '&.merge-btn svg': {
            fill: theme.palette.myPrimary.white,
          },
          '& svg.play-icon': {
            path: {
              strokeWidth: getResponsiveSize(24, mobileRatio), // 24px,
            },
          },
        },
      },
    },
  },
};
