import React, { memo, useMemo } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';

import { durationStyle } from './style';

export const DurationTransformer = memo(({ duration }) => {
  const { container } = durationStyle;

  const transformDuration = useMemo(() => {
    if (duration) {
      const partsOfTime = duration
        .split(':')
        .map((component) => component.trim());
      const hours = partsOfTime[0];
      const minutes = partsOfTime[1];
      const seconds = partsOfTime[2];
      return (
        <Box className='time'>
          {hours}
          <Typography className='time-symbols'>h</Typography>
          {minutes}
          <Typography className='time-symbols'>m</Typography>
          {seconds}
          <Typography className='time-symbols'>s</Typography>
        </Box>
      );
    } else {
      return <SvgIcon color='disabled' component={TimerOffOutlinedIcon} />;
    }
  }, [duration]);

  return <Box sx={container}>{transformDuration}</Box>;
});
