import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { clientActions } from '../../store/actions/client.actions';
import { teamActions } from '../../store/actions/team.actions';

import { TeamSelect } from '../Teams/TeamSelect';
import { createClientFormStyle } from './style';
import { TextFieldUi } from '../UI/TextField/TextField';
import { ButtonUi } from '../UI/Button/Button';

const CreateClientForm = (props) => {
  const { getAllTeams, teams, createClient, closeModal, onSuccess } = props;
  const { createClientForm } = createClientFormStyle;
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [teamError, setTeamError] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState();

  const teamsToShow = teams?.teams || [];
  const showTeamErrorMessage = submitted && teamError;

  /**
   * Sets value to the name textfield and reset submitted form state
   * @param event native event
   */
  const onNameInputChange = useCallback((event) => {
    setName(event.target.value);
    setSubmitted(false);
  }, []);

  /**
   * Sets value to the autocomplete
   * @param value selected value
   */
  const onSelect = useCallback((value) => {
    setSubmitted(false);
    if (!value || !value.value) {
      setSelectedTeamId(null);
      return;
    }
    setSelectedTeamId(value.value);
  }, []);

  /**
   * Creates client on form submition
   * @param event native submit event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedTeamId && teamsToShow?.length > 1) {
      setSubmitted(true);
      setTeamError(t('errors.select.team'));
      return;
    }
    const actualTeam = teamsToShow[0];
    const createdClient = await createClient({
      name,
      teamId: selectedTeamId || actualTeam.id,
    });
    if (onSuccess) {
      onSuccess(createdClient);
    }
    closeModal();
  };

  /**
   * Fetches all teams if they are not defined
   */
  useEffect(() => {
    getAllTeams();
  }, [getAllTeams]);

  return (
    <Box
      component='form'
      className='create-form validate-form'
      onSubmit={handleSubmit}
    >
      <Grid container sx={createClientForm}>
        {teamsToShow.length > 1 && (
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <TeamSelect handleChange={onSelect} selectedId={selectedTeamId} />
              {showTeamErrorMessage && (
                <FormHelperText error>{teamError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.client_name_with_example')}
            autoFocus
            name='name'
            fullWidth
            required
            value={name}
            handleChange={onNameInputChange}
          />
        </Grid>
      </Grid>
      <Box className='modal-footer justify-end'>
        <ButtonUi
          className='action-btn accept'
          variant='contained'
          type='primary'
          size='small'
          btnType='submit'
        >
          {t('buttons.create.client')}
        </ButtonUi>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = {
  createClient: clientActions.createClient,
  getAllTeams: teamActions.getAllTeamsWithoutMeta,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientForm);
