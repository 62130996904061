import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const textFieldStyle = {
  textField: {
    '&.loading-skeleton': {
      width: '100%',
      height: getResponsiveSize(53),
      borderRadius: getResponsiveSize(12), // 12px
      backgroundColor: theme.palette.myPrimary.darkBlueRGBA50,
      border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
    },
    '& .MuiFormLabel-root': {
      fontSize: getResponsiveSize(14), // 14px
      color: theme.palette.neutral.greyRGBA80,
      pl: getResponsiveSize(12), // 12px
      transform: `translate(${getResponsiveSize(14)}, ${getResponsiveSize(
        16,
      )}) scale(1)`,
      '&.Mui-focused': {
        p: 0,
        color: theme.palette.neutral.grey80,
        transform: `translate(${getResponsiveSize(
          14,
        )}, calc(${getResponsiveSize(9)}* -1)) scale(0.75)`,
      },
      '&.MuiFormLabel-filled': {
        p: 0,
        transform: `translate(${getResponsiveSize(
          14,
        )}, calc(${getResponsiveSize(9)}* -1)) scale(0.75)`,
      },
    },
    '& .MuiInputBase-root': {
      borderRadius: getResponsiveSize(12), // 12px
      pl: getResponsiveSize(9), // 9px
      pr: getResponsiveSize(8), // 8px
      background: theme.palette.myPrimary.white,
      '& .MuiInputBase-input': {
        py: getResponsiveSize(16.5), // 16.5px
        pl: getResponsiveSize(18), // 18px
        pr: getResponsiveSize(4), // 4px
        height: getResponsiveSize(20), // 20px
        color: theme.palette.neutral.grey70,
        fontSize: `${theme.typography.fs14} !important`, // 14px
        background: `${theme.palette.myPrimary.white} !important`,
      },
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 100px ${theme.palette.myPrimary.white} inset`,
        },
      },
      '& fieldset': {
        border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
        '& legend': {
          fontSize: getResponsiveSize(10.5),
        },
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.myPrimary.lightBlue100,
        },
      },
      '&.Mui-focused,': {
        '& fieldset': {
          borderColor: theme.palette.myPrimary.lightBlue100,
        },
      },

      '& .MuiInputAdornment-root svg': {
        color: theme.palette.myPrimary.lightBlue70,
      },
      '& .MuiButtonBase-root': {
        color: theme.palette.myPrimary.lightBlue70,
        p: getResponsiveSize(8), // 8px
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      height: getResponsiveSize(48, laptopRatio), // 48px
      borderRadius: getResponsiveSize(12, laptopRatio), // 12px
      '&.loading-skeleton': {
        height: getResponsiveSize(48, laptopRatio), // 48px
        borderRadius: getResponsiveSize(12, laptopRatio), // 12px
      },
      '& .MuiFormLabel-root': {
        fontSize: getResponsiveSize(13, laptopRatio), // 13px
        pl: getResponsiveSize(12, laptopRatio), // 12px
        lineHeight: getResponsiveSize(26, laptopRatio), // 26px
        '&.Mui-focused': {
          transform: `translate(${getResponsiveSize(
            14,
            laptopRatio,
          )}, calc(${getResponsiveSize(6, laptopRatio)} * -1)) scale(0.9)`,
          lineHeight: 1,
        },
        '&.MuiFormLabel-filled': {
          transform: `translate(${getResponsiveSize(
            14,
            laptopRatio,
          )}, calc(${getResponsiveSize(6, laptopRatio)} * -1)) scale(0.9)`,
          lineHeight: 1,
        },
      },
      '& .MuiInputBase-root': {
        borderRadius: getResponsiveSize(12, laptopRatio), // 12px
        pl: getResponsiveSize(9, laptopRatio), // 9px
        pr: getResponsiveSize(8, laptopRatio), // 8px
        '& .MuiInputBase-input': {
          py: getResponsiveSize(14, laptopRatio), // 14px
          pl: getResponsiveSize(16, laptopRatio), // 16px
          pr: getResponsiveSize(4, laptopRatio), // 4px
          height: getResponsiveSize(20, laptopRatio), // 20px
          fontSize: `${getResponsiveSize(13, laptopRatio)}!important`, // 13px
        },
        '& fieldset legend': {
          fontSize: getResponsiveSize(10.5, laptopRatio),
          '& span': {
            mr: getResponsiveSize(10, laptopRatio), // 10px
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      height: getResponsiveSize(42, mobileRatio), // 42px
      borderRadius: getResponsiveSize(12, mobileRatio), // 12px
      '&.loading-skeleton': {
        height: getResponsiveSize(42, mobileRatio), // 42px
        borderRadius: getResponsiveSize(12, mobileRatio), // 12px
      },
      '& .MuiFormLabel-root': {
        fontSize: getResponsiveSize(13, mobileRatio), // 13px
        pl: getResponsiveSize(12, mobileRatio), // 12px
        lineHeight: getResponsiveSize(26, mobileRatio), // 26px
        '&.Mui-focused': {
          transform: `translate(${getResponsiveSize(
            14,
            mobileRatio,
          )}, calc(${getResponsiveSize(6, mobileRatio)} * -1)) scale(0.9)`,
          lineHeight: 1,
        },
        '&.MuiFormLabel-filled': {
          transform: `translate(${getResponsiveSize(
            14,
            mobileRatio,
          )}, calc(${getResponsiveSize(6, mobileRatio)} * -1)) scale(0.9)`,
          lineHeight: 1,
        },
      },
      '& .MuiInputBase-root': {
        borderRadius: getResponsiveSize(8, mobileRatio), // 8px
        pl: getResponsiveSize(9, mobileRatio), // 9px
        pr: getResponsiveSize(8, mobileRatio), // 8px
        '& .MuiInputBase-input': {
          py: getResponsiveSize(12, mobileRatio), // 12px
          pl: getResponsiveSize(16, mobileRatio), // 16px
          pr: getResponsiveSize(4, mobileRatio), // 4px
          height: getResponsiveSize(18, mobileRatio), // 18px
          fontSize: `${getResponsiveSize(13, mobileRatio)}!important`, // 13px
        },
        '& fieldset legend': {
          fontSize: getResponsiveSize(10.5, mobileRatio),
          '& span': {
            mr: getResponsiveSize(10, mobileRatio), // 10px
          },
        },
      },
    },
  },
};
