import { laptopRatio, mobileRatio, tabletRatio, theme } from '../theme';
import { getResponsiveSize } from '../utils';

export const dashboardStyle = {
  dashboard: {
    '& .quick-access-title': {
      color: theme.palette.neutral.grey80,
      lineHeight: getResponsiveSize(27), // 27px
      py: getResponsiveSize(12), // 12px
      ...theme.palette.propVars.semiBoldFontWeight,
    },
    '& .btn-quick-access': {
      mt: getResponsiveSize(8), // 8px
      py: getResponsiveSize(12), // 12px
      px: getResponsiveSize(16), // 16px
      height: getResponsiveSize(46), // 46px
      fontSize: theme.typography.fs18, // 19px
      lineHeight: getResponsiveSize(22), // 22px
      textTransform: 'none',
      borderRadius: theme.palette.propVars.borderRadius,
      color: theme.palette.neutral.white,
      backgroundImage: `linear-gradient(to left, ${theme.palette.myPrimary.lightBlue100} 50%, ${theme.palette.myPrimary.darkBlue100} 50%)`,
      fontFamily: theme.typography.mainFontFamily,
      ...theme.palette.propVars.btnBoxShadow,
      ...theme.palette.propVars.semiBoldFontWeight,
      // slide color effect
      backgroundSize: '200%',
      backgroundPosition: ' right bottom',
      transition:
        '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',

      '&:hover': {
        color: theme.palette.neutral.white,
        backgroundPosition: 'left bottom',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&.disabled': {
        opacity: '0.5',
        background: theme.palette.myPrimary.lightBlue100,
      },
      '& .nav-option-icon ': {
        width: getResponsiveSize(24), // 24px
        height: getResponsiveSize(24), // 24px
        fill: theme.palette.neutral.white,
        ml: getResponsiveSize(16), // 16px
        '&.treatment': {
          width: getResponsiveSize(26), // 26px
          height: getResponsiveSize(21), // 21px
          '& path:nth-of-type(1)': {
            stroke: theme.palette.neutral.white,
            fill: 'transparent',
          },
        },
        '&.play': {
          transform: 'scale(1.5)',
          stroke: theme.palette.neutral.white,
          path: {
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: getResponsiveSize(24), // 24px,
          },
        },
        '&.remote-view': {
          height: getResponsiveSize(25), // 25px
          fill: theme.palette.myPrimary.darkBlue50,
          '& path': {
            fill: theme.palette.neutral.white,
          },
        },
        // Organizations icon
        '&.organizations': {
          width: getResponsiveSize(25), // 25px
          height: getResponsiveSize(24), // 24px
        },
        // Devices icon
        '&.devices': {
          fill: 'none',
          width: getResponsiveSize(26), // 26px
          height: getResponsiveSize(24), // 24px
          '& path': {
            '&.vr-boundaries': {
              // stroke: theme.palette.myPrimary.blue,
              stroke: theme.palette.neutral.white,
              strokeWidth: '1.3px',
            },
            '&.vr-nose, &.screen': {
              // fill: theme.palette.myPrimary.blue,
              fill: theme.palette.neutral.white,
            },
          },
        },
      },
    },

    // Desktop screen
    [theme.breakpoints.media.desktop]: {
      '& .btn-quick-access': {
        fontSize: getResponsiveSize(14), // 14px
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .quick-access-title': {
        fontSize: theme.typography.laptop.fs15,
      },
      '& .btn-quick-access': {
        height: getResponsiveSize(38, laptopRatio), // 38px
        fontSize: getResponsiveSize(15, laptopRatio), // 15px
        borderRadius: getResponsiveSize(8, laptopRatio), // 8px
        '& .nav-option-icon ': {
          '&.play': {
            transform: 'scale(2)',
            path: {
              strokeWidth: getResponsiveSize(24, laptopRatio), // 24px,
            },
          },
        },
      },
    },
    // Tablet XL screen
    [theme.breakpoints.media.tabletXL]: {
      '& .btn-quick-access': {
        fontSize: getResponsiveSize(12, laptopRatio), // 12px
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      justifyContent: 'flex-end',
      '& .content-wrapper': {
        flexDirection: 'column',
        minHeight: 'auto !important',
        m: 0,
        '& .quick-start': {
          p: 0,
          m: 0,
          minWidth: 'auto !important',
          gap: getResponsiveSize(4, mobileRatio), // 4px
          // minWidth: getResponsiveSize(200, mobileRatio), // 240px
          '& .quick-access-title': {
            fontSize: getResponsiveSize(16, mobileRatio), // 16px
            mb: getResponsiveSize(8, mobileRatio), // 8px
            p: 0,
          },
          '& .btn-quick-access': {
            height: getResponsiveSize(34, mobileRatio), // 34px
            fontSize: getResponsiveSize(13, mobileRatio), // 13px
            borderRadius: getResponsiveSize(8, mobileRatio), // 8px
            py: getResponsiveSize(8, mobileRatio), // 8px
            px: getResponsiveSize(12, mobileRatio), // 12px
            mt: 0,
            '& .nav-option-icon ': {
              width: getResponsiveSize(18, mobileRatio), // 18px
              height: getResponsiveSize(18, mobileRatio), // 18px
              minHeight: 18,
              minWidth: 18,
              ml: getResponsiveSize(8, mobileRatio), // 8px
              '&.play': {
                ml: getResponsiveSize(16, mobileRatio), // 16px
                transform: 'scale(2)',
                path: {
                  strokeWidth: getResponsiveSize(24, mobileRatio), // 24px,
                },
              },
            },
          },
        },
        '& .data-table-wrapper': {
          p: 0,
          mt: getResponsiveSize(12, mobileRatio), // 12px
        },
      },
    },
  },

  login: {
    '&.limiter': {
      width: '100%',
      m: '0 auto',
      '& .MuiTypography-body2': {
        fontSize: getResponsiveSize(14), // 14px
        m: 0,
        lineHeight: getResponsiveSize(24), // 24px
        color: '#666666',
      },
      '& .container-login100': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: '100vh',
        background: '#f2f2f2',
        '& .timeout-warning-container': {
          display: 'flex',
          flexDirection: 'column',
          width: getResponsiveSize(390), // 390px
          background: theme.palette.myPrimary.white,
          borderRadius: getResponsiveSize(10), // 10px
          overflow: 'hidden',
          py: getResponsiveSize(16), // 16px
          px: getResponsiveSize(33), // 33px
          mb: getResponsiveSize(16), // 16px
          '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              fontSize: getResponsiveSize(21.3), // 21.3px
              mr: getResponsiveSize(21.3), // 21.3px
            },
          },
          '& .text-no-icon': {
            ml: getResponsiveSize(42), // 42px
          },
        },
      },
      '& .wrap-login100': {
        width: getResponsiveSize(390), // 390px
        background: theme.palette.myPrimary.white,
        borderRadius: getResponsiveSize(10), // 10px
        overflow: 'hidden',
        p: getResponsiveSize(55), // 55px
        pb: getResponsiveSize(33), // 33px
        '& .login100-form': {
          width: '100%',
          '& .login100-form-title': {
            fontSize: getResponsiveSize(30), // 30px
            color: theme.palette.neutral.black,
            lineHeight: getResponsiveSize(36), // 36px
            textAlign: 'center',
            mb: getResponsiveSize(24), // 24px
            ...theme.palette.propVars.lightFontWeight,
          },
          '& .input-container': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            my: getResponsiveSize(16), // 16px
            '& .simple-text-field': {
              '& .MuiFormLabel-root': {
                fontSize: getResponsiveSize(14), // 14px
              },
              '& .MuiInputBase-input': {
                fontSize: getResponsiveSize(14), // 14px
                height: getResponsiveSize(20), // 20px
                px: getResponsiveSize(14), // 14px
                py: getResponsiveSize(16.5), // 16.5px
              },
            },
          },
          '& .container-login100-form-btn': {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            pt: getResponsiveSize(13), // 13px
            '& .wrap-login100-form-btn': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: '100%',
              zIndex: '1',
              borderRadius: getResponsiveSize(8), // 8px
              overflow: 'hidden',
              m: '0 auto',
              opacity: '0.9',
              ...theme.palette.propVars.easeInOutTransition,
              '&:hover': {
                opacity: '1',
              },
              '& .login100-form-btn': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: getResponsiveSize(50), // 50px
                width: '100%',
                px: getResponsiveSize(20), // 20px
                borderRadius: getResponsiveSize(5), // 5px
                fontSize: getResponsiveSize(15), // 15px
                color: theme.palette.myPrimary.white,
                lineHeight: getResponsiveSize(36), // 36px
                background: '#2983BF',
                fontFamily: theme.typography.mainFontFamily,
              },
            },
          },
          '& .password-reset-link-container': {
            display: 'flex',
            justifyContent: 'center',
            pt: getResponsiveSize(16), // 16px
            '& button': {
              fontSize: getResponsiveSize(16), // 16px
              fontFamily: theme.typography.mainFontFamily,
            },

            '& a': {
              cursor: 'pointer',

              '&:hover': {
                color: 'unset',
                textDecoration: 'underline',
              },
            },
          },
        },
      },
      // Laptop screen
      [theme.breakpoints.media.laptop]: {
        '& .wrap-login100 .login100-form': {
          '& .input-container': {
            '& .simple-text-field': {
              '& .MuiFormLabel-root': {
                fontSize: getResponsiveSize(12, laptopRatio), // 12px
              },
              '& .MuiInputBase-input': {
                fontSize: getResponsiveSize(11, laptopRatio), // 11px
                height: getResponsiveSize(12, laptopRatio), // 12px
                px: getResponsiveSize(10, laptopRatio), // 10px
                py: getResponsiveSize(12, laptopRatio), // 12px
              },
            },
          },
        },
      },
      // Tablet screen
      [theme.breakpoints.media.tablet]: {
        '& .MuiTypography-body2': {
          fontSize: getResponsiveSize(12, tabletRatio), // 12px
          lineHeight: getResponsiveSize(16, tabletRatio), // 16px
        },
        '& .container-login100': {
          '& .timeout-warning-container': {
            width: getResponsiveSize(340, tabletRatio), // 340px
            borderRadius: getResponsiveSize(8, tabletRatio), // 8px
            py: getResponsiveSize(16, tabletRatio), // 16px
            px: getResponsiveSize(33, tabletRatio), // 33px
            mb: getResponsiveSize(16, tabletRatio), // 16px
            '& .icon-wrapper svg': {
              fontSize: getResponsiveSize(21.3, tabletRatio), // 21.3px
              mr: getResponsiveSize(21.3, tabletRatio), // 21.3px
            },
            '& .text-no-icon': {
              ml: getResponsiveSize(42, tabletRatio), // 42px
            },
          },
        },
        '& .wrap-login100': {
          width: getResponsiveSize(340, tabletRatio), // 340px
          borderRadius: getResponsiveSize(8, tabletRatio), // 8px
          px: getResponsiveSize(48, tabletRatio), // 48px
          pt: getResponsiveSize(28, tabletRatio), // 33px
          pb: getResponsiveSize(24, tabletRatio), // 33px
          '& .login100-form': {
            '& .login100-form-title': {
              fontSize: getResponsiveSize(24, tabletRatio), // 24px
              lineHeight: getResponsiveSize(28, tabletRatio), // 28px
              mb: getResponsiveSize(18, tabletRatio), // 24px
            },
            '& .input-container': {
              '& .simple-text-field': {
                '& .MuiFormLabel-root': {
                  fontSize: getResponsiveSize(16, tabletRatio), // 16px
                },
                '& .MuiInputBase-input': {
                  fontSize: getResponsiveSize(12, tabletRatio), // 12px
                  height: getResponsiveSize(16, tabletRatio), // 16px
                  px: getResponsiveSize(12, tabletRatio), // 12px
                  py: getResponsiveSize(14, tabletRatio), // 14px
                },
                '& fieldset legend span': {
                  pr: getResponsiveSize(30, tabletRatio), // 30px
                },
              },
            },
            '& .container-login100-form-btn': {
              pt: getResponsiveSize(8, tabletRatio), // 13px
              '& .wrap-login100-form-btn': {
                borderRadius: getResponsiveSize(6, tabletRatio), // 6px
                '& .login100-form-btn': {
                  height: getResponsiveSize(45, tabletRatio), // 45px
                  px: getResponsiveSize(16, tabletRatio), // 16px
                  borderRadius: getResponsiveSize(5, tabletRatio), // 5px
                  fontSize: getResponsiveSize(15, tabletRatio), // 15px
                  lineHeight: getResponsiveSize(36, tabletRatio), // 36px
                },
              },
            },
            '& .password-reset-link-container': {
              pt: getResponsiveSize(16, tabletRatio), // 16px
              '& button': {
                fontSize: getResponsiveSize(14, tabletRatio), // 14px
              },
            },
          },
        },
      },
    },
  },

  remoteView: {
    selectOrganization: {
      width: getResponsiveSize(240),
      // Table simple responsive screen
      [theme.breakpoints.media.tableResponsive]: {
        width: getResponsiveSize(240, mobileRatio), // 240px
        '& .MuiFormLabel-root': {
          fontSize: getResponsiveSize(13, mobileRatio), // 13px
        },
        '& legend': {
          fontSize: '10px',
        },
      },
      '& .MuiOutlinedInput-root': {
        pr: `${getResponsiveSize(84)} !important`, // 84px
      },
      popperSx: {
        '& .MuiPaper-root': {
          width: `${getResponsiveSize(440)} !important`,
        },
        hintsSx: {
          width: getResponsiveSize(440),
        },
        // Tablet screen
        [theme.breakpoints.media.tablet]: {
          '& .MuiPaper-root': {
            width: `100% !important`,
          },
          hintsSx: {
            width: '100%',
          },
        },
      },
    },
  },

  changePasswordForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& .form-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: getResponsiveSize(390), // 390px
      background: theme.palette.myPrimary.white,
      borderRadius: getResponsiveSize(10), // 10px
      overflow: 'hidden',
      p: getResponsiveSize(55), // 55px
      pb: getResponsiveSize(33), // 33px
      ...theme.palette.propVars.btnBoxShadow,
      '& .validate-form': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      },
      '& .form-title': {
        fontSize: getResponsiveSize(26), // 26px
        color: theme.palette.neutral.black,
        lineHeight: getResponsiveSize(36), // 36px
        mb: getResponsiveSize(16), // 16px
        textAlign: 'center',
        ...theme.palette.propVars.lightFontWeight,
      },
      '& .input-field ': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        my: getResponsiveSize(8), // 8px
        '& .MuiFormControl-root': {
          width: '100%',
        },
      },
      '& .accept': {
        mt: getResponsiveSize(8),
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .form-wrapper': {
        width: getResponsiveSize(320, laptopRatio), // 320px
        '& .form-title': {
          mb: getResponsiveSize(16, laptopRatio), // 16px
          fontSize: getResponsiveSize(22, laptopRatio), // 22px
          lineHeight: getResponsiveSize(32, laptopRatio), // 32px
        },
        '& .input-field ': {
          my: getResponsiveSize(8, laptopRatio), // 8px
        },
        '& .accept': {
          mt: getResponsiveSize(8, laptopRatio), // 8px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '& .form-wrapper': {
        width: getResponsiveSize(280, mobileRatio), // 280px
        '& .form-title': {
          mb: getResponsiveSize(16, mobileRatio), // 16px
          fontSize: getResponsiveSize(18, mobileRatio), // 18px
          lineHeight: getResponsiveSize(32, mobileRatio), // 32px
        },
        '& .input-field ': {
          my: getResponsiveSize(8, mobileRatio), // 8px
        },
        '& .accept': {
          height: getResponsiveSize(46, mobileRatio), // 46px
          mt: getResponsiveSize(8, mobileRatio), // 8px
          fontSize: getResponsiveSize(16, mobileRatio), // 16px
        },
      },
    },
  },
};
