import { ReactComponent as AudioClicksIcon } from '../shared/assets/icons/advanced-settings/audio-clicks.svg';
import { ReactComponent as BuzzerIcon } from '../shared/assets/icons/advanced-settings/buzzer.svg';
import { ReactComponent as FeedbackIcon } from '../shared/assets/icons/advanced-settings/feedback.svg';
import { ReactComponent as RandomReverseIcon } from '../shared/assets/icons/advanced-settings/random-reverse.svg';
import { ReactComponent as ResponseTaskIcon } from '../shared/assets/icons/advanced-settings/response-task.svg';
import { ReactComponent as LinearPathkIcon } from '../shared/assets/icons/advanced-settings/trajectories/linear-path.svg';
import { ReactComponent as BouncingPathkIcon } from '../shared/assets/icons/advanced-settings/trajectories/bouncing-path.svg';
import { ReactComponent as EllipsePathkIcon } from '../shared/assets/icons/advanced-settings/trajectories/ellipse-path.svg';
import { ReactComponent as InfinityPathkIcon } from '../shared/assets/icons/advanced-settings/trajectories/infinity-path.svg';

/**
 * Maping iconName string to SVG Icon component
 * @param iconName icon name
 * @returns SVG Icon component
 */
export const mapIconsNameToIcon = (iconName) => {
  switch (iconName) {
    case 'response-task':
      return <ResponseTaskIcon />;
    case 'audio-clicks':
      return <AudioClicksIcon />;
    case 'random-reverse':
      return <RandomReverseIcon />;
    case 'feedback':
      return <FeedbackIcon />;
    case 'buzzer':
      return <BuzzerIcon />;
    case 'linear-path':
      return <LinearPathkIcon />;
    case 'bouncing-path':
      return <BouncingPathkIcon />;
    case 'ellipse-path':
      return <EllipsePathkIcon />;
    case 'infinity-path':
      return <InfinityPathkIcon />;
  }
};

/**
 * Generate ID consts based on products
 * @param products Wizard products
 * @returns ID consts based on products
 */
export const getProductIdsConsts = (products) => {
  let EMDR_PlusId;
  let EMDR_RemoteId;
  let EMDR_VrId;
  let MHId;
  products.forEach((product) => {
    switch (product.name) {
      case 'EMDR-Plus':
        EMDR_PlusId = product.id;
        break
      case 'EMDR-Remote':
        EMDR_RemoteId = product.id;
        break
      case 'EMDR-VR':
        EMDR_VrId = product.id;
        break
      case 'Medical Hypnosis':
        MHId = product.id;
        break
    }
  });
  return { EMDR_PlusId, EMDR_RemoteId, EMDR_VrId, MHId };
};

export const ADVANCED_SETTINGS_GROUPS = {
  GENERAL: 'General Settings',
  TASK: 'Task Settings',
  SPHERE: 'Sphere Settings',
  REACTION: 'Reaction Settings',
  AUDIO: 'Audio Settings',
};
