import { laptopRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';
import {
  bouncingAnimation,
  ellipseAnimation,
  infiniteAnimation,
  linearAnimation,
} from './cardIconsAnimations';

export const selectionCardGroupStyles = {
  selectionCardGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: getResponsiveSize(32), // 32px
    '& .card': {
      borderRadius: theme.palette.propVars.borderRadius,
      ...theme.palette.propVars.easeInOutTransition,
      ...theme.palette.propVars.btnBoxShadow,
      '&.active': {
        // border: 'none',
        boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.1)',
        '& .MuiButtonBase-root': {
          border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
          position: 'relative',
          '&:before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            filter: 'blur(2px)',
            border: 'inherit',
            zIndex: '1',
          },
        },
      },
      '& .MuiButtonBase-root': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.myPrimary.lightBlue40}`,
        p: getResponsiveSize(8), // 8px
        width: getResponsiveSize(95), // 95px
        height: getResponsiveSize(73.2), // 73.2px
        background: theme.palette.myPrimary.white,
        borderRadius: theme.palette.propVars.borderRadius,
        ...theme.palette.propVars.easeInOutTransition,
        '& .icon': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: getResponsiveSize(33.3), // 33.3px
          '& svg': {
            fill: 'none',
            width: getResponsiveSize(67), // 67px
            '& .circle': {
              stroke: '#4054B2',
              fill: theme.palette.myPrimary.white,
              ...theme.palette.propVars.easeInOutTransition,
            },
            '& .path': {
              fill: '#4054B2',
              ...theme.palette.propVars.easeInOutTransition,
            },
          },
        },
        '& .MuiCardContent-root': {
          p: 0,
          mt: 'auto',
          '& .desc': {
            fontSize: theme.typography.fs19,
            color: theme.palette.neutral.lightGrey80,
            textTransform: 'capitalize',
            ...theme.palette.propVars.regularFontWeight,
            ...theme.palette.propVars.easeInOutTransition,
          },
        },
        // card hover
        '&:hover': {
          background: theme.palette.myPrimary.lightGrey,
          '& .icon': {
            '& svg': {
              '& .circle': {
                stroke: '#2E3D7F',
                fill: '#2E3D7F',
              },
              '& .path': {
                fill: '#2E3D7F',
              },
            },
          },
          '& .MuiCardContent-root': {
            '& .desc': {
              color: theme.palette.neutral.grey70,
            },
          },
        },
      },
    },
    // icons sizes
    '& .linear .MuiButtonBase-root svg': {
      height: getResponsiveSize(13), // 13px
    },
    '& .bouncing .MuiButtonBase-root svg': {
      height: getResponsiveSize(45), // 45px
    },
    '& .ellipse .MuiButtonBase-root svg': {
      height: getResponsiveSize(28), // 28px
    },
    '& .infinity .MuiButtonBase-root svg': {
      height: getResponsiveSize(22), // 22px
    },

    // fixes for ellipse & infinity icons
    '& .ellipse .MuiButtonBase-root, & .infinity .MuiButtonBase-root': {
      pt: 0,
      '& .icon': {
        height: '100%',
        '& svg': {
          height: '100%',
          mt: getResponsiveSize(8), // 8px
        },
      },
    },

    '& .ellipse .MuiButtonBase-root svg ': {
      transform: 'rotate(180deg)',
      '& .circle': {
        offsetPath:
          'path("M63.34 13.75C63.34 17.0647 60.3624 20.2289 55.1626 22.5933C50.0078 24.9372 42.8514 26.4 34.92 26.4C26.9886 26.4 19.8322 24.9372 14.6774 22.5933C9.47759 20.2289 6.5 17.0647 6.5 13.75C6.5 10.4353 9.47759 7.27108 14.6774 4.9067C19.8322 2.56282 26.9886 1.1 34.92 1.1C42.8514 1.1 50.0078 2.56282 55.1626 4.9067C60.3624 7.27108 63.34 10.4353 63.34 13.75Z")',
        r: '6.3',
        strokeWidth: '1',
      },
    },
    // hover for icons
    '& .linear .MuiButtonBase-root:hover .circle': {
      animation: `${linearAnimation} 2s linear .5s infinite alternate`,
    },
    '& .bouncing .MuiButtonBase-root:hover .circle': {
      animation: `${bouncingAnimation} 3s linear .5s infinite alternate`,
    },
    '& .ellipse .MuiButtonBase-root:hover .circle': {
      animation: `${ellipseAnimation} 3s linear .5s infinite reverse`,
    },
    '& .infinity .MuiButtonBase-root:hover .circle': {
      animation: `${infiniteAnimation} 4s linear .5s infinite normal`,
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      gap: getResponsiveSize(10, laptopRatio), // 10px
      '& .card .MuiButtonBase-root': {
        p: getResponsiveSize(8, laptopRatio), // 8px
        width: getResponsiveSize(95, laptopRatio), // 95px
        height: getResponsiveSize(73.2, laptopRatio), // 73.2px
        '& .icon': {
          height: getResponsiveSize(33.3, laptopRatio), // 33.3px
          '& svg': {
            width: getResponsiveSize(67, laptopRatio), // 67px
            height: '100%',
          },
        },
        '& .MuiCardContent-root': {
          '& .desc': {
            fontSize: getResponsiveSize(15, laptopRatio), // 15px,
          },
        },
      },
    },
    // Tablet screen
    [theme.breakpoints.media.tablet]: {
      // hover for icons
      '& .MuiButtonBase-root:hover .circle': {
        animation: 'none !important',
      },
    },
  },
};
