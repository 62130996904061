import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const dateTimeStyle = {
  dateTimePlate: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: getResponsiveSize(6), // 6px
    '& .date, .time': {
      display: 'inline-flex',
      alignItems: 'flex-start',
      gap: getResponsiveSize(4), // 4px
      '& svg': {
        color: theme.palette.neutral.black,
        fontSize: getResponsiveSize(16), // 16px
        width: getResponsiveSize(16), // 16px
        height: getResponsiveSize(16), // 16px
        minHeight: 16,
        minWidth: 16,
      },
      '& .MuiTypography-root': {
        fontSize: getResponsiveSize(14), // 14px
        fontFamily: theme.typography.mainFontFamily,
        color: theme.palette.neutral.grey80,
        ...theme.palette.propVars.semiBoldFontWeight,
      },
      '&.error': {
        alignItems: 'center',
        '& svg': {
          color: theme.palette.neutral.errorDark,
        },
      },
    },
    '& .time': {
      gap: getResponsiveSize(2), // 2px
      alignItems: 'center',
      '& .MuiTypography-root': {
        fontSize: getResponsiveSize(13), // 13px
      },
      '& svg': {
        fontSize: getResponsiveSize(14), // 14px
        width: getResponsiveSize(14), // 14px
        height: getResponsiveSize(14), // 14px
        minHeight: 14,
        minWidth: 14,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      gap: getResponsiveSize(6, laptopRatio), // 6px
      '& .date, .time': {
        gap: getResponsiveSize(4, laptopRatio), // 4px
        '& svg': {
          fontSize: getResponsiveSize(16, laptopRatio), // 16px
        },
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(12, laptopRatio), // 12px
        },
      },
      '& .time': {
        gap: getResponsiveSize(2, laptopRatio), // 2px
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(12, laptopRatio), // 12px
        },
        '& svg': {
          fontSize: getResponsiveSize(14, laptopRatio), // 14px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      gap: getResponsiveSize(6, mobileRatio), // 6px
      '& .date, .time': {
        gap: getResponsiveSize(4, mobileRatio), // 4px
        '& svg': {
          fontSize: getResponsiveSize(16, mobileRatio), // 16px
        },
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(14, mobileRatio), // 14px
        },
      },
      '& .time': {
        gap: getResponsiveSize(2, mobileRatio), // 2px
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(14, mobileRatio), // 14px
        },
        '& svg': {
          fontSize: getResponsiveSize(14, mobileRatio), // 14px
        },
      },
    },
  },
};
