import React from 'react';
import ReactModal from 'react-modal';
import Typography from '@mui/material/Typography';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '45.5vw',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 101,
  },
  overlay: { zIndex: 100 },
};

const ModalWrapper = ({
  width,
  height,
  modalIsOpen,
  className,
  closeModal,
  title,
  children,
  isCloseBtn = true,
}) => {
  if (width) {
    customStyles.content.width = width;
  }
  if (height) {
    customStyles.content.width = height;
  }

  return (
    <ReactModal
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={`modal-content ${className ? className : ''}`}
      style={customStyles}
      contentLabel="Modal"
    >
      <div className="title-wrapper">
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        {isCloseBtn && (
          <button onClick={closeModal}>
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </div>
      {children}
    </ReactModal>
  );
};

export default ModalWrapper;
