import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const completeStyle = {
  container: {
    '& .status-container': {
      display: 'flex',
      borderRadius: getResponsiveSize(12), // 12px
      alignItems: 'center',
      justifyContent: 'flex-start',
      px: getResponsiveSize(8), // 8px,
      width: 'max-content',
      '&.done': {
        backgroundColor: theme.palette.dashBoar.mintGreenRGBA,
        '& .icon path': {
          fill: theme.palette.myPrimary.green,
        },
        '& .text': {
          color: theme.palette.myPrimary.green,
        },
      },
      '&.proccess': {
        backgroundColor: theme.palette.dashBoar.lightPeach40,
        '& .icon path': {
          fill: theme.palette.dashBoar.darkOrange,
        },
        '& .text': {
          color: theme.palette.dashBoar.darkOrange,
        },
      },

      '& .icon': {
        mr: getResponsiveSize(8), // 8px
        width: getResponsiveSize(16), // 16px
        height: getResponsiveSize(16), // 16px
        minHeight: 16,
        minWidth: 16,
      },

      '& .text': {
        fontSize: getResponsiveSize(14), // 14px
        fontFamily: theme.typography.mainFontFamily,
        ...theme.palette.propVars.semiBoldFontWeight,
      },
    },
    '&.dashboard': {
      '& .status-container': {
        mr: getResponsiveSize(16), // 16px,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .status-container': {
        borderRadius: getResponsiveSize(12, laptopRatio), // 12px
        px: getResponsiveSize(6, laptopRatio), // 6px,
        '& .icon': {
          mr: getResponsiveSize(6, laptopRatio), // 6px
        },
        '& .text': {
          fontSize: getResponsiveSize(12, laptopRatio), // 12px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '& .status-container': {
        borderRadius: getResponsiveSize(12, mobileRatio), // 12px
        px: getResponsiveSize(6, mobileRatio), // 6px,
        '& .icon': {
          mr: getResponsiveSize(6, mobileRatio), // 6px
        },
        '& .text': {
          fontSize: getResponsiveSize(14, mobileRatio), // 14px
        },
      },
    },
  },
};
