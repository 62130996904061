import { alertConstants } from '../constants/alert.constants';

export const alertActions = {
  success,
  error,
  sendSuccessMessage,
  sendErrorMessage,
};

function sendSuccessMessage(message) {
  return (dispatch) => {
    dispatch(success(message));
  };
}

function sendErrorMessage(message) {
  return (dispatch) => {
    dispatch(error(message));
  };
}

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}
