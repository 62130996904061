import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../components/Loader';
import { getParameterByName } from '../utils/history';
import { authService } from '../services/auth';
import { userActions } from '../store/actions/user.actions';

const SsoCallbackPage = ({ login }) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);

  const code = getParameterByName('code', history.location.url);

  useEffect(() => {
    authService
      .getSsoToken(code)
      .then((response) => {
        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;
        login({ type: 'sso', accessToken, refreshToken });
      })
      .catch((error) => {
        setErrorMessage('Failed to login with SSO, please contact support.');
      });
  }, [code, history, login]);

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        background: '#f2f2f2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className='sso-container'
    >
      {!errorMessage && <Loader />}
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  login: userActions.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(SsoCallbackPage);
