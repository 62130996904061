import { laptopRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const wizardFooterStyles = {
  wizardFooter: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    py: getResponsiveSize(20), // 20px
    pl: getResponsiveSize(60), // 60px
    pr: getResponsiveSize(40), // 40px
    borderRadius: `0 0 ${getResponsiveSize(12)} ${getResponsiveSize(12)}`,
    backgroundColor: theme.palette.myPrimary.lightGrey,
    // Action buttons wrapper
    '& .action-btn-wrapper': {
      display: 'flex',
      gap: getResponsiveSize(20), // 20px
      ml: 'auto',
      '& .action-btn': {
        height: getResponsiveSize(46), // 46px
        minWidth: getResponsiveSize(250), // 250px
        textTransform: 'capitalize',
        fontSize: theme.typography.fs19,
      },
      '& .next-btn-wrapper:hover .shown': {
        right: getResponsiveSize(40), // 40px
        visibility: 'visible',
        opacity: 1,
      },
    },
    [theme.breakpoints.media.laptop]: {
      '& .action-btn-wrapper .action-btn': {
        fontSize: theme.typography.laptop.fs15, // 15px
        height: getResponsiveSize(36, laptopRatio), // 38px
      },
    },
  },
};

export const wizardErrorStyles = {
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '100%',
    right: '-5%',
    pointerEvents: 'none',
    visibility: 'hidden',
    opacity: 0,
    width: getResponsiveSize(390),
    p: getResponsiveSize(16),
    background: 'rgba(255, 255, 255, 0.2)',
    border: `2px solid ${theme.palette.neutral.error}`,
    borderRadius: theme.palette.propVars.borderRadius,
    filter: 'drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.15))',
    backdropFilter: 'blur(15px)',
    ...theme.palette.propVars.easeInOutTransition,
    '& h6': {
      color: theme.palette.neutral.black,
      textAlign: 'justify',
      ...theme.palette.propVars.regularFontWeight,
    },
    [theme.breakpoints.media.laptop]: {
      '& h6': {
        lineHeight: 1.4,
      },
    },
  },
};

export const wizardSuccessStyles = {
  successContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    width: 'auto',
    py: 1,
    px: 2,
    background: 'transparent',
    gap: 1,
    border: '2px solid #61CE70',
    fill: '#61CE70',
    borderRadius: theme.palette.propVars.borderRadius,
    ...theme.palette.propVars.easeInOutTransition,
    '& h6': {
      color: '#61CE70',
      ...theme.palette.propVars.regularFontWeight,
    },
    // Animations
    '&.slide-in-entering': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      transform: 'translateX(40%)',
      '&.slide-left': {
        transform: 'translateX(-20%)',
      },
    },
    '&.slide-in-entered': {
      opacity: 1,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      transform: 'translateX(0%)',
    },
    '&.slide-in-exiting': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      transform: 'translateX(40%)',
      '&.slide-left': {
        transform: 'translateX(-20%)',
      },
    },
    '&.slide-in-exited': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
    },
  },
};
