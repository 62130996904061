import {
  Backdrop, Box, Modal, Fade, Typography,
} from '@mui/material';

import { ReactComponent as CloseIcon } from '../../../shared/assets/icons/close.svg';

import { ButtonUi } from '../Button/Button';
import { modalStyle } from './style';

export const ModalUI = (props) => {
  const {
    className,
    open,
    onCloseHandler,
    closeAfterTransition = true,
    disableEscapeKeyDown = false,
    keepMounted = false,
    children,
    backdropTimeout = 500,
    modalTitle,
    isCloseBtn = true,
    modalSx,
  } = props;

  const { modal } = modalStyle;

  return (
    <Modal
      sx={{ ...modal, ...modalSx }}
      className={className}
      open={open}
      onClose={onCloseHandler}
      closeAfterTransition={closeAfterTransition}
      disableEscapeKeyDown={disableEscapeKeyDown}
      keepMounted={keepMounted}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: backdropTimeout,
        },
      }}
    >
      <Fade in={open}>
        <Box className='modal-wrapper'>
          {modalTitle && (
            <Box className='modal-header'>
              <Typography variant='h5' className='title'>
                {modalTitle}
              </Typography>
              {isCloseBtn && (
                <ButtonUi
                  isIconBtn
                  className='close-btn'
                  size='small'
                  onClickHandler={onCloseHandler}
                >
                  <CloseIcon />
                </ButtonUi>
              )}
            </Box>
          )}
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};
