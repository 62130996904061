import { getI18n } from 'react-i18next';
import { practitionerService } from '../../services/practitioners';
import { practitionerConstants } from '../constants/practitioner.constants';
import { alertActions } from './alert.actions';
import { teamActions } from './team.actions';
import { teamService } from '../../services/teams';
import { userService } from '../../services/login';

export function getAllPractitioners(
  offset,
  limit,
  order,
  organizationId,
  searchQuery = '',
  monthlySession,
  totalSessions,
  activeClients,
) {
  return (dispatch) => {
    dispatch(request());
    return practitionerService
      .getAllPractitioners(
        offset,
        limit,
        order,
        organizationId,
        searchQuery,
        monthlySession,
        totalSessions,
        activeClients,
      )
      .then(
        (result) => {
          const { therapists = [], totalCount = 0 } = result?.data || {};
          dispatch(success({ therapists, totalCount }));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(getI18n().t('errors.get.practitioners')));
        },
      );
  };

  function request() {
    return { type: practitionerConstants.GETALL_REQUEST };
  }

  function success(practitioners) {
    return {
      type: practitionerConstants.GETALL_SUCCESS,
      practitioners,
    };
  }

  function failure(error) {
    return {
      type: practitionerConstants.GETALL_FAILURE,
      error,
    };
  }
}

function getPractitionersCount(filter) {
  return (dispatch) => {
    dispatch(request());
    return practitionerService.getPractitionersCount(filter).then(
      (result) => {
        const { count } = result.data;
        dispatch(success(count));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.practitioners')));
      },
    );
  };

  function request() {
    return { type: practitionerConstants.COUNT_REQUEST };
  }

  function success(count) {
    return {
      type: practitionerConstants.COUNT_SUCCESS,
      count,
    };
  }

  function failure(error) {
    return {
      type: practitionerConstants.COUNT_FAILURE,
      error,
    };
  }
}

function createPractitioner(practitioner) {
  return (dispatch) => {
    dispatch(request(practitioner));
    return practitionerService.createPractitioner(practitioner).then(
      (result) => {
        const createdPractitioner = result.data.practitioner;
        return practitionerService
          .findPractitionerById(createdPractitioner.id)
          .then((foundPractitioner) =>
            parsePractitionerWithMetadata(foundPractitioner.data).then(
              (parsedPractitioner) => {
                dispatch(success(parsedPractitioner));
                return parsedPractitioner;
              },
            ),
          );
      },
      (error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const errorDetails =
            error?.response?.data?.error?.details?.codes ||
            error?.response?.data?.error?.message;
          dispatch(failure(errorDetails));
          return error?.response?.data;
        } else dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.add.practitioner')));
      },
    );
  };

  function request(practitioner) {
    return {
      type: practitionerConstants.CREATE_REQUEST,
      practitioner,
    };
  }

  function success(practitioner) {
    return {
      type: practitionerConstants.CREATE_SUCCESS,
      practitioner,
    };
  }

  function failure(error) {
    return {
      type: practitionerConstants.CREATE_FAILURE,
      error,
    };
  }
}

function editPractitioner(practitioner) {
  return (dispatch) => {
    dispatch(request(practitioner));
    return practitionerService.updatePractitioner(practitioner).then(
      (result) => {
        const editedPractitioner = result.data;
        return userService.updateUser(practitioner).then(
          (result) => {
            practitionerService
              .findPractitionerById(editedPractitioner.id)
              .then((foundPractitioner) =>
                parsePractitionerWithMetadata(foundPractitioner.data).then(
                  (parsedPractitioner) => {
                    dispatch(success(parsedPractitioner));
                    return parsedPractitioner;
                  },
                ),
              );
          },
          (error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              const errorDetails =
                error?.response?.data?.error?.details?.codes ||
                error?.response?.data?.error?.message;
              dispatch(failure(errorDetails));
              return error?.response?.data;
            } else dispatch(failure(error.toString()));
          },
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.update.practitioner')));
      },
    );
  };

  function request(practitioner) {
    return {
      type: practitionerConstants.EDIT_REQUEST,
      practitioner,
    };
  }

  function success(practitioner) {
    return {
      type: practitionerConstants.EDIT_SUCCESS,
      practitioner,
    };
  }

  function failure(error) {
    return {
      type: practitionerConstants.EDIT_FAILURE,
      error,
    };
  }
}

function deletePractitioner(practitionerId) {
  return async (dispatch) => {
    dispatch(request(practitionerId));
    const practitionerUser = await practitionerService.getUserByPractitionerId(
      practitionerId,
    );
    const deletePratitionerQuery =
      practitionerService.deletePractitionerById(practitionerId);
    const deleteUserQuery = userService.updateUser({
      userId: practitionerUser?.data?.id,
      is_deleted: true,
    });

    return Promise.all([deletePratitionerQuery, deleteUserQuery]).then(
      () => {
        dispatch(success(practitionerId));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.delete.practitioner')));
      },
    );
  };

  function request(practitioner) {
    return {
      type: practitionerConstants.DELETE_REQUEST,
      practitioner,
    };
  }

  function success(id) {
    return {
      type: practitionerConstants.DELETE_SUCCESS,
      id,
    };
  }

  function failure(error) {
    return {
      type: practitionerConstants.DELETE_FAILURE,
      error,
    };
  }
}

function findPractitionerById(id) {
  return (dispatch) => {
    dispatch(request({ id }));
    return practitionerService.findPractitionerById(id).then(
      (result) =>
        parsePractitionerWithMetadata(result.data).then((practitioner) => {
          dispatch(success(practitioner));
          return practitioner;
        }),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request(practitioner) {
    return {
      type: practitionerConstants.GET_REQUEST,
      practitioner,
    };
  }

  function success(practitioner) {
    return {
      type: practitionerConstants.GET_SUCCESS,
      practitioner,
    };
  }

  function failure(error) {
    return {
      type: practitionerConstants.GET_FAILURE,
      error,
    };
  }
}

function clearPractitioners() {
  return (dispatch) => {
    dispatch({
      type: practitionerConstants.CLEAR_PRACTITIONERS,
    });
  };
}

function clearPratitionersErrors() {
  return (dispatch) => {
    dispatch({
      type: practitionerConstants.CLEAR_ERRORS,
    });
  };
}

const parsePractitionerWithMetadata = async (practitioner) => {
  let clientCount = 0;
  const values = await Promise.all(
    (practitioner.teams || []).map(async (team) => {
      const count = await teamService.getCountOfClients(team.id);
      if (count && count.data) {
        clientCount += count.data.count;
      }
      return teamActions.getStatsOfTeamById(team.id);
    }),
  );
  let sum = 0;
  let monthSum = 0;
  values.map((value) => {
    sum += value.sum;
    monthSum += value.monthSum;
    return {
      sum,
      monthSum,
    };
  });

  practitioner.totalSessions = sum;
  practitioner.sessionsThisMonth = monthSum;
  practitioner.clientCount = clientCount;
  return practitioner;
};

export const practitionerActions = {
  getAllPractitioners,
  createPractitioner,
  editPractitioner,
  findPractitionerById,
  deletePractitioner,
  getPractitionersCount,
  clearPractitioners,
  clearPratitionersErrors,
};
