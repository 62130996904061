import { memo } from 'react';
import { TextField, Skeleton, Zoom } from '@mui/material';
import { textFieldStyle } from './style';

export const TextFieldUi = memo((props) => {
  const {
    className,
    handleChange,
    label,
    name,
    value,
    required = false,
    fullWidth = true,
    autoFocus = true,
    loading = false,
    type
  } = props;

  const { textField } = textFieldStyle;

  if (loading)
    return (
      <Zoom
        in={loading}
        timeout={{ enter: 300, exit: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <Skeleton
          sx={textField}
          className='loading-skeleton'
          variant='rectangular'
        />
      </Zoom>
    );

  return (
    <Zoom
      in={!loading}
      timeout={{ enter: 300, exit: 0 }}
      mountOnEnter
      unmountOnExit
    >
      <TextField
        sx={textField}
        className={className}
        label={label}
        autoFocus={autoFocus}
        name={name}
        fullWidth={fullWidth}
        required={required}
        value={value}
        onChange={handleChange}
        type={type}
      />
    </Zoom>
  );
});
