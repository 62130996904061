import React from 'react';

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from './Loader';

class TeamDetail extends React.Component {
  render() {
    const {
      isLoading, rows, deleteAction, title, createButtonText, createAction,
    } = this.props;

    return (
      <>
        <div className='row'>
          <div className='title'>{title}</div>
          <ul className='contact-container'>
            <div className='module-box-topbar'>
              <button type='button' className='btn-primary' onClick={createAction}>
                {createButtonText}
              </button>
            </div>
            {isLoading ? (
              <div className='main-container-center'>
                <Loader />
              </div>
            ) : (
              rows.map((row, index) => (
                <div className='contact-item' key={row.id + index}>
                  <div className='col con-inf-mw-100' key={`col${index}`}>
                    <p className='mb-0'>
                      <span className='text-truncate contact-name'>
                        {row.name || row.firstname}
                      </span>
                      <span className='d-inline-block toolbar-separator'>&nbsp;</span>
                      <span className='text-truncate contact-name'>{row.lastname || ''}</span>
                    </p>
                    <div className='text-muted'>
                      <span className='email d-inline-block mr-2' />
                      <span className='phone d-inline-block' />
                    </div>
                  </div>
                  <button className='table-action-btn-delete' onClick={(e) => deleteAction(e, row)}>
                    <FontAwesomeIcon icon='trash' />
                  </button>
                </div>
              ))
            )}
          </ul>
        </div>
      </>
    );
  }
}

export default TeamDetail = connect()(TeamDetail);
