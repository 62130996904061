import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faEdit,
  faTrash,
  faToggleOff,
  faToggleOn,
  faTimes,
  faCheckSquare,
  faMedkit,
  faTachometerAlt,
  faUsers,
  faSitemap,
  faUserNurse,
  faCalendarAlt,
  faVrCardboard,
  faUserFriends,
  faUser,
  faSignOutAlt,
  faCog,
  faChevronLeft,
  faBars,
  faCheckCircle,
  faTimesCircle,
  faEllipsisV,
  faArrowRight,
  faArrowLeft,
  faSort,
  faSortDown,
  faSortUp,
  faPlus,
  faExclamationCircle,
  faCopy,
  faPlusCircle,
  faPlayCircle,
  faArrowDown,
  faArrowUp,
  faInfoCircle,
  faArrowsAltV,
  faDesktop,
  faFileInvoiceDollar,
  faSpinner,
  faUsersCog,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faHome,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faHome,
  faEdit,
  faTrash,
  faToggleOff,
  faToggleOn,
  faTimes,
  faCheckSquare,
  faMedkit,
  faTachometerAlt,
  faUsers,
  faSitemap,
  faUserNurse,
  faCalendarAlt,
  faVrCardboard,
  faUserFriends,
  faUser,
  faSignOutAlt,
  faCog,
  faChevronLeft,
  faArrowRight,
  faArrowLeft,
  faBars,
  faCheckCircle,
  faTimesCircle,
  faEllipsisV,
  faSort,
  faSortDown,
  faSortUp,
  faPlus,
  faExclamationCircle,
  faCopy,
  faPlusCircle,
  faPlayCircle,
  faArrowDown,
  faArrowUp,
  faDesktop,
  faInfoCircle,
  faFileInvoiceDollar,
  faSpinner,
  faArrowsAltV,
  faUsersCog,
  faChevronDown,
  faChevronUp,
  faChevronRight,
);

export default library;
