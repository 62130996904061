import { mobileRatio, theme } from '../../theme';
import { getResponsiveSize } from '../../utils';

export const createClientFormStyle = {
  createClientForm: {
    py: getResponsiveSize(31.5), // 31.5px
    px: getResponsiveSize(36), // 36px
    gap: getResponsiveSize(18), // 18px
    '& .team-select': {
      width: '100%',
    },
    '& .modal-footer': {
      justifyContent: 'flex-end',
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      gap: getResponsiveSize(12, mobileRatio), // 12px
    },
  },
};
