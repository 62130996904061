import { laptopRatio, mobileRatio, tabletRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const mergeOrganizationDialogStyles = {
  mergeOrganizationDialog: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    px: getResponsiveSize(24), // 24px
    py: getResponsiveSize(29), // 29px
    '& .merge-visual': {
      position: 'relative',
      '& .merge-icon': {
        position: 'absolute',
        top: '30%',
        left: getResponsiveSize(-10), // -10px
        width: getResponsiveSize(28), // 28px
        height: getResponsiveSize(28), // 28px
        minWidth: '24px',
        minHeight: '24px',
        color: theme.palette.myPrimary.lightBlue100,
        rotate: '180deg',
      },
    },
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: getResponsiveSize(20),
      '& .label-value-row': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'noWrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: getResponsiveSize(8), // 8px
        '& .data-label': {
          width: '40%',
          color: theme.palette.neutral.greyRGBA80,
          ...theme.palette.propVars.regularFontWeight,
        },
        '& .data-value': {
          color: theme.palette.myPrimary.lightBlue100,
          ...theme.palette.propVars.boldFontWeight,
        },
        '& .MuiAutocomplete-root': {
          width: getResponsiveSize(280), // 280px
        },
        '&:before': {
          content: "''",
          width: getResponsiveSize(8), // 8px
          height: getResponsiveSize(8), // 8px
          background: theme.palette.myPrimary.darkBlue100,
          borderRadius: '50%',
        },
      },
    },
    '& .content-footer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: getResponsiveSize(24), // 24px
      mt: getResponsiveSize(24), // 24px
      '& .main-btn': {
        width: getResponsiveSize(165), // 165px
        fontSize: theme.typography.fs16,
        border: 'none',
        ...theme.palette.propVars.regularFontWeight,
      },
      '& .secondary-btn': {
        width: getResponsiveSize(165), // 165px
        fontSize: theme.typography.fs19,
        ...theme.palette.propVars.regularFontWeight,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      px: getResponsiveSize(18, laptopRatio), // 18px
      py: getResponsiveSize(20, laptopRatio), // 20px
      '& .merge-visual .merge-icon': {
        top: '28%',
        left: '-8px',
      },
      '& .content': {
        gap: getResponsiveSize(16, laptopRatio), // 16px
        '& .label-value-row': {
          gap: getResponsiveSize(8, laptopRatio), // 8px
          '& .MuiAutocomplete-root': {
            width: 'auto',
            flex: '1',
          },
          '&:before': {
            width: getResponsiveSize(8, laptopRatio), // 8px
            height: getResponsiveSize(8, laptopRatio), // 8px
          },
        },
      },
      '& .content-footer': {
        gap: getResponsiveSize(24, laptopRatio), // 24px
        mt: getResponsiveSize(20, laptopRatio), // 20px
        '& .main-btn': {
          width: getResponsiveSize(148, laptopRatio), // 148px
          height: getResponsiveSize(38, laptopRatio), // 38px
          fontSize: getResponsiveSize(14, laptopRatio), // 14px
        },
        '& .secondary-btn': {
          width: getResponsiveSize(148, laptopRatio), // 148px
          height: getResponsiveSize(36, laptopRatio), // 36px
          fontSize: getResponsiveSize(14, laptopRatio), // 14px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      px: getResponsiveSize(18, mobileRatio), // 18px
      py: getResponsiveSize(20, mobileRatio), // 20px
      '& .content': {
        gap: getResponsiveSize(16, mobileRatio), // 16px
        '& .label-value-row': {
          gap: getResponsiveSize(8, mobileRatio), // 8px
          '&:before': {
            width: getResponsiveSize(8, mobileRatio), // 8px
            height: getResponsiveSize(8, mobileRatio), // 8px
          },
          '& .data-label, .data-value': {
            fontSize: getResponsiveSize(14, mobileRatio), // 14px
          },
        },
      },
      '& .content-footer': {
        gap: getResponsiveSize(24, mobileRatio), // 24px
        mt: getResponsiveSize(20, mobileRatio), // 20px
        '& .main-btn': {
          width: getResponsiveSize(100, mobileRatio), // 100px
          height: getResponsiveSize(32, mobileRatio), // 32px
          fontSize: getResponsiveSize(14, mobileRatio), // 14px
        },
        '& .secondary-btn': {
          width: getResponsiveSize(100, mobileRatio), // 100px
          height: getResponsiveSize(32, mobileRatio), // 32px
          fontSize: getResponsiveSize(14, mobileRatio), // 14px
        },
      },
    },
  },
  organizationSelect: {
    popperSx: {
      '& .MuiPaper-root': {
        width: `${getResponsiveSize(440)} !important`,
      },
      hintsSx: {
        width: getResponsiveSize(440),
        // Laptop screen
        [theme.breakpoints.media.laptop]: {
          width: `${getResponsiveSize(360, laptopRatio)} !important`,
        },
        // Tablet screen
        [theme.breakpoints.media.tablet]: {
          display: 'none',
        },
      },
      // Laptop screen
      [theme.breakpoints.media.laptop]: {
        '& .MuiPaper-root': {
          width: `${getResponsiveSize(360, laptopRatio)} !important`,
        },
      },
      // Tablet screen
      [theme.breakpoints.media.tablet]: {
        '& .MuiPaper-root': {
          width: `100% !important`,
        },
      },
    },
  },
};
