import axios from 'axios';
import { authUrl } from '../utils/auth-header';
import BASEURL from '../utils/config';

const baseURL = `${BASEURL}`;

const getAllProductConfigurations = () =>
  axios.get(`${baseURL}/therapists/me/active-products${authUrl()}`);

const getActiveSubscriptionsByUser = (organizationId, userId) =>
  axios.get(
    `${baseURL}/organizations/${organizationId}/active-subscriptions${authUrl()}&userId=${userId}`,
  );

const getAvailableProducts = (organizationId) =>
  axios.get(
    `${baseURL}/organizations/${organizationId}/available-products${authUrl()}`,
  );

const getConfigurationTemplatesOfProductId = (id) => {
  const filter = { where: { configurationdefinitionId: id } };
  return axios.get(
    `${baseURL}/configurationtemplates${authUrl()}&filter=${JSON.stringify(
      filter,
    )}`,
  );
};

export const productsService = {
  getAllProductConfigurations,
  getConfigurationTemplatesOfProductId,
  getAvailableProducts,
  getActiveSubscriptionsByUser,
};
