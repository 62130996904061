import { stepClasses } from '@mui/material';
import { laptopRatio, tabletRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';
import { steps } from './consts';

let hiddenSlides = '';
steps.forEach((step, index) => {
  const startDataIndex = index + 1;
  const endDataIndex = steps.length * 2 + startDataIndex;
  hiddenSlides = `${hiddenSlides}${
    index !== 0 ? ',' : ''
  }&[data-index="${startDataIndex}"],&[data-index="${endDataIndex}"]`;
});

export const deviceConnectionStyles = {
  deviceConnection: {
    '& .react-multi-carousel-list': {
      pt: getResponsiveSize(4), // 4px
      pb: getResponsiveSize(20), // 20px
      minHeight: getResponsiveSize(367.5), // 367.5px
      '& .react-multi-carousel-track ': {
        '& .react-multi-carousel-item': {
          [hiddenSlides]: {
            visibility: 'hidden',
          },
          '& .step-instruction': {
            minHeight: getResponsiveSize(342), // 342px
            mx: getResponsiveSize(25), // 25px
            borderRadius: getResponsiveSize(16), // 16
            opacity: '0.5',
            boxShadow: 'none',
            background: theme.palette.myPrimary.white,
            userSelect: 'none !important',
            cursor: 'grab',
            ...theme.palette.propVars.easeInOutTransition,
            '& img': {
              width: '100%',
              height: getResponsiveSize(238), // 238px
              pointerEvents: 'none',
            },
            '& .MuiCardContent-root': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              py: getResponsiveSize(36), // 36px
              px: getResponsiveSize(16), // 16px
              '& .desc': {
                color: theme.palette.neutral.grey70,
                fontSize: theme.typography.fs24,
                ...theme.palette.propVars.regularFontWeight,
                span: {
                  fontFamily: theme.typography.mainFontFamily,
                  fontWeight: '700',
                },
              },
            },
          },
          '& .active': {
            opacity: '1',
            border: `${getResponsiveSize(2)} solid ${
              theme.palette.myPrimary.lightBlue100
            }`,
            boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.1)',
          },
        },
      },
      '& .control-btn': {
        position: 'absolute',
        background: `${theme.palette.myPrimary.lightBlue100}80`,
        width: getResponsiveSize(48), // 48px
        height: getResponsiveSize(48), // 48px
        cursor: 'pointer',
        ...theme.palette.propVars.easeInOutTransition,
        '&:hover': {
          background: theme.palette.myPrimary.lightBlue100,
          '&.prev': {
            '&:before': {
              right: getResponsiveSize(3), // 3px
            },
          },
          '&.next': {
            background: theme.palette.myPrimary.lightBlue100,
            '&:before': {
              left: getResponsiveSize(2), // 2px
            },
          },
        },
        '&.Mui-disabled': {
          background: `${theme.palette.neutral.grey20}CC`,
          opacity: 1,
        },
        '&.prev': {
          left: '33.6%',
          '&:before': {
            content: "'\\e824'",
            right: getResponsiveSize(1), // 1px
            position: 'relative',
            fontSize: getResponsiveSize(20), // 20px
            color: '#fff',
            display: 'block',
            fontFamily: 'revicons',
            textAlign: 'center',
            zIndex: '2',
            ...theme.palette.propVars.easeInOutTransition,
          },
        },
        '&.next': {
          right: '33.3%',
          '&:before': {
            content: "'\\e825'",
            left: '0',
            position: 'relative',
            fontSize: getResponsiveSize(20), // 20px
            color: '#fff',
            display: 'block',
            fontFamily: 'revicons',
            textAlign: 'center',
            zIndex: '2',
            ...theme.palette.propVars.easeInOutTransition,
          },
        },
      },
    },
    '& .loading-status': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      mt: getResponsiveSize(29), // 29px
      '& .desc': {
        mt: getResponsiveSize(29), // 29px
        fontSize: theme.typography.fs14,
        color: `${theme.palette.neutral.grey70}B2`,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .react-multi-carousel-list ': {
        pt: getResponsiveSize(4, laptopRatio), // 4px
        pb: getResponsiveSize(32, laptopRatio), // 32px
        minHeight: getResponsiveSize(250, laptopRatio), // 250px
        '& .react-multi-carousel-track .react-multi-carousel-item .step-instruction':
          {
            '& .MuiCardContent-root .desc': {
              fontSize: getResponsiveSize(16, laptopRatio), // 16px
              lineHeight: '1.5',
            },
            '&:not(.active)': {
              '& .MuiCardContent-root .desc': {
                textOverflow: 'ellipsis',
                textWrap: 'nowrap',
                overflow: 'hidden',
              },
            },
          },
        '& .control-btn': {
          width: getResponsiveSize(32, laptopRatio), // 32px
          height: getResponsiveSize(32, laptopRatio), // 32px
          '&:hover': {
            '&.prev:before': {
              right: getResponsiveSize(3, laptopRatio), // 3px
            },
            '&.next:before': {
              left: getResponsiveSize(2, laptopRatio), // 2px
            },
          },
          '&.prev:before': {
            right: getResponsiveSize(1, laptopRatio), // 1px
            fontSize: getResponsiveSize(15, laptopRatio), // 15px
          },
          '&.next:before': {
            fontSize: getResponsiveSize(15, laptopRatio), // 15px
          },
        },
      },
      '& .loading-status': {
        mt: getResponsiveSize(18, laptopRatio), // 18px
        '& .desc': {
          mt: getResponsiveSize(18, laptopRatio), // 18px
          fontSize: getResponsiveSize(13, laptopRatio), // 13px
        },
      },
    },
    // Tablet screen
    [theme.breakpoints.media.tablet]: {
      '& .react-multi-carousel-list ': {
        '& .react-multi-carousel-track ': {
          '& .react-multi-carousel-item': {
            [hiddenSlides]: {
              visibility: 'visible',
            },
            '&[data-index="0"],&[data-index="1"], &[data-index="5"], &[data-index="6"]':
              {
                visibility: 'hidden',
              },
            '& .step-instruction': {
              mx: getResponsiveSize(7.5, tabletRatio), // 7.5px
            },
          },
        },
      },
    },
  },

  // TODO: remove
  rootWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
  },
  boxWrapper: {
    display: 'flex',
  },
};
