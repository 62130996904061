/**
 * Compare object properties
 * @param objA first object
 * @param objB second object
 * @param prop property to compare in objects
 * @returns boolean value defines does objects properties equal
 */
export const checkObjEqualProp = (objA, objB, prop) =>
  objA?.[prop] === objB?.[prop];

/**
 * Generates classes
 * @param cls static node class
 * @param mods classes object where key is class and value is class condition to apply
 * @param additional additional classes array
 * @returns classes string
 */
export function classNames(cls, mods = {}, additional = []) {
  return [
    cls,
    ...additional.filter(Boolean),
    ...Object.entries(mods)
      .filter(([_, value]) => Boolean(value))
      .map(([className]) => className),
  ].join(' ');
}

/**
 * Generate responsive size
 * @param size responsive size to get
 * @param ratio size equal to the value in 1px
 * @returns responsive size
 */
export const getResponsiveSize = (
  size,
  ratio = 'calc(var(--index) *  0.034614)', // 1px
) => `calc(${ratio} * ${size})`;

/**
 * Generate responsive dimension
 * @param size responsive dimension to get
 * @param view view to calculate dimension for
 * @returns responsive dimension
 */
export const getResponsiveDimension = (size, view = 'desktop') => {
  const vw = window.innerWidth / 100;
  const vh = window.innerHeight / 100;
  let coff = 0.034614;

  switch (view) {
    case 'desktop':
      const ratio = (vw + vh) * coff;
      return ratio * size;
    default:
      break;
  }
};

/**
 * Check does table's filter exists and retrun it's trasformed value
 * @param filterList list of filters
 * @param filter looking filter
 * @returns transformed filter value
 */
export const getTransformedLogicTableFilterValue = (filterList, filter) => {
  if (
    filterList?.[filter] &&
    filterList?.[filter]?.sign !== null &&
    filterList?.[filter]?.value !== null
  )
    return JSON.stringify({
      [filterList?.[filter]?.sign]: filterList?.[filter]?.value,
    });
  else return '';
};
