import { Box, useMediaQuery } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  createSessionObject,
  sessionActions,
} from '../../store/actions/session.actions';

import useDebounce from '../../hooks/useDebounce';
import { useTableFilterConfig } from '../../hooks/useTableFilterConfig';

import getMuiTheme from '../../utils/MUI-Style';
import tableConfig from '../../utils/MUI-table-config';

import {
  classNames,
  getResponsiveDimension,
  getTransformedLogicTableFilterValue,
} from '../../utils';
import muiComponents from '../../utils/MUI-table-components';
import { muiTableLoadingSkeleton } from '../../utils/MUI-table-loading-skaleton';
import TableHeader from '../UI/TableHeader/TableHeader';

import CustomDateSortHeader from './CustomDateSortHeader';
// eslint-disable-next-line import/no-named-as-default-member
import LineGraph from '../LineGraph';
// eslint-disable-next-line import/no-named-as-default-member
import { productActions } from '../../store/actions/product.actions';
// eslint-disable-next-line import/no-named-as-default-member
import LineGraphReverse from '../LineGraphReverse';
import { CustomMuiDataTableComplete } from '../UI/MuiDatatable/CustomMuiDataTableComplete/CustomMuiDataTableComplete';
import { CustomMuiDataTableFilter } from '../UI/MuiDatatable/CustomMuiDataTableFilter/CustomMuiDataTableFilter';

const SessionsTable = ({
  authentication,
  label: propLabel,
  i18n,
  products: productsReducer,
  getAllSessions,
  getAllProductConfigurations,
  clearSessionsData,
  sessions,
  history,
}) => {
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  const isLaptop = useMediaQuery('(max-width: 1024px) and (min-width: 768px)');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentSort, setCurrentSort] = useState('up');
  const [currentSortType, setCurrentSortType] = useState('start_date');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  const [filterOpen, setFilterOpen] = useState(false);
  const [sessionsFilters, setSessionsFilters] = useState();
  const tableRef = useRef();

  const { products } = productsReducer;
  const { data, totalSessions, isLoading } = sessions;
  const { teams, userIsAdmin } = authentication;

  /**
   * Sets table graph height
   */
  const graphHeight = useMemo(() => {
    if (isDesktop) return getResponsiveDimension(38);
    else if (isLaptop) return getResponsiveDimension(60);
    else return getResponsiveDimension(50);
  }, [isDesktop, isLaptop]);

  /**
   * Sets table graph height
   */
  const graphWidth = useMemo(() => {
    if (isDesktop) return getResponsiveDimension(150);
    else if (isLaptop) return getResponsiveDimension(170);
    else return getResponsiveDimension(250);
  }, [isDesktop, isLaptop]);

  /**
   * Opens the filter's modal and grabs data for the filters
   */
  const onOpenFilterModal = useCallback(() => {
    if (products.length <= 0) getAllProductConfigurations();
    setFilterOpen(true);
  }, [getAllProductConfigurations, products]);

  /**
   * Generates filters
   */
  const sessionsFilter = useMemo(() => {
    let order = '';
    let direction = '';
    let searchQuery = '';
    let isAnonymous = false;
    const type = sessionsFilters?.type?.value.toLowerCase() || '';
    const completed = sessionsFilters?.completed?.value || null;
    const decrease = sessionsFilters?.decrease?.value;

    const startDate = getTransformedLogicTableFilterValue(
      sessionsFilters,
      'start_date',
    );

    switch (currentSort) {
      case 'up':
        direction = 'DESC';
        break;
      case 'down':
        direction = 'ASC';
        break;
      default:
        break;
    }

    if (currentSort && currentSortType && direction !== 'default')
      order = `${currentSortType} ${direction}`;

    if (debouncedSearchTerm) {
      searchQuery = `${debouncedSearchTerm.toLowerCase()}`;
      if (
        i18n
          .t('treatments.anon-client-name')
          .toLowerCase()
          .includes(searchQuery)
      )
        isAnonymous = true;
    }
    return {
      page: page * limit || 0,
      limit,
      order,
      searchQuery,
      isAnonymous,
      type,
      completed,
      decrease,
      startDate,
    };
  }, [
    i18n,
    page,
    limit,
    currentSort,
    currentSortType,
    debouncedSearchTerm,
    sessionsFilters,
  ]);

  /**
   * Gets all sessions on filters changes
   */
  useEffect(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      isAnonymous,
      type,
      completed,
      decrease,
      startDate,
    } = sessionsFilter;
    getAllSessions(
      limit,
      page,
      order,
      searchQuery,
      isAnonymous,
      type,
      completed,
      decrease,
      startDate,
    );
  }, [getAllSessions, sessionsFilter]);

  /**
   * Sort handler
   * @param type sort property
   */
  const handleSort = useCallback(
    (type) => {
      let nextSort;
      if (currentSort === 'down') nextSort = 'up';
      else if (currentSort === 'up') nextSort = 'default';
      else if (currentSort === 'default') nextSort = 'down';

      setCurrentSortType(type);
      setCurrentSort(nextSort);
    },
    [currentSort],
  );

  /**
   * Init table's options
   */
  const options = useCallback(
    () => ({
      serverSide: true,
      page,
      rowsPerPage: limit,
      count: totalSessions,
      sort: true,
      onRowClick: (rowData) => {
        const id = rowData[0]?.props?.children;
        history.push({
          pathname: `/admin/sessions/${id}`,
          state: {},
        });
      },
      onChangePage: (currentPage) => {
        setPage(currentPage);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        const { page: offset, limit } = sessionsFilter;
        const showedRows = offset + limit;
        const pagesLimit = Math.ceil(totalSessions / numberOfRows);
        if (showedRows <= numberOfRows && page !== 0) {
          const newPage = Math.floor(numberOfRows / showedRows) - 1;
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        } else if (showedRows > numberOfRows) {
          const newPage = Math.floor(showedRows / numberOfRows);
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        }
        setLimit(numberOfRows);
      },
      onSearchChange: (text) => setSearchText(text),
      searchText,
      searchAlwaysOpen: !userIsAdmin,
    }),
    [
      page,
      limit,
      totalSessions,
      searchText,
      userIsAdmin,
      history,
      sessionsFilter,
    ],
  );

  /**
   * Generates table rows
   */
  const dataToShow = (data || []).map((item) => createSessionObject(item));

  /**
   * Generates export CSV data
   */
  const exportData = (data || []).map((item) =>
    createSessionObject(item, true),
  );

  /**
   * Render talbe haed
   */
  const renderHead = useCallback(
    (mappedSortTypes, key) => {
      const sortType = mappedSortTypes[key];
      return (
        <CustomDateSortHeader
          key={`sort-header-${key}`}
          currentSort={currentSortType === sortType ? currentSort : 'default'}
          label={key}
          type={sortType}
          handleSort={handleSort}
        />
      );
    },
    [currentSort, currentSortType, handleSort],
  );

  /**
   * Render session's complete status
   */
  const renderCompleteStatus = useCallback(
    (status) => <CustomMuiDataTableComplete result={status} />,
    [],
  );

  /**
   * Render session's SUD score
   */
  const renderSUDScore = useCallback(
    (value) =>
      value.length > 0 ? (
        <LineGraph
          small
          values={value}
          height={graphHeight}
          width={graphWidth}
        />
      ) : (
        '-'
      ),
    [],
  );

  /**
   * Render session's VoC score
   */
  const renderVoCScore = useCallback(
    (value) =>
      value.length > 0 ? (
        <LineGraphReverse
          small
          values={value}
          height={graphHeight}
          width={graphWidth}
        />
      ) : (
        '-'
      ),
    [],
  );

  /**
   * Generates table attributes
   */
  let attributes = [];
  const mappedSortTypes = {
    [i18n.t('forms.client_name')]: 'client_name',
    [i18n.t('forms.type')]: 'type',
    [i18n.t('forms.duration')]: 'duration',
    [i18n.t('forms.sud_scores')]: 'decrease',
    [i18n.t('forms.validity_of_cognition_array')]: 'voc_decrease',
    [i18n.t('forms.start_date')]: 'start_date',
    [i18n.t('forms.completed')]: 'completed',
  };
  if (dataToShow && dataToShow.length > 0) {
    const keysToHide = ['id'];
    const excludedKeys = [i18n.t('forms.gen_id')];
    attributes = Object.keys(dataToShow[0]).map((key) => {
      if (keysToHide.includes(key))
        return {
          name: key,
          options: {
            display: false,
            sort: false,
          },
        };

      if (excludedKeys.includes(key))
        return {
          name: key,
          options: {
            display: 'excluded',
            sort: false,
          },
        };

      if (key === i18n.t('forms.sud_scores')) {
        return {
          name: key,
          options: {
            display: true,
            filter: false,
            sort: false,
            customHeadRender: () => renderHead(mappedSortTypes, key),
            customBodyRender: (value) => renderSUDScore(value),
          },
        };
      }
      if (key === i18n.t('forms.validity_of_cognition_array')) {
        return {
          name: key,
          options: {
            display: true,
            filter: false,
            sort: false,
            customBodyRender: (value) => renderVoCScore(value),
            customHeadRender: () => renderHead(mappedSortTypes, key),
          },
        };
      }
      if (key === i18n.t('forms.completed')) {
        return {
          name: key,
          options: {
            display: true,
            filter: false,
            sort: false,
            customHeadRender: () => renderHead(mappedSortTypes, key),
            customBodyRender: (value, tableMeta) =>
              renderCompleteStatus(tableMeta.rowData[8]),
          },
        };
      }
      if (mappedSortTypes[key]) {
        return {
          name: key,
          options: {
            customHeadRender: () => renderHead(mappedSortTypes, key),
          },
        };
      }
      return {
        name: key,
        options: { sort: false },
      };
    });
  }

  /**
   * Generates the table filter's config
   */
  const filtersConfig = useTableFilterConfig('sessions');

  /**
   * Closes the filter's modal and save data for the filters
   */
  const onSaveFilters = useCallback(
    (filters) => setSessionsFilters(filters),
    [],
  );

  /**
   * Checks does table have data
   */
  const isDataTableEmpty = dataToShow.length <= 0;

  /**
   * Flag to checks does filters active
   */
  const isFilters = Object.keys(sessionsFilters || {})?.length > 0;

  /**
   * mui datatable optional class names
   */
  const muiTableMods = {
    loading: isLoading,
    'no-data': isDataTableEmpty,
    'search-open': !userIsAdmin,
    filters: isFilters,
  };

  /**
   * Reset page for search if it's not the first page and there is no results
   */
  useEffect(() => {
    const pagesLimit = Math.ceil(totalSessions / limit);
    if (page > pagesLimit && debouncedSearchTerm) setPage(0);
  }, [debouncedSearchTerm, limit, page, totalSessions]);

  /**
   * Reset page for existings filters if it's not the first page and there is no results
   */
  useEffect(() => {
    if (isFilters && dataToShow.length <= 0 && page > 0) setPage(0);
  }, [dataToShow, isFilters, page]);

  /**
   * Clears sessions on leaving the page
   */
  useEffect(
    () => () => {
      clearSessionsData();
    },
    [],
  );

  return (
    <>
      <TableHeader
        isButton={false}
        title='sessions.title'
        subTitle='sessions.subtitle'
      />
      <Box className='table-container'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={getMuiTheme(
              { cursor: 'pointer' },
              {
                '&:nth-of-type(8)': {
                  width: 100,
                },
              },
            )}
          >
            <MUIDataTable
              ref={tableRef}
              data={isLoading ? muiTableLoadingSkeleton('body') : dataToShow}
              columns={
                isLoading ? muiTableLoadingSkeleton('header') : attributes
              }
              options={tableConfig(
                options(),
                isLoading,
                isDataTableEmpty,
                tableRef,
                isFilters,
                onOpenFilterModal,
                exportData,
              )}
              components={muiComponents(sessionsFilters, setSessionsFilters)}
              className={classNames('mui-data-table sessions', muiTableMods)}
              title=''
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
      {/* Filter modal */}
      {filterOpen && (
        <CustomMuiDataTableFilter
          isOpen={filterOpen}
          setFilterState={setFilterOpen}
          config={filtersConfig}
          filters={sessionsFilters}
          onSaveHandler={onSaveFilters}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllSessions: sessionActions.getAllSessions,
  getSessionCount: sessionActions.getSessionsCount,
  clearSessionsData: sessionActions.clearSessionsData,
  getAllProductConfigurations: productActions.getAllProductConfigurations,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SessionsTable),
);
