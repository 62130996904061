import { memo, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { clientAvatarStyle } from './style';
import { classNames } from '../../../../utils';
import { generateAvatar } from './avatarConfig';

export const ClientAvatar = (props) => {
  const { className, clientName, seedId } = props;
  const { clientAvatar } = clientAvatarStyle;

  /**
   * Generates custom avatar
   */
  const avatar = useMemo(
    () => generateAvatar(seedId).toDataUriSync(),
    [seedId],
  );

  return (
    <Box sx={clientAvatar} className={classNames(className, {})}>
      <img src={avatar} className='avatar' alt='Avatar' />
      <Typography variant='body2'>{clientName}</Typography>
    </Box>
  );
};
