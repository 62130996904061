import { organizationConstants } from '../constants/organization.constants';
import sortByProperty from '../../utils/sort-by-property';

export function organizations(
  state = {
    organizations: [],
    shortOrganizations: [],
    firstOrganization: {},
    totalOrganizations: 0,
  },
  action,
) {
  switch (action.type) {
    case organizationConstants.GETALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case organizationConstants.GETALL_SUCCESS: {
      const { data, count } = action.organizations;
      return {
        ...state,
        organizations: data,
        firstOrganization: data[0],
        isLoading: false,
        totalOrganizations: count,
      };
    }
    case organizationConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case organizationConstants.GETSHORT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case organizationConstants.GETSHORT_SUCCESS: {
      const { data, count } = action.organizations;
      return {
        ...state,
        shortOrganizations: data,
        isLoading: false,
      };
    }
    case organizationConstants.GETSHORT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case organizationConstants.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case organizationConstants.CREATE_SUCCESS: {
      const newOrganizations = state.organizations || [];
      newOrganizations.push(action.organization.organization);
      return {
        ...state,
        organizations: newOrganizations,
        isLoading: false,
      };
    }
    case organizationConstants.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case organizationConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case organizationConstants.UPDATE_SUCCESS: {
      const fetchedOrganization = action.organization;
      const newOrganizations = state.organizations.filter(
        (item) => item.id !== fetchedOrganization.id,
      );
      return {
        ...state,
        organizations: sortByProperty(
          [...newOrganizations, fetchedOrganization],
          'name',
        ),
        isLoading: false,
      };
    }
    case organizationConstants.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case organizationConstants.MERGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case organizationConstants.MERGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case organizationConstants.MERGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case organizationConstants.GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case organizationConstants.GET_SUCCESS: {
      const { organization } = action;
      return {
        ...state,
        selectedOrganization: organization,
        isLoading: false,
      };
    }
    case organizationConstants.GET_FAILURE:
      return {
        ...state,
        error: true,
      };
    case organizationConstants.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case organizationConstants.DELETE_SUCCESS: {
      const { id } = action;
      const filteredOrganizations = state.organizations.filter(
        (item) => item.id !== id,
      );
      return {
        ...state,
        organizations: sortByProperty(filteredOrganizations, 'name'),
        isLoading: false,
      };
    }
    case organizationConstants.DELETE_FAILURE:
      return {
        ...state,
        error: true,
      };

    case organizationConstants.COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case organizationConstants.COUNT_SUCCESS:
      return {
        ...state,
        totalOrganizations: action.count,
      };
    case organizationConstants.COUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case organizationConstants.CLEAR_SELECTED_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: null,
      };
    case organizationConstants.CLEAR_ORGANIZATIONS:
      return {
        ...state,
        organizations: [],
      };
    default:
      return state;
  }
}
