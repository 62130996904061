import React, { memo } from 'react';
import { withTranslation  } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { ReactComponent as BillingIcon } from '../../../shared/assets/icons/no-products-available/billing-no-products.svg';
import { ReactComponent as CrossDocIcon } from '../../../shared/assets/icons/no-products-available/cross-document.svg';
import { ReactComponent as FillDocIcon } from '../../../shared/assets/icons/no-products-available/fill-document.svg';
import { ReactComponent as CardsIcon } from '../../../shared/assets/icons/no-products-available/cards.svg';

import { history } from '../../../utils/history';
import { ButtonUi } from '../../UI/Button/Button';
import { noProductsAvailableStyle } from './style';

const NoProductsAvailable = ({ t }) => {
  const { noProductsAvailable } = noProductsAvailableStyle;

  return (
    <Box sx={noProductsAvailable} className='fade-in'>
      <BillingIcon className='icon' />
      <Typography className='default-setting-title' variant='h3'>
        {t('products.no-available-screen.title')}
      </Typography>
      <Box className='instructions'>
        <ul>
          <li className='instructions-item'>
            <CrossDocIcon />
            <Typography className='text' variant='h5'>
              {t('products.no-available-screen.point-1')}
            </Typography>
          </li>
          <li className='instructions-item'>
            <FillDocIcon />
            <Typography className='text' variant='h5'>
              {t('products.no-available-screen.point-2')}
            </Typography>
          </li>
          <li className='instructions-item'>
            <CardsIcon />
            <Typography className='text' variant='h5'>
              {t('products.no-available-screen.point-3')}
            </Typography>
          </li>
        </ul>
      </Box>
      <ButtonUi
        className='btn'
        variant='contained'
        type='primary'
        size='small'
        onClickHandler={() => history.push('/admin/billing')}
      >
        {t('buttons.go')}
      </ButtonUi>
    </Box>
  );
};

export default withTranslation ()(memo(NoProductsAvailable));
