import { Box, CircularProgress, Typography } from '@mui/material';

import { memo, useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import { ButtonUi } from '../Button/Button';
import { simpleModalContentStyle } from './style';

const SimpleModalContent = (props) => {
  const {
    t,
    content,
    mainBtn,
    secondaryBtn,
    onMainBtnClick,
    onSecondaryBtnClick,
    className,
    isLoading = false,
  } = props;
  const { modalContent } = simpleModalContentStyle;

  const isModalFooter = mainBtn || secondaryBtn;

  const modalFooter = useMemo(
    () =>
      isModalFooter ? (
        <Box className='content-footer'>
          {mainBtn && (
            <ButtonUi
              className='main-btn'
              variant='outlined'
              type='input'
              size='small'
              onClickHandler={onMainBtnClick}
            >
              {!isLoading ? t(mainBtn) : <CircularProgress />}
            </ButtonUi>
          )}
          {secondaryBtn && (
            <ButtonUi
              className='secondary-btn'
              variant='outlined'
              type='secondary'
              size='small'
              onClickHandler={onSecondaryBtnClick}
            >
              {t(secondaryBtn)}
            </ButtonUi>
          )}
        </Box>
      ) : null,
    [
      isLoading,
      isModalFooter,
      mainBtn,
      onMainBtnClick,
      onSecondaryBtnClick,
      secondaryBtn,
      t,
    ],
  );

  return (
    <Box sx={modalContent} className={className}>
      {content &&
        content.map((data) => {
          if (!data.hasOwnProperty('isHtml'))
            return (
              <Typography className='content-item' key={data} variant='h5'>
                {t(data)}
              </Typography>
            );
          return (
            <Typography
              className='content-item'
              key={data}
              variant='h5'
              dangerouslySetInnerHTML={{ __html: t(data?.translation) }}
            />
          );
        })}
      {modalFooter}
    </Box>
  );
};

export default withTranslation()(memo(SimpleModalContent));
