import { Box, FormControl, FormHelperText, Grid } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { object, reach, string } from 'yup';

import { useActions } from '../../hooks/useActions';

import { userService } from '../../services/login';
import { practitionerService } from '../../services/practitioners';
import { classNames } from '../../utils';
import { OrganizationSelect } from '../Organizations/OrganizationSelect';
import { ButtonUi } from '../UI/Button/Button';
import { TextFieldUi } from '../UI/TextField/TextField';
import { practitionerFormStyle } from './style';

const defaultPractitionerData = {
  organizationId: null,
  email: '',
  firstname: '',
  lastname: '',
  username: '',
  password: '',
  passwordRepeat: '',
};

export const CreateEditPractitionerForm = memo((props) => {
  const { practitionerToEdit, closeModal } = props;
  const { practitionerForm } = practitionerFormStyle;
  const { t, language } = useTranslation();

  const {
    createPractitioner,
    editPractitioner,
    clearPratitionersErrors,
    sendSuccessMessage,
    sendErrorMessage,
  } = useActions();
  const { error } = useSelector((state) => state.practitioners);

  const {
    clients,
    monthly_sessions,
    sessions,
    organizationid,
    name,
    ...editPractionerData
  } = practitionerToEdit || {};

  const [practitionerData, setPractitionerData] = useState(
    !practitionerToEdit
      ? defaultPractitionerData
      : {
          ...editPractionerData,
          organizationId: organizationid,
        },
  );
  const [validationErrors, setValidationErrors] = useState({});

  const submitBtnLabel = practitionerToEdit
    ? 'buttons.save'
    : 'buttons.create.practitioner';

  const isSubmitionDisabled = Object.keys(validationErrors).length > 0;

  /**
   * Get Validation schema for the form fields
   */
  const getValidationSchema = useCallback(
    () =>
      object().shape({
        organizationId: string().required(t('errors.select.organisation')),
        email: string()
          .email(t('errors.validation.email'))
          .required(t('errors.validation.required')),
        firstname: string().required(t('errors.validation.required')),
        lastname: string().required(t('errors.validation.required')),
        password: !practitionerToEdit
          ? string().required(t('errors.validation.required'))
          : string().notRequired(),
        passwordRepeat: !practitionerToEdit
          ? string().when('password', ([password], schema, options) => {
              const passwordValue = practitionerData?.password;
              const passwordRepeatValue = options.value;
              if (passwordValue != '') {
                if (passwordRepeatValue == '')
                  return schema.required(t('errors.validation.required'));
                if (passwordValue !== passwordRepeatValue)
                  return schema.oneOf(
                    ['password'],
                    t('errors.validation.password_match'),
                  );
              } else {
                return schema;
              }
            })
          : string().notRequired(),
      }),
    [practitionerData?.password, practitionerToEdit, t],
  );

  /**
   * Validates form fields and sets error
   * @control field control name
   * @value field value
   */
  const validateControl = useCallback(
    async (control, value) => {
      const checkValue = value;
      const validationSchema = getValidationSchema();

      try {
        const result = await reach(validationSchema, control).validate(
          checkValue,
          {
            abortEarly: false,
          },
        );
        setValidationErrors((prev) => {
          const { [control]: validControl, ...rest } = prev;
          return rest;
        });
      } catch (error) {
        const { message } = error?.inner?.[0] || {};
        setValidationErrors((prev) => ({
          ...prev,
          [control]: message,
        }));
      }
    },
    [getValidationSchema],
  );

  /**
   * Sets value to the autocomplete
   * @param value selected value
   */
  const onSelect = useCallback(
    (value) => {
      validateControl('organizationId', value?.value);
      setPractitionerData((current) => ({
        ...current,
        organizationId: value?.value,
      }));
    },
    [validateControl],
  );

  /**
   * Sets value to the textfield
   * @param event native event
   */
  const onChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setPractitionerData((current) => ({ ...current, [name]: value }));
      validateControl(name, value);
    },
    [validateControl],
  );

  /**
   * Shows form control field error message
   * @returns boolean value is form valid
   */
  const validateSubmitiion = async () => {
    let valid = true;

    const { id, userId, username, ...validateControls } = practitionerData;
    Object.keys(validateControls).forEach((control) => {
      validateControl(control, practitionerData[control]);
    });

    const validationSchema = getValidationSchema();

    try {
      await validationSchema.validate(practitionerData, { abortEarly: false });
      valid = true;
    } catch (error) {
      valid = false;
    }

    return valid;
  };

  const resetUserPassword = async () => {
    try {
      await userService.forgotPassword({
        email: practitionerData?.email,
        lang: language,
      });
      sendSuccessMessage(t('password-reset.send-email-verification'));
    } catch (e) {
      sendErrorMessage(t('password-reset.something-went-wrong'));
    }
  };

  /**
   * Update / create practitioner form submition
   * * Sets error if fields are not filled
   * @param event native submit event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const valid = await validateSubmitiion();
    if (!valid) return;

    const { passwordRepeat, ...ptrdata } = practitionerData;
    ptrdata.username = ptrdata.email;

    const result = practitionerToEdit
      ? await editPractitioner(ptrdata)
      : await createPractitioner(ptrdata);

    if (!result?.hasOwnProperty('error')) closeModal();
  };

  /**
   * Shows form control field error message
   * @control field control name
   */
  const showValidationErrors = useCallback(
    (control) => {
      if (validationErrors[control])
        return (
          <FormHelperText key={control} error>
            {validationErrors?.[control]}
          </FormHelperText>
        );
      return null;
    },
    [validationErrors],
  );

  /**
   * Sets form control field error message on request failture
   */
  useEffect(() => {
    if (error) {
      Object.keys(error).forEach((errKey) => {
        if (error[errKey].includes('uniqueness'))
          setValidationErrors((prev) => ({
            ...prev,
            [errKey]: t('errors.validation.field_exists', {
              fieldName: `${errKey[0].toUpperCase()}${errKey.slice(1)}`,
            }),
          }));
      });
    }
  }, [error, t]);

  /**
   * Gets practitioner user data
   */
  const getUserForEdit = async () => {
    try {
      const prtUser = await practitionerService.getUserByPractitionerId(
        practitionerToEdit?.id,
      );
      const { id, email } = prtUser.data;
      setPractitionerData((prev) => ({
        ...prev,
        userId: id,
        email,
      }));
    } catch (error) {
      sendErrorMessage(t('errors.get.practitioners'));
    }
  };

  const footerClasses = {
    'justify-between': practitionerToEdit,
    'justify-end': !practitionerToEdit,
  };

  /**
   * Clears create/edit form request failure errors
   */
  useEffect(() => {
    if (practitionerToEdit) getUserForEdit();
    return () => clearPratitionersErrors();
  }, []);

  return (
    <Box
      component='form'
      className='create-form validate-form'
      onSubmit={handleSubmit}
      sx={practitionerForm}
    >
      <Grid container className='form-wrapper'>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <OrganizationSelect
              selectedId={practitionerData?.organizationId}
              handleChange={onSelect}
            />
            {showValidationErrors('organizationId')}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.firstname')}
            autoFocus
            name='firstname'
            fullWidth
            value={practitionerData?.firstname}
            handleChange={onChange}
          />
          {showValidationErrors('firstname')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.lastname')}
            autoFocus
            name='lastname'
            fullWidth
            value={practitionerData?.lastname}
            handleChange={onChange}
          />
          {showValidationErrors('lastname')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.email')}
            autoFocus
            name='email'
            type='email'
            fullWidth
            value={practitionerData?.email || ''}
            handleChange={onChange}
          />
          {showValidationErrors('email')}
        </Grid>
        {!practitionerToEdit && (
          <>
            <Grid item xs={12} sm={6}>
              <TextFieldUi
                className='simple-text-field'
                label={t('forms.password')}
                autoFocus
                name='password'
                type='password'
                fullWidth
                value={practitionerData?.password}
                handleChange={onChange}
              />
              {showValidationErrors('password')}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldUi
                className='simple-text-field'
                label={t('forms.repeat-password')}
                autoFocus
                name='passwordRepeat'
                type='password'
                fullWidth
                value={practitionerData?.passwordRepeat}
                handleChange={onChange}
              />
              {showValidationErrors('passwordRepeat')}
            </Grid>
          </>
        )}
      </Grid>
      <Box className={classNames('modal-footer ', footerClasses, [])}>
        {practitionerToEdit && (
          <ButtonUi
            className='action-btn reset-pass'
            variant='outlined'
            type='secondary'
            size='small'
            onClickHandler={resetUserPassword}
          >
            {t('password-reset.send-mail-therapist')}
          </ButtonUi>
        )}
        <ButtonUi
          className='action-btn accept'
          variant='contained'
          type='primary'
          size='small'
          btnType='submit'
          disabled={isSubmitionDisabled}
        >
          {t(submitBtnLabel)}
        </ButtonUi>
      </Box>
    </Box>
  );
});
