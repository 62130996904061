import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withTranslation } from 'react-i18next';

import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { ReactComponent as TreatmentIcon } from '../shared/assets/icons/sidenav/treatment.svg';
import { ReactComponent as AddClientIcon } from '../shared/assets/icons/sidenav/practitioners.svg';
import { ReactComponent as RemoteViewIcon } from '../shared/assets/icons/sidenav/remote-view.svg';
import { ReactComponent as OrganizationsIcon } from '../shared/assets/icons/sidenav/organizations.svg';
import { ReactComponent as DevicesIcon } from '../shared/assets/icons/sidenav/devices.svg';
import { ReactComponent as PlayIcon } from '../shared/assets/icons/play.svg';

import getMuiTheme from '../utils/MUI-Style';
import tableConfig from '../utils/MUI-table-config';

import { dashboardActions } from '../store/actions/dashboard.actions';
import { sessionActions } from '../store/actions/session.actions';
import { sessionService } from '../services/sessions';

// eslint-disable-next-line import/no-named-as-default-member
import LineGraph from '../components/LineGraph';
// eslint-disable-next-line import/no-named-as-default-member
import LineGraphReverse from '../components/LineGraphReverse';
import { QuickStartSession } from '../components/QuickStartSession/QuickStartSession';
import useAuthenticatedUser from '../hooks/useAuthenticatedUser';
import { CustomMuiDataTableComplete } from '../components/UI/MuiDatatable/CustomMuiDataTableComplete/CustomMuiDataTableComplete';
import { dashboardStyle } from './style';
import { muiTableLoadingSkeleton } from '../utils/MUI-table-loading-skaleton';
import { classNames, getResponsiveDimension } from '../utils';
import NoProductsAvailable from '../components/Billing/NoProductsAvailable/NoProductsAvailable';

const DashboardPage = function ({
  history,
  authentication: { userIsAdmin, practitioner, canManageTeams, teams },
  i18n,
  devices: { devices, isLoading: deviceLoading },
}) {
  useAuthenticatedUser();
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  const isLaptop = useMediaQuery('(max-width: 1024px) and (min-width: 768px)');
  const { dashboard } = dashboardStyle;
  const [sessions, setSessions] = useState([]);
  const [isValidSubscription, setIsValidSubscription] = useState(true);
  const [sessionModalIsOpen, setSessionModalIsOpen] = useState(false);

  const tableRef = useRef();

  const isLoading = deviceLoading || sessions.length <= 0;

  /**
   * Sets table graph height
   */
  const graphHeight = useMemo(() => {
    if (isDesktop) return getResponsiveDimension(50);
    else if (isLaptop) return getResponsiveDimension(60);
    else return getResponsiveDimension(50);
  }, [isDesktop, isLaptop]);

  /**
   * Sets table graph height
   */
  const graphWidth = useMemo(() => {
    if (isDesktop) return getResponsiveDimension(150);
    else if (isLaptop) return getResponsiveDimension(200);
    else return getResponsiveDimension(250);
  }, [isDesktop, isLaptop]);

  const options = tableConfig({
    pagination: false,
    search: false,
    filter: false,
    sort: false,
    download: false,
    print: false,
    viewColumns: false,
    tableBodyMaxHeight: '100%',

    onRowClick: (rowData) => {
      const id = rowData[0]?.props?.children;
      history.push({
        pathname: `/admin/sessions/${id}`,
        state: {},
      });
    },
  });

  useEffect(() => {
    sessionService.getAllSessions(5, 0, 'start_date DESC').then((result) => {
      setSessions(result.data.sessions);
    });
  }, []);

  const handleModalOpen = () => {
    setSessionModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setSessionModalIsOpen(false);
  };

  const AdminButtons = useMemo(() => {
    if (!userIsAdmin) {
      return undefined;
    }
    return (
      <>
        <NavLink
          className='btn-quick-access btn-icon w-full '
          to='/admin/devices?create=true'
        >
          {i18n.t('buttons.create.device')}
          <DevicesIcon className='nav-option-icon devices' />
        </NavLink>
        <NavLink
          className='btn-quick-access btn-icon w-full '
          to='/admin/organizations?create=true'
        >
          {i18n.t('buttons.create.organization')}
          <OrganizationsIcon className='nav-option-icon organizations' />
        </NavLink>
      </>
    );
  }, []);

  const sessionsToShow = sessions.map((item) =>
    sessionActions.createSessionObject(item),
  );
  const listOfKeysToHide = [
    i18n.t('forms.gen_id'),
    'id',
    i18n.t('forms.delta'),
  ];
  let attributes = [];
  if (sessionsToShow.length > 0) {
    attributes = [...new Set(Object.keys(sessionsToShow[0]))];
    attributes = attributes.map((key) => {
      if (listOfKeysToHide.includes(key)) {
        return {
          name: key,
          options: {
            display: false,
          },
        };
      }
      if (key === i18n.t('forms.sud_scores')) {
        return {
          name: key,
          options: {
            display: true,
            filter: false,
            customBodyRender: (value) =>
              value.length > 0 ? (
                <LineGraph
                  small
                  values={value}
                  height={graphHeight}
                  width={graphWidth}
                />
              ) : (
                '-'
              ),
          },
        };
      }
      if (key === i18n.t('forms.validity_of_cognition_array')) {
        return {
          name: key,
          options: {
            display: true,
            filter: false,
            customBodyRender: (value) =>
              value.length > 0 ? (
                <LineGraphReverse
                  small
                  values={value}
                  height={graphHeight}
                  width={graphWidth}
                />
              ) : (
                '-'
              ),
          },
        };
      }
      if (key === i18n.t('forms.completed')) {
        return {
          name: key,
          options: {
            display: true,
            filter: false,
            customBodyRender: (value, tableMeta) => (
              <CustomMuiDataTableComplete
                result={tableMeta.rowData[8]}
                className='dashboard'
              />
            ),
          },
        };
      }
      return key;
    });
  }

  /**
   * Checks does table have data
   */
  const isDataTableEmpty = sessionsToShow.length <= 0;

  if (!isValidSubscription) return <NoProductsAvailable />;

  return (
    <>
      {practitioner ? (
        <QuickStartSession
          isOpen={sessionModalIsOpen}
          close={handleCloseModal}
          history={history}
          teamId={(teams || [])[0]}
          setIsValidSubscription={setIsValidSubscription}
        />
      ) : null}

      <Box className='organizations-wrapper' sx={dashboard}>
        <Box
          className='content-wrapper flex items-center justify-center m-auto'
          style={{ minHeight: '50%' }}
        >
          <Box
            className='quick-start row h-full mr-2'
            style={{ minWidth: '200px' }}
          >
            <Typography variant='h5' className='quick-access-title'>
              {i18n.t('quick-access')}
            </Typography>
            {practitioner ? (
              <>
                <button
                  type='button'
                  className='btn-quick-access btn-icon w-full '
                  onClick={handleModalOpen}
                >
                  {i18n.t('quick_start.title')}
                  <PlayIcon className='nav-option-icon play' />
                </button>
                <NavLink
                  className='btn-quick-access btn-icon w-full'
                  to='/admin/treatments/create'
                >
                  {i18n.t('buttons.create.treatment')}
                  <TreatmentIcon className='nav-option-icon treatment' />
                </NavLink>
                {/* <NavLink
                  className='btn-primary btn-icon w-full'
                  to='/admin/treatments/create-wizard'
                >
                  {i18n.t('buttons.create.treatment')} wizard
                  <FontAwesomeIcon icon='arrow-right' />
                </NavLink> */}
              </>
            ) : null}
            {practitioner ? (
              <NavLink
                className='btn-quick-access btn-icon w-full'
                to='/admin/clients?create=true&redirectTo=dashboard'
              >
                {i18n.t('buttons.create.client')}
                <AddClientIcon className='nav-option-icon practitioner' />
              </NavLink>
            ) : null}
            <NavLink
              className='btn-quick-access btn-icon w-full'
              to='/admin/remote-view'
            >
              {i18n.t('buttons.remote-view')}
              <RemoteViewIcon className='nav-option-icon remote-view' />
            </NavLink>

            {userIsAdmin || canManageTeams ? (
              <NavLink
                className='btn-quick-access btn-icon w-full'
                to='/admin/practitioners?create=true'
              >
                {i18n.t('buttons.create.practitioner')}
                <AddClientIcon className='nav-option-icon practitioner' />
              </NavLink>
            ) : null}
            {AdminButtons}
          </Box>
          <Box className='data-table-wrapper row h-full'>
            <StyledEngineProvider injectFirst>
              <ThemeProvider
                theme={getMuiTheme(
                  { cursor: 'pointer' },
                  {
                    '&:nth-of-type(2)': {
                      width: 120,
                    },
                    '&:nth-of-type(4)': {
                      width: 150,
                    },
                    '&:nth-of-type(5)': {
                      width: 150,
                    },
                    '&:nth-of-type(6)': {
                      width: 200,
                    },
                  },
                )}
              >
                <MUIDataTable
                  ref={tableRef}
                  data={
                    isLoading
                      ? muiTableLoadingSkeleton('body', 5)
                      : sessionsToShow
                  }
                  columns={
                    isLoading ? muiTableLoadingSkeleton('header') : attributes
                  }
                  options={tableConfig(
                    options,
                    isLoading,
                    isDataTableEmpty,
                    tableRef,
                  )}
                  className={classNames('mui-data-table dashboard', {
                    loading: isLoading,
                  })}
                />
              </ThemeProvider>
            </StyledEngineProvider>
          </Box>
        </Box>
      </Box>
    </>
  );
};

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = {
  getAllStatistics: dashboardActions.getAllStatistics,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPage),
);
