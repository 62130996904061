import React, { memo, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { CachedOutlined as UnCompletedIcon } from '@mui/icons-material';
import { ReactComponent as ApproveIcon } from '../../../../shared/assets/icons/circle_check.svg';


import { completeStyle } from './style';

export const CustomMuiDataTableComplete = memo((props) => {
  const { result, className } = props;
  const { container } = completeStyle;
  const { t } = useTranslation();

  const iconToShow = useMemo(() => {
    switch (result) {
      case true:
        return (
          <Box className='status-container done'>
            <ApproveIcon className='icon' />
            <Typography className='text'>
              {t('sessions.completed-label.completed')}
            </Typography>
          </Box>
        );
      case false:
        return (
          <Box className='status-container proccess'>
            <UnCompletedIcon className='icon' />
            <Typography className='text'>
              {t('sessions.completed-label.in-progress')}
            </Typography>
          </Box>
        );
    }
  }, [result, t]);

  return (
    <Box sx={container} className={className}>
      {iconToShow}
    </Box>
  );
});
