import { createTheme } from '@mui/material';
import { getResponsiveSize } from './utils';

const laptopSize1 = 'calc(var(--index) * 0.0558036)'; // 1px 1024x768
const tabletSize1 = 'calc(var(--index) * 0.05581)'; // 1px 768x1024
const mobileSize1 = 'calc(var(--index) * 0.081038)'; // 1px 390x844

export const theme = createTheme({
  components: {
    // Name of the component ⚛️
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          margin: 'calc(var(--index) * 0.276) auto', // 8px
          borderRadius: 'calc(var(--index) * 0.42)', // 12px
          border: '1px solid #2392BA',
          width: 'calc(100% + calc(var(--index) * 0.864))',
          '@media screen and (max-width: 1024px)': {
            margin: 'calc(var(--index) * 0.447) auto', // 8px
            borderRadius: 'calc(var(--index) * 0.67)', // 12px
          },
          '@media screen and (max-width: 600px)': {
            borderRadius: getResponsiveSize(12, mobileSize1), // 12px
          },
        },
        noOptions: {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: 'calc(var(--index) * 1.66)', // 48px
          padding: 'calc(var(--index) * 0.208) calc(var(--index) * 0.55)', // 6px 16px
          marginBottom: 'calc(var(--index) * 1.66)', // 48px
          color: '#393B37',
          fontSize: 'calc(var(--index) * 0.55)', // 16px
          fontWeight: 400,
          fontFamily: 'Ubuntu',
          '@media screen and (max-width: 1024px)': {
            minHeight: 'calc(var(--index) * 1.787)', // 32px
            padding: 'calc(var(--index) * 0.335) calc(var(--index) * 0.85)', // 6px 16px
            marginBottom: 'calc(var(--index) * 1.787)', // 32px
            fontSize: 'calc(var(--index) * 0.85)', // 16px
          },
          '@media screen and (max-width: 600px)': {
            minHeight: getResponsiveSize(32, mobileSize1), // 32px
            padding: `${getResponsiveSize(6, mobileSize1)} ${getResponsiveSize(
              16,
              mobileSize1,
            )}`, // 6px 16px
            marginBottom: 0,
            fontSize: getResponsiveSize(16, mobileSize1), // 16px
          },
        },
        listbox: {
          maxHeight: 'calc(var(--index) * 11)',
          marginRight: 'calc(var(--index) * 0.276)', // 8px
          padding: 'calc(var(--index) * 0.276) calc(var(--index) * 0.42)', // 8px 12px
          marginBottom: 'calc(var(--index) * 1.796)', // 52px
          '@media screen and (max-width: 1024px)': {
            marginRight: 'calc(var(--index) * 0.447)', // 8px
            padding: 'calc(var(--index) * 0.447) calc(var(--index) * 0.67)', // 8px 12px
            marginBottom: 'calc(var(--index) * 2.176)', // 39px
          },
          '@media screen and (max-width: 768px)': {
            marginBottom: 0,
          },
          '& .MuiAutocomplete-option': {
            color: '#393B37',
            fontSize: 'calc(var(--index) * 0.55)', // 16px
            fontWeight: 400,
            fontFamily: 'Ubuntu',
            borderRadius: 'calc(var(--index) * 0.42)', // 12px
            minHeight: 'calc(var(--index) * 1.66)', // 48px
            padding: 'calc(var(--index) * 0.208) calc(var(--index) * 0.55)', // 6px 16px
            transition: 'all .3s ease-in-out',
            gap: getResponsiveSize(10),
            flexWrap: 'wrap',
            '&[aria-selected="true"]': {
              background: 'transparent',
            },
            '&:hover, &.Mui-focused': {
              background: '#D3E9F1',
            },
            '& .option-hint': {
              color: '#BBBBBD',
            },
            '@media screen and (max-width: 1024px)': {
              fontSize: 'calc(var(--index) * 0.85)', // 16px
              borderRadius: 'calc(var(--index) * 0.67)', // 12px
              minHeight: 'calc(var(--index) * 1.787)', // 32px
              padding: 'calc(var(--index) * 0.335) calc(var(--index) * 0.85)', // 6px 16px
            },
            '@media screen and (max-width: 600px)': {
              fontSize: getResponsiveSize(14, mobileSize1), // 14px
              borderRadius: getResponsiveSize(8, mobileSize1), // 8px
              minHeight: getResponsiveSize(32, mobileSize1), // 32px
              padding: `${getResponsiveSize(
                6,
                mobileSize1,
              )} ${getResponsiveSize(16, mobileSize1)}`, // 6px 16px
            },
          },

          '&::-webkit-scrollbar': {
            width: 'calc(var(--index) * 0.208)', // 6px
            '@media screen and (max-width: 1024px)': {
              width: getResponsiveSize(5, laptopSize1), // 5px
            },
          },
          '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '100px',
            borderRadius: '100px',
            margin: 'calc(var(--index) * 0.276) auto', // 8px
            '@media screen and (max-width: 1024px)': {
              margin: 'calc(var(--index) * 0.447) auto', // 8px
            },
          },
          '&::-webkit-scrollbar-thumb': {
            webkitBorderRadius: '100px',
            borderRadius: '100px',
            background: '#D3E9F1',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: getResponsiveSize(11), // 11px
          fontFamily: 'Ubuntu',
          maxWidth: getResponsiveSize(300), // 300px
          padding: `${getResponsiveSize(4)} ${getResponsiveSize(8)}`, // 4px 8px
          '@media screen and (max-width: 1024px)': {
            fontSize: getResponsiveSize(11, laptopSize1), // 11px
            maxWidth: getResponsiveSize(300, laptopSize1), // 300px
            padding: `${getResponsiveSize(4, laptopSize1)} ${getResponsiveSize(
              8,
              laptopSize1,
            )}`, // 4px 8px
          },
          '@media screen and (max-width: 600px)': {
            fontSize: getResponsiveSize(11, mobileSize1), // 11px
            maxWidth: getResponsiveSize(300, mobileSize1), // 300px
            padding: `${getResponsiveSize(4, mobileSize1)} ${getResponsiveSize(
              8,
              mobileSize1,
            )}`, // 4px 8px
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['Ubuntu', 'Actor', 'Open Sans'].join(','),
    h1: {
      fontSize: getResponsiveSize(32), // 32px
      fontWeight: '600',
      '@media screen and (max-width: 1024px)': {
        fontSize: getResponsiveSize(24, laptopSize1), // 24px
      },
      '@media screen and (max-width: 600px)': {
        fontSize: getResponsiveSize(20, mobileSize1), // 20px
      },
    },
    h3: {
      fontSize: getResponsiveSize(24), // 24px
      lineHeight: getResponsiveSize(28), // 28px
      fontWeight: '600',
      '@media screen and (max-width: 1024px)': {
        fontSize: getResponsiveSize(19, laptopSize1), // 19px
      },
    },
    h4: {
      fontSize: getResponsiveSize(20), // 20px
      fontWeight: '600',
      '@media screen and (max-width: 1024px)': {
        fontSize: getResponsiveSize(16, laptopSize1), // 16px
      },
    },
    h5: {
      fontSize: getResponsiveSize(19), // 19px
      fontWeight: '600',
      '@media screen and (max-width: 1024px)': {
        fontSize: getResponsiveSize(15, laptopSize1), // 15px
      },
    },
    h6: {
      fontSize: getResponsiveSize(16), // 16px
      fontWeight: '600',
      '@media screen and (max-width: 1024px)': {
        fontSize: getResponsiveSize(14, laptopSize1),
      },
      '@media screen and (max-width: 600px)': {
        fontSize: getResponsiveSize(13, mobileSize1), // 13px
      },
    },
    caption: {
      fontSize: getResponsiveSize(14), // 14px
      fontWeight: '400',
    },
    subtitle1: {
      fontSize: '1em',
      fontWeight: '600',
    },
    body1: {
      fontSize: getResponsiveSize(16), // 16px
      '@media screen and (max-width: 1024px)': {
        fontSize: getResponsiveSize(14, laptopSize1),
      },
      '@media screen and (max-width: 600px)': {
        fontSize: getResponsiveSize(13, mobileSize1), // 13px
      },
    },
    body2: {
      fontSize: getResponsiveSize(14), // 14px
    },
    fs14: getResponsiveSize(14), // 14px
    fs16: getResponsiveSize(16), // 16px
    fs18: getResponsiveSize(18), // 18px
    fs19: getResponsiveSize(19), // 19px
    fs24: getResponsiveSize(24), // 24px
    laptop: {
      fs14: 'calc(var(--index) * 0.74)', // 16px -> 14px
      fs15: 'calc(var(--index) * 0.794)', // 19px -> 15px
      fs16: 'calc(var(--index) * 0.85)', // 20px -> 16px
      fs18: 'calc(var(--index) * 1.01)', // 18px
      fs19: 'calc(var(--index) * 1.061)', // 24px -> 19px
    },
    mainFontFamily: 'Ubuntu',
    secondaryFontFamily: 'Actor',
    additionalFontFamily: 'Open Sans',
  },
  consts: {
    desktop: {
      size1: 'calc(var(--index) *  0.034614)', // 1px
    },
    laptop: {
      size1: laptopSize1,
    },
    tablet: {
      size1: tabletSize1,
    },
    mobile: {
      size1: mobileSize1,
    },
  },
  palette: {
    neutral: {
      white: '#F9FBFF',
      grey4: '#F6F6F6',
      grey8: '#EDEDED',
      grey10: '#E8E8E9',
      grey15: '#DDDDDE',
      grey20: '#D2D2D3',
      grey30: '#BBBBBD',
      grey50: '#8D8E90',
      greyRGBA50: 'rgba(57, 59, 55, 0.5)',
      grey70: '#393B37',
      lightGrey80: '#393B37CC',
      grey80: '#494A4E',
      greyRGBA80: 'rgba(57, 59, 55, 0.8)',
      black: '#1C1D22',
      error: '#FFA1A1',
      errorDark: '#F55D3E',
    },
    myPrimary: {
      yellow: '#FEC47F',
      blue: '#B8E7F0',
      green: '#23ba23',
      red: '#ba2323',
      white: '#FFFFFF',
      white15: '#FFFFFF26',
      lightGrey: '#F0F9FC',
      gradient: 'linear-gradient(100.09deg, #4054B2 0%, #6EC1E4 100%);',
      lightBlue: '#6EC1E4',
      lightBlue10: '#F2FBFF',
      lightBlue20: '#D3E9F1',
      lightBlue30: '#D6EEF7',
      lightBlue40: '#A7D3E3',
      lightBlue50: '#C7E4EF',
      lightBlue70: '#65B3CF',
      lightBlue100: '#2392BA',
      darkBlue50: '#2B93B8',
      darkBlueRGBA50: 'rgba(43, 147, 184, 0.1)',
      darkBlue70: '#588595',
      darkBlue100: '#1F7EA1',
      darkAquamarine: '#17607A',
      hover: '#1D85AB',
    },
    dashBoar: {
      lightCoral: '#FFA69E',
      darkCoral: '#b43c31',
      lightCoralRGBA: `rgba(255, 166, 158, 0.4)`,
      mintGreen: '#B0E5C1',
      darkmintGreen: '#2a934c',
      mintGreenRGBA: `rgba(176, 229, 193, 0.5)`,
      softPeach: '#FFD8B1',
      darksoftPeach: '#c56e15',
      softPeachRGBA: `rgba(255, 216, 177, 0.7)`,
      lightPeach40: '#FFD16666',
      darkOrange: '#F0A868', // #F0A868 BF4E30
    },
    // property vars
    propVars: {
      index: 'var(--index)',
      borderRadius: getResponsiveSize(12), // 12px
      actorFamily: { fontFamily: 'Actor' },
      lightFontWeight: { fontWeight: '300' },
      regularFontWeight: { fontWeight: '400' },
      semiBoldFontWeight: { fontWeight: '500' },
      boldFontWeight: { fontWeight: '600' },
      linearTransition: { transition: 'all .3s linear' },
      easeInOutTransition: { transition: 'all .3s ease-in-out' },
      btnBoxShadow: { boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)' },
      dropShadowFilter: {
        filter: 'drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.1))',
      },
      modalBg: { background: 'rgba(255, 255, 255, 0.8)' },
      sliderBg: 'rgba(23, 96, 122, 0.38)',
    },
  },
  breakpoints: {
    media: {
      mobile: '@media screen and (max-width: 424px)',
      tableResponsive: '@media screen and (max-width: 600px)',
      tablet: '@media screen and (max-width: 768px)',
      tabletXL: '@media screen and (max-width: 820px)',
      laptop: '@media screen and (max-width: 1024px)',
      desktop: '@media screen and (max-width: 1441px)',
      desktopXLToDesktop:
        '@media screen and (max-width: 2560px) and (min-width: 1441px)',
      desktopXL: '@media screen and (max-width: 2560px)',
      desktopXXLToDesktopXL:
        '@media screen and (max-width: 3840px) and (min-width: 2560px)',
    },
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1025,
      desktop: 1441,
      largeDesktop: 1921,
      desktopXL: 2560,
      desktopXXL: 3840,
    },
  },
});

export const laptopRatio = theme.consts.laptop.size1;
export const tabletRatio = theme.consts.tablet.size1;
export const mobileRatio = theme.consts.mobile.size1;
