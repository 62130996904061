export const createTreatmentWizardConstants = {
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  SET_SELECTED_PRODUCT: 'SET_SELECTED_PRODUCT',
  SET_SELECTED_CLIENT: 'SET_SELECTED_CLIENT',
  SET_ANONYMOUS_CLIENT: 'SET_ANONYMOUS_CLIENT',
  SET_SELECTED_DEVICE: 'SET_SELECTED_DEVICE',
  SET_SELECTED_DEVICE_CONFIGURATION: 'SET_SELECTED_DEVICE_CONFIGURATION',
  SET_NEXT_BUTTON: 'SET_NEXT_BUTTON',
  SET_TIMEOUT: 'SET_TIMEOUT',
  SET_ISPREVIOUSSESSION: 'SET_ISPREVIOUSSESSION',
  SET_DEFAULT_STEPS: 'SET_DEFAULT_STEPS',
  SET_LOCATION: 'SET_LOCATION',
  SET_ERROR: 'SET_ERROR',
  SET_ANONYMOUSSESSION: 'SET_ANONYMOUSSESSION',
  SET_SHOWNOTIFICATION: 'SET_SHOWNOTIFICATION',

  GET_PRODUCT_CONFIG_TEMPLATES_SUCCESS: 'GET_PRODUCT_CONFIG_TEMPLATES_SUCCESS',
  GET_PRODUCT_CONFIG_TEMPLATES_FAILURE: 'GET_PRODUCT_CONFIG_TEMPLATES_FAILURE',
  SEND_REQUEST: 'SEND_REQUEST',

  ADD_STEP: 'ADD_STEP',
  REMOVE_STEP: 'REMOVE_STEP',

  RESET_STATE: 'RESET_STATE',
};
