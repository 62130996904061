import React, { memo, useCallback, useState } from 'react';
import { Box, FormControl, FormHelperText, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';

import { OrganizationSelect } from '../Organizations/OrganizationSelect';
import { ButtonUi } from '../UI/Button/Button';
import { TextFieldUi } from '../UI/TextField/TextField';
import { teamFormStyle } from './style';

export const CreateEditTeamForm = memo((props) => {
  const { teamToEdit, closeModal } = props;
  const { teamForm } = teamFormStyle;
  const { t } = useTranslation();

  const { createTeam, updateTeam } = useActions();
  const { organizations } = useSelector((state) => state.organizations);

  const {
    id,
    [t('forms.name')]: teamName,
    [t('forms.total.active_clients')]: clients,
    [t('forms.total.monthly_sessions')]: monthly_sessions,
    [t('forms.total.sessions')]: total_sessions,
    organizationId: teamOrganizationId,
  } = teamToEdit || {};

  const [name, setName] = useState(teamName || '');
  const [organizationError, setOrganizationError] = useState();
  const [nameError, setNameError] = useState();
  const [organizationId, setOrganizationId] = useState(teamOrganizationId);

  const submitBtnLabel = teamToEdit ? 'buttons.save' : 'buttons.create.team';
  const isSubmitionDisabled = !!organizationError || !!nameError;

  /**
   * Sets value to the textfield
   * @param event native event
   */
  const onChange = useCallback(
    ({ target: { value } }) => {
      if (value.toString().trim().length <= 0)
        setNameError(t('errors.validation.required'));
      else setNameError(null);
      setName(value);
    },
    [t],
  );

  /**
   * Sets value to the autocomplete
   * @param value selected value
   */
  const onSelect = useCallback(
    (value) => {
      if (!value || !value.value) {
        setOrganizationId(null);
        setOrganizationError(t('errors.select.organisation'));
        return;
      }
      setOrganizationError(null);
      setOrganizationId(value.value);
    },
    [t],
  );

  /**
   * Shows form control field error message
   * @returns boolean value is form valid
   */
  const validateSubmitiion = () => {
    let valid = true;
    if (name.toString().trim().length <= 0) {
      setNameError(t('errors.validation.required'));
      valid = false;
    }
    if (!organizationId) {
      setOrganizationError(t('errors.select.organisation'));
      valid = false;
    }
    return valid;
  };

  /**
   * Update / create team form submition
   * * Sets error if fields are not filled
   * @param event native submit event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const valid = validateSubmitiion();
    if (!valid) return;

    let teamData;
    teamToEdit
      ? // Create a new team object which can be updated in the table.
        // You might want to undo this when you change the way of working.
        (teamData = {
          id,
          name,
          organizationid: organizationId,
          org_name: organizations.find((item) => item.id === organizationId)
            .name,
          clients,
          monthly_sessions,
          total_sessions,
        })
      : (teamData = {
          name,
          organizationId,
        });
    teamToEdit ? updateTeam(teamData) : createTeam(teamData);
    closeModal();
  };

  return (
    <Box
      component='form'
      className='create-form validate-form'
      onSubmit={handleSubmit}
    >
      <Grid container sx={teamForm}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <OrganizationSelect
              selectedId={organizationId}
              handleChange={onSelect}
            />
            {organizationError && (
              <FormHelperText error>{organizationError}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.name')}
            autoFocus
            name='name'
            fullWidth
            value={name}
            handleChange={onChange}
          />
          {nameError && <FormHelperText error>{nameError}</FormHelperText>}
        </Grid>
      </Grid>
      <Box className='modal-footer justify-end'>
        <ButtonUi
          className='action-btn accept'
          variant='contained'
          type='primary'
          size='small'
          btnType='submit'
          disabled={isSubmitionDisabled}
        >
          {t(submitBtnLabel)}
        </ButtonUi>
      </Box>
    </Box>
  );
});
