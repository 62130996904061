import { Box } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutocompleteUi from '../../Autocomplete/Autocomplete';
import { ModalUI } from '../../Modal/Modal';

import { ButtonUi } from '../../Button/Button';
import { RangeFilter } from '../../RangeFilter/RangeFilter';
import CombineToggle from '../../CombineToggle/CombineToggle';
import { LogicCheckFilterItem } from '../../LogicCheckFilterItem/LogicCheckFilterItem';
import { filterModalStyle } from './style';

export const CustomMuiDataTableFilter = memo((props) => {
  const {
    isOpen,
    setFilterState,
    config,
    onSaveHandler,
    filters: filtersData,
    className,
  } = props;
  const { filterModal } = filterModalStyle;
  const { t } = useTranslation();
  const [filters, setFilters] = useState({ ...filtersData });

  /**
   * Sets default filter values
   */
  const setDefaultFilterValues = useCallback(() => {
    if (filters) return;
    config.forEach((filter) => {
      const isLogicFilter = filter.type.split('-')[0] === 'conditional';
      setFilters((prev) => ({
        ...prev,
        [filter.filter]: {
          value: null,
          ...(isLogicFilter && { sign: null }),
        },
      }));
    });
  }, [config, filters]);

  /**
   * Sets filter value
   * @param filter filter
   * @param value value
   */
  const handleVariableChange = useCallback((filter, value) => {
    const hasSign = value?.hasOwnProperty('sign');
    setFilters((prev) => ({
      ...prev,
      [filter]: {
        value: hasSign ? value.value : value,
        ...(hasSign && { sign: value?.sign, touched: true }),
      },
    }));
  }, []);

  /**
   * Changes option layout
   * @param props component props
   * @param option option
   */
  const formatOptionLabel = useCallback((props, option) => {
    const { 'data-option-index': optionIdx } = props;
    const { text, number } = option;
    return (
      <Box component='li' {...props} key={`${optionIdx} - ${number}`}>
        {text}
      </Box>
    );
  }, []);

  /**
   * Generates filters autocomplete component for filter
   * @param item filter item
   * @returns filter autocomplete component for filter item
   */
  const getAutocomplete = useCallback(
    (item) => {
      const filterValue = filters[item.filter]?.value;

      const autocompleteValue = filterValue
        ? {
            text:
              item.retrunType === 'number' || item.retrunType === 'boolean'
                ? t(
                    item.options.find((option) => option.value === filterValue)
                      ?.text,
                  )
                : filterValue,
            value: filterValue,
          }
        : null;

      return (
        <Box key={item.filter} className='filter-row'>
          <AutocompleteUi
            label={t(item?.label || '')}
            placeholder=''
            autocompleteValue={autocompleteValue}
            onAutocompleteChangeHandler={(_, value) =>
              handleVariableChange(item.filter, value ? value.value : null)
            }
            onAutocompleteRenderOptionsHandler={formatOptionLabel}
            options={item?.options.map((option) => ({
              ...option,
              text: t(option.text),
            }))}
            loading={item?.isLoading}
          />
        </Box>
      );
    },
    [filters, formatOptionLabel, handleVariableChange, t],
  );

  /**
   * Generates filters logic check component for filter
   * @param item filter item
   * @returns filter logic check component for filter item
   */
  const getLogicFilter = useCallback(
    (item, type) => {
      const filter = filters[item.filter];

      return (
        <Box key={item.filter} className='filter-row'>
          <LogicCheckFilterItem
            label={t(item?.label || '')}
            type={type}
            filter={filter}
            min={item?.min || 0}
            max={item?.max || 999}
            onChange={(value) => handleVariableChange(item.filter, value)}
          />
        </Box>
      );
    },
    [filters, handleVariableChange, t],
  );

  /**
   * Generates filters range component for filter
   * @param item filter item
   * @returns filter range component for filter item
   */
  const getRangeFilter = useCallback(
    (item) => {
      const filter = filters[item.filter];

      return (
        <Box key={item.filter} className='filter-row'>
          <RangeFilter
            label={t(item?.label || '')}
            filter={filter}
            min={item?.min || 0}
            max={item?.max || 999}
            step={item?.step || 1}
            onChange={(value) => handleVariableChange(item.filter, value)}
          />
        </Box>
      );
    },
    [filters, handleVariableChange, t],
  );

  /**
   * Generates toggle component for filter
   * @param item filter item
   * @returns toggle component for filter item
   */
  const getToggle = useCallback(
    (item) => {
      const filterValue = filters[item.filter]?.value || false;

      return (
        <Box key={item.filter} className='filter-row'>
          <CombineToggle
            name='hide_deleted'
            className={item?.filter}
            selectValue={!filterValue}
            options={item.options}
            onChangeHandler={({ variable, value }) =>
              variable === item?.filter
                ? handleVariableChange(variable, value ? value : null)
                : null
            }
          />
        </Box>
      );
    },
    [filters, handleVariableChange],
  );

  /**
   * Generates filter component based on filter configuration
   * @param filter configuration
   * @returns filter component
   */
  const getFilterLayout = useCallback(
    (filter) => {
      const { type } = filter;
      switch (type) {
        case 'autocomplete':
          return getAutocomplete(filter);
        case 'toggle':
          return getToggle(filter);
        case 'range':
          return getRangeFilter(filter);
        case 'conditional-number':
          return getLogicFilter(filter, 'number');
        case 'conditional-date':
          return getLogicFilter(filter, 'calendar');
      }
    },
    [getAutocomplete, getLogicFilter, getRangeFilter, getToggle],
  );

  /**
   * Generates filters layout filters configuration
   * @returns filters layout
   */
  const renderFilters = useMemo(
    () => config.map((filter) => getFilterLayout(filter)),
    [config, getFilterLayout],
  );

  /**
   * Close Handler
   */
  const onCloseModal = useCallback(() => {
    setFilterState(false);
  }, [setFilterState]);

  /**
   * Clear filters handler
   */
  const onClearFilters = useCallback(() => {
    Object.keys(filters).forEach((key) => {
      const isLogicFilter = filters[key]?.hasOwnProperty('sign');
      setFilters((current) => {
        const { [key]: removedFilter, ...rest } = current;
        if (isLogicFilter) return { [key]: { touched: false }, ...rest };
        else return rest;
      });
    });
  }, [filters]);

  /**
   * Save Handler
   */
  const onSaveModal = useCallback(() => {
    setFilterState(false);
    const filtersData = {};
    Object.keys(filters).forEach((filterKey) => {
      const hasTouchedFlag = filters[filterKey].hasOwnProperty('touched');
      const logicFilterIsNotEmpty = filters[filterKey].hasOwnProperty('value');
      if (hasTouchedFlag && !logicFilterIsNotEmpty) return;
      else filtersData[filterKey] = filters[filterKey];
    });
    onSaveHandler(filtersData);
  }, [filters, onSaveHandler, setFilterState]);

  /**
   * Sets default filter values
   */
  useEffect(() => {
    setDefaultFilterValues();
  }, []);

  return (
    <ModalUI
      modalSx={filterModal}
      open={isOpen}
      onCloseHandler={onCloseModal}
      className='filter-modal'
      modalTitle={t('mui-datatables.toolbar.filterTable')}
    >
      <Box className='filters-wrapper'>{renderFilters}</Box>
      <Box className='filter-footer'>
        <ButtonUi
          className='action-btn clear'
          variant='outlined'
          type='secondary'
          size='small'
          onClickHandler={onClearFilters}
        >
          {t('buttons.clear')}
        </ButtonUi>
        <ButtonUi
          className='action-btn save'
          variant='contained'
          type='primary'
          size='small'
          onClickHandler={onSaveModal}
        >
          {t('buttons.save')}
        </ButtonUi>
      </Box>
    </ModalUI>
  );
});
