import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { organizationActions } from '../../store/actions/organization.actions';
import { teamActions } from '../../store/actions/team.actions';

// eslint-disable-next-line import/no-cycle
import { ReactComponent as DeleteIcon } from '../../shared/assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../shared/assets/icons/edit.svg';
import { ReactComponent as AddTeamIcon } from '../../shared/assets/icons/sidenav/teams.svg';

import useDebounce from '../../hooks/useDebounce';
import { useTableFilterConfig } from '../../hooks/useTableFilterConfig';

import { classNames, getTransformedLogicTableFilterValue } from '../../utils';
// eslint-disable-next-line import/no-cycle
import { checkRouteAccess } from '../../utils/check-access';
import getMuiTheme from '../../utils/MUI-Style';
import tableConfig from '../../utils/MUI-table-config';
import muiComponents from '../../utils/MUI-table-components';
import { convertToCamelCase } from '../../utils/convertToCamelCase';
import { muiTableLoadingSkeleton } from '../../utils/MUI-table-loading-skaleton';

import { CreateEditTeamForm } from './CreateEditTeamForm';
import CustomDateSortHeader from '../Sessions/CustomDateSortHeader';
import { ModalUI } from '../UI/Modal/Modal';
import { FormModal } from '../UI/FormModal/FormModal';
import TableHeader from '../UI/TableHeader/TableHeader';
import { DataCell } from '../UI/MuiDatatable/DataCell/DataCell';
import { deleteConfirmationStyle } from '../UI/SimpleModalContent/style';
import SimpleModalContent from '../UI/SimpleModalContent/SimpleModalContent';
import { CustomMuiDataTableFilter } from '../UI/MuiDatatable/CustomMuiDataTableFilter/CustomMuiDataTableFilter';
import { CustomMuiSessionsPerMonth } from '../UI/MuiDatatable/CustomMuiSessionsPerMonth/CustomMuiSessionsPerMonth';
import { CustomMuiDataTableBodyButton } from '../UI/MuiDatatable/CustomMuiDataTableBodyButton/CustomMuiDataTableBodyButton';

const TeamsTable = ({
  teams: teamsReducer,
  deleteTeam,
  organizations,
  authentication,
  getAllOrganizations,
  getAllTeams,
  clearTeams,
  history,
  location,
}) => {
  const { t } = useTranslation();
  const { deleteConfirmation } = deleteConfirmationStyle;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentSort, setCurrentSort] = useState('up');
  const [currentSortType, setCurrentSortType] = useState('total_sessions');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  const [filterOpen, setFilterOpen] = useState(false);
  const [teamFilters, setTeamFilters] = useState();
  const tableRef = useRef();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [teamToEdit, setTeamToEdit] = useState(null);
  const [teamToDelete, setTeamToDelete] = useState(null);

  const { teams, isLoading, totalTeams } = teamsReducer;
  const { userIsAdmin, isLogisticsAdmin, organizationId } = authentication;
  const { shortOrganizations: organizationOptions } = organizations;

  const hasOrganizationAccess = isLogisticsAdmin || userIsAdmin;

  const keysToHide = ['organizationId', 'id'];
  let dataToShow = teams || [];
  let exportData = [];
  let attributes = [];

  /**
   * Check does user has permision to be on this page
   */
  useEffect(() => {
    const { foundUser, roles, userIsAdmin: isAdmin } = authentication;
    checkRouteAccess(location.pathname, foundUser, roles, isAdmin, history);
  }, []);

  /**
   * Opens the filter's modal and grabs data for the filters
   */
  const onOpenFilterModal = useCallback(() => {
    if (organizationOptions.length <= 0 && hasOrganizationAccess)
      getAllOrganizations();
    setFilterOpen(true);
  }, [getAllOrganizations, hasOrganizationAccess, organizationOptions]);

  /**
   * Generates filters
   */
  const teamFilter = useMemo(() => {
    let order = '';
    let direction = '';
    let searchQuery = '';
    const orgId = hasOrganizationAccess
      ? teamFilters?.organizationId?.value || ''
      : organizationId;

    const monthlySession = getTransformedLogicTableFilterValue(
      teamFilters,
      'monthly_sessions',
    );

    const totalSessions = getTransformedLogicTableFilterValue(
      teamFilters,
      'sessions',
    );

    const activeClients = getTransformedLogicTableFilterValue(
      teamFilters,
      'clients',
    );

    switch (currentSort) {
      case 'up':
        direction = 'DESC';
        break;
      case 'down':
        direction = 'ASC';
        break;
      default:
        break;
    }

    if (currentSort && currentSortType && direction !== 'default')
      order = `${currentSortType} ${direction}`;

    if (debouncedSearchTerm)
      searchQuery = `${debouncedSearchTerm.toLowerCase()}`;

    return {
      page: page * limit || 0,
      limit,
      order,
      orgId,
      searchQuery,
      monthlySession,
      totalSessions,
      activeClients,
    };
  }, [
    page,
    limit,
    currentSort,
    currentSortType,
    hasOrganizationAccess,
    organizationId,
    debouncedSearchTerm,
    teamFilters,
  ]);

  /**
   * Gets all Teams on filters changes
   */
  useEffect(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      orgId,
      monthlySession,
      totalSessions,
      activeClients,
    } = teamFilter;
    getAllTeams(
      page,
      limit,
      order,
      orgId,
      searchQuery,
      monthlySession,
      totalSessions,
      activeClients,
    );
  }, [getAllTeams, teamFilter]);

  /**
   * Refresh teams data
   */
  const refreshCurrentData = useCallback(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      orgId,
      monthlySession,
      totalSessions,
      activeClients,
    } = teamFilter;
    getAllTeams(
      page,
      limit,
      order,
      orgId,
      searchQuery,
      monthlySession,
      totalSessions,
      activeClients,
    );
  }, [getAllTeams, teamFilter]);

  /**
   * Open edit / create team modal
   */
  const openModal = () => {
    setModalIsOpen(true);
  };

  /**
   * Close edit / create team modal
   */
  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    refreshCurrentData();
    setTeamToEdit(null);
  }, [refreshCurrentData]);

  /**
   * Open delete modal
   */
  const onOpenDeleteModal = useCallback((event, id) => {
    event.stopPropagation();
    setTeamToDelete(id);
    setDeleteModalIsOpen(true);
  }, []);

  /**
   * Close delete modal
   */
  const onCloseDeleteModal = useCallback(() => setDeleteModalIsOpen(false), []);

  /**
   * Open edit team
   * @param event native event
   * @param id team's to edit id
   */
  const editTeam = useCallback(
    (event, id) => {
      event.stopPropagation();
      setModalIsOpen(true);
      setTeamToEdit(teams.find((item) => item.id === id));
    },
    [teams],
  );

  /**
   * Delete team handler
   */
  const onDeleteTeam = useCallback(async () => {
    await deleteTeam(teamToDelete);
    refreshCurrentData();
    setDeleteModalIsOpen(false);
  }, [deleteTeam, teamToDelete, refreshCurrentData]);

  /**
   * Sort handler
   * @param type sort property
   */
  const handleSort = useCallback(
    (type) => {
      let nextSort;
      if (currentSort === 'down') nextSort = 'up';
      else if (currentSort === 'up') nextSort = 'default';
      else if (currentSort === 'default') nextSort = 'down';

      setCurrentSortType(type);
      setCurrentSort(nextSort);
    },
    [currentSort],
  );

  /**
   * Init table's options
   */
  const options = useCallback(
    () => ({
      serverSide: true,
      page,
      rowsPerPage: limit,
      count: totalTeams,
      onRowClick: (rowData) => {
        const id = rowData[0]?.props?.children;
        history.push({
          pathname: `/admin/teams/${id}`,
          state: {},
        });
      },
      onChangePage: (currentPage) => {
        setPage(currentPage);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        const { page: offset, limit } = teamFilter;
        const showedRows = offset + limit;
        const pagesLimit = Math.ceil(totalTeams / numberOfRows);
        if (showedRows <= numberOfRows && page !== 0) {
          const newPage = Math.floor(numberOfRows / showedRows) - 1;
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        } else if (showedRows > numberOfRows) {
          const newPage = Math.floor(showedRows / numberOfRows);
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        }
        setLimit(numberOfRows);
      },
      onSearchChange: (text) => setSearchText(text),
      searchText,
      searchAlwaysOpen: true,
    }),
    [page, limit, totalTeams, searchText, history, teamFilter],
  );

  /**
   * Generates table rows
   */
  const createViewItemListForTeams = (dataToShow) =>
    dataToShow.map((item) => {
      const {
        id,
        monthlySessions,
        name,
        organizationName,
        numberOfActiveClients,
        sessionsTotal,
      } = item;

      return {
        id: <DataCell>{id}</DataCell>,
        [t('forms.name')]: <DataCell>{name}</DataCell>,
        [t('forms.organization_name')]: (
          <DataCell type='secondary'>{organizationName}</DataCell>
        ),
        [t('forms.total.active_clients')]: (
          <DataCell type='numerics'>{numberOfActiveClients}</DataCell>
        ),
        [t('forms.total.monthly_sessions')]: (
          <CustomMuiSessionsPerMonth isSum={false} monthSum={monthlySessions} />
        ),
        [t('forms.total.sessions')]: (
          <CustomMuiSessionsPerMonth isMonthSum={false} sum={sessionsTotal} />
        ),
      };
    });

  /**
   * Generates export CSV data
   * @param data server data to transform
   */
  const generateExportData = (data) =>
    data.map((item) => {
      const {
        id,
        monthlySessions,
        name,
        organizationName,
        numberOfActiveClients,
        sessionsTotal,
      } = item;

      return {
        id,
        [t('forms.name')]: name,
        [t('forms.organization_name')]: organizationName || 'null',
        [t('forms.total.active_clients')]: numberOfActiveClients || 0,
        [t('forms.total.monthly_sessions')]: monthlySessions,
        [t('forms.total.sessions')]: sessionsTotal,
      };
    });

  if (dataToShow.length > 0) {
    exportData = generateExportData(dataToShow);
    dataToShow = createViewItemListForTeams(dataToShow);
  }

  /**
   * Render talbe haed
   */
  const renderHead = useCallback(
    (mappedSortTypes, key) => {
      const sortType = mappedSortTypes[key];
      return (
        <CustomDateSortHeader
          key={`sort-header-${key}`}
          currentSort={currentSortType === sortType ? currentSort : 'default'}
          label={key}
          type={sortType}
          handleSort={handleSort}
        />
      );
    },
    [currentSort, currentSortType, handleSort],
  );

  /**
   * Remder talbe actions
   */
  const renderActions = useCallback(
    (value, tableMeta) => (
      <CustomMuiDataTableBodyButton
        firstButtonIcon={<EditIcon />}
        secondButtonIcon={<DeleteIcon />}
        onPrimaryActionButton={(e) =>
          editTeam(e, tableMeta.rowData[0]?.props?.children)
        }
        onSecondaryActionButton={(e) =>
          onOpenDeleteModal(e, tableMeta.rowData[0]?.props?.children)
        }
      />
    ),
    [editTeam, onOpenDeleteModal],
  );

  /**
   * Generates table attributes
   */
  const createTableAttributes = useCallback(
    (dataToShow, attributes, keysToHide) => {
      if (dataToShow.length > 0) {
        const mappedSortTypes = {
          [t('forms.name')]: 'name',
          [t('forms.organization_name')]: 'org_name',
          [t('forms.total.active_clients')]: 'clients',
          [t('forms.total.monthly_sessions')]: 'monthly_sessions',
          [t('forms.total.sessions')]: 'total_sessions',
        };

        attributes = Object.keys(dataToShow[0]).map((key) => {
          if (keysToHide.includes(key)) {
            return {
              name: key,
              options: { display: false, sort: false },
            };
          }
          return {
            name: key,
            options: {
              customHeadRender: () => renderHead(mappedSortTypes, key),
            },
          };
        });

        attributes.push({
          name: '',
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) =>
              renderActions(value, tableMeta),
          },
        });
      }
      return attributes;
    },
    [t, renderHead, renderActions],
  );

  attributes = createTableAttributes(dataToShow, attributes, keysToHide);

  /**
   * Generates the table filter's config
   */
  const filtersConfig = useTableFilterConfig('team');

  /**
   * Closes the filter's modal and save data for the filters
   */
  const onSaveFilters = useCallback((filters) => setTeamFilters(filters), []);

  /**
   * Checks does table have data
   */
  const isDataTableEmpty = dataToShow.length <= 0;

  /**
   * Flag to checks does filters active
   */
  const isFilters = Object.keys(teamFilters || {})?.length > 0;

  /**
   * mui datatable optional class names
   */
  const muiTableMods = {
    loading: isLoading,
    'no-data': isDataTableEmpty,
    filters: isFilters,
  };

  /**
   * Reset page for search if it's not the first page and there is no results
   */
  useEffect(() => {
    const pagesLimit = Math.ceil(totalTeams / limit);
    if (page > pagesLimit && debouncedSearchTerm) setPage(0);
  }, [debouncedSearchTerm, limit, page, totalTeams]);

  /**
   * Reset page for existings filters if it's not the first page and there is no results
   */
  useEffect(() => {
    if (isFilters && dataToShow.length <= 0 && page > 0) setPage(0);
  }, [dataToShow.length, isFilters, page]);

  /**
   * Clears teams on leaving the page
   */
  useEffect(() => {
    if (organizationOptions.length <= 0 && hasOrganizationAccess)
      getAllOrganizations();
    return () => {
      clearTeams();
    };
  }, []);

  return (
    <>
      <TableHeader
        buttonTitle='buttons.create.team'
        title='teams.title'
        subTitle='teams.subtitle'
        onClickCallback={openModal}
        btnIcon={<AddTeamIcon />}
      />
      <Box className='table-container'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={getMuiTheme(
              { cursor: 'pointer' },
              {
                '&:nth-of-type(6)': {
                  width: 100,
                },
              },
            )}
          >
            <MUIDataTable
              ref={tableRef}
              data={isLoading ? muiTableLoadingSkeleton('body') : dataToShow}
              columns={
                isLoading ? muiTableLoadingSkeleton('header') : attributes
              }
              options={tableConfig(
                options(),
                isLoading,
                isDataTableEmpty,
                tableRef,
                isFilters,
                onOpenFilterModal,
                exportData,
              )}
              components={muiComponents(teamFilters, setTeamFilters)}
              className={classNames('mui-data-table search-open', muiTableMods)}
              title=''
            />
          </ThemeProvider>
        </StyledEngineProvider>
        {/* Edit / create organization modal */}
        {modalIsOpen && (
          <FormModal
            isOpen={modalIsOpen}
            title={
              teamToEdit ? t('buttons.edit.team') : t('buttons.create.team')
            }
            setOpen={setModalIsOpen}
            onClose={closeModal}
          >
            <CreateEditTeamForm
              closeModal={closeModal}
              teamToEdit={teamToEdit}
            />
          </FormModal>
        )}
        {/* Delete confirmation modal */}
        <ModalUI
          modalSx={deleteConfirmation}
          open={deleteModalIsOpen}
          isCloseBtn={false}
          onCloseHandler={onCloseDeleteModal}
          className='confirmation-modal'
          modalTitle={t('alerts.titles.delete')}
        >
          <SimpleModalContent
            content={['alerts.confirm.team']}
            mainBtn='buttons.delete'
            onMainBtnClick={onDeleteTeam}
            secondaryBtn='buttons.cancel'
            onSecondaryBtnClick={onCloseDeleteModal}
          />
        </ModalUI>
        {/* Filter modal */}
        {filterOpen && (
          <CustomMuiDataTableFilter
            isOpen={filterOpen}
            setFilterState={setFilterOpen}
            config={filtersConfig}
            filters={teamFilters}
            onSaveHandler={onSaveFilters}
          />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllTeams: teamActions.getAllTeams,
  getTeamsCount: teamActions.getTeamsCount,
  deleteTeam: teamActions.deleteTeam,
  clearTeams: teamActions.clearTeams,
  getAllOrganizations: organizationActions.getShortOrganizationsWithoutMetadata,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsTable);
