import { memo, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { CallMergeOutlined } from '@mui/icons-material';

import { useActions } from '../../../hooks/useActions';

import { OrganizationSelect } from '../OrganizationSelect';
import { ButtonUi } from '../../UI/Button/Button';
import SimpleModalContent from '../../UI/SimpleModalContent/SimpleModalContent';
import { ModalUI } from '../../UI/Modal/Modal';

import { mergeOrganizationDialogStyles } from './style';
import { deleteConfirmationStyle } from '../../UI/SimpleModalContent/style';

export const MergeOrganizationDialog = memo((props) => {
  const { organizationFromMerge, closeModal } = props;
  const { t } = useTranslation();

  const { mergeOrganizations } = useActions();
  const { isLoading } = useSelector((state) => state.organizations);

  const [mergeOrganization, setMergeOrganization] = useState(null);
  const [mergeConfirmationOpen, setMergeConfirmationOpen] = useState(false);

  const { mergeOrganizationDialog, organizationSelect } =
    mergeOrganizationDialogStyles;
  const { deleteConfirmation } = deleteConfirmationStyle;

  /**
   * Sets merge organization on organization select
   */
  const selectMergeOrganization = useCallback(
    (org) => setMergeOrganization(org),
    [],
  );

  /**
   * Open merge confirmation dialog
   */
  const openMergeConfirmation = useCallback(
    () => setMergeConfirmationOpen(true),
    [],
  );

  /**
   * Close merge confirmation dialog
   */
  const closeMergeConfirmation = useCallback(
    () => setMergeConfirmationOpen(false),
    [],
  );

  /**
   * Close merge confirmation dialog
   */
  const organizationsMerge = useCallback(() => {
    mergeOrganizations(organizationFromMerge?.id, mergeOrganization?.value);
    setMergeConfirmationOpen(false);
    setTimeout(() => closeModal(), 500);
  }, [
    organizationFromMerge,
    mergeOrganization,
    mergeOrganizations,
    closeModal,
  ]);

  return (
    <>
      <Box sx={mergeOrganizationDialog}>
        <Box className='merge-visual'>
          <CallMergeOutlined className='merge-icon' />
        </Box>
        <Box className='content'>
          <Box className='label-value-row'>
            <Typography variant='h5' className='data-label'>
              {t('organizations.current')}:
            </Typography>
            <Typography variant='h5' className='data-value'>
              {organizationFromMerge?.name}
            </Typography>
          </Box>
          <Box className='label-value-row'>
            <Typography variant='h5' className='data-label'>
              {t('organizations.merge-to')}:
            </Typography>
            <OrganizationSelect
              excludeIds={[organizationFromMerge?.id]}
              selfSx={organizationSelect}
              handleChange={selectMergeOrganization}
            />
          </Box>
        </Box>
        <Box className='content-footer'>
          <ButtonUi
            className='main-btn'
            variant='outlined'
            type='input'
            size='small'
            disabled={!mergeOrganization}
            onClickHandler={openMergeConfirmation}
          >
            {t('buttons.merge')}
          </ButtonUi>
          <ButtonUi
            className='secondary-btn'
            variant='outlined'
            type='secondary'
            size='small'
            onClickHandler={closeModal}
          >
            {t('buttons.cancel')}
          </ButtonUi>
        </Box>
      </Box>
      {/* Delete confirmation modal */}
      <ModalUI
        modalSx={deleteConfirmation}
        open={mergeConfirmationOpen}
        isCloseBtn={false}
        onCloseHandler={closeMergeConfirmation}
        className='confirmation-modal'
        modalTitle={t('alerts.titles.organization-merge-confirmation')}
      >
        <SimpleModalContent
          content={[
            {
              translation: t('alerts.confirm.merge-organization', {
                interpolation: { escapeValue: false },
                mergeOrganization: organizationFromMerge?.name,
                destinationOrg: mergeOrganization?.text,
              }),
              isHtml: true,
            },
          ]}
          isLoading={isLoading}
          mainBtn='buttons.confirm'
          onMainBtnClick={organizationsMerge}
          secondaryBtn='buttons.cancel'
          onSecondaryBtnClick={closeMergeConfirmation}
        />
      </ModalUI>
    </>
  );
});
