import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const modalStyle = {
  modal: {
    '& .MuiBackdrop-root': {
      background: theme.palette.myPrimary.white15,
      backdropFilter: 'blur(15px)',
    },
    '& .modal-wrapper': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      filter: 'drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.15))',
      borderRadius: theme.palette.propVars.borderRadius,
      maxHeight: '90vh',
      ...theme.palette.propVars.modalBg,
      '& .modal-header': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: getResponsiveSize(31.5), // 31.5px
        px: getResponsiveSize(36), // 36px
        borderRadius: `${theme.palette.propVars.borderRadius} ${theme.palette.propVars.borderRadius} 0 0`,
        borderBottom: `1px solid  ${theme.palette.myPrimary.lightBlue20}`,
        '& .title': {
          color: theme.palette.neutral.grey70,
          fontWeight: 700,
          lineHeight: getResponsiveSize(27), // 27px
          fontSize: getResponsiveSize(19), // 19px
        },
        '& .close-btn': {
          color: theme.palette.neutral.grey70,
          p: getResponsiveSize(5), // 5px
          '& svg': {
            width: getResponsiveSize(19), // 19px
            height: getResponsiveSize(20), // 20px
            fill: theme.palette.neutral.grey70,
          },
        },
      },
      // Laptop screen
      [theme.breakpoints.media.laptop]: {
        '& .modal-header': {
          '& .title': {
            fontSize: getResponsiveSize(15, laptopRatio), // 15px
          },
          '& .close-btn': {
            p: getResponsiveSize(5, laptopRatio), // 5px
            '& svg': {
              width: getResponsiveSize(15, laptopRatio), // 15px
              height: getResponsiveSize(16, laptopRatio), // 16px
            },
          },
        },
      },
      // Table simple responsive screen
      [theme.breakpoints.media.tableResponsive]: {
        borderRadius: getResponsiveSize(8, mobileRatio), // 8px

        '& .modal-header': {
          '& .title': {
            fontSize: getResponsiveSize(15, mobileRatio), // 15px
          },
          '& .close-btn': {
            p: getResponsiveSize(5, mobileRatio), // 5px
            '& svg': {
              width: getResponsiveSize(15, mobileRatio), // 15px
              height: getResponsiveSize(16, mobileRatio), // 16px
            },
          },
        },
      },
    },
  },
};
