/* eslint-disable react/jsx-props-no-spreading */
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { connect } from 'react-redux';
import { DEVICE_STREAM_URL } from '../utils/config';
import { DevicesSelect as NewDevSel } from '../components/Devices/DevicesSelectNew';
import Loader from '../components/Loader';
import { OrganizationSelect } from '../components/Organizations/OrganizationSelect';
import { deviceActions } from '../store/actions/device.actions';
import { organizationActions } from '../store/actions/organization.actions';
import { dashboardStyle } from './style';

const organizationFields = { fields: ['id', 'name'] };

let DeviceDetailPage = ({
  match,
  getDeviceById,
  devices,
  history,
  i18n,
  authentication,
  getAllOrganizations,
  organizations,
}) => {
  const [deviceId, setDeviceId] = useState(match.params.id);
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [stats, setStats] = useState([]);
  const [activeTabValue, setActiveTabValue] = useState(0);
  const [organizationToFilter, setOrganizationToFilter] = useState();
  const {
    shortOrganizations: organizationOptions,
    isLoading: organizationsIsLoading,
  } = organizations;
  // role checker
  const { userIsAdmin, user } = authentication;
  const token = user?.id;
  const {
    remoteView: { selectOrganization },
  } = dashboardStyle;

  const handleChange = (event, index) => {
    setActiveTabValue(index);
  };

  /**
   * Sets value to the organization autocomplete
   * @param value selected value
   */
  const onOrganizationSelect = useCallback((value) => {
    if (!value || !value.value) {
      setOrganizationToFilter(null);
      return;
    }
    setOrganizationToFilter(value.value);
  }, []);

  /**
   * Render Organization dropdown select if user has needed role
   */
  const renderOrganizationSelect = useMemo(() => {
    if (!userIsAdmin) return null;

    if (!organizationToFilter && !deviceId)
      setOrganizationToFilter(organizationOptions?.[0]?.id);

    return (
      <OrganizationSelect
        selfSx={selectOrganization}
        selectedId={organizationToFilter}
        handleChange={onOrganizationSelect}
      />
    );
  }, [
    userIsAdmin,
    organizationToFilter,
    deviceId,
    organizationOptions,
    selectOrganization,
    onOrganizationSelect,
  ]);

  /**
   * Render device dropdown select
   */
  const renderDeviceSelect = useMemo(
    () => (
      <NewDevSel
        organizationId={organizationToFilter}
        selectedId={deviceId}
        showDeviceNumber
        label='forms.device'
        handleChange={(newDevice) => {
          setDeviceId(newDevice?.value ? newDevice?.value : '');
        }}
      />
    ),
    [organizationToFilter, deviceId],
  );

  /**
   * Sets organization based on the selected device
   */
  useEffect(() => {
    if (userIsAdmin && !organizationToFilter && devices?.selectedDevice) {
      getAllOrganizations(organizationFields).then((orgs) => {
        const selectedDeviceOrganizationId = orgs.find(
          (org) => org?.id === devices?.selectedDevice?.organizationId,
        )?.id;
        setOrganizationToFilter(selectedDeviceOrganizationId);
      });
    }
  }, [
    getAllOrganizations,
    devices,
    userIsAdmin,
    organizationToFilter,
    organizationOptions,
  ]);

  useEffect(() => {
    const firstDevice = (devices?.devices || [])[0];
    if (deviceId !== 'null' || !firstDevice) return;

    setDeviceId(firstDevice.id);
    history.push(`/admin/devices/${firstDevice.id}`);
  }, [deviceId, devices, history]);

  useEffect(() => {
    if (!deviceId || deviceId === 'null') {
      return;
    }
    setIsLoadingStats(true);
    getDeviceById(deviceId);
    deviceActions
      .getStatsOfDeviceById(deviceId)
      .then((statistics) => {
        setStats(statistics.data);
        setIsLoadingStats(false);
      })
      .catch(() => {
        setStats([]);
        setIsLoadingStats(false);
      });
  }, [deviceId, getDeviceById]);

  const { selectedDevice } = devices || {};
  const counts = stats.map((item) => parseInt(item.count, 10));
  const months = stats.map(
    (item) => `${moment(item.week).format('DD-MM-YYYY')}   `,
  );

  const options = {
    chart: {
      id: 'basic-bar',
      background: '#fff',
    },
    xaxis: {
      categories: months,
    },
    series: [
      {
        name: 'Weekly session count',
        data: counts,
      },
    ],
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  if (isLoadingStats || organizationsIsLoading) {
    return (
      <div className='main-container-center'>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className='device-detail-filter-wrapper'>
        {renderOrganizationSelect}
        <div className='select-wrapper'>{renderDeviceSelect}</div>
      </div>
      {!deviceId && (
        <div className='holding-placeholder'>
          <h3>{i18n.t('device-details.no-device.title')}</h3>
          <h4>{i18n.t('device-details.no-device.sub-title')}</h4>
        </div>
      )}
      {deviceId && (
        <Tabs
          indicatorColor='primary'
          style={{ paddingBottom: '4px' }}
          value={activeTabValue}
          onChange={handleChange}
          aria-label='Device content tabs'
        >
          <Tab label={i18n.t('tabs.device-feed')} {...a11yProps(0)} />
          <Tab label={i18n.t('tabs.statistics')} {...a11yProps(1)} />
        </Tabs>
      )}
      {deviceId && activeTabValue === 1 && (
        <div className='device-detail-container'>
          <div className='chart-container'>
            {stats.length > 0 ? (
              <>
                <div className='chart-title'>
                  <h3>Session overview </h3>
                </div>
                <Chart
                  options={options}
                  series={options.series}
                  type='bar'
                  height='300'
                />
              </>
            ) : (
              'No session data found.'
            )}
          </div>
        </div>
      )}
      {deviceId && activeTabValue === 0 && (
        <div className='device-detail-container'>
          <iframe
            scrolling='no'
            style={{
              width: '100%',
              minHeight: '80vh',
            }}
            title='device-live-feed'
            // eslint-disable-next-line max-len
            src={`${DEVICE_STREAM_URL}?device=${selectedDevice.generated_UUID}&language=${i18n.language}&token=${token}`}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getDeviceById: deviceActions.getDeviceById,
  getAllOrganizations: organizationActions.getShortOrganizationsWithoutMetadata,
};

export default DeviceDetailPage = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DeviceDetailPage),
);
