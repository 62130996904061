import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const headerTablePage = {
  headerTable: {
    px: getResponsiveSize(16), // 16px
    mb: getResponsiveSize(16), // 16px
    '& .title-group': {
      '& .title-row': {
        display: 'flex',
        justifyContent: 'space-between',
        mb: getResponsiveSize(16), // 16px
        '& .action-btn': {
          '& .MuiButton-startIcon': {
            mr: getResponsiveSize(12), // 12px
            ml: 0,
            '& svg': {
              fontSize: getResponsiveSize(24), // 24px
              fill: theme.palette.neutral.white,
              width: getResponsiveSize(24), // 24px
              height: getResponsiveSize(24), // 24px
              '&.device-icon': {
                fill: 'none',
                '& path': {
                  '&.vr-boundaries': {
                    stroke: theme.palette.myPrimary.white,
                    strokeWidth: '1.3px',
                  },
                  '&.vr-nose, &.screen': {
                    fill: theme.palette.myPrimary.white,
                  },
                },
              },
              '&.treatment-icon path:nth-of-type(1)': {
                stroke: theme.palette.myPrimary.white,
                fill: 'transparent',
              },

              '&.practitioner-icon path': {
                '&:nth-of-type(3), :nth-of-type(4)': {
                  stroke: theme.palette.myPrimary.white,
                },
              },
            },
          },
        },
      },
      '& .main-title': {
        alignSelf: 'center',
        justifyContent: 'space-between',
        color: theme.palette.myPrimary.lightBlue100,
      },
    },
    '& .desc-wrapper': {
      height: '7dvh',
      maxHeight: '7dvh',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: getResponsiveSize(6), // 6px
      },
      '&::-webkit-scrollbar-track': {
        webkitBorderRadius: '100px',
        borderRadius: '100px',
      },
      '&::-webkit-scrollbar-thumb': {
        webkitBorderRadius: '100px',
        borderRadius: '100px',
        background: theme.palette.myPrimary.lightBlue20,
      },
      '& .title-description': {
        color: theme.palette.neutral.lightGrey80,
        fontWeight: '400',
        width: '80%',
        textAlign: 'justify',
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .title-group .title-row': {
        '& .action-btn': {
          fontSize: getResponsiveSize(16, laptopRatio), // 16px
          '& .MuiButton-startIcon': {
            '& svg': {
              fontSize: getResponsiveSize(20, laptopRatio), // 20px
              width: getResponsiveSize(20, laptopRatio), // 20px
              height: getResponsiveSize(20, laptopRatio), // 20px
            },
          },
        },
      },
      '& .desc-wrapper': {
        height: '5dvh',
        '.title-description': {
          width: '100%',
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      px: 0,
      mb: getResponsiveSize(8, mobileRatio), // 8px
      '& .title-group .title-row': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: getResponsiveSize(8, mobileRatio), // 8px
        '& .main-title': {
          alignSelf: 'flex-start',
        },
        '& .action-btn': {
          fontSize: getResponsiveSize(15, mobileRatio), // 15px
          '& .MuiButton-startIcon': {
            '& svg': {
              fontSize: getResponsiveSize(20, mobileRatio), // 20px
              width: getResponsiveSize(20, mobileRatio), // 20px
              height: getResponsiveSize(20, mobileRatio), // 20px
            },
          },
        },
      },
    },
  },
};
