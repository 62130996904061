import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { wizardErrorStyles } from '../style';

export const WizardError = memo((props) => {
  const { children, className } = props;
  const { errorContainer } = wizardErrorStyles;
  return (
    <Box sx={errorContainer} className={className}>
      <Typography variant='h6'>{children}</Typography>
    </Box>
  );
});
