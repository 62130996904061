import * as React from 'react';
import DeviceConnection from './DeviceConnection/DeviceConnection';
import SelectClient from './SelectClient/SelectClient';
import SelectDevice from './SelectDevice/SelectDevice';
import TherapyLocation from './TherapyLocation/TherapyLocation';

export const wizardSteps = [
  {
    stepId: 0,
    stepName: 'product',
    label: 'create-treatment-wizard.progress-panel.product-step',
    infoLabel: 'create-treatment-wizard.info-panel.selected-product',
  },
  {
    stepId: 1,
    stepName: 'client',
    label: 'create-treatment-wizard.progress-panel.client-step',
    infoLabel: 'create-treatment-wizard.info-panel.selected-client',
    component: <SelectClient />,
    nodeRef: React.createRef(),
  },
];

export const deviceSelection = {
  stepId: 2,
  stepName: 'device',
  label: 'create-treatment-wizard.progress-panel.device-step',
  infoLabel: 'create-treatment-wizard.info-panel.selected-device',
  nodeRef: React.createRef(),
  component: <SelectDevice />,
};

export const deviceSync = {
  stepId: 3,
  stepName: 'device-sync',
  label: 'create-treatment-wizard.progress-panel.device-sync-step',
  title: 'Follow the instruction to sync the device',
  nodeRef: React.createRef(),
  component: <DeviceConnection />,
};

export const therapyType = {
  stepId: 3,
  stepName: 'location-chooser',
  label: 'create-treatment-wizard.info-panel.selected-therapy-type',
  title: 'Do you want to use video/call conferencing?',
  infoLabel: 'create-treatment-wizard.info-panel.selected-therapy-type',
  nodeRef: React.createRef(),
  component: <TherapyLocation />,
};
