import { laptopRatio, mobileRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const filterModalStyle = {
  filterModal: {
    '&.filter-modal': {
      '& .modal-wrapper': {
        width: getResponsiveSize(1010), // 1010px
        '& .modal-header': {
          backgroundColor: '#2392BA0D',
        },
        '& .filters-wrapper': {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          py: getResponsiveSize(31.5), // 31.5px
          px: getResponsiveSize(36), // 36px
          gap: getResponsiveSize(16), // 16px
          '& .filter-row': {
            display: 'flex',
            flexDirection: 'column',
            '& .title': {
              color: theme.palette.neutral.grey70,
              fontFamily: theme.typography.secondaryFontFamily,
              fontSize: getResponsiveSize(24), // 24px
              mb: getResponsiveSize(12), // 12px
              ...theme.palette.propVars.regularFontWeight,
            },
            '& .MuiAutocomplete-root': {
              '& .MuiCircularProgress-root': {
                mr: getResponsiveSize(16), // 16px
                color: theme.palette.myPrimary.lightBlue70,
              },
            },
            '& .toggle': {
              width: getResponsiveSize(428),
              '& .mask': {
                width: getResponsiveSize(204), // 153px
                height: getResponsiveSize(39), // 39px
              },
              '& .MuiButtonBase-root': {
                height: getResponsiveSize(39), // 39px
                width: getResponsiveSize(210), // 210px
              },
            },
          },
        },
        '& .filter-footer': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#2392BA0D',
          py: getResponsiveSize(15), // 31.5px
          px: getResponsiveSize(36), // 36px
          borderRadius: `0 0 ${theme.palette.propVars.borderRadius} ${theme.palette.propVars.borderRadius}`,
          borderBottom: `1px solid  ${theme.palette.myPrimary.lightBlue20}`,
          '& .action-btn': {
            minWidth: getResponsiveSize(120), // 120px
          },
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '&.filter-modal': {
        '& .modal-wrapper': {
          width: getResponsiveSize(720, laptopRatio), // 720px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '&.filter-modal': {
        '& .modal-wrapper': {
          width: '85dvw',
          '& .filters-wrapper': {
            gap: getResponsiveSize(12, mobileRatio), // 12px
            '& .filter-row': {
              width: '100%',
            },
          },
        },
      },
    },
  },
};
