import { theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const clientStepStyles = {
  clientSelect: {
    '& .checkbox-card-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      gap: getResponsiveSize(44), // 44px
    },
    // Tablet screen
    [theme.breakpoints.media.tablet]: {
      '& .checkbox-card-wrapper': {
        flexDirection: 'column',
      },
    },
  },
};
