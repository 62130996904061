import { deviceConstants } from '../constants/device.constants';

export function devices(
  state = {
    devices: [],
    devicesNames: [],
    devicesSerialNumbers: [],
    deviceProfiles: [],
    selectedDevice: {},
    totalDevices: 0,
  },
  action,
) {
  switch (action.type) {
    case deviceConstants.GETALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deviceConstants.GETALL_SUCCESS:
      const { devices, count } = action;
      return {
        ...state,
        devices,
        isLoading: false,
        totalDevices: count,
      };
    case deviceConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case deviceConstants.GETALL_NAMES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deviceConstants.GETALL_NAMES_SUCCESS:
      const { names: devicesNames } = action;
      return {
        ...state,
        devicesNames,
        isLoading: false,
      };
    case deviceConstants.GETALL_NAMES_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case deviceConstants.GETALL_SERIALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deviceConstants.GETALL_SERIALS_SUCCESS:
      const { devicesSerialNumbers } = action;
      return {
        ...state,
        devicesSerialNumbers,
        isLoading: false,
      };
    case deviceConstants.GETALL_SERIALS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case deviceConstants.GETALL_PROFILE_REQUEST:
      return {
        ...state,
        isLoadingProfiles: true,
      };
    case deviceConstants.GETALL_PROFILE_SUCCESS:
      return {
        ...state,
        deviceProfiles: action.deviceProfiles,
        isLoadingProfiles: false,
      };
    case deviceConstants.GETALL_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoadingProfiles: false,
      };
    case deviceConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deviceConstants.UPDATE_SUCCESS:
      const fetchedDevice = action.device;
      const newDevices = state.devices.filter(
        (item) => item.id !== fetchedDevice.id,
      );
      return {
        ...state,
        devices: [...newDevices, fetchedDevice],
        isLoading: false,
      };
    case deviceConstants.UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case deviceConstants.UPDATE_FEW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deviceConstants.UPDATE_FEW_SUCCESS:
      const updatedDevice = action.devices;
      const updatedDeviceIds = updatedDevice.map((device) => device.id);
      const oldDevices = state.devices.filter(
        (item) => !updatedDeviceIds.includes(item.id),
      );
      return {
        ...state,
        devices: [...oldDevices, ...updatedDevice],
        isLoading: false,
      };
    case deviceConstants.UPDATE_FEW_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case deviceConstants.GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deviceConstants.GET_SUCCESS:
      return {
        ...state,
        selectedDevice: action.device,
        isLoading: false,
      };
    case deviceConstants.GET_FAILURE:
      return {
        ...state,
        error: true,
      };
    case deviceConstants.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deviceConstants.CREATE_SUCCESS: {
      const { device } = action;
      const { device_profile_fk } = device;
      const { deviceProfiles, devices } = state;
      const foundDeviceProfile = deviceProfiles.find(
        (item) => item.id === device_profile_fk,
      );
      const addedDevice = { ...device, deviceprofile: foundDeviceProfile };
      return {
        ...state,
        devices: [...devices, addedDevice],
        isLoading: false,
      };
    }
    case deviceConstants.CREATE_FEW_SUCCESS: {
      const { devices: addedDevices } = action;
      const { device_profile_fk } = addedDevices[0];
      const { deviceProfiles, devices } = state;
      const foundDeviceProfile = deviceProfiles.find(
        (item) => item.id === device_profile_fk,
      );
      const newDevices = addedDevices.map((device) => ({
        ...device,
        deviceprofile: foundDeviceProfile,
      }));
      return {
        ...state,
        devices: [...devices, newDevices],
        isLoading: false,
      };
    }
    case deviceConstants.CREATE_FAILURE:
      return {
        ...state,
        error: true,
      };
    case deviceConstants.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deviceConstants.DELETE_SUCCESS:
      const { id } = action;
      const filteredDevices = state.devices.filter((item) => item.id !== id);
      return {
        ...state,
        devices: filteredDevices,
        isLoading: false,
      };
    case deviceConstants.DELETE_FAILURE:
      return {
        ...state,
        error: true,
      };
    case deviceConstants.COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deviceConstants.COUNT_SUCCESS:
      return {
        ...state,
        totalDevices: action.count,
      };
    case deviceConstants.COUNT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case deviceConstants.CLEAR_DEVICES:
      return {
        ...state,
        devices: [],
      };
    default:
      return state;
  }
}
