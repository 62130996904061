import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as EMDRPlusIcon } from '../../shared/assets/icons/EMDR-Plus.svg';
import { ReactComponent as EMDRRemoteIcon } from '../../shared/assets/icons/EMDR-Remote.svg';
import { ReactComponent as EMDRVRIcon } from '../../shared/assets/icons/EMDR-VR.svg';
import { ReactComponent as PlusIcon } from '../../shared/assets/icons/treatment-creation-result/info-panel/EMDR-Plus.svg';
import { ReactComponent as RemoteIcon } from '../../shared/assets/icons/treatment-creation-result/info-panel/EMDR-Remote.svg';
import { ReactComponent as VRIcon } from '../../shared/assets/icons/treatment-creation-result/info-panel/EMDR-VR.svg';

import { clientService } from '../../services/clients';
import { productsService } from '../../services/products';

import useFetch from '../../hooks/useFetch';

import { authUrl } from '../../utils/auth-header';
import BASEURL, { ANON_CLIENT_IDENTIFIER } from '../../utils/config';

import { useActions } from '../../hooks/useActions';
import Loader from '../Loader';
import { ButtonUi } from '../UI/Button/Button';
import { FormModal } from '../UI/FormModal/FormModal';
import { quickStartStyle } from './style';

const productIcons = {
  'EMDR-Remote': {
    ProductIcon: <EMDRRemoteIcon className='img' />,
    ButtonIcon: <RemoteIcon />,
  },
  'EMDR-VR': {
    ProductIcon: <EMDRVRIcon className='img' />,
    ButtonIcon: <VRIcon />,
  },
  'EMDR-Plus': {
    ProductIcon: <EMDRPlusIcon className='img' />,
    ButtonIcon: <PlusIcon />,
  },
};

export const QuickStartSession = (props) => {
  const { isOpen, close, teamId, history, setIsValidSubscription } = props;
  const { t } = useTranslation();
  const { quickStart } = quickStartStyle;

  const { getAvailableProducts } = useActions();
  const { availableProducts, isLoading: productsLoqading } = useSelector(
    (state) => state.products,
  );
  const {
    organizationId,
    user: { userId },
  } = useSelector((state) => state.authentication);

  const restrictedProducts = ['Medical Hypnosis'];

  const filter = { where: { name: ANON_CLIENT_IDENTIFIER } };
  const url = `${BASEURL}/clients${authUrl()}&filter=${JSON.stringify(filter)}`;
  const { response, isLoading: clientLoading } = useFetch(url, {});

  const [clientToUse, setClientToUse] = useState(null);

  const isLoading = productsLoqading || clientLoading;

  useEffect(() => {
    if (response && response.length > 0) {
      setClientToUse(response[0]);
    }
  }, [response]);

  const createAnonymousClient = async () => {
    try {
      const response = await clientService.createClient({
        name: ANON_CLIENT_IDENTIFIER,
        teamId,
      });
      const { data: client } = response || {};
      return client;
    } catch (e) {
      return null;
    }
  };

  const findProduct = async (productName) => {
    try {
      const productsResponse =
        await productsService.getActiveSubscriptionsByUser(
          organizationId,
          userId,
        );
      const { data: products } = productsResponse || {};
      return products.find((item) => item.name.includes(productName));
    } catch (e) {
      return null;
    }
  };

  const handleProductClick = async (product) => {
    try {
      let client = clientToUse;
      if (!client) {
        client = await createAnonymousClient();
      }
      const foundProduct = await findProduct(product);
      if (!foundProduct) {
        // TODO: handle error'
        setIsValidSubscription(false);
        close();
        return;
      }
      const { id: productId } = foundProduct;
      history.push({
        pathname: '/admin/treatments/create',
        search: `?clientId=${client.id}&productId=${productId}&quickStart=true`,
      });
    } catch (e) {
      history.push('/login');
    }
  };

  useEffect(() => {
    getAvailableProducts(organizationId);
  }, []);

  return (
    <FormModal isOpen={isOpen} title={t('quick_start.title')} onClose={close}>
      {isLoading ? (
        <Box sx={quickStart} className='loader'>
          <Loader />
        </Box>
      ) : (
        <Grid container sx={quickStart}>
          <Box className='quick-start-title'>
            <Typography variant='h6' className='title'>
              {t('quick_start.info')}
            </Typography>
          </Box>
          {availableProducts.map((product) => {
            // TODO: remove the Medical Hypnosis filtering when it becomes correct
            if (restrictedProducts.includes(product.name)) return null;
            return (
              <Grid
                key={product.id}
                item
                mobile={12}
                tablet={4}
                className={product.name.toLowerCase()}
              >
                <Box className='session-container'>
                  {productIcons[product.name].ProductIcon}
                  <ButtonUi
                    startIcon={productIcons[product.name].ButtonIcon}
                    className='btn product-btn'
                    variant='outlined'
                    type='primary'
                    size='small'
                    onClickHandler={() => handleProductClick(product.name)}
                  >
                    {t(`quick_start.${product.name.toLowerCase()}`)}
                  </ButtonUi>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
    </FormModal>
  );
};
