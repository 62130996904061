import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../../../shared/assets/icons/treatment-creation-result/info-panel/EMDR-Plus.svg';
import { ReactComponent as RemoteIcon } from '../../../../shared/assets/icons/treatment-creation-result/info-panel/EMDR-Remote.svg';
import { ReactComponent as VRIcon } from '../../../../shared/assets/icons/treatment-creation-result/info-panel/EMDR-VR.svg';

import { pillIconStyles } from './style';

export const CustomMuiDatatableEmdrType = memo(({ type }) => {
  const { container } = pillIconStyles;

  const TreatmentIcon = useMemo(() => {
    switch (type) {
      case 'EMDR-Remote':
        return <RemoteIcon className='remote' />;
      case 'EMDR-Plus':
        return <PlusIcon className='plus' />;
      case 'EMDR-VR':
        return <VRIcon className='vr' />;
    }
  }, [type]);

  return (
    <Box sx={container}>
      {TreatmentIcon} {type}
    </Box>
  );
});
