import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as SettingsIcon } from '../../../../shared/assets/icons/sidenav/settings.svg';
import { ReactComponent as TaskIcon } from '../../../../shared/assets/icons/advanced-settings/task.svg';
import { ReactComponent as SphereIcon } from '../../../../shared/assets/icons/advanced-settings/sphere.svg';
import { ReactComponent as ReactionIcon } from '../../../../shared/assets/icons/advanced-settings/reaction.svg';
import { ReactComponent as AudioIcon } from '../../../../shared/assets/icons/advanced-settings/audio.svg';
import { ADVANCED_SETTINGS_GROUPS } from '../../../../utils/wizard-helper';

/**
 * Generate Advenced settings groups with group settings data
 */
export const useAdvancedSettingsGroupsData = () => {
  const { selectedProduct } = useSelector(
    (state) => state.createTreatmentWizard,
  );

  return useMemo(
    () =>
      Object.values(
        selectedProduct.variables.reduce((items, variable) => {
          const { group } = variable.ui_configuration;
          const noGroup = 'no group';
          if (group) {
            items[group] = items[group] || {
              groupName: group,
              variables: [],
              Icon: mapGroupsIconst(group),
            };
            // TODO refactor variables config on backend
            if (
              variable.name === 'task_clicks_replace' ||
              variable.name === 'task_sphere_random_clr'
            )
              return items;
            if (variable.name === 'task_include_clicks') {
              items[group].variables.push({
                ...variable,
                ui_configuration: {
                  ...variable.ui_configuration,
                  type: 'combine-toggle',
                  toggle_options: [
                    {
                      type: 'text',
                      content: 'with_clicks',
                      variable: variable.name,
                    },
                    {
                      type: 'text',
                      content: 'without_clicks',
                      variable: 'task_clicks_replace',
                    },
                  ],
                },
              });
            } else if (variable.name === 'task_sphere_speed') {
              items[group].variables.push({
                ...variable,
                type: 'number',
                ui_configuration: {
                  created: variable.ui_configuration.created,
                  group: variable.ui_configuration.group,
                  type: 'slider',
                  slider_max: 250,
                  slider_min: 50,
                  slider_step: 1,
                  slider_label: '%',
                  percentage: true,
                },
              });
            } else if (variable.name === 'task_sphere_color') {
              items[group].variables.push({
                ...variable,
                type: 'string',
                ui_configuration: {
                  ...variable.ui_configuration,
                  order: 4,
                  type: 'combine-toggle',
                  toggle_options: [
                    {
                      type: 'dropdown',
                      dropdownOptions: variable.ui_configuration?.values,
                      variable: variable.name,
                    },
                    {
                      type: 'text',
                      content: 'random_color',
                      variable: 'task_sphere_random_clr',
                    },
                  ],
                },
              });
            } else if (variable.name === 'task_sphere_reaction_include') {
              // Response task
              items[group].variables.push({
                ...variable,
                ui_configuration: {
                  ...variable.ui_configuration,
                  order: 5,
                  iconName: 'response-task',
                  desc: 'response_task_description',
                },
              });
            } else if (variable.name === 'task_sphere_path_reverse') {
              // Random reverse
              items[group].variables.push({
                ...variable,
                ui_configuration: {
                  ...variable.ui_configuration,
                  order: 7,
                  iconName: 'random-reverse',
                  desc: 'random_reverse_description',
                },
              });
            } else if (variable.name === 'task_sphere_audio') {
              // Audio clicks
              items[group].variables.push({
                ...variable,
                ui_configuration: {
                  ...variable.ui_configuration,
                  order: 6,
                  iconName: 'audio-clicks',
                  desc: 'audio_clicks_description',
                },
              });
            } else if (variable.name === 'task_reaction_feedback') {
              // Feedback
              items[group].variables.push({
                ...variable,
                ui_configuration: {
                  ...variable.ui_configuration,
                  order: 3,
                  iconName: 'feedback',
                  desc: 'feedback_description',
                },
              });
            } else if (variable.name === 'task_clicks_random_noise') {
              // Buzzer
              items[group].variables.push({
                ...variable,
                ui_configuration: {
                  ...variable.ui_configuration,
                  order: 2,
                  iconName: 'buzzer',
                  desc: 'buzzer_description',
                },
              });
            } else if (variable.name === 'task_reaction_increment') {
              items[group].variables.push({
                ...variable,
                ui_configuration: {
                  ...variable.ui_configuration,
                  order: 2,
                },
              });
            } else if (variable.name === 'task_sphere_path_option') {
              // Trajectory
              // TODO: Change task_sphere_path_option translation to Trajectory
              items[group].variables.push({
                ...variable,
                type: 'string',
                ui_configuration: {
                  ...variable.ui_configuration,
                  type: 'selection-card-group',
                  options: [
                    {
                      value: variable.ui_configuration.values[0],
                      content: variable.ui_configuration.values[0],
                      iconName: 'linear-path',
                    },
                    {
                      value: variable.ui_configuration.values[2],
                      content: variable.ui_configuration.values[2],
                      iconName: 'bouncing-path',
                    },
                    {
                      value: variable.ui_configuration.values[1],
                      content: variable.ui_configuration.values[1],
                      iconName: 'ellipse-path',
                    },
                    {
                      value: 'infinity',
                      content: 'infinity',
                      iconName: 'infinity-path',
                    },
                  ],
                },
              });
            } else {
              items[group].variables.push(variable);
            }
          } else {
            items[noGroup] = items[noGroup] || {
              groupName: '',
              variables: [],
            };
            items[noGroup].variables.push(variable);
          }
          return items;
        }, {}),
      ),
    [selectedProduct],
  );
};

const mapGroupsIconst = (groupName) => {
  switch (groupName) {
    case ADVANCED_SETTINGS_GROUPS.GENERAL:
      return <SettingsIcon className='general' />;
    case ADVANCED_SETTINGS_GROUPS.TASK:
      return <TaskIcon />;
    case ADVANCED_SETTINGS_GROUPS.SPHERE:
      return <SphereIcon />;
    case ADVANCED_SETTINGS_GROUPS.REACTION:
      return <ReactionIcon />;
    case ADVANCED_SETTINGS_GROUPS.AUDIO:
      return <AudioIcon />;
  }
};
