import { getI18n } from 'react-i18next';
import { organizationService } from '../../services/organizations';
import { organizationConstants } from '../constants/organization.constants';
import { alertActions } from './alert.actions';

function getOrganizationsCount() {
  function request() {
    return { type: organizationConstants.COUNT_REQUEST };
  }

  function success(count) {
    return {
      type: organizationConstants.COUNT_SUCCESS,
      count,
    };
  }

  function failure(error) {
    return {
      type: organizationConstants.COUNT_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    return organizationService.getOrganizationsCount().then(
      (result) => {
        const { count } = result.data;
        dispatch(success(count));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.organizations')));
      },
    );
  };
}

function deleteOrganization(organizationId) {
  function request(organization) {
    return {
      type: organizationConstants.DELETE_REQUEST,
      organization,
    };
  }

  function success(id) {
    return {
      type: organizationConstants.DELETE_SUCCESS,
      id,
    };
  }

  function failure(error) {
    return {
      type: organizationConstants.DELETE_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request(organizationId));
    return organizationService.deleteOrganizationById(organizationId).then(
      () => {
        dispatch(success(organizationId));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.delete.organization')));
      },
    );
  };
}

function createOrganization(organization) {
  function request(data) {
    return {
      type: organizationConstants.CREATE_REQUEST,
      organization: data,
    };
  }

  function success(result) {
    return {
      type: organizationConstants.CREATE_SUCCESS,
      organization: result,
    };
  }

  function failure(error) {
    return {
      type: organizationConstants.CREATE_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request(organization));
    return organizationService.createOrganization(organization).then(
      (result) => {
        dispatch(success({ organization: result.data }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.add.organization')));
      },
    );
  };
}

function updateOrganization(organization) {
  function request(data) {
    return {
      type: organizationConstants.UPDATE_REQUEST,
      organization: data,
    };
  }

  function success(result) {
    return {
      type: organizationConstants.UPDATE_SUCCESS,
      organization: result.organization,
    };
  }

  function failure(error) {
    return {
      type: organizationConstants.UPDATE_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    const { id } = organization;
    dispatch(request(organization));
    return organizationService
      .updateOrganization(organization)
      .then(() => {
        organizationService
          .getOrganizationById(id)
          .then((result) => {
            dispatch(success({ organization: result.data }));
          })
          .catch((error) => {
            dispatch(failure(error.toString()));
          });
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.update.organization')));
      });
  };
}
/**
 * Merge data from one into another organization
 * @fromId organization id that will be merged into
 * @toId organization id to where we are merging
 */
function mergeOrganizations(fromId, toId) {
  function request() {
    return {
      type: organizationConstants.MERGE_REQUEST,
    };
  }

  function success() {
    return {
      type: organizationConstants.MERGE_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: organizationConstants.MERGE_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    return organizationService
      .mergeOrganizations(fromId, toId)
      .then(() => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.merge.organization')));
      });
  };
}

function getAllOrganizationsWithoutMetadata(filter = {}) {
  function request() {
    return {
      type: organizationConstants.GETALL_REQUEST,
    };
  }

  function success(organizations) {
    return {
      type: organizationConstants.GETALL_SUCCESS,
      organizations,
    };
  }

  function failure(error) {
    return {
      type: organizationConstants.GETALL_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    return organizationService.getAllOrganizations(filter).then(
      (result) => {
        dispatch(success({ data: (result || {}).data || {} || [] }));
        return (result || {}).data || {} || [];
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.organizations')));
      },
    );
  };
}

function getShortOrganizationsWithoutMetadata(filter = {}) {
  function request() {
    return {
      type: organizationConstants.GETSHORT_REQUEST,
    };
  }

  function success(organizations) {
    return {
      type: organizationConstants.GETSHORT_SUCCESS,
      organizations,
    };
  }

  function failure(error) {
    return {
      type: organizationConstants.GETSHORT_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    return organizationService.getAllOrganizations(filter).then(
      (result) => {
        dispatch(success({ data: (result || {}).data || {} || [] }));
        return (result || {}).data || {} || [];
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.organizations')));
      },
    );
  };
}

function getAllOrganizations(
  offset,
  limit,
  order,
  searchQuery = '',
  totalSessions,
  monthlySession,
  activeClients,
) {
  function request() {
    return { type: organizationConstants.GETALL_REQUEST };
  }

  function success(organizations) {
    return {
      type: organizationConstants.GETALL_SUCCESS,
      organizations,
    };
  }

  function failure(error) {
    return {
      type: organizationConstants.GETALL_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    return organizationService
      .getOrganizationsOverview(
        offset,
        limit,
        order,
        searchQuery,
        totalSessions,
        monthlySession,
        activeClients,
      )
      .then(
        (result) => {
          dispatch(
            success({
              data: result.data.organizations,
              count: result.data.count,
            }),
          );
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(getI18n().t('errors.get.organizations')));
        },
      );
  };
}

function getOrganizationById(id) {
  function request(data) {
    return {
      type: organizationConstants.GET_REQUEST,
      id: data,
    };
  }

  function success(organization) {
    return {
      type: organizationConstants.GET_SUCCESS,
      organization,
    };
  }

  function failure(error) {
    return {
      type: organizationConstants.GET_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request(id));
    return organizationService.getOrganizationById(id).then(
      (result) => {
        dispatch(success(result.data));
        return result.data;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.organization')));
      },
    );
  };
}

function clearSelectedOrganization() {
  return (dispatch) => {
    dispatch({
      type: organizationConstants.CLEAR_SELECTED_ORGANIZATION,
    });
  };
}

function clearOrganizations() {
  return (dispatch) => {
    dispatch({
      type: organizationConstants.CLEAR_ORGANIZATIONS,
    });
  };
}

export const organizationActions = {
  getAllOrganizations,
  createOrganization,
  deleteOrganization,
  updateOrganization,
  mergeOrganizations,
  getOrganizationById,
  getAllOrganizationsWithoutMetadata,
  getShortOrganizationsWithoutMetadata,
  getOrganizationsCount,
  clearOrganizations,
  clearSelectedOrganization,
};
