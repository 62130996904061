import { Box } from '@mui/material';
import { ButtonUi } from '../../Button/Button';
import { actionButtonsStyle } from './style';

export const CustomMuiDataTableBodyButton = (props) => {
  const {
    onPrimaryActionButton,
    onSecondaryActionButton,
    isPrimary = true,
    isSecondary = true,
    isPrimaryDisabled = false,
    isSecondaryDisabled = false,
    firstButtonIcon,
    secondButtonIcon,
    additionalButtons,
  } = props;
  const { actionButtons } = actionButtonsStyle;
  return (
    <Box sx={actionButtons}>
      {additionalButtons?.length && additionalButtons.map((button) => button)}
      {isPrimary && (
        <ButtonUi
          isIconBtn
          disabled={isPrimaryDisabled}
          className='table-action-btn primary'
          size='small'
          onClickHandler={onPrimaryActionButton}
        >
          {firstButtonIcon}
        </ButtonUi>
      )}
      {isSecondary && (
        <ButtonUi
          isIconBtn
          disabled={isSecondaryDisabled}
          className='table-action-btn secondary'
          size='small'
          onClickHandler={onSecondaryActionButton}
        >
          {secondButtonIcon}
        </ButtonUi>
      )}
    </Box>
  );
};
