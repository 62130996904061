import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { organizationActions } from '../store/actions/organization.actions';
import { billingService } from '../services/billing';
import LicenseModal from '../components/Billing/LicenseModal/LicenseModal';
import BillingRow from '../components/Billing/BillingRow/BillingRow';

const TableHeader = ({ label }) => (
  <th
    className='px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
  >
    {label}
  </th>
);

const BillingPage = (
  {
    i18n,
    authentication: {
      foundUser,
      practitioner,
    },
  },
) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isCreatingLicense, setIsCreatingLicense] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fullName = useMemo(() => {
    if (!practitioner) {
      return '-';
    }
    return `${practitioner?.firstname} ${practitioner.lastname}`;
  }, [practitioner]);

  const fetchAllSubscriptions = async () => {
    setIsLoading(true);
    try {
      const result = await billingService.getMySubscriptions();
      setSubscriptions(result.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => setIsCreatingLicense(false);

  useEffect(() => {
    if (!foundUser) {
      return;
    }
    fetchAllSubscriptions();
  }, [foundUser]);

  return (
    <div className='organizations-wrapper'>
      <div className='title'>
        {i18n.t('billing.title')}
        <div className='metadata-wrapper'>
          <p className='title-description' />
        </div>
      </div>
      {isCreatingLicense && <LicenseModal closeModal={closeModal} />}
      <div className='w-full'>
        <div className='container mx-auto px-4 sm:px-8'>
          <div className='flex items-center py-8'>
            <div className='md:w-96 rounded shadow-xl py-4 px-4 bg-white'>
              <p className='text-md font-bold leading-4 text-gray-800'>
                {i18n.t('billing.payment-information')}
              </p>
              <p className='pt-4 text-s leading-4 dark:text-gray-400 text-gray-600 dark:text-gray-500'>
                {i18n.t('billing.email')}:{foundUser?.email}
              </p>
              <p className='pt-4 text-s leading-4 text-gray-600 dark:text-gray-500'>
                {i18n.t('billing.name')}:{fullName}
              </p>
            </div>
          </div>
          <div className='py-8'>
            <div>
              <div className='sm:flex items-center justify-between'>
                <h2 className='text-2xl font-semibold leading-tight'>
                  {i18n.t('billing.licenses')}
                </h2>
                {subscriptions?.length > 0 && (
                  <div className='mt-4 sm:mt-0'>
                    <button
                      type='button'
                      className='focus:ring-2 focus:ring-offset-2 inline-flex sm:ml-3 items-start justify-start px-6 py-3 bg-blue-500 hover:bg-blue-400 focus:outline-none rounded'
                      onClick={() => setIsCreatingLicense(true)}
                    >
                      <p className='text-sm font-medium leading-none text-white'>
                        {i18n.t('billing.create-new-license')}
                      </p>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className='-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto'>
              <div className='inline-block min-w-full shadow-md rounded-lg overflow-hidden'>
                <table className='min-w-full leading-normal'>
                  <thead>
                    <tr>
                      <TableHeader label={i18n.t('billing.description')} />
                      <TableHeader label={i18n.t('billing.price')} />
                      <TableHeader label={i18n.t('billing.status')} />
                      {/* <TableHeader label={i18n.t('billing.details')}/> */}
                      <TableHeader label={i18n.t('billing.due-date')} />
                      <TableHeader label={undefined} />
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptions?.length > 0 &&
                      subscriptions.map((subscription) => (
                        <BillingRow
                          key={`subscription-element-${subscription.id}`}
                          subscription={subscription}
                          refreshSubscriptions={fetchAllSubscriptions}
                        />
                      ))}
                  </tbody>
                </table>
                {!subscriptions?.length && !isLoading && (
                  <div className='min-w-full flex justify-center p-2'>
                    <button
                      type='button'
                      className='focus:ring-2 focus:ring-offset-2 inline-flex sm:ml-3 items-center justify-center px-6 py-3 bg-purple-500 hover:bg-purple-400 focus:outline-none rounded'
                      disabled={isLoading}
                      onClick={() => setIsCreatingLicense(true)}
                    >
                      <p className='text-sm font-medium leading-none text-white'>
                        {i18n.t('billing.create-new-license-empty')}
                      </p>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = { getOrganizationById: organizationActions.getOrganizationById };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BillingPage));
