import { getI18n } from 'react-i18next';
import { clientService } from '../../services/clients';
import { clientConstants } from '../constants/client.constants';
import { alertActions } from './alert.actions';

const joinValueIntoArray = (val) => {
  if (!val) {
    return [];
  }
  if (Array.isArray(val)) {
    return val;
  }
  return [];
};

const getClientsCount = (teamId) => {
  const request = () => ({ type: clientConstants.COUNT_REQUEST });
  const success = (count) => ({
    type: clientConstants.COUNT_SUCCESS,
    count,
  });
  const failure = (error) => ({
    type: clientConstants.COUNT_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    return clientService.getClientsCount(teamId).then(
      (result) => {
        const { count } = (result || {}).data || {};
        dispatch(success(count || 0));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.clients')));
      },
    );
  };
};
const getAllClientsWithStats = (
  offset,
  limit,
  order,
  searchQuery = '%',
  anonymousInclude,
  startScore,
  endScore,
  totalSessions,
  monthlySession,
  decrease,
) => {
  const request = () => ({ type: clientConstants.GETALL_REQUEST });
  const success = (clients, count) => ({
    type: clientConstants.GETALL_SUCCESS,
    clients,
    count,
  });
  const failure = (error) => ({
    type: clientConstants.GETALL_FAILURE,
    error,
  });

  const createClientsList = (clients) =>
    clients.map((item) => {
      // let [first, last] = [0, 0];
      let first, last;
      if (item && item.latestSudArray) {
        const lastSudScores = joinValueIntoArray(item.latestSudArray);
        const lengthOfSudScores = lastSudScores.length;
        if (lengthOfSudScores && lengthOfSudScores > 0) {
          first = parseInt(lastSudScores[0]);
          last = parseInt(lastSudScores[lengthOfSudScores - 1]);
        }
      }
      return {
        id: item.id,
        name: item.name,
        active: item.active,
        organizationId: item.organizationId,
        teamId: item.teamId,
        sum: item.amountOfSessions || 0,
        monthSum: item.sessionsThisMonth || 0,
        first,
        last,
      };
    });

  return (dispatch) => {
    dispatch(request());
    return clientService
      .getAllClientsWithStats(
        offset,
        limit,
        order,
        searchQuery,
        anonymousInclude,
        startScore,
        endScore,
        totalSessions,
        monthlySession,
        decrease,
      )
      .then(
        (result) => {
          const { clients = [], totalCount = 0 } = result?.data || {};
          dispatch(success(createClientsList(clients), totalCount));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(getI18n().t('errors.get.clients')));
        },
      );
  };
};

const getAllClients = (filter) => {
  const request = () => ({ type: clientConstants.GETALL_REQUEST });
  const success = (clients) => ({
    type: clientConstants.GETALL_SUCCESS,
    clients,
  });
  const failure = (error) => ({
    type: clientConstants.GETALL_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    return clientService.getAllClients(filter).then(
      (result) => {
        const clients = result.data;
        if (clients.length > 0) {
          dispatch(success(clients));
        } else {
          dispatch(success(clients));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.clients')));
      },
    );
  };
};

const createClient = (client) => {
  const request = (data) => ({
    type: clientConstants.CREATE_REQUEST,
    client: data,
  });
  const success = (data) => ({
    type: clientConstants.CREATE_SUCCESS,
    client: data,
  });
  const failure = (error) => ({
    type: clientConstants.CREATE_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request(client));
    return clientService.createClient(client).then(
      (result) => {
        dispatch(success(result.data));
        return result.data;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.add.client')));
      },
    );
  };
};

const findClientById = (id) => {
  const request = (client) => ({
    type: clientConstants.GET_REQUEST,
    client,
  });

  const success = (client) => ({
    type: clientConstants.GET_SUCCESS,
    client,
  });

  const failure = (error) => ({
    type: clientConstants.GET_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request({ id }));

    return clientService.findClientById(id).then(
      (client) => {
        dispatch(success(client.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.client')));
      },
    );
  };
};

const updateClient = (client) => {
  const request = (data) => ({
    type: clientConstants.UPDATE_REQUEST,
    client: data,
  });
  const success = (data) => ({
    type: clientConstants.UPDATE_SUCCESS,
    client: data.client,
  });
  const failure = (error) => ({
    type: clientConstants.UPDATE_FAILURE,
    error,
  });
  return (dispatch) => {
    const { id } = client;
    dispatch(request(client));
    return clientService.updateClient(client).then(
      () =>
        clientService.findClientById(id).then((result) => {
          dispatch(success({ client: result.data }));
        }),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.update.client')));
      },
    );
  };
};

const deleteClient = (clientId) => {
  const request = (client) => ({
    type: clientConstants.DELETE_REQUEST,
    client,
  });
  const success = (id) => ({
    type: clientConstants.DELETE_SUCCESS,
    id,
  });
  const failure = (error) => ({
    type: clientConstants.DELETE_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(clientId));
    return clientService.deleteClientById(clientId).then(
      () => {
        dispatch(success(clientId));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.delete.client')));
      },
    );
  };
};

function clearClients() {
  return (dispatch) => {
    dispatch({
      type: clientConstants.CLEAR_CLIENTS,
    });
  };
}

export const clientActions = {
  getAllClients,
  createClient,
  updateClient,
  findClientById,
  getAllClientsWithStats,
  deleteClient,
  getClientsCount,
  clearClients,
};
