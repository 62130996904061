import { theme } from '../../theme';
import { getResponsiveSize } from '../../utils';

const smallSideNavOptions = {
  '& .psy-side-nav__header-container': {
    justifyContent: 'space-evenly',
  },
  // Options
  '& .psy-side-nav__option': {
    '&-wrapper:not(.no-click)': {
      my: `calc(${theme.palette.propVars.index} * 0.16)`,
      mx: `calc(${theme.palette.propVars.index} * 0.5)`,
      p: `calc(${theme.palette.propVars.index} * 0.4)`,
      justifyContent: 'center',
      '&:before': {
        content: 'none',
      },
      '& .nav-option-icon': {
        m: 0,
        '&.remote-view': {
          ml: getResponsiveSize(-3), // -3px
        },
      },
      '& .nav-caption': {
        display: 'none',
      },
    },
    // login container
    '&.login-container': {
      width: '100%', // 80px
      '& .no-click': {
        display: 'flex',
        flexDirection: 'column',
        m: 0,
        alignItems: 'center',
        gap: 0,
        '& .user-name': {
          mx: 0,
          mt: 0.5,
          px: '5px',
          textAlign: 'center',
        },
        '& .actions': {
          flexDirection: 'column',
        },
        '& .icon-btn': {
          mx: `calc(${theme.palette.propVars.index} * 0.5)`,
        },
        '& .user-wrapper': {
          order: '2',
        },
        // Collapsed / small language switcher animations
        '& .lng-switcher-wrapper .lng-select': {
          bottom: '10% !important',
          '&.entering': {
            opacity: 0,
            transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
            left: 0,
          },
          '&.entered': {
            opacity: 1,
            transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
            left: '90%',
          },
          '&.exiting': {
            opacity: 0,
            transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
            left: 0,
          },
          '&.exited': {
            opacity: 0,
            transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
            left: 0,
          },
        },
      },
    },
  },
  // small screen
  [theme.breakpoints.media.laptop]: {
    '& .psy-side-nav__option': {
      '&-wrapper:not(.no-click)': {
        maxWidth: 42,
        p: 1,
        m: '0 auto',
        '& svg': {
          minWidth: 24,
          minHeight: 24,
        },
      },
      // Login user actions btns
      '&.login-container .no-click': {
        '& .user-wrapper': {
          width: '90%',
          m: '5px auto 0',
          textAlign: 'center',
          '& .user-name': {
            width: '100%',
            m: 0,
            '& .name-label': {
              fontSize: 14,
            },
            '& .role-label, .org-label': {
              fontSize: 13,
            },
          },
        },
        '& .icon-btn': {
          width: 42,
          height: 42,
          p: 1,
          m: '0 auto',
          '& svg': {
            minWidth: 20,
            minHeight: 20,
          },
        },
        // Language switcher
        '& .lng-switcher-wrapper': {
          '& .lng-select': {
            '& .lng-list': {
              minWidth: 160,
              pb: 1,
              '& .option-wrapper': {
                '&.active-lng ': {
                  mb: 1,
                },
                '& .lng-option': {
                  my: 0,
                  mx: 1.5,
                  py: 1.25,
                  px: 1,
                  gap: 1,
                  '& svg': {
                    minWidth: 20,
                    minHeight: 20,
                  },
                  '& .check': {
                    minWidth: 16,
                    minHeight: 12,
                  },
                  '& h6': {
                    fontSize: 14,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export const sideNavStyle = {
  psySideNav: {
    letterSpacing: 'normal',
    color: theme.palette.myPrimary.white,
    fontWeight: 300,
    width: getResponsiveSize(260),
    position: 'relative',
    height: '100%',
    backgroundColor: theme.palette.myPrimary.darkBlue50,
    ...theme.palette.propVars.linearTransition,
    '& ul': {
      listStyle: 'none',
      m: 0,
      py: getResponsiveSize(33.5),
      overflowY: 'scroll',
      // Hide scrollbar
      // msOverflowStyle: 'none',
      // scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    // Header
    '& .psy-side-nav__header': {
      py: `calc(${theme.palette.propVars.index} * 1.14)`,
      pl: `calc(${theme.palette.propVars.index} * 1.4)`,
      pr: `calc(${theme.palette.propVars.index} * 0.52)`,
      borderBottom: `1px solid ${theme.palette.myPrimary.lightBlue70}`,
      position: 'relative',
      '&-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        '& .logo-href': {
          display: 'flex',
          gap: '0.5em',
          alignItems: 'center',
          '& .logo': {
            fill: theme.palette.myPrimary.white,
            width: `calc(${theme.palette.propVars.index} * 1.11)`,
            height: `calc(${theme.palette.propVars.index} * 1.14)`,
          },
          '& .logo-title': {
            color: theme.palette.myPrimary.white,
            fontWeight: '700',
            letterSpacing: '0.05vmin',
            fontSize: theme.typography.fs19,
          },
        },
        '& .chevron-button': {
          position: 'absolute',
          width: getResponsiveSize(24), // 24px
          height: getResponsiveSize(24), // 24px
          bottom: `calc(calc(${theme.palette.propVars.index} * -0.83) / 2)`,
          right: `calc(calc(${theme.palette.propVars.index} * -0.83) / 2)`,
          background: theme.palette.myPrimary.lightBlue70,
          borderRadius: '50%',
          fontSize: '14px',
          ...theme.palette.propVars.linearTransition,
          '&.collapsed': {
            transform: 'rotate(180deg)',
          },
          '&:hover': {
            backgroundColor: theme.palette.myPrimary.hover,
          },
          '& svg': {
            mr: `calc(${theme.palette.propVars.index} * 0.04)`,
            width: `calc(${theme.palette.propVars.index} * 0.35)`,
            height: `calc(${theme.palette.propVars.index} * 0.48)`,
            [theme.breakpoints.media.desktop]: {
              minHeight: 16,
            },
          },
        },
      },
    },
    // Options
    '& .psy-side-nav__option': {
      textDecoration: 'none',
      '&-wrapper:not(.no-click)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        p: `calc(${theme.palette.propVars.index} * 0.43)`,
        mb: 0,
        mt: `calc(${theme.palette.propVars.index} * 0.17)`,
        mx: `calc(${theme.palette.propVars.index} * 0.78)`,
        borderRadius: theme.palette.propVars.borderRadius,
        textTransform: 'capitalize',
        willChange: 'auto',
        transition: '.4s all ease-in-out',
        '&:before': {
          content: '""',
          display: 'flex',
          background: theme.palette.myPrimary.darkBlue50,
          borderRadius: '50%',
          // mt: `calc(${theme.palette.propVars.index} * 0.14)`,
          mt: 0.5,
          opacity: 0,
          willChange: 'translateX, margin-right',
          transform: 'translateX(-150%)',
          transition: '.4s all ease-in-out',
        },
        '&:not(.is-active):hover': {
          backgroundColor: theme.palette.myPrimary.hover,
          '&:before': {
            opacity: 1,
            width: `calc(${theme.palette.propVars.index} * 0.35)`,
            height: `calc(${theme.palette.propVars.index} * 0.35)`,
            mr: `calc(${theme.palette.propVars.index} * 0.4)`,
            transform: 'translateX(0%)',
            backgroundColor: 'transparent',
          },
        },

        '& .nav-option-icon': {
          fill: theme.palette.myPrimary.blue,
          mr: `calc(${theme.palette.propVars.index} * 0.4)`,
          width: getResponsiveSize(24), // 24px
          height: getResponsiveSize(24), // 24px
          minWidth: '24px',
          // Home icon
          '&.home': {
            width: getResponsiveSize(24), // 24px
            height: getResponsiveSize(25), // 25px
          },
          // Organizations icon
          '&.organizations': {
            width: getResponsiveSize(25), // 25px
            height: getResponsiveSize(24), // 24px
          },
          // Clients icon
          '&.clients': {
            width: getResponsiveSize(24), // 24px
            height: getResponsiveSize(21), // 21px
          },
          // Treatment icon
          '&.treatment': {
            width: getResponsiveSize(26), // 26px
            height: getResponsiveSize(21), // 21px
            '& path:nth-of-type(1)': {
              stroke: theme.palette.myPrimary.blue,
              fill: 'transparent',
            },
          },
          // Session icon
          '&.session': {
            width: getResponsiveSize(22), // 22px
            height: getResponsiveSize(25), // 25px
            stroke: theme.palette.myPrimary.blue,
            fill: theme.palette.myPrimary.darkBlue50,
          },
          // Admin icon
          '&.admin': {
            fill: theme.palette.myPrimary.darkBlue50,
            '& path': {
              fill: theme.palette.myPrimary.blue,
            },
          },
          // Remote view icon
          '&.remote-view': {
            width: getResponsiveSize(24), // 24px
            height: getResponsiveSize(25), // 25px
            fill: theme.palette.myPrimary.darkBlue50,
            ml: getResponsiveSize(-2), // -2px
            '& path': {
              fill: theme.palette.myPrimary.blue,
            },
          },
          // Video library
          '&.video-library': {
            fill: 'none',
            stroke: theme.palette.myPrimary.blue,
            strokeWidth: '2px',
            strokeLinejoin: 'round',
          },
          // Report
          '&.report': {
            width: getResponsiveSize(21), // 21px
            height: getResponsiveSize(25), // 25px
          },
          // Devices icon
          '&.devices': {
            fill: 'none',
            width: getResponsiveSize(26), // 26px
            height: getResponsiveSize(24), // 24px
            '& path': {
              '&.vr-boundaries': {
                stroke: theme.palette.myPrimary.blue,
                strokeWidth: '1.3px',
              },
              '&.vr-nose, &.screen': {
                fill: theme.palette.myPrimary.blue,
              },
            },
          },
        },
        '& .nav-caption': {
          color: theme.palette.myPrimary.blue,
          fontWeight: 700,
          lineHeight: '1em',
          fontSize: theme.typography.fs16,
        },
        '&.is-active': {
          backgroundColor: theme.palette.myPrimary.lightBlue,
          '&:before': {
            opacity: 1,
            width: `calc(${theme.palette.propVars.index} * 0.35)`,
            height: `calc(${theme.palette.propVars.index} * 0.35)`,
            mr: `calc(${theme.palette.propVars.index} * 0.4)`,
            transform: 'translateX(0%)',
          },
          '& .nav-option-icon': {
            fill: theme.palette.myPrimary.white,
            // Treatment icon
            '&.treatment path:nth-of-type(1)': {
              stroke: theme.palette.myPrimary.white,
            },
            // Session icon
            '&.session': {
              fill: 'transparent',
              stroke: theme.palette.myPrimary.white,
            },
            // Remote view icon
            '&.remote-view': {
              fill: theme.palette.myPrimary.darkBlue50,
              '& path': {
                fill: theme.palette.myPrimary.white,
              },
            },
            // devices icon
            '&.devices': {
              fill: 'none',
              '& path': {
                '&.vr-boundaries': {
                  stroke: theme.palette.myPrimary.white,
                  strokeWidth: '1.3px',
                },
                '&.vr-nose, &.screen': {
                  fill: theme.palette.myPrimary.white,
                },
              },
            },
          },
          '& .nav-caption': {
            color: theme.palette.myPrimary.white,
          },
        },
      },
      // login container
      '&.login-container': {
        position: 'absolute',
        bottom: `calc(${theme.palette.propVars.index} * 2.5)`,
        zIndex: 1,
        width: '100%',
        '& .no-click': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column',
          p: 0,
          mb: 0,
          mt: `calc(${theme.palette.propVars.index} * 0.17)`,
          mx: `calc(${theme.palette.propVars.index} * 0.78)`,
          gap: getResponsiveSize(8), // 8px
          '& .user-wrapper': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            '& svg': {
              mr: 0.5,
            },
            '& .user-name': {
              display: 'flex',
              flexDirection: 'column',
              ml: '5px',
              width: '100%',
              '& .name-label, .role-label, .org-label': {
                color: theme.palette.myPrimary.lightBlue20,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              '& .name-label': {
                fontWeight: 500,
              },
              '& .role-label, .org-label': {
                textTransform: 'capitalize',
                fontWeight: 400,
                fontSize: theme.typography.fs14,
              },
            },
          },
          '& .actions': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
          },
          // Language switcher
          '& .lng-switcher-wrapper': {
            position: 'relative',
            '& .lng-select': {
              position: 'absolute',
              background: theme.palette.myPrimary.white,
              borderRadius: theme.palette.propVars.borderRadius,
              backdropFilter: 'blur(15px)',
              bottom: '120%',
              ...theme.palette.propVars.dropShadowFilter,
              // animations
              '&.entering': {
                opacity: 0,
                transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
                bottom: '0',
              },
              '&.entered': {
                opacity: 1,
                transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
                bottom: '120%',
              },
              '&.exiting': {
                opacity: 0,
                transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
                bottom: '0%',
              },
              '&.exited': {
                opacity: 0,
                transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
                bottom: '0%',
              },
              '& .lng-list': {
                display: 'flex',
                width: 'fit-content',
                minWidth: getResponsiveSize(240), // 240px
                flexDirection: 'column',
                pb: getResponsiveSize(12), // 12px
                pt: 0,
                '& .option-wrapper': {
                  '&:last-of-type .lng-option': {
                    mb: 0,
                  },
                  '& .lng-option': {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                    px: getResponsiveSize(12), // 12px
                    py: getResponsiveSize(16), // 16px,
                    ml: getResponsiveSize(12), // 12px
                    mr: getResponsiveSize(24), // 24px,
                    my: getResponsiveSize(8), // 8px,
                    gap: getResponsiveSize(12), // 12px,
                    borderRadius: theme.palette.propVars.borderRadius,
                    // slide color effect
                    backgroundSize: '200%',
                    backgroundPosition: ' right bottom',
                    backgroundImage: `linear-gradient(to left, transparent 50%, ${theme.palette.myPrimary.lightBlue20} 50%)`,
                    transition:
                      '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',
                    '& svg:not(.check)': {
                      width: getResponsiveSize(22), // 22px
                      height: getResponsiveSize(16), // 16px
                    },
                    '& .check': {
                      ml: 'auto',
                      fill: '#00B63F',
                      width: getResponsiveSize(16), // 16px
                      height: getResponsiveSize(12), // 12px
                    },
                    '& h6': {
                      color: theme.palette.neutral.black,
                      fontSize: theme.typography.fs16,
                      ...theme.palette.propVars.regularFontWeight,
                    },
                  },
                },
                '& .option-wrapper:not(.active-lng)': {
                  '& .lng-option': {
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundPosition: 'left bottom',
                    },
                  },
                },
                '& .option-wrapper.active-lng': {
                  borderBottom: `1px solid ${theme.palette.neutral.grey50}`,
                  '& .lng-option': {
                    my: 0,
                  },
                },
              },
            },
          },
          '& .icon-btn': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: getResponsiveSize(10), // 10px
            width: getResponsiveSize(42), // 42px
            height: getResponsiveSize(42), // 42px
            transition: '.4s all ease-in-out',
            borderRadius: theme.palette.propVars.borderRadius,
            '&.language': {
              py: getResponsiveSize(13), // 13px
              px: getResponsiveSize(9), // 9px
              '& svg': {
                width: getResponsiveSize(22), // 22px
                height: getResponsiveSize(16), // 16px
              },
            },
            '& svg': {
              fill: theme.palette.myPrimary.blue,
              width: getResponsiveSize(22), // 22px
              mr: 0,
            },
            '& .settings': {
              width: getResponsiveSize(24), // 24px
              height: getResponsiveSize(23), // 23px
              stroke: theme.palette.myPrimary.blue,
            },
            '& .logout-button': {
              width: getResponsiveSize(24), // 24px
              height: getResponsiveSize(25), // 25px
            },
            '&:hover': {
              backgroundColor: theme.palette.myPrimary.hover,
              color: theme.palette.myPrimary.white,
            },
          },
        },
      },
    },

    // Collapsed
    '&.psy-side-nav__small': {
      width: getResponsiveSize(80), // 80px
      // Header
      '& .psy-side-nav__header': {
        px: getResponsiveSize(40), // 40px
      },
      '& .logo-title': {
        display: 'none',
      },
      // Options
      ...smallSideNavOptions,
      // user menu if menu items is long
      '&.long': {
        // if billing banner is open add scroller
        [theme.breakpoints.media.desktopXLToDesktop]: {
          '&.banner-open': {
            '& ul:not(.lng-list)': {
              py: 0,
              mt: `calc(${theme.palette.propVars.index} * 1.16)`,
              maxHeight: `calc(${theme.palette.propVars.index} * 18)`,
              mr: '1px',
              '&::-webkit-scrollbar': {
                display: 'block',
                width: 3,
              },
              '&::-webkit-scrollbar-track': {
                webkitBorderRadius: '100px',
                borderRadius: '100px',
              },
              '&::-webkit-scrollbar-thumb': {
                webkitBorderRadius: '100px',
                borderRadius: '100px',
                background: theme.palette.myPrimary.lightBlue20,
              },
            },
          },
        },
        '& .login-container': {
          bottom: `calc(${theme.palette.propVars.index} * 0.5)`,
        },
      },
    },

    // Small screen
    [theme.breakpoints.media.laptop]: {
      '&.psy-side-nav__small': {
        width: 80,
        '& .psy-side-nav__header': {
          px: 0,
          '& .logo-href': {
            display: 'flex',
          },
        },
      },
      width: 80,
      '& ul': {
        py: 3,
      },
      // Header
      '& .psy-side-nav__header': {
        p: '30px 0',
        '& .logo-title, .chevron-button': {
          display: 'none',
        },
        '& .logo': {
          minWidth: 32,
          minHeight: 33,
        },
      },
      // user menu if menu items is long
      '&.long': {
        // if billing banner is open add scroller
        '&.banner-open': {
          '& ul:not(.lng-list)': {
            py: 0,
            mt: 3,
            maxHeight: 445,
            mr: '1px',
            '&::-webkit-scrollbar': {
              display: 'block',
              width: 3,
            },
            '&::-webkit-scrollbar-track': {
              webkitBorderRadius: '100px',
              borderRadius: '100px',
            },
            '&::-webkit-scrollbar-thumb': {
              webkitBorderRadius: '100px',
              borderRadius: '100px',
              background: theme.palette.myPrimary.lightBlue20,
            },
          },
        },
        '& .login-container': {
          bottom: `calc(${theme.palette.propVars.index} * 0.5)`,
        },
      },
      // Options
      ...smallSideNavOptions,
    },
  },
};
