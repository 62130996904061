import * as WizardActionCreators from './wizard.actions';
import { clientActions } from './client.actions';
import { treatmentRemoteActions } from './treatment-remote.actions';
import { deviceActions } from './device.actions';
import { teamActions } from './team.actions';
import { organizationActions } from './organization.actions';
import { practitionerActions } from './practitioner.actions';
import { userActions } from './user.actions';
import { productActions } from './product.actions';
import { alertActions } from './alert.actions';

export default {
  ...WizardActionCreators,
  ...clientActions,
  ...treatmentRemoteActions,
  ...deviceActions,
  ...teamActions,
  ...organizationActions,
  ...productActions,
  ...practitionerActions,
  ...userActions,
  ...alertActions,
};
