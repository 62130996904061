import { getI18n } from 'react-i18next';
import { productsService } from '../../services/products';
import { productConstants } from '../constants/product.constants';
import { alertActions } from './alert.actions';

export const productActions = {
  getAllProductConfigurations,
  getAvailableProducts,
};

function getAllProductConfigurations() {
  return (dispatch) => {
    dispatch(request());
    return productsService.getAllProductConfigurations().then(
      (result) => {
        dispatch(success(result.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.products')));
      },
    );
  };

  function request() {
    return { type: productConstants.GETALL_REQUEST };
  }
  function success(products) {
    return { type: productConstants.GETALL_SUCCESS, products };
  }
  function failure(error) {
    return { type: productConstants.GETALL_FAILURE, error };
  }
}

function getAvailableProducts(organizationId) {
  return (dispatch) => {
    dispatch(request());
    return productsService.getAvailableProducts(organizationId).then(
      (result) => {
        dispatch(success(result.data.products));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.products')));
      },
    );
  };

  function request() {
    return { type: productConstants.GET_AVAILABLE_REQUEST };
  }
  function success(products) {
    return { type: productConstants.GET_AVAILABLE_SUCCESS, products };
  }
  function failure(error) {
    return { type: productConstants.GET_AVAILABLE_FAILURE, error };
  }
}
