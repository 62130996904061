import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const logicRangeStyle = {
  logicRange: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: getResponsiveSize(12), // 12px
    height: getResponsiveSize(53), // 53px
    width: getResponsiveSize(428), // 428px
    border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
    py: getResponsiveSize(9), // 9px
    px: getResponsiveSize(26), // 26px
    backgroundColor: theme.palette.myPrimary.white,
    borderRadius: theme.palette.propVars.borderRadius,
    position: 'relative',
    "& [class*='-MuiPopper-root ']": {
      zIndex: 1,
    },
    '& .MuiTypography-root.label': {
      mr: 'auto',
      color: theme.palette.neutral.greyRGBA80,
      left: getResponsiveSize(24),
      top: getResponsiveSize(-10),
      position: 'absolute',
      zIndex: '0',
      px: 0.5,
      '&:after': {
        content: "''",
        position: 'absolute',
        background: theme.palette.myPrimary.white,
        width: '100%',
        height: '2px',
        left: '0',
        bottom: '50%',
        zIndex: -'1',
      },
    },
    '& .range-slider': {
      width: '100%',
      gap: 0,
      '& .MuiSlider-root': {
        width: '100%',
        '& .MuiSlider-markLabel': {
          top: getResponsiveSize(18), // 18px
          fontSize: getResponsiveSize(14), // 14px
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      height: getResponsiveSize(49, laptopRatio), // 49px
      width: getResponsiveSize(325, laptopRatio), // 325px
      gap: getResponsiveSize(10, laptopRatio), // 12px
      py: getResponsiveSize(9, laptopRatio), // 9px
      px: getResponsiveSize(26, laptopRatio), // 26px
      '& .MuiTypography-root.label': {
        fontSize: getResponsiveSize(14, laptopRatio), // 14px
        top: getResponsiveSize(-10, laptopRatio),
      },
      '& .range-slider': {
        '& .MuiSlider-root': {
          '& .MuiSlider-markLabel': {
            top: getResponsiveSize(14, laptopRatio), // 14px
            fontSize: getResponsiveSize(13, laptopRatio), // 13px
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      width: '100%',
      height: getResponsiveSize(42, mobileRatio), // 42px
      gap: getResponsiveSize(10, mobileRatio), // 10px
      py: getResponsiveSize(9, mobileRatio), // 9px
      px: getResponsiveSize(26, mobileRatio), // 26px
      borderRadius: getResponsiveSize(8, mobileRatio), // 8px
      '& .MuiTypography-root.label': {
        fontSize: getResponsiveSize(13, mobileRatio), // 13px
        top: getResponsiveSize(-9, mobileRatio),
        left: getResponsiveSize(20, mobileRatio),
      },
      '& .range-slider': {
        '& .MuiSlider-root': {
          '& .MuiSlider-markLabel': {
            top: getResponsiveSize(14, mobileRatio), // 14px
          },
        },
        '& .MuiInputBase-root': {
          width: getResponsiveSize(38, mobileRatio), // 38px
          height: getResponsiveSize(32, mobileRatio), // 32px
          borderRadius: getResponsiveSize(8, mobileRatio), // 8px
          p: getResponsiveSize(3, mobileRatio), // 3px
          '& .MuiInput-input': {
            fontSize: `${getResponsiveSize(14, mobileRatio)} !important`, // 14px
          },
        },
        '& .error-wrapper .error.visible .MuiTypography-root': {
          fontSize: getResponsiveSize(13, mobileRatio), // 13px
        },
      },
    },
  },
};
