import React from 'react';
import Select from 'react-select';

import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';
import { clientActions } from '../../store/actions/client.actions';
import translateClientName from '../../utils/TranslatedPossibleClientName';

class ClientsSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: 'clients.title',
    };
  }

  componentDidMount() {
    const filter = this.props.filter || '';
    this.props.getAllClients(filter);
  }

  render() {
    const { clients, selectedId, i18n, disabled, handleChange, label } = this.props;
    const options = clients.clients.map((item) => ({ value: item.id, label: item.name }));
    let value = null;

    if (selectedId) {
      value = options.find((item) => item.value === selectedId);
    }

    return (
      <>
        <label className='select-label'>{label || i18n.t(this.state.label)}</label>
        <Select
          className='select-filter'
          options={options}
          isClearable
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          placeholder={clients.isLoading ? i18n.t('clients.loading') : i18n.t('clients.select')}
          isDisabled={clients.isLoading || disabled}
          onChange={handleChange}
          getOptionLabel={({ label: newLabel }) => translateClientName(newLabel)}
          value={value}
          inputProps={{
            name: 'client',
            id: 'select-client',
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllClients: clientActions.getAllClients,
};

export default withTranslation ()(connect(mapStateToProps, mapDispatchToProps)(ClientsSelect));
