export const treatmentsRemoteConstants = {
  CREATE_CONFIG_REQUEST: 'CREATE_TREATMENT_REMOTE_REQUEST',
  CREATE_CONFIG_SUCCESS: 'CREATE_TREATMENT_REMOTE_SUCCESS',
  CREATE_CONFIG_FAILURE: 'CREATE_TREATMENT_REMOTE_FAILURE',

  DELETE_TREATMENT: 'DELETE_TREATMENT_REMOTE_REQUEST',
  DELETE_TREATMENT_SUCCESS: 'DELETE_TREATMENT_REMOTE_SUCCESS',
  DELETE_TREATMENT_FAILURE: 'DELETE_TREATMENT_REMOTE_FAILURE',

  CREATE_SESSION_REQUEST: 'CREATE_REMOTE_SESSION_REQUEST',
  CREATE_SESSION_SUCCESS: 'CREATE_REMOTE_SESSION_SUCCESS',
  CREATE_SESSION_FAILURE: 'CREATE_REMOTE_SESSION_FAILURE',

  PATCH_CONFIG_REQUEST: 'PATCH_TREATMENT_REMOTE_REQUEST',
  PATCH_CONFIG_SUCCESS: 'PATCH_TREATMENT_REMOTE_SUCCESS',
  PATCH_CONFIG_FAILURE: 'PATCH_TREATMENT_REMOTE_FAILURE',

  GET_CONFIG_REQUEST: 'GET_TREATMENT_REMOTE_REQUEST',
  GET_CONFIG_SUCCESS: 'GET_TREATMENT_REMOTE_SUCCESS',
  GET_CONFIG_FAILURE: 'GET_TREATMENT_REMOTE_FAILURE',

  GET_ALL_CONFIG_REQUEST: 'GET_ALL_TREATMENT_REMOTE_REQUEST',
  GET_ALL_CONFIG_SUCCESS: 'GET_ALL_TREATMENT_REMOTE_SUCCESS',
  GET_ALL_CONFIG_FAILURE: 'GET_ALL_TREATMENT_REMOTE_FAILURE',

  RESUME_SESSION: 'RESUME_ACTIVE_SESSION',
  CLEAR_STATE: 'CLEAR_STATE',
};
