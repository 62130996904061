import React, { Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { theme } from '../theme';
import { getResponsiveSize } from '../utils';

class LineGraph extends React.Component {
  COLOR_GREEN = theme.palette.dashBoar.mintGreen;
  COLOR_ORANGE = theme.palette.dashBoar.softPeach;
  COLOR_RED = theme.palette.dashBoar.lightCoral;
  COLOR_TEXT_GREEN = theme.palette.dashBoar.darkmintGreen;
  COLOR_TEXT_ORANGE = theme.palette.dashBoar.darksoftPeach;
  COLOR_TEXT_RED = theme.palette.dashBoar.darkCoral;

  checkIfArray = (val) => {
    if (!val) {
      return [];
    }
    if (Array.isArray(val)) {
      return val;
    }
    return [];
  };

  deltaSeriesText(seriesData) {
    if (!seriesData || seriesData.length <= 1) {
      return '0';
    }
    const delta = seriesData[seriesData.length - 1] - seriesData[0];
    return delta > 0 ? `+${delta}` : `${delta}`;
  }

  // Returns one of three colors based on the delta in the series, note: positive delta is bad.
  classifySeriesInColor(seriesData) {
    if (seriesData.length <= 1) {
      return '#FFFFFF';
    }
    const delta = seriesData[seriesData.length - 1] - seriesData[0];
    if (delta > 0) {
      return this.COLOR_RED;
    }
    if (delta < 0) {
      return this.COLOR_GREEN;
    }
    return this.COLOR_ORANGE;
  }
  // Returns one of three colors based on the delta in the series, note: positive delta is bad.
  classifyTextSeriesInColor(seriesData) {
    if (seriesData.length <= 1) {
      return this.COLOR_TEXT_RED;
    }
    const delta = seriesData[seriesData.length - 1] - seriesData[0];
    if (delta > 0) {
      return this.COLOR_TEXT_RED;
    }
    if (delta < 0) {
      return this.COLOR_TEXT_GREEN;
    }
    return this.COLOR_TEXT_ORANGE;
  }

  render() {
    const { values, height, width, small, i18n } = this.props;
    const seriesData = (this.checkIfArray(values) || []).map((item) =>
      parseInt(item),
    );
    const seriesColor = this.classifySeriesInColor(seriesData);
    const seriesTextColor = this.classifyTextSeriesInColor(seriesData);
    const seriesDeltaText = this.deltaSeriesText(seriesData);
    const options = {
      chart: {
        stacked: true,
        type: 'area',
      },
      plotOptions: {
        area: {
          paddingBottom: 0,
        },
      },
      stroke: {
        width: [2, 2],
        curve: 'smooth',
      },
      title: {
        text: 'Sud scores',
        align: 'right',
      },
      markers: {
        strokeColors: theme.palette.neutral.grey70,
        strokeWidth: 1.5,
        strokeOpacity: 0.7,
        size: 3,
        hover: {
          size: 5,
        },
      },
      xaxis: {
        categories: [],
      },
      series: [
        {
          name: 'area',
          type: 'area',
          data: seriesData,
        },
      ],
    };

    if (small) {
      options.grid = {
        padding: {
          left: 0,
          right: 0,
          top: 5,
        },
      };
      options.chart.stacked = false;
      options.chart.sparkline = {
        enabled: true,
      };
      options.fill = {
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.1,
          inverseColors: true,
          opacityFrom: 0.55,
          opacityTo: 0.45,
          stops: [20, 100, 20, 100],
        },
      };
      options.colors = [seriesColor];
      options.legend = {
        show: false,
      };
      options.yaxis = {
        min: 0,
        max: 11,
        show: false,
      };
      options.tooltip = {
        intersect: false,
        followCursor: true,
        custom({ series, seriesIndex, dataPointIndex, w }) {
          const value = series[seriesIndex][dataPointIndex];
          const delta = value - series[seriesIndex][dataPointIndex - 1];

          let output = '';
          if (!isNaN(delta)) {
            output = ` (${delta > 0 ? '+' : ''}${delta})`;
          }

          return (
            `${
              '<div class="tooltip-sparkline">' + '<span>'
            }${value}${output}</span>` + '</div>'
          );
        },
      };
      delete options.title;
    }

    return seriesData.length ? (
      <Box className='graph-wrapper'>
        {small && (
          <Box
            className='label'
            sx={{
              borderColor: seriesTextColor,
              color: seriesTextColor,
              background: seriesColor,
            }}
          >
            <Typography>{seriesDeltaText}</Typography>
          </Box>
        )}
        <Chart
          options={options}
          series={options.series}
          height={height || '300'}
          width={width || '100%'}
        />
      </Box>
    ) : (
      i18n.t('errors.graphs.empty-sud')
    );
  }
}

export default LineGraph = withTranslation()(connect()(LineGraph));
