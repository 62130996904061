import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import moment from 'moment';

import { ReactComponent as SumIcon } from '../../../../shared/assets/icons/sum.svg';

import { ReactComponent as JanIcon } from '../../../../shared/assets/icons/months/jan.svg';
import { ReactComponent as FebIcon } from '../../../../shared/assets/icons/months/feb.svg';
import { ReactComponent as MarIcon } from '../../../../shared/assets/icons/months/mar.svg';
import { ReactComponent as AprIcon } from '../../../../shared/assets/icons/months/apr.svg';
import { ReactComponent as MayIcon } from '../../../../shared/assets/icons/months/may.svg';
import { ReactComponent as JunIcon } from '../../../../shared/assets/icons/months/jun.svg';
import { ReactComponent as JulIcon } from '../../../../shared/assets/icons/months/jul.svg';
import { ReactComponent as AugIcon } from '../../../../shared/assets/icons/months/aug.svg';
import { ReactComponent as SepIcon } from '../../../../shared/assets/icons/months/sep.svg';
import { ReactComponent as OctIcon } from '../../../../shared/assets/icons/months/oct.svg';
import { ReactComponent as NovIcon } from '../../../../shared/assets/icons/months/nov.svg';
import { ReactComponent as DecIcon } from '../../../../shared/assets/icons/months/dec.svg';

import { sessionsStyles } from './style';

export const CustomMuiSessionsPerMonth = memo(
  ({ isSum = true, sum, isMonthSum = true, monthSum }) => {
    const { container } = sessionsStyles;
    const currentMonth = moment().format('MMMM');

    const MonthIcon = useMemo(() => {
      switch (currentMonth) {
        case 'January':
          return <JanIcon />;
        case 'February':
          return <FebIcon />;
        case 'March':
          return <MarIcon />;
        case 'April':
          return <AprIcon />;
        case 'May':
          return <MayIcon />;
        case 'June':
          return <JunIcon />;
        case 'July':
          return <JulIcon />;
        case 'August':
          return <AugIcon />;
        case 'September':
          return <SepIcon />;
        case 'October':
          return <OctIcon />;
        case 'November':
          return <NovIcon />;
        case 'December':
          return <DecIcon />;
      }
    }, [monthSum]);
    return (
      <Box sx={container}>
        {isSum && (
          <Box className='item sum'>
            <SumIcon />
            {sum}
          </Box>
        )}
        {isMonthSum && (
          <Box className='item months'>
            {MonthIcon} {monthSum}
          </Box>
        )}
      </Box>
    );
  },
);
