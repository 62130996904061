import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, FormHelperText, Grid, Skeleton, Zoom } from '@mui/material';
import { ClearRounded } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';

import { ButtonUi } from '../UI/Button/Button';
import { TextFieldUi } from '../UI/TextField/TextField';
import { EMAIL_REGEX, PHONE_REGEX } from '../../utils/config';
import { organizationFormStyle } from './style';

const defaultOrganizationData = {
  id: '',
  name: '',
  contact_email: '',
  contact_telephone: '',
  contact_address: '',
  contact_name: '',
  funds: 0,
  image: '',
  imageString: '',
};

const FORM_FIELDS_VALIDATORS = {
  name: ['required'],
  contact_email: ['required', 'email'],
  contact_telephone: ['required', 'phone'],
  contact_address: ['required'],
  contact_name: ['required'],
  funds: ['required'],
};

export const CreateEditOrganizationForm = memo((props) => {
  const { organizationToEdit, closeModal } = props;
  const { organizationForm } = organizationFormStyle;
  const { t } = useTranslation();

  const {
    updateOrganization,
    createOrganization,
    getOrganizationById,
    clearSelectedOrganization,
  } = useActions();
  const { selectedOrganization, isLoading: organizationLoading } = useSelector(
    (state) => state.organizations,
  );
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(!!organizationToEdit);

  const [organizationData, setOrganizationData] = useState(
    defaultOrganizationData,
  );
  const [isUploading, setIsUploading] = useState(false);

  const submitBtnLabel = organizationToEdit
    ? 'buttons.save'
    : 'buttons.create.organization';

  const isSubmitionDisabled =
    Object.keys(validationErrors).length > 0 || isLoading;

  /**
   * Validates form fields and sets error
   * @control field control name
   * @value field value
   */
  const validateControl = useCallback(
    (control, value) => {
      FORM_FIELDS_VALIDATORS[control].forEach((validator) => {
        switch (validator) {
          case 'required':
            if (value.trim().length <= 0)
              setValidationErrors((prev) => ({
                ...prev,
                [control]: {
                  required: {
                    valid: false,
                    message: t('errors.validation.required'),
                  },
                },
              }));
            else
              setValidationErrors((prev) => {
                const { [control]: validControl, ...rest } = prev;
                return rest;
              });
            break;
          case 'email':
            if (value.trim().length > 0 && !value.match(EMAIL_REGEX))
              setValidationErrors((prev) => ({
                ...prev,
                [control]: {
                  ...(!!prev && !!prev[control] && { ...prev[control] }),
                  email: {
                    valid: false,
                    message: t('errors.validation.email'),
                  },
                },
              }));
            break;
          case 'phone':
            if (value.trim().length > 0 && !value.match(PHONE_REGEX))
              setValidationErrors((prev) => ({
                ...prev,
                [control]: {
                  ...(!!prev && !!prev[control] && { ...prev[control] }),
                  phone: {
                    valid: false,
                    message: t('errors.validation.phone'),
                  },
                },
              }));
            break;
          default:
            break;
        }
      });
    },
    [t],
  );

  /**
   * Sets value to the textfield
   * @param event native event
   */
  const onChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      validateControl(name, value);
      setOrganizationData((current) => ({ ...current, [name]: value }));
    },
    [validateControl],
  );

  /**
   * Shows form control field error message
   * @returns boolean value is form valid
   */
  const validateSubmitiion = () => {
    let valid = true;
    Object.keys(FORM_FIELDS_VALIDATORS).forEach((control) => {
      if (organizationData[control].toString().trim().length <= 0) {
        setValidationErrors((prev) => ({
          ...prev,
          [control]: {
            ...(!!prev && !!prev[control] && { ...prev[control] }),
            required: {
              valid: false,
              message: t('errors.validation.required'),
            },
          },
        }));
        valid = false;
      }
    });
    return valid;
  };

  /**
   * Update / create organization form submition
   * * Sets error if fields are not filled
   * @param event native submit event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const valid = validateSubmitiion();
    if (!valid) return;

    const { imageString, ...orgData } = organizationData;
    organizationToEdit
      ? await updateOrganization(orgData)
      : await createOrganization(orgData);
    closeModal();
  };

  /**
   * Transform img into base64
   * @param file selected file data
   */
  const readFile = (file) =>
    new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsDataURL(file);
    });

  /**
   * Image upload handler
   * @param event native upload event
   */
  const onUploadImage = useCallback(async (event) => {
    const files = Array.from(event.target.files);
    const file = files[0];
    if (!file) {
      return;
    }
    setIsUploading(true);
    const base64string = await readFile(file);
    setIsUploading(false);

    setOrganizationData((current) => ({
      ...current,
      imageString: base64string,
      image: base64string,
    }));
  }, []);

  /**
   * Remove image handler
   */
  const removeImage = useCallback(
    () =>
      setOrganizationData((current) => {
        const { imageString, ...rest } = current;
        return { ...rest, image: '' };
      }),
    [],
  );

  /**
   * Generates image wrapper layout
   * Upload button / image
   */
  const renderOrganizationImg = useMemo(() => {
    if (isLoading)
      return (
        <Zoom
          in={isLoading}
          timeout={{ enter: 300, exit: 0 }}
          mountOnEnter
          unmountOnExit
        >
          <Skeleton className='loading-img-skeleton' variant='circular' />
        </Zoom>
      );
    else
      return (
        <Grid item xs={12} sm={12}>
          <Box className='file-container'>
            <Box className='input-file-container'>
              <Zoom
                in={!!organizationData?.imageString}
                timeout={{ enter: 300, exit: 0 }}
                mountOnEnter
                unmountOnExit
              >
                <Box className='preview-container'>
                  <ButtonUi
                    isIconBtn
                    size='small'
                    className='remove-img'
                    onClickHandler={removeImage}
                  >
                    <ClearRounded />
                  </ButtonUi>
                  <img
                    className='preview-image'
                    src={organizationData?.imageString}
                    alt='Preview'
                  />
                </Box>
              </Zoom>

              <Zoom
                in={!organizationData?.imageString}
                timeout={{ enter: 300, exit: 0 }}
                mountOnEnter
                unmountOnExit
              >
                <Box className='load-img-wrapper'>
                  <input
                    className='input-file'
                    id='my-file'
                    type='file'
                    accept='image/x-png,image/gif,image/jpeg'
                    onChange={onUploadImage}
                  />
                  <label htmlFor='my-file' className='input-file-trigger'>
                    {t('buttons.upload-image')}
                  </label>
                </Box>
              </Zoom>
            </Box>
          </Box>
        </Grid>
      );
  }, [onUploadImage, organizationData, removeImage, t, isLoading]);

  /**
   * Shows form control field error message
   * @control field control name
   */
  const showValidationErrors = useCallback(
    (control) => {
      if (validationErrors[control])
        return Object.keys(validationErrors[control])?.map((validatorKey) => {
          const validator = validationErrors[control][validatorKey];
          if (!validator?.valid)
            return (
              <FormHelperText key={validatorKey} error>
                {validator?.message}
              </FormHelperText>
            );
          return null;
        });
      return null;
    },
    [validationErrors],
  );

  /**
   * Sets state after fetching organization data
   */
  useEffect(() => {
    if (selectedOrganization) {
      setOrganizationData(() => ({
        id: organizationToEdit?.id,
        name: organizationToEdit?.name,
        contact_email: organizationToEdit?.contact_email,
        contact_telephone: organizationToEdit?.contact_telephone,
        contact_address: selectedOrganization?.contact_address,
        contact_name: selectedOrganization?.contact_name,
        funds: selectedOrganization?.funds,
        image: selectedOrganization?.image,
        imageString: selectedOrganization?.image,
      }));
      setIsLoading(organizationLoading);
    }
  }, [selectedOrganization, organizationToEdit, organizationLoading]);

  useEffect(() => {
    if (organizationToEdit) getOrganizationById(organizationToEdit?.id);
    return () => clearSelectedOrganization();
  }, []);

  return (
    <Box
      component='form'
      className='create-form validate-form'
      onSubmit={handleSubmit}
    >
      <Grid container sx={organizationForm}>
        {renderOrganizationImg}
        <Grid item xs={12} sm={6}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.name')}
            autoFocus
            name='name'
            fullWidth
            value={organizationData?.name}
            loading={isLoading}
            handleChange={onChange}
          />
          {showValidationErrors('name')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.email')}
            autoFocus
            name='contact_email'
            fullWidth
            value={organizationData?.contact_email}
            loading={isLoading}
            handleChange={onChange}
          />
          {showValidationErrors('contact_email')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.contact_address')}
            autoFocus
            name='contact_address'
            fullWidth
            value={organizationData?.contact_address}
            loading={isLoading}
            handleChange={onChange}
          />
          {showValidationErrors('contact_address')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.contact_name')}
            autoFocus
            name='contact_name'
            fullWidth
            value={organizationData?.contact_name}
            loading={isLoading}
            handleChange={onChange}
          />
          {showValidationErrors('contact_name')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.contact_telephone')}
            autoFocus
            name='contact_telephone'
            fullWidth
            value={organizationData?.contact_telephone}
            loading={isLoading}
            handleChange={onChange}
          />
          {showValidationErrors('contact_telephone')}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextFieldUi
            className='simple-text-field'
            label={t('forms.funds')}
            autoFocus
            name='funds'
            fullWidth
            type='number'
            value={organizationData?.funds}
            loading={isLoading}
            handleChange={onChange}
          />
          {showValidationErrors('funds')}
        </Grid>
      </Grid>
      <Box className='modal-footer justify-end'>
        <ButtonUi
          className='action-btn accept'
          variant='contained'
          type='primary'
          size='small'
          btnType='submit'
          disabled={isSubmitionDisabled}
        >
          {t(submitBtnLabel)}
        </ButtonUi>
      </Box>
    </Box>
  );
});
