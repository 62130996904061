import { Box } from '@mui/material';

const helpCenterStyle = {
  height: '100%',
  '& iframe': {
    width: '100%',
    height: '100%',
  },
};

export const HelpCenter = () => (
  <Box className='help-center' sx={helpCenterStyle}>
    <iframe title='help-center' src='https://psylaris.helpkit.so/' />
  </Box>
);
