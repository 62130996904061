import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { ToolbarButton } from './style';
import { classNames } from '../../../../utils';

const CustomToolbarBtn = memo((props) => {
  const { Icon, label, disabled, t } = props;
  const { button } = ToolbarButton;
  return (
    <Box sx={button} className={classNames('', { disabled })}>
      <Icon />
      <Typography className='label' variant='body'>
        {t(label)}
      </Typography>
    </Box>
  );
});

export default withTranslation()(memo(CustomToolbarBtn));
