import { teamConstants } from '../constants/team.constants';

export function teams(state = { teams: [], totalTeams: 0 }, action) {
  switch (action.type) {
    case teamConstants.GETALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teamConstants.GETALL_SUCCESS: {
      const { data, totalCount } = action.teams;
      return {
        ...state,
        teams: data,
        totalTeams: totalCount,
        isLoading: false,
      };
    }
    case teamConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case teamConstants.GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teamConstants.GET_SUCCESS:
      return {
        ...state,
        selectedTeam: action.team,
        isLoading: false,
      };
    case teamConstants.GET_FAILURE:
      return {
        ...state,
        error: true,
      };

    case teamConstants.COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teamConstants.COUNT_SUCCESS: {
      const { count } = action;
      return {
        ...state,
        totalTeams: count,
      };
    }
    case teamConstants.COUNT_FAILURE:
      return {
        ...state,
        error: true,
      };

    case teamConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teamConstants.UPDATE_SUCCESS: {
      const fetchedTeam = action.team;

      const newTeams = state.teams.filter((item) => item.id !== fetchedTeam.id);
      return {
        ...state,
        teams: [...newTeams, fetchedTeam],
        isLoading: false,
      };
    }
    case teamConstants.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case teamConstants.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teamConstants.CREATE_SUCCESS: {
      const { teams } = state;
      const combinedTeams = [...teams, action.team];
      return {
        ...state,
        teams: combinedTeams,
        isLoading: false,
      };
    }
    case teamConstants.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case teamConstants.UPDATE_REL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teamConstants.UPDATE_REL_SUCCESS:
      return {
        ...state,
        selectedTeam: action.team,
        isLoading: false,
      };
    case teamConstants.UPDATE_REL_FAILURE:
      return {
        ...state,
        error: true,
      };
    case teamConstants.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teamConstants.DELETE_SUCCESS: {
      const { id } = action;
      const filteredOrganizations = state.teams.filter(
        (item) => item.id !== id,
      );
      return {
        ...state,
        teams: filteredOrganizations,
        isLoading: false,
      };
    }
    case teamConstants.DELETE_FAILURE:
      return {
        ...state,
        error: true,
      };
    case teamConstants.CLEAR_TEAMS:
      return {
        ...state,
        teams: [],
      };
    default:
      return state;
  }
}
