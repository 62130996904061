import { laptopRatio, mobileRatio, theme } from '../../theme';
import { getResponsiveSize } from '../../utils';

export const organizationFormStyle = {
  organizationForm: {
    py: getResponsiveSize(31.5), // 31.5px
    px: getResponsiveSize(36), // 36px
    gap: getResponsiveSize(18), // 18px
    '& .loading-img-skeleton': {
      width: getResponsiveSize(150), // 150px
      height: getResponsiveSize(150), // 150px
      m: '0 auto',
      backgroundColor: '#2392BA0D',
    },
    '& .input-file-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .load-img-wrapper': {
        position: 'relative',
        '& .input-file': {
          position: 'absolute',
          opacity: 0,
          cursor: 'pointer',
          width: '100%',
          height: '100%',
          '&:hover + .input-file-trigger, &:focus + .input-file-trigger': {
            backgroundPosition: 'left bottom',
          },
        },
        '& .input-file-trigger': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: getResponsiveSize(12), // 12px
          px: getResponsiveSize(16), // 16px
          height: getResponsiveSize(46), // 46px
          fontSize: theme.typography.fs19, // 19px
          lineHeight: getResponsiveSize(22), // 22px
          textTransform: 'none',
          borderRadius: theme.palette.propVars.borderRadius,
          ...theme.palette.propVars.btnBoxShadow,
          ...theme.palette.propVars.semiBoldFontWeight,
          // slide color effect
          backgroundSize: '200%',
          backgroundPosition: ' right bottom',
          transition:
            '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',
          backgroundImage: `linear-gradient(to left, transparent 50%, ${theme.palette.myPrimary.lightBlue40} 50%)`,
          border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
          color: theme.palette.myPrimary.lightBlue100,
          '&:hover': {
            backgroundPosition: 'left bottom',
          },
          '&:active': {
            color: theme.palette.myPrimary.lightBlue100,
          },
        },
      },
      '& .preview-container': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row-reverse',
        '& .remove-img': {
          color: theme.palette.myPrimary.lightBlue100,
          '& svg': {
            fontSize: getResponsiveSize(20), // 20px
          },
        },
        '& .preview-image': {
          aspectRatio: '1',
          borderRadius: '50%',
          objectFit: 'cover',
          height: getResponsiveSize(150), // 150px
          width: '100%',
        },
      },
    },
    '& .modal-footer': {
      justifyContent: 'flex-end',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .loading-img-skeleton': {
        width: getResponsiveSize(100, laptopRatio), // 100px
        height: getResponsiveSize(100, laptopRatio), // 100px
      },
      '& .input-file-container': {
        '& .load-img-wrapper .input-file-trigger': {
          py: getResponsiveSize(12, laptopRatio), // 12px
          px: getResponsiveSize(16, laptopRatio), // 16px
          height: getResponsiveSize(38, laptopRatio), // 38px
          fontSize: getResponsiveSize(15, laptopRatio), // 15px
        },
        '& .preview-container': {
          '& .remove-img svg': {
            fontSize: getResponsiveSize(20, laptopRatio), // 20px
          },
          '& .preview-image': {
            height: getResponsiveSize(100, laptopRatio), // 100px
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      gap: getResponsiveSize(12, mobileRatio), // 12px
    },
  },
};
