import { useCallback, useState } from 'react';

import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { ModalUI } from '../Modal/Modal';
import { ButtonUi } from '../Button/Button';
import { formModalStyle } from './style';

export const FormModal = (props) => {
  const {
    isOpen,
    title,
    children,
    setOpen,
    onClose,
    cancelBtnLabel,
    cancelBtnHandler,
    acceptBtnLabel,
    acceptBtnHandler,
  } = props;
  const { formModal } = formModalStyle;
  const { t } = useTranslation();

  const onCloseModal = useCallback(() => {
    if (onClose) onClose();
    else setOpen(false);
  }, [onClose, setOpen]);

  const isFooter = cancelBtnLabel || acceptBtnLabel;

  return (
    <ModalUI
      modalSx={formModal}
      open={isOpen}
      onCloseHandler={onCloseModal}
      className='form-modal'
      modalTitle={title}
    >
      {children}
      {isFooter && (
        <Box className='modal-footer'>
          {cancelBtnLabel && (
            <ButtonUi
              className='action-btn cancel'
              variant='outlined'
              type='secondary'
              size='small'
              onClickHandler={cancelBtnHandler}
            >
              {t(cancelBtnLabel)}
            </ButtonUi>
          )}
          {acceptBtnLabel && (
            <ButtonUi
              className='action-btn accept'
              variant='contained'
              type='primary'
              size='small'
              onClickHandler={acceptBtnHandler}
            >
              {t(acceptBtnLabel)}
            </ButtonUi>
          )}
        </Box>
      )}
    </ModalUI>
  );
};
