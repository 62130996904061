import { memo, useCallback } from 'react';
import { ButtonUi } from '../../../UI/Button/Button';

/**
 * Carousel responsive config
 */
export const responsive = {
  desktop: {
    breakpoint: { max: 3840, min: 768 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

/**
 * Carousel steps
 */
export const steps = [
  {
    imgUrl: '/assets/sync-steps/Step 3',
    message: 'device-connection.step-3',
    stepNumber: 3,
  },
  {
    imgUrl: '/assets/sync-steps/Step 1',
    message: 'device-connection.step-1',
    stepNumber: 1,
  },
  {
    imgUrl: '/assets/sync-steps/Step 2',
    message: 'device-connection.step-2',
    stepNumber: 2,
  },
];

/**
 * Carousel left/prev arrow
 */
export const CustomLeftArrow = memo(({ onClick, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;

  // Disable prev button if current slide is the first one
  const isPrevBtnDisabled = currentSlide === steps.length;

  return (
    <ButtonUi
      onClickHandler={() => onClick()}
      ariaLabel='Go to previous slide'
      isIconBtn
      disabled={isPrevBtnDisabled}
      className='control-btn prev'
      size='small'
    />
  );
});

/**
 * Carousel right/next arrow
 */
export const CustomRightArrow = memo(({ onClick, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;

  // Disable next button if current slide is the last one
  // currentSlide + 1 as index starts from 0
  const isNextBtnDisabled = currentSlide + 1 === steps.length * 2;

  return (
    <ButtonUi
      onClickHandler={() => onClick()}
      ariaLabel='Go to next slide'
      isIconBtn
      disabled={isNextBtnDisabled}
      className='control-btn next'
      size='small'
    />
  );
});
