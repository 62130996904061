import { Box, Skeleton } from '@mui/material';
import { useMemo } from 'react';
import { classNames } from '../../../../utils';
import { skeletonStyle } from './style';

export const LoadingSkeleton = (props) => {
  const { variant, className } = props;
  const { skeleton } = skeletonStyle;

  /**
   * @return skeleton loader depends in variant
   */
  const skeletonLoader = useMemo(() => {
    switch (variant) {
      case 'header':
        return (
          <Box component='td'>
            <Skeleton
              sx={skeleton}
              className={classNames(className, {}, ['header'])}
              variant='rounded'
            />
          </Box>
        );
      case 'body':
        return (
          <Skeleton
            sx={skeleton}
            className={classNames(className, {}, ['body'])}
            variant='rounded'
          />
        );
      case 'footer':
        return (
          <Box component='tfoot'>
            <Box component='tr'>
              <Box component='td'>
                <Skeleton
                  sx={skeleton}
                  className={classNames(className, {}, ['footer'])}
                  variant='rounded'
                />
              </Box>
            </Box>
          </Box>
        );
      default:
        break;
    }
  }, [className, skeleton, variant]);

  return skeletonLoader;
};
