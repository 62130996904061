import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo } from 'react';

import { withTranslation  } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { useActions } from '../../../../hooks/useActions';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';
import { REMOTE_THERAPY, THERAPY_ROOM } from '../../../../utils/config';
import CheckboxCard from '../../../UI/CheckboxCard/CheckboxCard';
import { WizardContentBox } from '../../../UI/WizardContentBox/WizardContentBox';

import { useTreatmentUrl } from '../hooks/useClientTreatmentUrl';
import { useFetchTherapyType } from '../hooks/useFetchTherapyType';
import { useWizardTherapyError } from '../hooks/useWizardTherapyError';
import { therapyLocationStyles } from './style';

function TherapyLocation(props) {
  // TODO: remove
  const { treatmentsRemote, t } = props;
  // TODO: remove
  const { remoteTreatmentUUID, remoteSessionUUID, deviceId } = treatmentsRemote;
  const { therapyLocation } = therapyLocationStyles;
  const { location, canGoNext } = useSelector(
    (state) => state.createTreatmentWizard,
  );
  const { setLocation, setNextButton, setNotification } = useActions();
  const [copiedText, copy] = useCopyToClipboard();
  const [therapyType, setTherapyType] = useFetchTherapyType();
  const { url } = useTreatmentUrl();

  // TODO: remove
  /**
   * Checks does treatment has been created
   */
  const isTreatmentCreated = useMemo(
    () => (remoteTreatmentUUID ? true : false),
    [remoteTreatmentUUID],
  );

  // TODO: remove
  /**
   * Copy client`s treatment link handler
   */
  const onCopyLink = useCallback(() => {
    copy(url);
    setNotification(true);
    setTimeout(() => {
      setNotification(false);
    }, 2000);
  }, [url]);

  /**
   * Handle therapy type changed
   * @param checkbox selected checkbox data
   */
  const handleTherapyTypeSelect = useCallback(
    (checkbox) => {
      switch (checkbox.type) {
        case THERAPY_ROOM:
          setLocation(THERAPY_ROOM);
          break;
        case REMOTE_THERAPY:
          setLocation(REMOTE_THERAPY);
          break;
      }
    },
    [setLocation],
  );

  // TODO: Remove hints
  /**
   * Generate therapy connection hint node
   * depends on therapy type
   */
  // const therapyConnectionHint = useMemo(() => {
  //   return (
  //     <Transition in={isTreatmentCreated} timeout={300} mountOnEnter unmountOnExit appear={true}>
  //       {(state) => {
  //         switch (location) {
  //           case REMOTE_THERAPY:
  //             return (
  //               <Box className={`join-hint slide-in-${state}`}>
  //                 <Box className="outer-wizard-box-title">
  //                   <Typography variant="h3" className="wizard-box-title">
  //                     How to invite client and join remote session?
  //                   </Typography>
  //                 </Box>
  //                 <WizardContentBox className="copy-box" active>
  //                   <Typography variant="h5" className="wizard-box-subtitle">
  //                     Send this link to your client
  //                   </Typography>
  //                   <ButtonUi
  //                     className="copy-btn"
  //                     variant="contained"
  //                     type="input"
  //                     size="small"
  //                     onClickHandler={onCopyLink}
  //                   >
  //                     Click to copy
  //                   </ButtonUi>
  //                 </WizardContentBox>
  //               </Box>
  //             );
  //           case THERAPY_ROOM:
  //             return (
  //               <WizardContentBox
  //                 active={isTreatmentCreated}
  //                 className={`notification slide-in-${state}`}
  //               >
  //                 <Typography variant="h3" className="wizard-box-title centred">
  //                   Open the link by clicking "Start therapy” button
  //                 </Typography>
  //               </WizardContentBox>
  //             );
  //         }
  //       }}
  //     </Transition>
  //   );
  // }, [location, REMOTE_THERAPY, THERAPY_ROOM, isTreatmentCreated, onCopyLink]);

  /**
   * Set error if therapy type isn't selected
   */
  useWizardTherapyError(isTreatmentCreated, copiedText);

  /**
   * Set next button if location previously was selected
   */
  useEffect(() => {
    if (location) if (!canGoNext) setNextButton(true);
  }, [canGoNext, setNextButton]);

  return (
    <Box sx={therapyLocation} className='step-container'>
      <WizardContentBox active={!isTreatmentCreated}>
        <Typography variant='h3' className='wizard-box-title'>
          {t('create-treatment-wizard.select-therapy-type.title')}
        </Typography>
        <Box className='checkbox-card-wrapper'>
          {therapyType &&
            Object.values(therapyType).map((therapy) => (
              <CheckboxCard
                type='column'
                disabled={isTreatmentCreated}
                key={therapy.type}
                checkboxItem={therapy}
                onCardSelectHandler={handleTherapyTypeSelect}
              />
            ))}
        </Box>
      </WizardContentBox>
      {/* TODO: Remove hints */}
      {/* Therapy connection hint */}
      {/* {therapyConnectionHint} */}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withTranslation ()(connect(mapStateToProps)(TherapyLocation));
