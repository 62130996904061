import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../hooks/useActions';
import { wizardErrors } from '../wizardErrors';

/**
 * Set error if client isn't selected and not anonymous session
 * @param isAnonymous is anonymous session
 */
export const useWizardClientError = (isAnonymous) => {
  const { selectedClient } = useSelector((state) => state.createTreatmentWizard);
  const { setError } = useActions();

  useEffect(() => {
    if (!selectedClient && !isAnonymous) setError(wizardErrors.CLIENT);
    else setError(null);
    return () => {
      setError(null);
    };
  }, [setError, selectedClient, wizardErrors, isAnonymous]);
};
