import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const simpleModalContentStyle = {
  modalContent: {
    px: getResponsiveSize(24), // 24px
    py: getResponsiveSize(29), // 29px
    '& .content-item': {
      fontSize: theme.typography.fs19,
      color: theme.palette.neutral.black,
      mb: getResponsiveSize(8.5), // 8.5px
      ...theme.palette.propVars.regularFontWeight,
      '& span': {
        color: theme.palette.myPrimary.darkBlue100,
        ...theme.palette.propVars.semiBoldFontWeight,
      },
    },
    '& .content-footer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: getResponsiveSize(24), // 24px
      mt: getResponsiveSize(50), // 50px
      '& .main-btn': {
        width: getResponsiveSize(165), // 165px
        fontSize: theme.typography.fs16,
        border: 'none',
        ...theme.palette.propVars.regularFontWeight,
        '& .MuiCircularProgress-root': {
          width: `${getResponsiveSize(28)} !important`, // 28px
          height: `${getResponsiveSize(28)} !important`, // 28px
          color: theme.palette.neutral.white,
        },
      },
      '& .secondary-btn': {
        width: getResponsiveSize(165), // 165px
        fontSize: theme.typography.fs19,
        ...theme.palette.propVars.regularFontWeight,
      },
    },
    '&.expanded-confirm': {
      '& .content-item': {
        fontSize: getResponsiveSize(16), // 16px
        textAlign: 'left',
      },
      '& .data-wrapper': {
        maxHeight: '100px',
        overflow: 'auto',
        pt: getResponsiveSize(8), // 8px
        '& .confirm-modal-data': {
          display: 'flex',
          flexDirection: 'column',
          py: getResponsiveSize(8), // 8px
          '& .label-value-row': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: getResponsiveSize(8), // 8px
            '& svg': {
              color: theme.palette.myPrimary.lightBlue100,
            },
            '& .data-label': {
              ...theme.palette.propVars.boldFontWeight,
            },
            '& .data-value': {
              ...theme.palette.propVars.regularFontWeight,
            },
          },
          '&.divider:not(:last-of-type):after': {
            content: "''",
            width: '90%',
            height: 2,
            background: theme.palette.myPrimary.lightBlue100,
            borderRadius: '100px',
            ml: '0.5%',
            mt: getResponsiveSize(8), // 4px
          },
        },
        '&::-webkit-scrollbar': {
          width: getResponsiveSize(6), // 6px
        },
        '&::-webkit-scrollbar-track': {
          margin: `${getResponsiveSize(8)} auto`, // 8px auto
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '100px',
          background: theme.palette.myPrimary.lightBlue20,
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      px: getResponsiveSize(16, laptopRatio), // 16px
      pt: getResponsiveSize(20, laptopRatio), // 20px
      '& .content-item': {
        fontSize: getResponsiveSize(15, laptopRatio), // 15px
        mb: getResponsiveSize(8.5, laptopRatio), // 8.5px
      },
      '&.expanded-confirm': {
        '& .content-item': {
          fontSize: getResponsiveSize(15, laptopRatio), // 15px
        },
        '& .confirm-modal-data': {
          '& .label-value-row': {
            gap: getResponsiveSize(8, laptopRatio), // 8px
            '& .data-label': {
              fontSize: getResponsiveSize(13, laptopRatio), // 13px
              ...theme.palette.propVars.boldFontWeight,
            },
            '& .data-value': {
              fontSize: getResponsiveSize(14, laptopRatio), // 14px
              ...theme.palette.propVars.regularFontWeight,
            },
          },
        },
      },
      '& .content-footer': {
        gap: getResponsiveSize(20, laptopRatio), // 20px
        mt: getResponsiveSize(40, laptopRatio), // 40px
        '& .main-btn': {
          width: getResponsiveSize(148, laptopRatio), // 148px
          height: getResponsiveSize(38, laptopRatio), // 38px
          fontSize: getResponsiveSize(14, laptopRatio), // 14px
          '& .MuiCircularProgress-root': {
            width: `${getResponsiveSize(24, laptopRatio)} !important`, // 24px
            height: `${getResponsiveSize(24, laptopRatio)} !important`, // 24px
          },
        },
        '& .secondary-btn': {
          width: getResponsiveSize(148, laptopRatio), // 148px
          height: getResponsiveSize(36, laptopRatio), // 36px
          fontSize: getResponsiveSize(14, laptopRatio), // 14px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      px: getResponsiveSize(12, mobileRatio), // 12px
      py: getResponsiveSize(20, mobileRatio), // 16px
      '& .content-item': {
        fontSize: getResponsiveSize(14, mobileRatio), // 14px
        mb: getResponsiveSize(8.5, mobileRatio), // 8.5px
      },
      '&.expanded-confirm': {
        '& .content-item': {
          fontSize: getResponsiveSize(15, mobileRatio), // 15px
        },
        '& .confirm-modal-data': {
          '& .label-value-row': {
            gap: getResponsiveSize(8, mobileRatio), // 8px
            '& .data-label': {
              fontSize: getResponsiveSize(13, mobileRatio), // 13px
              ...theme.palette.propVars.boldFontWeight,
            },
            '& .data-value': {
              fontSize: getResponsiveSize(14, mobileRatio), // 14px
              ...theme.palette.propVars.regularFontWeight,
            },
          },
        },
      },
      '& .content-footer': {
        gap: getResponsiveSize(20, mobileRatio), // 20px
        mt: getResponsiveSize(20, mobileRatio), // 20px
        '& .main-btn': {
          width: getResponsiveSize(100, mobileRatio), // 100px
          height: getResponsiveSize(32, mobileRatio), // 32px
          fontSize: getResponsiveSize(14, mobileRatio), // 14px
          '& .MuiCircularProgress-root': {
            width: `${getResponsiveSize(24, mobileRatio)} !important`, // 24px
            height: `${getResponsiveSize(24, mobileRatio)} !important`, // 24px
          },
        },
        '& .secondary-btn': {
          width: getResponsiveSize(100, mobileRatio), // 100px
          height: getResponsiveSize(32, mobileRatio), // 32px
          fontSize: getResponsiveSize(14, mobileRatio), // 14px
        },
      },
    },
  },
};

export const deleteConfirmationStyle = {
  deleteConfirmation: {
    '&.confirmation-modal': {
      '& .modal-wrapper ': {
        width: getResponsiveSize(584), // 584px
      },
      '& .modal-header': {
        px: getResponsiveSize(24), // 24px
        pt: getResponsiveSize(29), // 29px
        pb: '0',
        border: 'none',
        justifyContent: 'center',
        '& .title': {
          color: theme.palette.neutral.black,
        },
      },
      '& .content-item': {
        textAlign: 'center',
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '&.troubleshoot-modal': {
        '& .modal-wrapper ': {
          width: getResponsiveSize(460, laptopRatio), // 460px
        },
        '& .modal-header': {
          px: getResponsiveSize(16, laptopRatio), // 16px
          pt: getResponsiveSize(20, laptopRatio), // 20px
        },
      },
      '&.confirmation-modal': {
        '& .modal-wrapper .modal-header': {
          px: getResponsiveSize(16, laptopRatio), // 16px
          pt: getResponsiveSize(20, laptopRatio), // 20px
          '& .title': {
            fontSize: getResponsiveSize(16, laptopRatio), // 16px
          },
        },
      },
      '&.merge-organization-modal': {
        '& .modal-wrapper ': {
          width: getResponsiveSize(460, laptopRatio), // 460px
          '& .modal-header': {
            '& .title': {
              fontSize: getResponsiveSize(16, laptopRatio), // 16px
            },
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '&.confirmation-modal': {
        '& .modal-wrapper ': {
          width: '80dvw',
          '& .modal-header': {
            px: getResponsiveSize(12, mobileRatio), // 12px
            pt: getResponsiveSize(20, mobileRatio), // 20px
            '& .title': {
              fontSize: getResponsiveSize(16, mobileRatio), // 16px
            },
          },
        },
      },
    },
  },
};
