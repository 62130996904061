import { alertConstants } from '../constants/alert.constants';
import { showToast } from '../../utils/toast';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      showToast(action.message, 'success', 2000);
      return {
        type: 'alert-success',
        message: action.message,
      };
    case alertConstants.ERROR:
      showToast(action.message, 'error', 3000);
      return {
        type: 'alert-danger',
        message: action.message,
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
