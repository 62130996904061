import { laptopRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const poperHintStyles = {
  hints: {
    position: 'absolute',
    bottom: getResponsiveSize(8), // 8px
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: theme.palette.myPrimary.white,
    borderRadius: `0px 0px ${getResponsiveSize(12)} ${getResponsiveSize(12)}`,
    width: `calc(100% + ${getResponsiveSize(25)})`,
    border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
    borderTop: 'none',
    '& .hint-item': {
      background: 'rgba(35, 146, 186, 0.05)',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 'auto',
      gap: getResponsiveSize(4), // 4px,
      py: '11px',
      '&:first-of-type': {
        pl: getResponsiveSize(16), // 16px,
      },
      '&:last-of-type': {
        pr: getResponsiveSize(16), // 16px,
      },
      '& .title': {
        color: theme.palette.neutral.greyRGBA80,
        ml: getResponsiveSize(4), // 4px,
        ...theme.palette.propVars.regularFontWeight,
      },
      '& .esc': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: `calc(${theme.palette.propVars.index}* 1.56)`,
        height: `calc(${theme.palette.propVars.index} * 1)`,
        py: getResponsiveSize(5), // 5px,
        px: getResponsiveSize(11), // 11px,
        background: 'rgba(211, 233, 241, 0.5)',
        borderRadius: theme.palette.propVars.borderRadius,
        '& .icon-text': {
          color: theme.palette.neutral.greyRGBA80,
          letterSpacing: getResponsiveSize(0.7), // 0.7px
          ...theme.palette.propVars.regularFontWeight,
        },
      },
      '& .icon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(211, 233, 241, 0.5)',
        borderRadius: theme.palette.propVars.borderRadius,
        width: `calc(${theme.palette.propVars.index} * 1)`,
        height: `calc(${theme.palette.propVars.index} * 1)`,
        '&.arrow-down': {
          '& svg': {
            transform: 'rotate(180deg)',
          },
        },
        '& svg': {
          width: getResponsiveSize(13), // 13px
          height: getResponsiveSize(11), // 11px
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .hint-item .MuiTypography-root': {
        fontSize: getResponsiveSize(12, laptopRatio),
      },
    },
    // Tablet screen
    [theme.breakpoints.media.tablet]: {
      display: 'none',
    },
  },
};
