import { memo } from 'react';
import { Typography, Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { ReactComponent as VrNoConnectionIcon } from '../../shared/assets/icons/treatment-creation-result/EMDR-VR-no-connection.svg';

import { useActions } from '../../hooks/useActions';

import { ButtonUi } from '../UI/Button/Button';
import { connectionErrorStyle } from './style';

export const ConnectionError = memo((props) => {
  const { t } = useTranslation();
  const { logout } = useActions();
  const { connectionError } = connectionErrorStyle;

  return (
    <Box sx={connectionError}>
      <VrNoConnectionIcon className='icon' />
      <Typography className='connection-title' variant='h3'>
        {t('errors.connection.error_title')}
      </Typography>
      <Typography className='connection-sub-title' variant='h5'>
        {t('errors.connection.error_message')}
      </Typography>
      <ButtonUi
        className='btn'
        variant='contained'
        type='primary'
        size='small'
        onClickHandler={() => logout()}
      >
        {t('buttons.logout')}
      </ButtonUi>
    </Box>
  );
});
