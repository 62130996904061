import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useActions } from '../../../../hooks/useActions';

/**
 * Fetch configuration templates for product
 */
export const useProductConfigTemplates = () => {
  const { selectedProduct, productConfigTemplates: configurations } = useSelector(
    (state) => state.createTreatmentWizard,
  );
  const { getProductConfigTemplates } = useActions();

  useEffect(() => {
    if (!configurations) getProductConfigTemplates(selectedProduct?.id);
  }, [selectedProduct?.id, configurations, getProductConfigTemplates]);
};
