import { laptopRatio, theme } from "../../theme";
import { getResponsiveSize } from "../../utils";

export const connectionErrorStyle = {
  connectionError: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    '& .icon': {
      mb: getResponsiveSize(40), // 40px
      width: getResponsiveSize(706), // 707px
      height: getResponsiveSize(520), // 520px
    },
    '& .connection-title': {
      fontWeight: 700,
      color: '#4054B2',
      mb: getResponsiveSize(40), // 40px
    },
    '& .connection-sub-title': {
      color: theme.palette.neutral.black,
      mb: getResponsiveSize(40), // 40px
      ...theme.palette.propVars.regularFontWeight,
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .btn': {
        height: getResponsiveSize(36, laptopRatio), // 36px
      },
    },
  },
};
