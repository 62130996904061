import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from 'react-router';

const useAuthenticatedUser = () => {
  const history = useHistory();

  const navigateToLogin = () => {
    history.push('/login');
  };

  useEffect(() => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const { id } = JSON.parse(token);
        if (!id) {
          navigateToLogin();
        }
      } else {
        navigateToLogin();
      }
    } catch (e) {
      navigateToLogin();
    }
  }, [history]);

  return null;
};

export default useAuthenticatedUser;
