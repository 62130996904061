import { laptopRatio, mobileRatio, tabletRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

const dayCalendarWeekContainer = {
  '& .MuiDayCalendar-weekContainer': {
    '& .MuiPickersDay-root': {
      ...theme.palette.propVars.linearTransition,
      '&.MuiPickersDay-today:not(.Mui-selected)': {
        borderColor: theme.palette.myPrimary.lightBlue70,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.myPrimary.lightBlue100,
        '&:hover, :focus': {
          backgroundColor: theme.palette.myPrimary.darkBlue100,
        },
      },
      '&:hover, :focus': {
        color: theme.palette.neutral.white,
        backgroundColor: theme.palette.myPrimary.lightBlue100,
      },
    },
  },
};
const yearCalendar = {
  '& .MuiYearCalendar-root': {
    '& .MuiPickersYear-root': {
      '& .MuiPickersYear-yearButton': {
        ...theme.palette.propVars.semiBoldFontWeight,
        // slide color effect
        backgroundSize: '200%',
        backgroundPosition: ' right bottom',
        transition:
          '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',
        backgroundImage: `linear-gradient(to left, transparent 50%, ${theme.palette.myPrimary.lightBlue40} 50%)`,
        '&:hover, &.Mui-selected': {
          color: theme.palette.myPrimary.lightBlue100,
          backgroundPosition: 'left bottom',
        },
      },
    },
    '&::-webkit-scrollbar': {
      width: getResponsiveSize(6), // 6px
    },
    '&::-webkit-scrollbar-track': {
      margin: `${getResponsiveSize(8)} auto`, // 8px auto
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '100px',
      background: theme.palette.myPrimary.lightBlue20,
    },
  },
};

export const datePickerStyles = {
  datePicker: {
    backgroundColor: '#17607A1F',
    width: getResponsiveSize(180), // 180px
    borderRadius: theme.palette.propVars.borderRadius,
    '& .MuiFormLabel-root ': {
      top: '50%',
      transform: `translate(${getResponsiveSize(14)}, -50%)`,
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      '&.MuiFormLabel-filled, &.Mui-focused': {
        transform: `translate(${getResponsiveSize(0)}, -50%)`,
        opacity: 0,
      },
    },
    '& .MuiInputBase-root': {
      height: getResponsiveSize(40.5), // 40.5px
      p: getResponsiveSize(4.5), // 4.5px
      borderRadius: theme.palette.propVars.borderRadius,
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-error': {
        '& fieldset': {
          border: `1px solid ${theme.palette.neutral.error}`,
          '& legend': {
            width: 0,
          },
        },
      },
    },
    '& .MuiInputAdornment-root': {
      '& .MuiButtonBase-root': {
        width: 'auto',
        height: 'auto',
        mr: getResponsiveSize(2), // 2px
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: theme.palette.neutral.white,
        },
        '& svg': {
          width: getResponsiveSize(20), // 20px
          height: getResponsiveSize(20), // 20px
          stroke: theme.palette.myPrimary.lightBlue70,
          fill: 'transparent',
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      width: getResponsiveSize(130, laptopRatio), // 130px
      '& .MuiFormLabel-root ': {
        fontSize: getResponsiveSize(13, laptopRatio), // 13px
      },
      '& .MuiInputBase-root': {
        height: getResponsiveSize(32, laptopRatio), // 32px
        p: getResponsiveSize(3, laptopRatio), // 3px
        borderRadius: getResponsiveSize(8, laptopRatio), // 8px
        fontSize: getResponsiveSize(13, laptopRatio), // 13px
        '& input': {
          pl: getResponsiveSize(7, laptopRatio), // 7px
        },
      },
      '& .MuiInputAdornment-root .MuiButtonBase-root': {
        mr: getResponsiveSize(2, laptopRatio), // 2px
        '& svg': {
          width: getResponsiveSize(16, laptopRatio), // 20px
          height: getResponsiveSize(16, laptopRatio), // 20px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      width: getResponsiveSize(100, mobileRatio), // 100px
      '& .MuiFormLabel-root ': {
        fontSize: getResponsiveSize(12, mobileRatio), // 13px
      },
      '& .MuiInputBase-root': {
        height: getResponsiveSize(32, mobileRatio), // 32px
        p: getResponsiveSize(3, mobileRatio), // 3px
        borderRadius: getResponsiveSize(8, mobileRatio), // 8px
        fontSize: getResponsiveSize(13, mobileRatio), // 13px
        '& input': {
          pl: getResponsiveSize(7, mobileRatio), // 7px
          pr: 0,
        },
      },
      '& .MuiInputAdornment-root .MuiButtonBase-root': {
        display: 'none',
      },
    },
  },
  datePickerPopper: {
    // Picker layout
    zIndex: '1300',
    '& .MuiPaper-root': {
      borderRadius: getResponsiveSize(12), // 12px
      mt: getResponsiveSize(8), // 12px
      border: `1px solid ${theme.palette.myPrimary.lightBlue70}`,
      '& .MuiPickersLayout-root ': {
        '& .MuiPickersCalendarHeader-root': {
          '& .MuiPickersCalendarHeader-labelContainer': {
            fontSize: '16px',
          },
          '& .MuiPickersCalendarHeader-switchViewButton': {
            width: getResponsiveSize(40), // 40px
            height: getResponsiveSize(40), // 40px
            maxWidth: 40,
            maxHeight: 40,
            fontSize: '27px',
            color: theme.palette.myPrimary.lightBlue70,
            p: getResponsiveSize(2), // 2px
            ...theme.palette.propVars.easeInOutTransition,
            '& svg': {
              fontSize: 'inherit',
              ...theme.palette.propVars.linearTransition,
            },
          },
          '& .MuiPickersArrowSwitcher-root .MuiButtonBase-root': {
            '& svg': {
              color: theme.palette.myPrimary.lightBlue70,
            },
          },
        },
        '& .MuiPickersFadeTransitionGroup-root': {
          ...dayCalendarWeekContainer,
          ...yearCalendar,
          '& .MuiDayCalendar-header .MuiDayCalendar-weekDayLabel, & .MuiDayCalendar-weekContainer .MuiPickersDay-root':
            {
              fontSize: '14px',
            },
        },
      },
    },
  },
  // Mobile datepicker
  mobilePicker: {
    borderRadius: getResponsiveSize(12, tabletRatio), // 12px
    '& .MuiPickersLayout-root': {
      '& .MuiPickersToolbar-root ': {
        background: '#2392BA0D',
        '& span.MuiTypography-root': {
          fontSize: getResponsiveSize(15, tabletRatio), // 15px
          color: theme.palette.myPrimary.lightBlue100,
          fontFamily: theme.typography.secondaryFontFamily,
          ...theme.palette.propVars.boldFontWeight,
        },
        '& .MuiPickersToolbar-content h4': {
          fontSize: getResponsiveSize(14, tabletRatio), // 15px
          color: theme.palette.neutral.greyRGBA80,
        },
      },
      '& .MuiPickersLayout-contentWrapper': {
        '& .MuiPickersCalendarHeader-root': {
          '& .MuiPickersCalendarHeader-labelContainer': {
            '& .MuiPickersCalendarHeader-label': {
              color: theme.palette.neutral.grey70,
              fontSize: '14px',
            },
            '& .MuiPickersCalendarHeader-switchViewButton': {
              color: theme.palette.myPrimary.lightBlue70,
              '& svg': {
                // fontSize: 'inherit',
                ...theme.palette.propVars.linearTransition,
              },
            },
          },
          '& .MuiPickersArrowSwitcher-root': {
            '& svg': {
              color: theme.palette.myPrimary.lightBlue70,
            },
          },
        },
        '& .MuiPickersFadeTransitionGroup-root': {
          ...dayCalendarWeekContainer,
          ...yearCalendar,
          '& .MuiDayCalendar-header .MuiDayCalendar-weekDayLabel, & .MuiDayCalendar-weekContainer .MuiPickersDay-root':
            {
              fontSize: '13px',
            },
        },
      },
      '& .MuiDialogActions-root': {
        background: '#2392BA0D',
        '& button': {
          py: getResponsiveSize(12), // 12px
          px: getResponsiveSize(16), // 16px
          height: getResponsiveSize(46), // 46px
          fontSize: theme.typography.laptop.fs15, // 15px
          lineHeight: getResponsiveSize(22), // 22px
          textTransform: 'capitalize',
          borderRadius: theme.palette.propVars.borderRadius,
          ...theme.palette.propVars.btnBoxShadow,
          ...theme.palette.propVars.semiBoldFontWeight,
          // slide color effect
          backgroundSize: '200%',
          backgroundPosition: ' right bottom',
          transition:
            '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',

          '&:first-of-type': {
            backgroundImage: `linear-gradient(to left, transparent 50%, ${theme.palette.myPrimary.lightBlue40} 50%)`,
            border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
            color: theme.palette.myPrimary.lightBlue100,
            '&:hover': {
              backgroundPosition: 'left bottom',
            },
            '&:active': {
              color: theme.palette.myPrimary.lightBlue100,
            },
          },
          '&:last-of-type': {
            backgroundImage: `linear-gradient(to left, ${theme.palette.myPrimary.lightBlue100} 50%, ${theme.palette.myPrimary.darkBlue100} 50%)`,
            color: theme.palette.myPrimary.white,
            '&:hover': {
              backgroundPosition: 'left bottom',
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
            },
            '&:active': {
              boxShadow: 'none',
            },
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      borderRadius: getResponsiveSize(12, mobileRatio), // 12px
      '& .MuiPickersLayout-root': {
        '& .MuiPickersToolbar-root ': {
          '& span.MuiTypography-root': {
            fontSize: getResponsiveSize(15, mobileRatio), // 15px
          },
          '& .MuiPickersToolbar-content h4': {
            fontSize: getResponsiveSize(14, mobileRatio), // 15px
          },
        },
        '& .MuiDialogActions-root button': {
          py: getResponsiveSize(8, mobileRatio), // 8px
          px: getResponsiveSize(12, mobileRatio), // 12px
          height: getResponsiveSize(32, mobileRatio), // 32px
          fontSize: getResponsiveSize(15, mobileRatio), // 15px
        },
      },
    },
  },
  // Date error validation
  error: {
    position: 'relative',
    '& .error.visible': {
      visibility: 'visible',
      // opacity: '1',
      position: 'absolute',
      py: getResponsiveSize(5.75), // 5.75px
      px: getResponsiveSize(8), // 8px
      width: 'max-content',
      bottom: '100%',
      left: '50%',
      right: 'unset',
      '& .MuiTypography-root': {
        fontSize: theme.typography.fs19,
        margin: 0,
      },
    },
    // Animations
    '& .slide-in-entering': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(-50%, 10%)',
      },
    },
    '& .slide-in-entered': {
      opacity: 1,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(-50%, -20%)',
      },
    },
    '& .slide-in-exiting': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(-50%, 10%)',
      },
    },
    '& .slide-in-exited': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .error.visible': {
        padding: getResponsiveSize(3.75, laptopRatio), // 3.75px
        minWidth: getResponsiveSize(180, laptopRatio), // 180px
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(14, laptopRatio), // 15px
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      '& .error.visible': {
        padding: getResponsiveSize(3.75, mobileRatio), // 3.75px
        minWidth: getResponsiveSize(100, mobileRatio), // 100px
        borderRadius: getResponsiveSize(8, mobileRatio), // 8px
        background: 'rgba(255, 255, 255, 0.8)',
        '& .MuiTypography-root': {
          fontSize: getResponsiveSize(13, mobileRatio), // 13px
        },
      },
    },
  },
};
