import { useState, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

const COMPLETED_STATUSES = [
  { text: 'sessions.completed-label.completed', value: 'true' },
  { text: 'sessions.completed-label.in-process', value: 'false' },
];

/**
 * Generates the table filter's config based on table name
 * @param table table name eg. device |
 */
export const useTableFilterConfig = (table) => {
  const {
    deviceProfiles: deviceTypes,
    devicesNames,
    devicesSerialNumbers,
    isLoading: isDevicesLoading,
  } = useSelector((state) => state.devices);

  const { userIsAdmin, isDeviceAdmin, isLogisticsAdmin, isDeviceManager } =
    useSelector((state) => state.authentication);

  const { products, isLoading: isProductsLoading } = useSelector(
    (state) => state.products,
  );

  const {
    shortOrganizations: organizationOptions,
    isLoading: isOrganizationLoading,
  } = useSelector((state) => state.organizations);

  const hasDeviceOrganizationAccess = isDeviceAdmin || userIsAdmin;
  const hasPractitionerOrganizationAccess = isLogisticsAdmin || userIsAdmin;
  const hasOrganizationAccess = userIsAdmin;
  const [config, setConfig] = useState([]);

  useEffect(() => {
    switch (table) {
      // Client table filter config
      case 'client':
        setConfig(() => [
          {
            filter: 'sessions',
            label: 'forms.total.sessions',
            type: 'conditional-number',
            max: 9999,
            min: 0,
            order: 1,
          },
          {
            filter: 'monthly_sessions',
            label: 'forms.total.monthly_sessions',
            type: 'conditional-number',
            isLoading: isDevicesLoading,
            max: 999,
            min: 0,
            order: 2,
          },
          {
            filter: 'start_score',
            label: 'forms.begin_score',
            type: 'range',
            max: 10,
            min: 0,
            order: 3,
          },
          {
            filter: 'end_score',
            label: 'forms.end_score',
            type: 'range',
            max: 10,
            min: 0,
            order: 4,
          },
          {
            filter: 'decrease',
            label: 'forms.delta',
            type: 'range',
            max: 10,
            min: -10,
            order: 5,
          },
        ]);
        break;
      // Device table filter config
      case 'device':
        setConfig(() => [
          {
            filter: 'generated_uuid',
            label: 'forms.uuid',
            type: 'autocomplete',
            options: [...new Set(devicesSerialNumbers)]
              .filter(({ generated_UUID }) => generated_UUID !== null)
              .map(({ generated_UUID }) => ({
                value: generated_UUID,
                text: generated_UUID,
              })),
            retrunType: 'string',
            isLoading: isDevicesLoading,
            order: 1,
          },
          hasDeviceOrganizationAccess && {
            filter: 'organizationId',
            label: 'forms.organization_name',
            type: 'autocomplete',
            options: organizationOptions.map((item) => ({
              value: item.id,
              text: item.name,
            })),
            retrunType: 'number',
            isLoading: isOrganizationLoading,
            order: 3,
          },
          isDeviceManager && {
            filter: 'show_deleted',
            type: 'toggle',
            options: [
              {
                type: 'text',
                content: 'forms.hide_deleted',
                variable: 'hide_deleted',
              },
              {
                type: 'text',
                content: 'forms.show_deleted',
                variable: 'show_deleted',
              },
            ],
            retrunType: 'boolean',
            order: 6,
          },
          {
            filter: 'type',
            label: 'forms.type',
            type: 'autocomplete',
            options: [
              ...new Set(deviceTypes.map((deviceType) => deviceType.type)),
            ].map((item) => ({ value: item, text: item })),
            retrunType: 'string',
            isLoading: isDevicesLoading,
            order: 4,
          },
          {
            filter: 'monthly_sessions',
            label: 'forms.total.monthly_sessions',
            type: 'conditional-number',
            isLoading: isDevicesLoading,
            max: 999,
            min: 0,
            order: 5,
          },
        ]);
        break;
      // Sessions table filter config
      case 'sessions':
        setConfig(() => [
          {
            filter: 'type',
            label: 'forms.type',
            type: 'autocomplete',
            options: [...new Set(products)].map(({ name }) => ({
              value: name,
              text: name,
            })),
            retrunType: 'string',
            isLoading: isProductsLoading,
            order: 1,
          },
          {
            filter: 'completed',
            label: 'forms.completed',
            type: 'autocomplete',
            options: [...new Set(COMPLETED_STATUSES)],
            retrunType: 'boolean',
            order: 1,
          },
          {
            filter: 'decrease',
            label: 'forms.delta',
            type: 'range',
            max: 10,
            min: -10,
            order: 3,
          },
          {
            filter: 'start_date',
            label: 'forms.start_date',
            type: 'conditional-date',
            order: 4,
          },
        ]);
        break;
      // Treatment table filter config
      case 'treatment':
        setConfig(() => [
          {
            filter: 'device_name',
            label: 'forms.device',
            type: 'autocomplete',
            options: [...new Set(devicesNames)]
              .filter(({ name }) => name !== null)
              .map(({ name }) => ({
                value: name,
                text: name,
              })),
            retrunType: 'string',
            isLoading: isDevicesLoading,
            order: 1,
          },
          hasOrganizationAccess && {
            filter: 'organizationId',
            label: 'forms.organization_name',
            type: 'autocomplete',
            options: organizationOptions.map((item) => ({
              value: item.id,
              text: item.name,
            })),
            retrunType: 'number',
            isLoading: isOrganizationLoading,
            order: 2,
          },
          {
            filter: 'treatment_type',
            label: 'forms.type',
            type: 'autocomplete',
            options: [...new Set(products)].map(({ name }) => ({
              value: name,
              text: name,
            })),
            retrunType: 'string',
            isLoading: isProductsLoading,
            order: 3,
          },
          {
            filter: 'sessions',
            label: 'forms.total.sessions',
            type: 'conditional-number',
            max: 9999,
            min: 0,
            order: 4,
          },
          {
            filter: 'start_date',
            label: 'forms.start_date',
            type: 'conditional-date',
            order: 5,
          },
        ]);
        break;
      // Organization table filter config
      case 'organization':
        setConfig(() => [
          {
            filter: 'sessions',
            label: 'forms.total.sessions',
            type: 'conditional-number',
            max: 9999,
            min: 0,
            order: 1,
          },
          {
            filter: 'monthly_sessions',
            label: 'forms.total.monthly_sessions',
            type: 'conditional-number',
            max: 999,
            min: 0,
            order: 2,
          },
          {
            filter: 'therapists',
            label: 'forms.total.therapists',
            type: 'conditional-number',
            max: 999,
            min: 0,
            order: 3,
          },
        ]);
        break;
      // Teams | Practitioner table filter config
      default:
        setConfig(() => [
          hasPractitionerOrganizationAccess && {
            filter: 'organizationId',
            label: 'forms.organization_name',
            type: 'autocomplete',
            options: organizationOptions.map((item) => ({
              value: item.id,
              text: item.name,
            })),
            retrunType: 'number',
            isLoading: isOrganizationLoading,
            order: 1,
          },
          {
            filter: 'clients',
            label: 'forms.total.active_clients',
            type: 'conditional-number',
            max: 999,
            min: 0,
            order: 2,
          },
          {
            filter: 'sessions',
            label: 'forms.total.sessions',
            type: 'conditional-number',
            max: 9999,
            min: 0,
            order: 3,
          },
          {
            filter: 'monthly_sessions',
            label: 'forms.total.monthly_sessions',
            type: 'conditional-number',
            max: 999,
            min: 0,
            order: 4,
          },
        ]);
        break;
    }
  }, [
    deviceTypes,
    devicesNames,
    hasDeviceOrganizationAccess,
    hasPractitionerOrganizationAccess,
    devicesSerialNumbers,
    isDevicesLoading,
    isOrganizationLoading,
    organizationOptions,
    table,
    products,
    isProductsLoading,
    hasOrganizationAccess,
    isDeviceManager,
  ]);

  return config.sort((a, b) => a.order - b.order);
};
