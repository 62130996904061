import { memo } from 'react';
import { Box, Typography } from '@mui/material';

import { withTranslation  } from 'react-i18next';
import { ReactComponent as CaretIcon } from '../../../shared/assets/icons/caret.svg';
import { ReactComponent as EnterIcon } from '../../../shared/assets/icons/enter.svg';

import { poperHintStyles } from './style';

const AutocompleteSelectHints = (props) => {
  const { hints } = poperHintStyles;
  const { className, t, hintsSx } = props;

  return (
    <Box sx={{...hints, ...hintsSx}} className={className}>
      <Box className='hint-item'>
        <Box className='icon arrow-up'>
          <CaretIcon />
        </Box>
        <Box className='icon arrow-down'>
          <CaretIcon />
        </Box>
        <Typography variant='h6' className='title'>
          {t('hints.autocomplete.to-navigate')}
        </Typography>
      </Box>
      <Box className='hint-item'>
        <Box className='icon enter'>
          <EnterIcon />
        </Box>
        <Typography variant='h6' className='title'>
          {t('hints.autocomplete.to-select')}
        </Typography>
      </Box>
      <Box className='hint-item'>
        <Box className='esc'>
          <Typography className='icon-text' variant='h6'>
            esc
          </Typography>
        </Box>
        <Typography variant='h6' className='title'>
          {t('hints.autocomplete.to-dismiss')}
        </Typography>
      </Box>
    </Box>
  );
};

export default withTranslation ()(memo(AutocompleteSelectHints));
