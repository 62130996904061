import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const logicCheckStyle = {
  logicCheck: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: getResponsiveSize(12), // 12px
    height: getResponsiveSize(53), // 53px
    width: getResponsiveSize(428), // 428px
    border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
    py: getResponsiveSize(9), // 9px
    px: getResponsiveSize(24), // 24px
    pr: getResponsiveSize(12), // 12px
    backgroundColor: theme.palette.myPrimary.white,
    borderRadius: theme.palette.propVars.borderRadius,
    "& [class*='-MuiPopper-root ']": {
      zIndex: 1,
    },
    '& .MuiTypography-root': {
      mr: 'auto',
      color: theme.palette.neutral.greyRGBA80,
    },
    '& .option-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      backgroundColor: theme.palette.myPrimary.white,
      border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
      borderRadius: getResponsiveSize(8), // 8px
      py: getResponsiveSize(4), // 4px
      px: getResponsiveSize(8), // 8px
      gap: getResponsiveSize(8), // 8px
    },
    '& .MuiButtonBase-root': {
      p: getResponsiveSize(5), // 5px
      height: getResponsiveSize(28), // 28px
      width: getResponsiveSize(48), // 48px
      borderRadius: getResponsiveSize(8), // 8px
      ...theme.palette.propVars.btnBoxShadow,
      ...theme.palette.propVars.semiBoldFontWeight,
      // slide color effect
      backgroundSize: '200%',
      backgroundPosition: ' right bottom',
      transition:
        '.45s background cubic-bezier(0.4, 0, 0.2, 1), .4s box-shadow cubic-bezier(0.4, 0, 0.2, 1)',
      '& svg': {
        ...theme.palette.propVars.easeInOutTransition,
        fontSize: getResponsiveSize(24), // 24px
      },
      '&.option': {
        backgroundImage: `linear-gradient(to left, transparent 50%, ${theme.palette.myPrimary.lightBlue40} 50%)`,
        border: `1px solid ${theme.palette.myPrimary.lightBlue100}`,
        color: theme.palette.myPrimary.lightBlue100,
        '&:hover': {
          backgroundPosition: 'left bottom',
        },
        '&:active': {
          color: theme.palette.myPrimary.lightBlue100,
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
        },
        '&.selected': {
          backgroundImage: `linear-gradient(to left, ${theme.palette.myPrimary.lightBlue100} 50%, ${theme.palette.myPrimary.darkBlue100} 50%)`,
          color: theme.palette.myPrimary.white,
        },
      },
      '&.active-sign': {
        backgroundImage: `linear-gradient(to left, #17607A1F 50%, ${theme.palette.myPrimary.darkBlue100} 50%)`,
        color: theme.palette.myPrimary.lightBlue70,
        '&.open': {
          '& svg': {
            transform: 'rotate(180deg)',
          },
        },
        '&:hover': {
          color: theme.palette.myPrimary.white,
          backgroundPosition: 'left bottom',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          background: theme.palette.myPrimary.lightBlue100,
        },
        '&.selected': {
          backgroundImage: `linear-gradient(to left, ${theme.palette.myPrimary.lightBlue100} 50%, ${theme.palette.myPrimary.darkBlue100} 50%)`,
          color: theme.palette.myPrimary.white,
        },
      },
    },
    '& .error-wrapper': {
      position: 'relative',
      '& .error.visible': {
        visibility: 'visible',
        position: 'absolute',
        padding: getResponsiveSize(5.75), // 5.75px
        minWidth: getResponsiveSize(255), // 255px
        width: 'max-content',
        bottom: '100%',
        left: '50%',
        right: 'unset',
        '& .MuiTypography-root': {
          fontSize: theme.typography.fs19,
          margin: 0,
        },
      },
    },
    // Animations
    '& .slide-in-entering': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(-50%, 10%)',
      },
    },
    '& .slide-in-entered': {
      opacity: 1,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(-50%, -20%)',
      },
    },
    '& .slide-in-exiting': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(-50%, 10%)',
      },
    },
    '& .slide-in-exited': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      height: getResponsiveSize(49, laptopRatio), // 49px
      width: getResponsiveSize(325, laptopRatio), // 325px
      gap: getResponsiveSize(10, laptopRatio), // 12px
      py: getResponsiveSize(9, laptopRatio), // 9px
      px: getResponsiveSize(24, laptopRatio), // 24px
      pr: getResponsiveSize(12, laptopRatio), // 12px
      '& .MuiTypography-root ': {
        fontSize: getResponsiveSize(14, laptopRatio), // 14px
      },
      '& .option-wrapper': {
        borderRadius: getResponsiveSize(8, mobileRatio), // 8px
        py: getResponsiveSize(4, mobileRatio), // 4px
        px: getResponsiveSize(8, mobileRatio), // 8px
        gap: getResponsiveSize(8, mobileRatio), // 8px
      },
      '& .MuiButtonBase-root': {
        p: getResponsiveSize(5, laptopRatio), // 5px
        height: getResponsiveSize(28, laptopRatio), // 28px
        width: getResponsiveSize(48, laptopRatio), // 48px
        borderRadius: getResponsiveSize(8, laptopRatio), // 8px
        '& svg': {
          fontSize: getResponsiveSize(24, laptopRatio), // 24px
        },
      },
      '& .error-wrapper': {
        '& .error.visible': {
          padding: getResponsiveSize(3.75, laptopRatio), // 3.75px
          minWidth: getResponsiveSize(180, laptopRatio), // 180px
          borderRadius: getResponsiveSize(8, laptopRatio), // 8px
          '& .MuiTypography-root': {
            fontSize: getResponsiveSize(14, laptopRatio), // 14px
          },
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      width: '100%',
      height: getResponsiveSize(42, mobileRatio), // 42px
      gap: getResponsiveSize(10, mobileRatio), // 10px
      py: getResponsiveSize(9, mobileRatio), // 9px
      px: getResponsiveSize(24, mobileRatio), // 24px
      pr: getResponsiveSize(12, mobileRatio), // 12px
      borderRadius: getResponsiveSize(8, mobileRatio), // 8px
      '& .MuiTypography-root ': {
        fontSize: getResponsiveSize(13, mobileRatio), // 13px
      },
      '& .MuiButtonBase-root': {
        p: getResponsiveSize(5, mobileRatio), // 5px
        height: getResponsiveSize(28, mobileRatio), // 28px
        width: getResponsiveSize(48, mobileRatio), // 48px
        borderRadius: getResponsiveSize(8, mobileRatio), // 8px
        '& svg': {
          fontSize: getResponsiveSize(24, mobileRatio), // 24px
        },
      },
      '& .error-wrapper': {
        '& .error.visible': {
          padding: getResponsiveSize(3.75, mobileRatio), // 3.75px
          minWidth: getResponsiveSize(195, mobileRatio), // 195px
          borderRadius: getResponsiveSize(8, mobileRatio), // 8px
          background: 'rgba(255, 255, 255, 0.8)',
          left: 0,
          '& .MuiTypography-root': {
            fontSize: getResponsiveSize(13, mobileRatio), // 13px
          },
        },
      },
      // Animations
      '& .slide-in-entering.slide-top': {
        transform: 'translate(0%, -25%)',
      },
      '& .slide-in-entered.slide-top': {
        transform: 'translate(-73.5%, -25%)',
      },
      '& .slide-in-exiting.slide-top': {
        transform: 'translate(-0%, -25%)',
      },
    },
  },
  inputStyle: {
    backgroundColor: '#17607A1F',
    width: getResponsiveSize(46.5), // 46.5px
    borderRadius: theme.palette.propVars.borderRadius,
    height: getResponsiveSize(40.5), // 40.5px
    p: getResponsiveSize(4.5), // 4.5px
    '& input::selection': {
      backgroundColor: '#7FA8B7',
    },
    '&.Mui-focused': {
      '&:after': {
        content: 'none',
      },
    },

    '& .MuiInput-input': {
      textAlign: 'center',
      p: 0,
      fontSize: `${theme.typography.fs19} !important`,
    },
    '&:before': {
      content: 'none',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      width: getResponsiveSize(38, laptopRatio), // 38px
      height: getResponsiveSize(32, laptopRatio), // 32px
      borderRadius: getResponsiveSize(8, laptopRatio), // 8px
      p: getResponsiveSize(3, laptopRatio), // 3px
      '& .MuiInput-input': {
        fontSize: `${getResponsiveSize(15, laptopRatio)} !important`, // 15px
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      width: getResponsiveSize(38, mobileRatio), // 38px
      height: getResponsiveSize(32, mobileRatio), // 32px
      borderRadius: getResponsiveSize(8, mobileRatio), // 8px
      p: getResponsiveSize(3, mobileRatio), // 3px
      '& .MuiInput-input': {
        fontSize: `${getResponsiveSize(14, mobileRatio)} !important`, // 14px
      },
    },
  },
};
