import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Transition } from 'react-transition-group';
import { ReactComponent as DeutschFlagIcon } from '../../../shared/assets/icons/languages/deutsch.svg';
import { ReactComponent as DutchFlagIcon } from '../../../shared/assets/icons/languages/dutch.svg';
import { ReactComponent as EnglishFlagIcon } from '../../../shared/assets/icons/languages/english.svg';
import { LanguageSelectOptions } from './LanguageSelectOptions/LanguageSelectOptions';

const supportedLanguages = [
  {
    value: 'nl',
    label: 'Dutch',
    Icon: <DutchFlagIcon />,
  },
  {
    value: 'en',
    label: 'English',
    Icon: <EnglishFlagIcon />,
  },
  {
    value: 'de',
    label: 'Deutsch',
    Icon: <DeutschFlagIcon />,
  },
];

const LanguageSwitcher = function ({ i18n }) {
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [isSelectVisible, setIsSelectVisible] = useState(false);

  /**
   * Initializes language
   */
  const initLang = useCallback(() => {
    const cachedLanguage = (localStorage.getItem('i18nextLng') || '')
      .toLowerCase()
      .substring(0, 2);
    const foundLanguage =
      supportedLanguages.find(
        (language) => language.value === cachedLanguage,
      ) || supportedLanguages.find((language) => language.value === 'nl');
    setCurrentLanguage(foundLanguage);
    i18n.changeLanguage(foundLanguage.value);
  }, [i18n]);

  /**
   * Handles select`s option click
   * @param selectedValue selected option
   */
  const selectLanguage = useCallback(
    (selectedValue) => {
      i18n.changeLanguage(selectedValue.value).then(() => {
        setCurrentLanguage(selectedValue);
        setIsSelectVisible(false);
      });
    },
    [i18n],
  );

  useEffect(() => {
    initLang();
  }, []);

  return (
    <Box className='lng-switcher-wrapper'>
      <Transition
        in={isSelectVisible}
        timeout={300}
        mountOnEnter
        unmountOnExit
        appear={true}
      >
        {(state) => (
          <Box className={`lng-select ${state}`}>
            <ul className='lng-list'>
              {/* Active language option */}
              <LanguageSelectOptions isActive={true} option={currentLanguage} />
              {/* Other language options */}
              {supportedLanguages
                ?.filter((lng) => currentLanguage?.value !== lng?.value)
                ?.map((lng) => (
                  <LanguageSelectOptions
                    key={lng.value}
                    option={lng}
                    onSelectOption={selectLanguage}
                  />
                ))}
            </ul>
          </Box>
        )}
      </Transition>

      <button
        type='button'
        className='icon-btn language'
        onClick={() => setIsSelectVisible((prev) => (prev = !prev))}
      >
        {currentLanguage?.Icon}
      </button>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default withTranslation()(connect(mapStateToProps)(LanguageSwitcher));
