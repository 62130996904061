import { tabletRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const checkboxStyle = {
  checkbox: {
    color: theme.palette.myPrimary.lightBlue70,
    p: getResponsiveSize(9), // 9px
    '&:hover': {
      backgroundColor: theme.palette.myPrimary.lightBlue20,
    },
    '&.Mui-checked': {
      color: theme.palette.myPrimary.lightBlue70,
    },
    '& svg': {
      width: getResponsiveSize(24), // 24px
      height: getResponsiveSize(24), // 24px
      fontSize: theme.typography.fs24, // 24px
      minHeight: 22,
      minWidth: 22,
    },
    // Tablet screen
    [theme.breakpoints.media.tablet]: {
      p: getResponsiveSize(8, tabletRatio), // 8px
      '& svg': {
        width: getResponsiveSize(24, tabletRatio), // 24px
        height: getResponsiveSize(24, tabletRatio), // 24px
      },
    },
  },
};
