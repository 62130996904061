import moment from 'moment';
import { getI18n } from 'react-i18next';
import { ClientAvatar } from '../../components/UI/MuiDatatable/ClientAvatar/ClientAvatar';
import CustomDateTimeLayout from '../../components/UI/MuiDatatable/CustomDateTimeLayout/CustomDateTimeLayout';
import { CustomMuiDatatableEmdrType } from '../../components/UI/MuiDatatable/CustomMuiDatatableEmdrType/CustomMuiDatatableEmdrType';
import { DataCell } from '../../components/UI/MuiDatatable/DataCell/DataCell';
import { DurationTransformer } from '../../components/UI/MuiDatatable/DurationTransformer/DurationTransformer';
import { sessionService } from '../../services/sessions';
import translateClientName from '../../utils/TranslatedPossibleClientName';
import { sessionConstants } from '../constants/session.constants';
import { alertActions } from './alert.actions';

const joinValueIntoArray = (val) => {
  if (!val) {
    return [];
  }
  if (Array.isArray(val)) {
    if (val.length === 1) {
      return [...val, ...val];
    }
    return val;
  }
  return [];
};

const deltaSeriesText = (seriesData) => {
  if (!seriesData || seriesData.length <= 1) {
    return null;
  }
  const delta = seriesData[seriesData.length - 1] - seriesData[0];
  return delta > 0 ? `+${delta}` : `${delta}`;
};

export const createSessionObject = (element, isExport = false) => {
  if (!element) return {};

  const { attributes } = element;
  const combinedObject = { ...attributes, ...element };
  const {
    id,
    type,
    duration,
    generateduuid,
    client_name,
    start_date,
    sud_array,
    completed,
    validity_of_cognition_array,
    deviceId,
    treatmentUUID,
    client,
  } = combinedObject;

  if (!isExport)
    return {
      id: <DataCell>{id}</DataCell>,
      [getI18n().t('forms.gen_id')]: (
        <DataCell type='secondary'>{generateduuid}</DataCell>
      ),
      [getI18n().t('forms.client_name')]: (
        <DataCell>
          <ClientAvatar
            clientName={translateClientName(client_name)}
            seedId={id}
          />
        </DataCell>
      ),
      [getI18n().t('forms.type')]: (
        <DataCell type='secondary'>
          <CustomMuiDatatableEmdrType type={type} />
        </DataCell>
      ),
      [getI18n().t('forms.duration')]: (
        <DataCell type='numerics'>
          <DurationTransformer duration={duration} />
        </DataCell>
      ),
      [getI18n().t('forms.sud_scores')]: joinValueIntoArray(sud_array),
      [getI18n().t('forms.validity_of_cognition_array')]: joinValueIntoArray(
        validity_of_cognition_array,
      ),
      [getI18n().t('forms.start_date')]: (
        <DataCell type='secondary'>
          <CustomDateTimeLayout date={start_date} isTime />
        </DataCell>
      ),
      [getI18n().t('forms.completed')]: completed,
    };
  else
    return {
      id,
      [getI18n().t('forms.gen_id')]: generateduuid,
      [getI18n().t('forms.client_name')]: translateClientName(client_name),
      [getI18n().t('forms.type')]: type,
      [getI18n().t('forms.duration')]: duration || 'null',
      [getI18n().t('forms.sud_scores')]: joinValueIntoArray(sud_array),
      [getI18n().t('forms.validity_of_cognition_array')]: joinValueIntoArray(
        validity_of_cognition_array,
      ),
      [getI18n().t('forms.start_date')]: moment(start_date).format(
        'DD-MM-YYYY [at] HH:mm',
      ),
      [getI18n().t('forms.completed')]: completed,
    };
};

function findSessionById(id) {
  return (dispatch) => {
    dispatch(request({ id }));

    return sessionService.findSessionById(id).then(
      (session) => {
        dispatch(success(session.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.session')));
      },
    );
  };

  function request(session) {
    return {
      type: sessionConstants.GET_REQUEST,
      session,
    };
  }

  function success(session) {
    return {
      type: sessionConstants.GET_SUCCESS,
      session,
    };
  }

  function failure(error) {
    return {
      type: sessionConstants.GET_FAILURE,
      error,
    };
  }
}

const getAllSessions = (
  limit,
  offset,
  order,
  searchQuery = '',
  anonymousInclude,
  sessionType,
  sessionCompleted,
  sessionDecrease,
  startDate,
) => {
  const request = () => ({ type: sessionConstants.GETALL_REQUEST });
  const success = (sessions) => ({
    type: sessionConstants.GETALL_SUCCESS,
    sessions,
  });
  const failure = (error) => ({
    type: sessionConstants.GETALL_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    return (
      sessionService
        .getAllSessions(
          limit,
          offset,
          order,
          searchQuery,
          anonymousInclude,
          sessionType,
          sessionCompleted,
          sessionDecrease,
          startDate,
        )
        .then((result) => {
          const { sessions = [], totalCount = 0 } = result?.data || {};
          if (sessions.length > 0) {
            const createdSessions = sessions;
            const attributes = [...new Set(Object.keys(createdSessions[0]))];
            dispatch(
              success({
                attributes,
                data: createdSessions,
                totalCount,
              }),
            );
          } else {
            dispatch(
              success({
                attributes: [],
                data: [],
              }),
            );
          }
        }),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.sessions')));
      }
    );
  };
};

const getSessionsCount = (filter) => {
  const request = () => ({ type: sessionConstants.COUNT_REQUEST });
  const success = (count) => ({
    type: sessionConstants.COUNT_SUCCESS,
    count,
  });
  const failure = (error) => ({
    type: sessionConstants.COUNT_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    return sessionService.getSessionsCount(filter).then(
      (result) => {
        const { count } = result.data;
        dispatch(success(count));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(getI18n().t('errors.get.session')));
      },
    );
  };
};

function clearSessionsData() {
  return (dispatch) => {
    dispatch({
      type: sessionConstants.CLEAR_SESSIONSDATA,
    });
  };
}

export const sessionActions = {
  getAllSessions,
  findSessionById,
  createSessionObject,
  getSessionsCount,
  clearSessionsData,
};
