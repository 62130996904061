import { LoadingSkeleton } from '../components/UI/MuiDatatable/LoadingSkeleton/LoadingSkeleton';

/**
 * @param type type of skeleton data to return body | header
 * @returns mui-datatable data to display skeletons
 */
export const muiTableLoadingSkeleton = (type, bodySize = 10) => {
  switch (type) {
    /**
     * Haed loading attributes
     */
    case 'header':
      return [
        {
          name: '',
          options: {
            filter: false,
            sort: false,
            empty: true,
            viewColumns: false,
            customHeadRender: () => (
              <LoadingSkeleton key='header-skeleton' variant='header' />
            ),
          },
        },
      ];
    /**
     * Body loading attributes
     */
    case 'body':
      return new Array(bodySize).fill().map((_, id) => ({
        '': <LoadingSkeleton key={`body-skeleton-${id}`} variant='body' />,
      }));
    default:
      break;
  }
};
