import { Box, Typography } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { classNames } from '../../../utils';

import RangeInput from '../RangeInput/RangeInput';
import { logicRangeStyle } from './style';

const MIN_INPUT = -99;
const MAX_INPUT = 99;

export const RangeFilter = memo((props) => {
  const {
    label,
    onChange,
    step,
    min = MIN_INPUT,
    max = MAX_INPUT,
    filter,
    className,
  } = props;
  const { logicRange } = logicRangeStyle;
  const { t } = useTranslation();

  const [value, setValue] = useState([min, max]);

  /**
   * Generates range's marks
   */
  const rangeMarks = useMemo(() => {
    const sliderRange = max - min;

    // Slider marks
    return [
      // min
      {
        value: min,
        label: <span className='first'>{min}</span>,
      },
      // 1/4
      {
        value: sliderRange / 4 + min,
        label: `${Math.round(sliderRange / 4 + min)} `,
      },
      // 1/2
      {
        value: Math.round(sliderRange / 2 + min),
        label: `${Math.round(sliderRange / 2 + min)} `,
      },
      // 3/4
      {
        value: sliderRange * 0.75 + min,
        label: `${Math.round(sliderRange * 0.75 + min)} `,
      },
      // max
      {
        value: max,
        label: <span className='last'>{max}</span>,
      },
    ];
  }, [max, min]);

  /**
   * Handles range slider change value
   * @param data slider data
   */
  const handleSliderChange = (data) => {
    const { value } = data;
    onChange(value);
  };

  /**
   * Init sign and value after reset the filter data
   */
  useEffect(() => {
    setValue(filter?.value || [min, max]);
  }, [filter, max, min]);

  return (
    <Box
      sx={logicRange}
      className={classNames('logic-range-filter', {}, [className])}
    >
      <Typography variant='body2' className='label'>
        {t(label)}
      </Typography>
      <RangeInput
        isRange
        rangeValue={value}
        min={min}
        max={max}
        step={step}
        marks={rangeMarks}
        onSliderChangeCommittedHandler={handleSliderChange}
      />
    </Box>
  );
});
