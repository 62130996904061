import { Box } from '@mui/material';
import { TabPanelStyle } from './style';

export function TabPanel(props) {
  const { children, value, index, className} = props;
  const { tabPanel } = TabPanelStyle;

  return (
    <Box
      component={'div'}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={tabPanel}
      className={className}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
