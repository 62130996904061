import { Box, FormControl, FormHelperText, Typography } from '@mui/material';
import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { userActions } from '../store/actions/user.actions';

import { TextFieldUi } from '../components/UI/TextField/TextField';
import { showToast } from '../utils/toast';
import { dashboardStyle } from './style';
import { ButtonUi } from '../components/UI/Button/Button';

const AccountManagementPage = ({ i18n, changePassword }) => {
  const { changePasswordForm } = dashboardStyle;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [validateNewPassword, setValidateNewPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== validateNewPassword) {
      setError(i18n.t('account-management.no-match'));
      return;
    }
    try {
      const dataToSubmit = { oldPassword, newPassword };
      await changePassword(dataToSubmit);
      showToast(i18n.t('account-management.success'), 'success');
      setOldPassword('');
      setNewPassword('');
      setValidateNewPassword('');
    } catch (exception) {
      setError(i18n.t('account-management.wrong-password'));
    }
  };
  return (
    <Box sx={changePasswordForm} className='organizations-wrapper'>
      <Box className='form-wrapper'>
        <Typography className='form-title'>
          {i18n.t('account-management.title')}
        </Typography>
        {/* <Box className='metadata-wrapper'>
            <p className='title-description' />
          </Box> */}
        <Box component='form' className='validate-form' onSubmit={handleSubmit}>
          <Box className='input-field'>
            <FormControl>
              <TextFieldUi
                className='simple-text-field'
                label={i18n.t('forms.old-password')}
                name='oldPassword'
                type='password'
                value={oldPassword}
                handleChange={(event) => setOldPassword(event.target.value)}
                required
              />
            </FormControl>
          </Box>
          <Box className='input-field'>
            <FormControl>
              <TextFieldUi
                className='simple-text-field'
                label={i18n.t('forms.new-password')}
                name='newPassword'
                type='password'
                value={newPassword}
                handleChange={(event) => setNewPassword(event.target.value)}
                required
              />
            </FormControl>
          </Box>
          <Box className='input-field'>
            <FormControl>
              <TextFieldUi
                className='simple-text-field'
                label={i18n.t('forms.validate-new-password')}
                name='validateNewPassword'
                type='password'
                value={validateNewPassword}
                handleChange={(event) =>
                  setValidateNewPassword(event.target.value)
                }
                required
              />
            </FormControl>
          </Box>
          <FormHelperText error>{error}</FormHelperText>
          <ButtonUi
            className='action-btn accept'
            variant='contained'
            type='primary'
            size='small'
            btnType='submit'
          >
            {i18n.t('buttons.change-password')}
          </ButtonUi>
        </Box>
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = {
  changePassword: userActions.changePassword,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AccountManagementPage),
);
