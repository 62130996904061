import BASEURL from "../utils/config";
import axios from "axios";
import {authUrl} from "../utils/auth-header";

const baseURL = `${BASEURL}/AdminConfigs`;

const getFirstAdminConfig = async () => {
  try {
    const result = await axios.get(`${baseURL}${authUrl()}`, {});
    const adminConfigs = result.data;
    return adminConfigs[0];
  } catch (e) {
    console.error(e);
    return null;
  }
}

const patchAdminConfig = async (id, data = {}) => {
  try {
    await axios.patch(`${baseURL}/${id}${authUrl()}`, data);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export const adminService = {
  getFirstAdminConfig,
  patchAdminConfig
};
