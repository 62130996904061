import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';
import TextField from '@mui/material/TextField';
import PasswordStrengthBar from 'react-password-strength-bar';
import usePasswordResetToken from '../hooks/usePasswordResetToken';
import { userActions } from '../store/actions/user.actions';
import { alertActions } from '../store/actions/alert.actions';
import { dashboardStyle } from './style';

const PasswordReset = ({ i18n, resetPassword, sendSuccessMessage, history }) => {
  const { login: loginPage } = dashboardStyle;
  const passwordResetToken = usePasswordResetToken();
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');

  const scoreWords = [
    i18n.t('password-reset.weak'),
    i18n.t('password-reset.medium'),
    i18n.t('password-reset.good'),
    i18n.t('password-reset.strong'),
    i18n.t('password-reset.strong'),
  ];
  const shortScoreWord = i18n.t('password-reset.too-short');

  const handleSubmit = async () => {
    setError('');
    if (password !== repeatPassword) {
      setError('password-reset.not-matched');
      return;
    }
    try {
      await resetPassword({ newPassword: password }, passwordResetToken);
      sendSuccessMessage(i18n.t('password-reset.password-successfully-changed'));
      history.push('/login');
    } catch (e) {
      setError('password-reset.something-went-wrong');
    }
  };

  const handlePasswordChange = useCallback(
    (event) => {
      const passwordValue = event.target.value;
      if (isStrongPassword || !passwordValue) {
        setError(null);
      } else {
        setError('password-reset.too-weak');
      }
      setPassword(passwordValue);
    },
    [isStrongPassword],
  );

  const handlePasswordRepeatChange = (event) => {
    setRepeatPassword(event.target.value);
  };

  return (
    <Box sx={loginPage} className='limiter'>
      <Box className='container-login100'>
        <Box className='wrap-login100'>
          <Box component='form' className='login100-form validate-form'>
            <Typography variant='body2' className='login100-form-title'>
              {i18n.t('password-reset.title')}
            </Typography>
            <Box className='input-container'>
              <TextField
                className='simple-text-field'
                label={i18n.t('forms.password')}
                name='username'
                type='password'
                autoFocus
                fullWidth
                required
                value={password}
                onChange={handlePasswordChange}
              />
            </Box>
            {password && (
              <Box style={{ marginTop: '8px' }}>
                <PasswordStrengthBar
                  scoreWords={scoreWords}
                  shortScoreWord={shortScoreWord}
                  onChangeScore={(score) => {
                    const isStrongEnough = score >= 3;
                    setIsStrongPassword(isStrongEnough);
                  }}
                  minLength={8}
                  password={password}
                />
              </Box>
            )}
            <Box style={{ marginTop: 0 }} className='input-container'>
              <TextField
                className='simple-text-field'
                label={i18n.t('forms.repeat-password')}
                name='password'
                type='password'
                fullWidth
                required
                value={repeatPassword}
                onChange={handlePasswordRepeatChange}
              />
            </Box>
            <Box className='container-login100-form-btn'>
              <Box className='wrap-login100-form-btn'>
                <Box className='login100-form-bgbtn' />
                <button
                  disabled={!isStrongPassword}
                  onClick={handleSubmit}
                  type='button'
                  className='login100-form-btn'
                >
                  {i18n.t('buttons.change-password')}
                </button>
              </Box>
            </Box>
            {error && (
              <div style={{ color: 'red', marginTop: 16 }}>{i18n.t(error)}</div>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = {
  resetPassword: userActions.resetPassword,
  sendSuccessMessage: alertActions.sendSuccessMessage,
  sendErrorMessage: alertActions.sendErrorMessage,
};

export default withTranslation ()(connect(mapStateToProps, mapDispatchToProps)(PasswordReset));
