import React from 'react';
import Select from 'react-select';

const CustomDropdown = ({
  selectedValue,
  variable,
  values,
  label,
  handleChange,
}) => {
  const options = values.map((item) => ({ value: item, label: item, variable: variable.name }));
  let value = null;

  if (selectedValue) {
    value = options.find((option) => option.value == selectedValue);
  }

  return (
    <>
      <label className="select-label">{label || label}</label>
      <Select
        className="select-filter"
        options={options}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        onChange={handleChange}
        value={value}
        inputProps={{
          name: 'custom-dropdown',
          id: 'select-custom-dropdown',
        }}
      />
    </>
  );
};
export default CustomDropdown;
