import React, {useState} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {billingService} from '../../../services/billing';
import {showToast} from '../../../utils/toast';

const getPillClassByStatus = (status) => {
  switch (status) {
    case 'active':
      return 'green'
    case 'expired':
      return 'yellow'
    case 'failed':
      return 'red'
    case 'canceled':
    default:
      // Not paid, subscription is canceled.
      return 'gray'
  }
}

const getButtonClassByStatus = (status) => {
  switch (status) {
    case 'active':
      return 'red'
    case 'expired':
      return 'green'
    case 'failed':
      return 'yellow'
    case 'canceled':
    default:
      return 'green'
  }
}

const BillingRow = (
  {
    i18n,
    subscription,
    refreshSubscriptions
  }
) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleManageSubscription = async () => {
    try {
      const response = await billingService.manageSubscription(subscription.id);
      window.location.href = response.data.url;
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleManageClicked = async () => {
    setIsLoading(true);
    await handleManageSubscription();
    setIsLoading(false);
  };

  const { status, name, amount, currency, dueDate, daysLeft } = subscription;

  const isCancelled = status === 'canceled';
  const buttonClass = getButtonClassByStatus(status);
  return (
    <tr>
      <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm'>
        <div className='flex'>
          <div className='ml-3'>
            <p className='text-gray-900 whitespace-no-wrap'>{name}</p>
          </div>
        </div>
      </td>
      <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm'>
        <p className='text-gray-900 whitespace-no-wrap'>{amount}</p>
        <p className='text-gray-600 whitespace-no-wrap'>{currency}</p>
      </td>
      <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm'>
        <div
          className={`relative inline-block px-3 py-1 font-semibold leading-tight text-${getPillClassByStatus(
            status,
          )}-900`}
        >
          <span
            aria-hidden
            className={`absolute inset-0 opacity-50 rounded-full bg-${getPillClassByStatus(
              status,
            )}-200`}
          />
          <span className='relative'>
            {i18n.t(`billing.statuses.${status}`)}
          </span>
        </div>
      </td>
      <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm'>
        {!isCancelled ? (
          <>
            <p className='text-gray-900 whitespace-no-wrap'>{dueDate}</p>
            <p className='text-gray-600 whitespace-no-wrap'>
              {daysLeft ? `${daysLeft} ${i18n.t('billing.days')}` : '-'}
            </p>
          </>
        ) : (
          <p className='text-gray-600 whitespace-no-wrap'>-</p>
        )}
      </td>
      <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm text-right'>
        <button
          type='button'
          className={`min-w-full h-10 px-5 m-2 text-white bg-${buttonClass}-300 rounded-lg focus:shadow-outline hover:bg-${buttonClass}-400`}
          onClick={handleManageClicked}
          disabled={isLoading}
        >
          {i18n.t('billing.buttons.manage')}
        </button>
      </td>
    </tr>
  );
};

function mapStateToProps(state) {
  return {...state};
}

const mapDispatchToProps = {};


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BillingRow));
