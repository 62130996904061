import { memo } from 'react';
import { Box } from '@mui/material';

import { ReactComponent as ColoredLogoIcon } from '../../../shared/assets/icons/logo-colored.svg';

import { classNames } from '../../../utils';
import { loaderStyles } from './style';

export const LogoLoader = memo((props) => {
  const { className } = props;
  const { loader } = loaderStyles;

  return (
    <Box sx={loader}>
      <ColoredLogoIcon className={classNames('loader', {}, [className])} />
    </Box>
  );
});
