import React, { useEffect, useState } from 'react';
import './PaymentUpdate.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { withTranslation  } from 'react-i18next';
import { connect } from 'react-redux';
import { billingService } from '../../services/billing';
import Loader from '../Loader';

const PaymentUpdate = ({ i18n }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const subscriptionId = params.get('session_id');

  const successMessage = () => (
    <>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 130.2 130.2'
      >
        <circle
          className='path circle'
          fill='none'
          stroke='#73AF55'
          strokeWidth='6'
          strokeMiterlimit='10'
          cx='65.1'
          cy='65.1'
          r='62.1'
        />
        <polyline
          className='path check'
          fill='none'
          stroke='#73AF55'
          strokeWidth='6'
          strokeLinecap='round'
          strokeMiterlimit='10'
          points='100.2,40.2 51.5,88.8 29.8,67.5 '
        />
      </svg>
      <p className='success'>{i18n.t('billing.payment-update.success')}</p>
    </>
  );

  const failMessage = () => (
    <>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 130.2 130.2'
      >
        <circle
          className='path circle'
          fill='none'
          stroke='#D06079'
          strokeWidth='6'
          strokeMiterlimit='10'
          cx='65.1'
          cy='65.1'
          r='62.1'
        />
        <line
          className='path line'
          fill='none'
          stroke='#D06079'
          strokeWidth='6'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='34.4'
          y1='37.9'
          x2='95.8'
          y2='92.3'
        />
        <line
          className='path line'
          fill='none'
          stroke='#D06079'
          strokeWidth='6'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='95.8'
          y1='38'
          x2='34.4'
          y2='92.2'
        />
      </svg>
      <p className='error'>{i18n.t('billing.payment-update.failed')}</p>
    </>
  );

  useEffect(() => {
    billingService
      .getSessionBySubscription(subscriptionId)
      .then((response) => {
        const { checkoutSession } = response.data || {};
        if (checkoutSession.payment_status === 'paid') {
          setIsSuccess(true);
          setIsLoading(false);
        } else {
          setIsSuccess(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsSuccess(false);
        setIsLoading(false);
      });
  }, [subscriptionId]);

  return (
    <div className='denied-container'>
      {!isLoading && isSuccess && successMessage()}
      {!isLoading && !isSuccess && failMessage()}
      {isLoading ? (
        <Loader />
      ) : (
        <NavLink
          className='bg-blue-500 hover:bg-blue-700 hover:text-white text-white font-bold py-2 px-4 rounded'
          to='/admin/billing'
        >
          {i18n.t('billing.payment-update.back-to-dashboard')}
        </NavLink>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = {};

export default withTranslation ()(
  connect(mapStateToProps, mapDispatchToProps)(PaymentUpdate),
);
