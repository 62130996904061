import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import usePasswordResetToken from '../hooks/usePasswordResetToken';
import useSsoHint from '../hooks/useSsoHint';

import { userActions } from '../store/actions/user.actions';
import { alertActions } from '../store/actions/alert.actions';
import { userService } from '../services/login';

import Loader from '../components/Loader';
import { dashboardStyle } from './style';

const LoginPage = ({
  authentication: { loggingIn },
  login,
  history,
  location: { search },
  sendSuccessMessage,
  sendErrorMessage,
}) => {
  const idpHint = useSsoHint();
  const passwordResetToken = usePasswordResetToken();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (passwordResetToken) {
      history.push(`/password-reset?password-reset=${passwordResetToken}`);
    }
  }, [history, passwordResetToken]);

  const { t, language } = useTranslation();
  const { login: loginPage } = dashboardStyle;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isTimeout, setIsTimeout] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [passwordRequestSent, setPasswordRequestSent] = useState(false);

  const usernameInput = !forgotPassword
    ? t('forms.username')
    : t('forms.email');

  const forgotPasswordLabel = !forgotPassword
    ? t('password-reset.forgot')
    : t('buttons.login');

  const buttonText = !forgotPassword
    ? t('buttons.login')
    : t('password-reset.send-mail');

  const buttonTextToRender = showPassword ? buttonText : t('buttons.next');

  const handleUserNameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!showPassword) {
      try {
        const { data } = await userService.checkSsoEnabled(username);
        if (data && data.ssoEnabled) {
          history.push(`/login?idp_hint=${data.idpAlias}`);
        } else {
          setShowPassword(true);
        }
      } catch (error) {
        setShowPassword(true);
      }
    }
    if (forgotPassword && username) {
      try {
        await userService.forgotPassword({
          email: username,
          lang: language,
        });
        sendSuccessMessage(t('password-reset.send-email-verification'));
      } catch (e) {
        sendErrorMessage(t('password-reset.something-went-wrong'));
      }
    } else if (username && password) {
      login({ username, password });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const isAuthTimeout = JSON.parse(params.get('isAuthTimeout')) || false;
    setIsTimeout(isAuthTimeout);
    if (isAuthTimeout) {
      window.history.replaceState(null, 'Login', '/login');
    }
  }, [search]);

  return loggingIn || idpHint ? (
    <Box sx={loginPage} className='limiter'>
      <Box className='container-login100'>
        <Loader />
      </Box>
    </Box>
  ) : (
    <Box sx={loginPage} className='limiter'>
      <Box className='container-login100'>
        {isTimeout ? (
          <Box className='timeout-warning-container'>
            <Box className='icon-wrapper'>
              <FontAwesomeIcon icon='info-circle' size='lg' />
              <Typography variant='body2'>
                {t('session_timeout.title')}
              </Typography>
            </Box>
            <Typography variant='body2' className='text-no-icon'>
              {t('session_timeout.description')}
            </Typography>
          </Box>
        ) : null}
        <Box className='wrap-login100'>
          <Box
            component='form'
            className='login100-form validate-form'
            onSubmit={handleSubmit}
            autoComplete='on'
          >
            <Typography className='login100-form-title'>
              Psylaris Dashboard
            </Typography>
            <Box className='input-container'>
              <TextField
                className='simple-text-field'
                label={usernameInput}
                type={forgotPassword ? 'email' : 'text'}
                autoFocus
                name='username'
                fullWidth
                required
                value={username}
                InputLabelProps={{ shrink: true }}
                onChange={handleUserNameChange}
              />
            </Box>
            {forgotPassword || !showPassword ? null : (
              <Box className='input-container'>
                <TextField
                  className='simple-text-field'
                  label={t('forms.password')}
                  name='password'
                  type='password'
                  fullWidth
                  value={password}
                  InputLabelProps={{ shrink: true }}
                  onChange={handlePasswordChange}
                />
              </Box>
            )}
            <Box className='container-login100-form-btn'>
              <Box className='wrap-login100-form-btn'>
                <Box className='login100-form-bgbtn' />
                <button type='submit' className='login100-form-btn'>
                  {buttonTextToRender}
                </button>
              </Box>
            </Box>
            <Box className='password-reset-link-container'>
              <button
                type='button'
                onClick={() => {
                  setPasswordRequestSent(false);
                  setForgotPassword((prevState) => !prevState);
                }}
              >
                {forgotPasswordLabel}
              </button>
            </Box>
            {passwordRequestSent && forgotPassword && (
              <Box>{t('password-reset.send-email-verification')}</Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  authentication: state.authentication,
  loggingIn: state.authentication.loggingIn,
});

const mapDispatchToProps = {
  login: userActions.login,
  sendSuccessMessage: alertActions.sendSuccessMessage,
  sendErrorMessage: alertActions.sendErrorMessage,
  logout: userActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
