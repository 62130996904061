import { mobileRatio, theme } from '../../theme';
import { getResponsiveSize } from '../../utils';

export const quickStartStyle = {
  quickStart: {
    py: getResponsiveSize(31.5), // 31.5px
    px: getResponsiveSize(36), // 36px
    '&.loader div': {
      mx: 'auto',
    },
    '& .quick-start-title .title': {
      color: theme.palette.neutral.grey50,
      fontFamily: theme.typography.mainFontFamily,
      mb: getResponsiveSize(16), // 16px
      ...theme.palette.propVars.regularFontWeight,
    },
    '& .emdr-remote': {
      order: 1,
    },
    '& .emdr-vr': {
      order: 2,
    },
    '& .emdr-plus': {
      order: 3,
    },
    '& .session-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      '& .img': {
        height: getResponsiveSize(250), // 250px
      },
      '& .MuiButtonBase-root': {
        mt: getResponsiveSize(16), // 16px
        '& svg path': {
          fill: theme.palette.myPrimary.white,
        },
      },
    },
    // Table simple responsive screen
    [theme.breakpoints.media.tableResponsive]: {
      gap: getResponsiveSize(16, mobileRatio), // 16px
    },
  },
};
