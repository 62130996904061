export const teamConstants = {
  GETALL_REQUEST: 'TEAMS_GETALL_REQUEST',
  GETALL_SUCCESS: 'TEAMS_GETALL_SUCCESS',
  GETALL_FAILURE: 'TEAMS_GETALL_FAILURE',

  COUNT_REQUEST: 'TEAMS_COUNT_REQUEST',
  COUNT_SUCCESS: 'TEAMS_COUNT_SUCCESS',
  COUNT_FAILURE: 'TEAMS_COUNT_FAILURE',

  GET_REQUEST: 'TEAM_GET_REQUEST',
  GET_SUCCESS: 'TEAM_GET_SUCCESS',
  GET_FAILURE: 'TEAM_GET_FAILURE',

  DELETE_REQUEST: 'TEAMS_DELETE_REQUEST',
  DELETE_SUCCESS: 'TEAMS_DELETE_SUCCESS',
  DELETE_FAILURE: 'TEAMS_DELETE_FAILURE',

  CREATE_REQUEST: 'CREATE_TEAM_REQUEST',
  CREATE_SUCCESS: 'CREATE_TEAM_SUCCESS',
  CREATE_FAILURE: 'CREATE_TEAM_FAILURE',

  UPDATE_REQUEST: 'UPDATE_TEAM_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_TEAM_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_TEAM_FAILURE',

  UPDATE_REL_REQUEST: 'UPDATE_TEAM_RELATION_REQUEST',
  UPDATE_REL_SUCCESS: 'UPDATE_TEAM_RELATION_SUCCESS',
  UPDATE_REL_FAILURE: 'UPDATE_TEAM_RELATION_FAILURE',

  CLEAR_TEAMS: 'CLEAR_TEAMS',
};
