import axios from 'axios';
import BASEURL from '../utils/config';
import { authUrl } from '../utils/auth-header';

const baseURL = `${BASEURL}`;

const getMySubscriptions = () => axios.get(`${baseURL}/subscriptions/me${authUrl()}`, {});
const getSubscriptionById = (subscriptionId) =>
  axios.get(`${baseURL}/subscriptions/${subscriptionId}${authUrl()}`, {});

const getSessionBySubscription = (sessionId) =>
  axios.get(`${baseURL}/subscriptions/${sessionId}/session${authUrl()}`, {});

const getLicensePlans = () => {
  const defaultOrder = { order: 'rank ASC', where: { hidden: false } };
  return axios.get(
    `${baseURL}/license-plans${authUrl()}&filter=${JSON.stringify(defaultOrder)}`,
    {},
  );
};

const createSubscription = (body) =>
  axios.post(`${baseURL}/subscriptions/create-custom-subscription${authUrl()}`, body);

const manageSubscription = (subscriptionId) =>
  axios.get(`${baseURL}/subscriptions/${subscriptionId}/manage${authUrl()}`);

export const billingService = {
  getMySubscriptions,
  getLicensePlans,
  createSubscription,
  getSubscriptionById,
  manageSubscription,
  getSessionBySubscription,
};
